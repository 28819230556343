import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { OnboardingService } from "@app/onboarding/onboarding.service";
import { WorkflowTemplateService } from "@app/workflow-template/workflow-template.service";
import { QuestionAddDialogComponent } from "@app/masterdata/question-add-dialog/question-add-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { DatabaseService } from "../database.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { UsePagination } from "@app/core/classes/use-pagination";
import { FormControl } from "@angular/forms";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  switchMap,
  tap,
} from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Helper } from "@app/core/classes/helper";
import { QUESTION_STATES_ObJ } from "@app/resources/question-state";
import { ConfirmationDialogComponent } from "@app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ReqHistoryComponent } from "@app/masterdata/req-history/req-history.component";

@Component({
  selector: "app-db-ava",
  templateUrl: "./db-ava.component.html",
  styleUrls: ["./db-ava.component.scss"],
})
export class DbAvaComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  spinner: boolean = true;
  isEmpty: boolean = true;
  Pagination: any = {
    length: 0,
    pageIndex: 0,
    pageSize: 5,
    previousPageIndex: 0,
  };
  questionTypes: any;
  selectedType: string = "";
  selectedCat: string = "";
  questionCategories: any;
  filteredQuestions: any = [];
  approvedQuestions: any = [];
  selectedTab: number = 0;
  state: string = QUESTION_STATES_ObJ["APPROVED"];
  constructor(
    private onboardingService: OnboardingService,
    private workflowTemplateService: WorkflowTemplateService,
    private matDialog: MatDialog,
    private databaseService: DatabaseService,
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private snackbar: MatSnackBar
  ) {}
  dataSource: any = [];
  displayedColumns: string[] = [
    "activity",
    "type",
    "category",
    "state",
    "action",
  ];
  categoryList: any = [];
  templateList: any = [];
  dialog: any;
  totalLength: number = 0;
  questionPagination = new UsePagination({
    page: 0,
    perPage: 100,
    total: this.totalLength,
  });

  showOptions: boolean = false;

  selectedTemplate: any;
  templateData: any;
  selectedCategory: any;

  selected: any = "";
  searchCtrl = new FormControl();
  filtered: any;
  isLoading = false;
  errorMsg!: string;
  minLengthTerm = 3;

  ngOnInit(): void {
    this.tabChange(0);
    this.getType();
    this.getCategories();
    this.getTemplateData();
  }

  getType() {
    this.databaseService.getDropDown("ACTIVITY", "ava", "type").subscribe({
      next: (res: any) => {
        if (res.length > 0) this.questionTypes = res.map((x: any) => x.name);
      },
      error: (error: any) => {
        // console.log("type dropdown", error);
      },
    });
  }
  getCategories() {
    this.databaseService.getDropDown("ACTIVITY", "ava").subscribe({
      next: (res: any) => {
        if (res.length > 0)
          this.questionCategories = res.map((x: any) => x.name);
      },
      error: (error: any) => {
        // console.log("type dropdown", error);
      },
    });
  }

  ngAfterViewInit() {}

  setStatus(status: string) {
    // Handle the selected status (active or inactive) here
    // console.log(`Selected status: ${status}`);
  }

  getApprovedQuestions() {
    // console.log(this.questionPagination);
    this.databaseService
      .getRefData(
        this.questionPagination.perPage,
        this.questionPagination.page,
        "AVA",
        this.state
      )
      .subscribe({
        next: (res: any) => {
          this.spinner = false;
          // console.log("approved ava question", res);
          this.approvedQuestions = [];
          if (res.data.length == 0) {
            this.isEmpty = true;
            this.dataSource = [];
          } else {
            // this.approvedQuestions = res.data
            // if(this.state == 'APPROVED'){
            //   this.dataSource = res.data.filter((x:any)=>  x.module == 'AVA')
            //   console.log('dataSource',this.dataSource);

            // }else{
            //   this.dataSource = res.data.filter((x:any)=> x.state == 'DRAFT')
            // }
            // this.approvedQuestions = res.data.filter((x:any)=>  x.module == 'AVA')
            this.dataSource = res.data;
            this.approvedQuestions = this.dataSource;
            this.totalLength = res.metadata[0].total;

            // console.log("type", this.questionTypes);

            this.isEmpty = false;
          }
        },
        error: (error: any) => {
          this.spinner = false;
          // console.log(error);
        },
      });
  }

  openDialog(type: string, element: any) {
    
    if(typeof element === 'object') {
      element.comment = "";
    }
    this.dialog = this.matDialog.open(QuestionAddDialogComponent, {
      width: "70em",
      disableClose: true,
      data: {
        from: type == "create" ? "AVA-Ref-Create" : "AVA-Ref-Edit",
        category: this.questionCategories,
        type: this.questionTypes,
        question: element,
        state: this.state,
      },
    });

    this.dialog.afterClosed().subscribe((res: any) => {
      if (
        (res && res?.event && res?.event == "avaQusCreate") ||
        res?.event == "avaQusEdit" ||
        res?.event == "avaDraft"
      ) {
        this.getApprovedQuestions();
      }
    });
  }

  deletePreview(element: any) {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: "40%",
      height: "auto",
      disableClose: false,
      data: {
        message: "Are you sure want to Deactivate the Question ?",
        buttonText: {
          ok: "Delete",
          cancel: "Cancel",
        },
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      // console.log("res", res, element);
      if (res) {
        let payload = { ...element };
        delete payload._id;
        delete payload.state;
        delete payload.updatedUser;
        payload.module = "AVA";
        payload.isActive = false;
        payload.oldState = QUESTION_STATES_ObJ["APPROVED"];
        payload.newState = QUESTION_STATES_ObJ["SUBMITTED"];

        this.databaseService.editRefData(element._id, payload).subscribe({
          next: (res: any) => {
            this.snackbar.open("Question Deactivated Successfully", "close");
            this.getApprovedQuestions();
          },
          error: (error: any) => {
            // console.log(error);
          },
        });
      }
    });
  }

  tabChange(tabIndex: any) {
    this.spinner = true;
    // console.log("tab", tabIndex);
    this.selectedTab = tabIndex;
    if (tabIndex == 0) {
      this.state = QUESTION_STATES_ObJ["APPROVED"];
    } else {
      this.state = QUESTION_STATES_ObJ["DRAFT"];
    }
    // this.getApprovedQuestions();
    this.clearFilters();
  }

  clearFilters() {
    this.selectedType = "";
    this.selectedCat = "";
    this.selectedTemplate = "";
    this.spinner = true;
    this.getApprovedQuestions();
    //  this.dataSource = this.approvedQuestions
  }

  getCategoryData() {
    this.onboardingService.getModuleData("CATEGORY").subscribe(
      (response) => {
        this.categoryList = response.filter(
          (category: any) => category.isParent
        ); /* Here display only the parent categories. */
      },
      (error) => {
        // console.log("Error on get Category list: ", error);
      }
    );
  }

  getTemplateData() {
    this.databaseService.getTemplateByModule("AVA").subscribe(
      (response) => {
        this.templateList = response["data"];
      },
      (error) => {
        // console.log("Error on get template list: ", error);
      }
    );
  }

  getTemplateByQuery() {
    this.databaseService
      .getTemplateByQuery(this.selectedTemplate, "AVA")
      .subscribe((res: any) => {
        this.dataSource = res["data"][0].questions;
      });
  }

  onTemplateSelect(selectedEvent: any) {
    this.selectedTemplate = selectedEvent.value;

    let res = this.templateList.findIndex(
      (template: any) => template.templateName === selectedEvent.value
    );
    // console.log(this.templateList[res]);
    this.dataSource = this.templateList[res].questions;
    this.approvedQuestions = this.dataSource;

    this.selectedType = "";
    this.selectedCat = "";
    //this.getTemplateByQuery();
  }

  onFilterChange(event: any, type: string) {
    //this.getApprovedQuestions();

    if (type == "types") {
      this.selectedType = event;
      //    if(this.filteredQuestions.length > 0){
      //     this.filteredQuestions = this.filteredQuestions.filter((x:any)=> x.type == event)
      //   }else{
      //   this.filteredQuestions = this.approvedQuestions.filter((x:any)=> x.type == event)
      // }
      this.filteredQuestions = this.approvedQuestions.filter(
        (x: any) => x.type == event
      );
      this.dataSource = this.filteredQuestions;
      this.cdr.markForCheck();
    }
    if (type == "category") {
      this.selectedCat = event;
      if (this.filteredQuestions.length > 0) {
        this.filteredQuestions = this.filteredQuestions.filter(
          (x: any) => x.category == event
        );
      } else {
        this.filteredQuestions = this.approvedQuestions.filter(
          (x: any) => x.category == event
        );
      }
      this.dataSource = this.filteredQuestions;
      this.cdr.markForCheck();
    }
  }

  getPageEvent(pageData: PageEvent) {
    this.spinner = true;
    // this.Pagination = pageData
    // console.log("pageData", pageData);
    this.questionPagination.doPagination(pageData);
    setTimeout(() => {
      this.getApprovedQuestions();
    }, 2000);
  }

  clearSelection() {
    this.selected = "";
    this.filtered = [];
  }

  onSelected() {
    this.selected = this.selected.question;
  }

  displayWith(value: any) {
    return value?.Title;
  }

  serachQuestion() {
    this.searchCtrl.valueChanges
      .pipe(
        filter((res) => {
          return res !== null && res.length >= this.minLengthTerm;
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.errorMsg = "";
          this.filtered = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.http.get("http://localhost:3000/refData?text=" + value).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data: any) => {
        this.filtered = data.data;
      });
  }

  openHistory(data: any) {
    this.databaseService.getQuestionHistory(data._id).subscribe(
      (res) => {
        let obj: any = {
          question: data.question,
          status: data.state,
          comments: data.comment,
        };
        let result: any = [];
        result.push(obj);
        for (let r of res) {
          let newObj: any = {
            question: r.question,
            status: r.state,
            comments: r.comment,
          };
          result.push(newObj);
        }
        let dialog = this.matDialog.open(ReqHistoryComponent, {
          width: "95em",
          // height: '750px',
          disableClose: false,
          data: result,
        });
      },
      (error) => {
        // console.log(error);
      }
    );
  }
}
