import { ChangeDetectionStrategy, Component, OnInit,Inject, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OnboardingService } from '@app/onboarding/onboarding.service';
import { SurveyService } from '@app/survey/survey.service';
import { ManagerInterviewService } from '../../manager-interview.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Helper } from '@app/core/classes/helper';
import { DatabaseService } from '@app/database/database.service';
import { WorkflowTemplateService } from '@app/workflow-template/workflow-template.service';
import { CustomService } from '@app/core/services/custom.service';
import { getSystemMap, getLensMap } from '@app/projects-v2/util/util';
@Component({
  selector: 'app-interview-popup',
  templateUrl: './interview-popup.component.html',
  styleUrls: ['./interview-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InterviewPopupComponent implements OnInit {
 
   interviewCreateForm  = new FormGroup({
    interviewName : new FormControl('', Validators.required),
    teamName : new FormControl('', Validators.required),
    clientFunction : new FormControl('', Validators.required),
    role : new FormControl('', Validators.required),
    scheduledDate : new FormControl('', Validators.required),
    interviewer : new FormControl('', Validators.required),
   })

   questionCreateForm = new FormGroup({
      template : new FormControl('',Validators.required),
      category : new FormControl('',Validators.required),
      subCategory : new FormControl('',Validators.required),
      lens : new FormControl('',Validators.required),
      question : new FormControl('',Validators.required),
   })
  clientId: any;
  clientDetails: any;
  role = ['Delivery Manager', 'Manager', 'Member']
  teams: any;
  functions: any;
  owners: any;
  action: any;
  spinner:boolean = false
  editData: any;
  templates: any;
  isDefaultTemplate: boolean = true
  isNewTemplate: boolean = false
  categories:any
  subCategories:any
  lens:any
  selectedTemp: any;
  defaultQuestions: any;
  invalidTemplate: boolean = false
  questionToEdit: any;
  todayDate = new Date();
  selectedCategory: any;
  selectedFunction: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data:any,
    private cdr:ChangeDetectorRef,
    private onboardservice : OnboardingService,
    private suveyService : SurveyService,
    public dialogRef : MatDialogRef<InterviewPopupComponent>,
    private interviewService : ManagerInterviewService,
    private snackbar : MatSnackBar,
    private databaseService : DatabaseService,
    private workflowTemplateService: WorkflowTemplateService,
    private customService: CustomService,
  ) { 
    
  }

  
  ngOnInit(): void {
    this.opMaturityMapping();
    console.log(this.data);
    this.action =  this.data.action
    this.clientId = this.data.clientId
   
    this.selectedTemp = this.data.templateSelected
    this.isDefaultTemplate = (this.selectedTemp == "default") ? true : false
    this.getCategoriesByClient();
    this.getClientDetails()

    if(this.action == 'createQuestion'){
       this.templates = this.data.templateData
       console.log('templates',this.templates);
       this.isDefaultTemplate = (this.selectedTemp == "default") ? true : false
       if(this.selectedTemp == "default"){
        this.isDefaultTemplate = true
       }else{
        this.isDefaultTemplate = false
            this.currentTemplate()
            
       }
       this.cdr.markForCheck()
      //  this.questionCreateForm.patchValue({
      //   template : this.templates.templateName
      //  })
      
       this.getTemplates()
       return
    }else if(this.action == 'editQuestion'){
      this.questionToEdit = this.data.question
      this.templates = this.data.templateData
      console.log('patch values', this.questionToEdit, this.categories)
      //  this.patchQuestion()
      this.getTemplates()
      return
    }
    this.getOwners()
    
  }

  opMaturityMapData:any;
  public opMaturityMapping(){
    this.databaseService.getOpMaturyMapping(sessionStorage.getItem("clientId") || "").subscribe((res) => {
      console.log(res,'Data res<>');
      this.opMaturityMapData = res;
    })
  }

  getCategoriesByClient(){
    this.databaseService.getOpMaturyMapping(sessionStorage.getItem("clientId") || "").subscribe((res) => {
      console.log(res,'Data res<>');
      this.opMaturityMapData = res;
      if(this.opMaturityMapData.length === 0){
        this.getCategories();
      }
      else{
        this.categories = getLensMap(res[0]); 
        if(this.action == 'editQuestion'){
          this.selectedCategory = this.categories.find((categoryDetail: any) => categoryDetail.category == this.questionToEdit.category);
          this.lens = this.selectedCategory.lens.find((lensDetail: any) => lensDetail.subCategory == this.questionToEdit.subCategory).lens;
          this.patchQuestion();
        }
      }
    })
  }

  getCategories(){
   
    this.databaseService.getDropDown('CATEGORY','managerInterview').subscribe({
      next:(res:any)=>{ if(res.length > 0){
        this.categories = res, console.log('categories',this.categories,  )
        if(this.action == 'editQuestion'){

          this.selectedCategory = this.categories.find((categoryDetail: any) => categoryDetail.category == this.questionToEdit.category);
          this.lens = this.selectedCategory.lens.find((lensDetail: any) => lensDetail.subCategory == this.questionToEdit.subCategory).lens;
          this.patchQuestion()
        }
      }
    },
      error:(error:any)=>{console.log('type dropdown',error, );}
    })
  }

  patchQuestion(){
    let selectedCatIndex :number = 0
    let selectedSubCatIndex :number = 0
     this.categories.forEach( (x:any, i:any)=>{
      if(x.category == this.questionToEdit.category){
        selectedCatIndex = i
        return this.selectedCategory = x
      }
   } )
   this.questionCreateForm.patchValue({
      template: this.templates.templateName,
      category: this.categories[selectedCatIndex],
      subCategory: this.questionToEdit.subCategory,
      lens: this.questionToEdit.lens,
      question: this.questionToEdit.question
    })
    this.questionCreateForm.controls.template.disable()
  }

  onFilterChange(value:any,type:any){
    this.selectedCategory = value;
    this.lens = [];
  }

  onChangeSubCategory(event:any){
    this.lens = this.selectedCategory.lens.find((lensDetail: any) => lensDetail.subCategory == event).lens;
  }

  currentTemplate(){
    this.questionCreateForm.patchValue({ template : this.templates.templateName })
    this.questionCreateForm.controls.template.disable()
    this.invalidTemplate = false
    this.cdr.markForCheck

    let payload: any = this.questionCreateForm.value;

    this.customService.checkQuestion("managerInterviews", [payload.question]).subscribe((response): any =>{

      if(response){
        this.snackbar.open("Question Already Exist", 'close');
        return false;
      }

      let updateData: any = {
        module: "managerInterviews", 
        question: payload["question"], 
        lens: payload["lens"], 
        category: payload["category"]['category'],
        subCategory: payload["subCategory"],
        min : 1,
        max : 5,
        questionState : 'SUBMITTED',
      }
      if(this.questionCreateForm.valid) this.saveQuestion(updateData)
    });

  }

  editTemplate(){
    this.questionCreateForm.markAllAsTouched()
    const {emptyFields} = Helper.getEmptyFields(this.questionCreateForm)
 
    if( this.questionCreateForm.valid &&  emptyFields.length == 0 ){

      let question: any = this.questionCreateForm.value.question;
      this.customService.checkQuestion("managerInterviews", [question], this.data.question._id).subscribe((response): any =>{

        if(response){
          this.snackbar.open("Question Already Exist", 'close');
          return false;
        } else{

          this.dialogRef.close({event:'editQuestion', data: this.questionCreateForm.value})
        }
      });
    }
  }

  newTemplate(){

    this.questionCreateForm.markAllAsTouched()
    const { emptyFields } = Helper.getEmptyFields(this.questionCreateForm);

    this.invalidTemplate = false
    if(this.questionCreateForm.controls.template.value == this.selectedTemp ){
      this.questionCreateForm.patchValue({ template : '' })
      this.questionCreateForm.controls.template.enable();

    }else if(this.questionCreateForm.valid && emptyFields.length == 0){
      let qus:any, allQus:any
      qus = this.questionCreateForm.value
      if (this.opMaturityMapData > 0) {
        let mapping: any = qus;
        let mappedResults: any = getSystemMap(this.opMaturityMapData[0], { category: mapping.category, subCategory: mapping.subCategory, lens: mapping.lens })

        mapping.category = mappedResults.category;
        mapping.subCategory = mappedResults.subCategory;
        mapping.lens = mappedResults.lens;
        qus = mapping;
      }
      console.log('question mi',qus)

      this.customService.checkQuestion("managerInterviews", [qus.question]).subscribe((response): any =>{

        if(response){
          this.snackbar.open("Question Already Exist", 'close');
          return false;
        }

        let refDataPayload:any = {
          question: qus.question,
          min: 1,
          max: 5,
          category: qus.category.category,
          subCategory: qus.subCategory,
          lens: qus.lens,
          module: 'managerInterviews',
          questionState: 'SUBMITTED'
        }
        /*  */
  
        let template :any = this.questionCreateForm.controls.template.value
        let templatePayload: any = {
          module: refDataPayload['module'],
          templateName: template,
          questions: [
            refDataPayload
          ]
        }
  
        this.workflowTemplateService.createTemplate(templatePayload).subscribe({
          next: (response) => {
    
            this.snackbar.open("Template Created and Question Sent for Approval Successfully", 'close');
            this.dialogRef.close();
          },
          error: (err) => {
            console.log(`Create template Error:`);
            console.log(err);
            console.log(err.ok);
            if(!err.ok){
              this.invalidTemplate = true;
            }
            let message = err.error.message;
            this.snackbar.open(message, 'close');
          },
          complete: () => {},
        });
        /*  */
      });


    }else{
      this.questionCreateForm.markAllAsTouched();
    }
  
  }

  functionSelection(selection:any){
    console.log(selection,'function',this.interviewCreateForm.controls.clientFunction.value);
    this.interviewCreateForm.controls.teamName.setValue('')
    this.selectedFunction = selection
 }

 getClientDetails(){
   this.onboardservice.getOrganisationByClientId(this.clientId).subscribe({
     next:(res:any)=>{
       console.log('client res',res);
       this.clientDetails = res
       // this.functions = res
       // this.teams = res.map((x:any) => x.teams[0]?.teamName)
       // let functions = res.map((x:any) => x.functionName)
       this.functions = this.removeDuplicates(res)
       console.log('team',this.teams);
       console.log('functions',this.functions);
       if(this.action == 'edit'){
         this.editData = this.data.editData
         this.patchValue()
       }
     },
     error:(error:any)=>{
       console.log('client', error);
     }
   })
 }

  getOwners(){
    this.suveyService.getSelectedOwner(this.clientId).subscribe({
      next:(res:any)=>{
 this.owners = res[0]['authors']
 console.log('owners',this.owners);
},
error:(error:any)=>{
  console.log('owner error',error);
}
    })
  }

  patchValue(){
    // interviewName : new FormControl('', Validators.required),
    // teamName : new FormControl('', Validators.required),
    // clientFunction : new FormControl('', Validators.required),
    // role : new FormControl('', Validators.required),
    // scheduledDate : new FormControl('', Validators.required),
    // interviewer : new FormControl('', Validators.required),

    const startDate = this.editData.scheduledDate
    .match(/([^T]+)/)[0]
    .split("-")
    .reverse()
    .join("/");

    let selectedFunctionIndex:number = 0
    this.functions.forEach((x:any,i:number) => {
      if(x.functionName == this.editData.clientFunction){
        selectedFunctionIndex = i
        this.selectedFunction = x
        console.log('ass',selectedFunctionIndex,this.selectedFunction);
      }
    })

    this.interviewCreateForm.patchValue({
      interviewName : this.editData.interviewName,
      teamName : this.editData.teamName,
      clientFunction : this.functions[selectedFunctionIndex],
      role : this.editData.role,
      scheduledDate : this.editData.scheduledDate,
      interviewer : this.editData.interviewer,
    })
    this.cdr.markForCheck()
  }

  save(type:any){
    console.log('type',type);
    this.interviewCreateForm.markAllAsTouched()
    if(this.interviewCreateForm.valid){
      let payload:any  = {
        ...this.interviewCreateForm.value
      }
      payload.clientId = this.clientId;
      payload.clientFunction = payload.clientFunction.functionName;
      console.log('payload',payload);
      if(type == 'create'){
        this.spinner = true
        this.interviewService.createInterview(payload).subscribe({
          next:(res:any)=>{
            console.log('interview Create',res,  res.status);
            this.spinner = false
            // if(res.status == 200 > 300 ){
            this.snackbar.open('Interview Created Successfully', 'close')
            this.dialogRef.close({event : 'createSuccess'})
            // }
          },
          error:(error:any)=>{
            this.spinner = false
            if(error.error.message){
              this.snackbar.open(
                error.error.message,
                "close",
                {
                  duration: 3500,
                  horizontalPosition: "end",
                  verticalPosition: "top",
                }
              );
            } else{
              console.log(`Create interview Error: ${error.error.message}`);
              this.dialogRef.close({event : 'createError'})
            }

          }
        })
      }
      else if (type == 'update'){
        this.spinner = true
        this.interviewService.editInterview(this.editData['_id'],payload).subscribe({
          next:(res:any)=>{
            console.log('interview Edit',res,  res.status);
            this.spinner = false
            this.snackbar.open('Interview Edited Successfully', 'close')
            this.dialogRef.close({event : 'editSuccess'})
          },
          error:(error:any)=>{
            this.spinner = false
            if(error.error.message){
              this.snackbar.open(
                error.error.message,
                "close",
                {
                  duration: 3500,
                  horizontalPosition: "end",
                  verticalPosition: "top",
                }
              );
            } else{
              console.log(`Update interview Error: ${error.error.message}`);
              this.dialogRef.close({event : 'editError'})
            }
          }
        })
      }
      
    }

  }



  getTemplates(){
    this.interviewService.getTemplatesByModule('managerInterviews').subscribe({
      next:(res:any)=>{
      console.log('temp',res);
      let templates:any = [...res.data]
      this.defaultQuestions = templates[0].questions
      //  let categories = this.defaultQuestions.map((x:any) => x.category )
      //        let subCategories = this.defaultQuestions.map((x:any) => x.subCategory )
      //        let lens = this.defaultQuestions.map((x:any) => x.lens )
      //        this.categories = this.removeDulpicates(categories)
      //        this.subCategories = this.removeDulpicates(subCategories)
      //        this.lens = this.removeDulpicates(lens)
      this.cdr.markForCheck()
      console.log('temp',templates);
      },
      error:(error:any)=>{
        console.log('templates',error);
        
      }
    })
  }

  removeDuplicates(element:any){
    let array:[] = element.reduce((acc: any[], current: any) => {
         if(!acc.includes(current)) acc.push(current)
         return acc
      }, [])
      return array
     }

       
  saveQuestion(refDataPayload: any = {}){
    this.questionCreateForm.markAllAsTouched();
    const { emptyFields } = Helper.getEmptyFields(this.questionCreateForm);

    if(this.questionCreateForm.valid && emptyFields.length == 0){
      if(this.action == 'createQuestion'){
        console.log('create question payload', this.questionCreateForm.value);
        // this.dialogRef.close({event : 'existingTemplate', data : this.questionCreateForm.value})

        /*  */
        let template :any =  this.templates.templateName
        let payload: any = {
          module: refDataPayload.module,
          templateName: template,
          questions: [
            refDataPayload
          ]
        }
        console.log('temp create payload',payload)
        let templateId: string = this.templates['_id'];
        this.workflowTemplateService.updateTemplate(templateId, payload)
        .subscribe((updateTemplateRes) => {

          console.log('---- Template Update Response ----', updateTemplateRes);
            
            this.snackbar.open("Question Sent for Approval Successfully", 'close');
            this.dialogRef.close();
        });
        /*  */


      }
      else if(this.action == 'editQuestion'){
        console.log('edit question payload', this.questionCreateForm.value);
        this.dialogRef.close({event : 'editQuestion', data : this.questionCreateForm.value})
      }
        
    }
  }

}




// next:(res:any)=>{

// },
// error:(error:any)=>{
  
// }