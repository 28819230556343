import { Component, OnInit } from "@angular/core";
import { MasterDataService } from "../master-data.service";
import { UsePagination } from "@app/core/classes/use-pagination";
import { FormControl } from "@angular/forms";
import { PageEvent } from "@angular/material/paginator";
import { MatSnackBar } from "@angular/material/snack-bar";
import { OnboardingService } from "@app/onboarding/onboarding.service";
import { QUESTION_STATES_ObJ, Modules } from "@app/resources/question-state";
import { ConfirmationDialogComponent } from "@app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DatabaseService } from "@app/database/database.service";
@Component({
  selector: "app-approval",
  templateUrl: "./approval.component.html",
  styleUrls: ["./approval.component.scss"],
})
export class ApprovalComponent implements OnInit {
  dataSource: any = [];
  displayedColumns: string[] = [
    "category",
    "module",
    "state",
    "question",
    "action",
  ];
  masterDataList: any = [];
  totalLength = 0;
  clientsPagination = new UsePagination({
    page: 0,
    perPage: 100,
    total: this.totalLength,
  });
  search = new FormControl("");
  searchText: string = "";
  searchModule = new FormControl("");
  selectedModuleName: string = "";
  searchCategory = new FormControl("");
  selectedCategoryName: string = "";
  moduleList: any = [];
  categoryList: any = [];
  questionsHistory: any = [];

  constructor(
    private masterDataService: MasterDataService,
    private snackBar: MatSnackBar,
    private onboardingService: OnboardingService,
    private matDialog: MatDialog,
    private databaseService: DatabaseService
  ) {}

  ngOnInit(): void {
    this.getWaitingToApproveMasterData();
    this.getModuleData();
    this.getCategoryData();
    this.getQuestionHistory();
    sessionStorage.setItem("CLIENTID", "empty");
  }

  getWaitingToApproveMasterData() {
    this.masterDataService
      .getMasterData(
        this.clientsPagination.page,
        this.clientsPagination.perPage,
        this.searchText,
        this.selectedModuleName,
        this.selectedCategoryName,
        QUESTION_STATES_ObJ["SUBMITTED"] /* Need to cover dynamically. */
      )
      .subscribe(
        (response: any) => {
          this.masterDataList = response.data;
          this.dataSource = response.data;
          this.totalLength =
            response.metadata && response.metadata.length
              ? response.metadata[0].total
              : this.totalLength;
        },
        (error: any) => {
          // console.log("-- error Master --", error);

          this.masterDataList = [];
          this.dataSource = [];
          this.totalLength = 0;
        }
      );
  }

  getModuleData() {
    this.onboardingService.getModuleData("REFDATA_MODULE").subscribe(
      (response) => {
        this.moduleList = response;
      },
      (error) => {
        // console.log("Error on get module list: ", error);
      }
    );
  }

  getCategoryData() {
    this.onboardingService.getModuleData("CATEGORY").subscribe(
      (response) => {
        this.categoryList = response;
      },
      (error) => {
        // console.log("Error on get Category list: ", error);
      }
    );
  }

  getPageEvent(pageData: PageEvent) {
    this.clientsPagination.doPagination(pageData);
    this.getWaitingToApproveMasterData();
  }

  onSearchChange(data: any) {
    this.searchText = data.target.value;
    this.getWaitingToApproveMasterData();
  }

  onApproveReference(referenceId: string, comments: string) {
    let questionIndex = this.masterDataList.findIndex(
      (masterDetail: any) => masterDetail._id === referenceId
    );
    if (questionIndex === -1) {
      // console.log(" No reference data found. ");
    }
    let selectedMasterData = this.masterDataList[questionIndex];
    let questionNewState =
      !selectedMasterData.isActive && selectedMasterData.state == "SUBMITTED"
        ? QUESTION_STATES_ObJ["REJECTED"]
        : QUESTION_STATES_ObJ["APPROVED"];

    let newSetData = {
      question: selectedMasterData["question"],
      questionType: selectedMasterData["questionType"],
      subCategory: selectedMasterData["subCategory"],
      lens: selectedMasterData["lens"],
      min: selectedMasterData["min"] || 0,
      max: selectedMasterData["max"] || 0,
      category: selectedMasterData["category"],
      module: selectedMasterData["module"],
      oldState: selectedMasterData["state"],
      // "newState": QUESTION_STATES_ObJ["APPROVED"],
      newState: questionNewState,
      comment:
        comments /* TODO: should get comment from user while approve a question. */,
    };
    // console.log(newSetData);
    // console.log(selectedMasterData);
    this.updateReferenceData(referenceId, newSetData, "approve");
  }

  onRejectReference(referenceId: string, comments: string) {
    let questionIndex = this.masterDataList.findIndex(
      (masterDetail: any) => masterDetail._id === referenceId
    );
    if (questionIndex === -1) {
      // console.log(" No reference data found. ");
    }
    let selectedMasterData = this.masterDataList[questionIndex];
    let questionNewState =
      !selectedMasterData.isActive && selectedMasterData.state == "SUBMITTED"
        ? QUESTION_STATES_ObJ["APPROVED"]
        : QUESTION_STATES_ObJ["REJECTED"];

    let newSetData = {
      question: selectedMasterData["question"],
      questionType: selectedMasterData["questionType"],
      subCategory: selectedMasterData["subCategory"],
      lens: selectedMasterData["lens"],
      min: selectedMasterData["min"] || 0,
      max: selectedMasterData["max"] || 0,
      category: selectedMasterData["category"],
      module: selectedMasterData["module"],
      oldState: selectedMasterData["state"],
      newState: questionNewState,
      comment:
        comments /* TODO: should get comment from user while reject a question. */,
    };
    // console.log(newSetData);
    // console.log(selectedMasterData);
    this.updateReferenceData(referenceId, newSetData, "reject");
  }

  updateReferenceData(referenceId: string, payload: Object = {}, type: string) {
    this.masterDataService.updateMasterData(referenceId, payload).subscribe(
      (response: any) => {
        // console.log("-- Update response Master --", response);

        this.getWaitingToApproveMasterData();

        if (type == "approve")
          this.snackBar.open("Approved Successfully", "close");
        if (type == "reject")
          this.snackBar.open("Rejected Successfully", "close");
      },
      (error: any) => {
        // console.log("-- Update error Master --", error);
      }
    );
  }

  onModuleSelect(selection: any) {
    this.selectedModuleName = selection.value;
    this.getWaitingToApproveMasterData();
  }

  onCategorySelect(selection: any) {
    this.selectedCategoryName = selection.value;
    this.getWaitingToApproveMasterData();
  }

  clearFilters() {
    this.search.patchValue("");
    this.searchModule.patchValue("");
    this.searchCategory.patchValue("");
    this.searchText = "";
    this.selectedModuleName = "";
    this.selectedCategoryName = "";
    this.getWaitingToApproveMasterData();
  }

  confirm(action: string, questionData: any) {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: "40%",
      height: "auto",
      disableClose: false,
      data: {
        from: "approval",
        message: `Are you sure want to ${action} the Question ?`,
        buttonText: {
          ok: action,
          cancel: "Cancel",
        },
      },
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      let questionId = questionData._id;
      // console.log("res", res, res.event, res.data);

      if (res.event == "approval") {
        if (action == "Approve") this.onApproveReference(questionId, res.data);
        if (action == "Reject") this.onRejectReference(questionId, res.data);
      }
    });
  }

  getModuleName(module: string) {
    let allModule: any = Modules;
    const moduleName = Object.keys(allModule).find(
      (key) => allModule[key] === module
    );
    return moduleName;
  }

  getQuestionHistory() {
    this.databaseService.getQuestionHistory().subscribe({
      next: (res: any) => {
        // console.log("== History response ==", res);
        this.questionsHistory = res;
      },
      error: (error: any) => {
        // console.log(error);
      },
    });
  }

  getQuestionState(questionData: any) {
    let editedQuestion = this.questionsHistory.find(
      (history: any) => history.objectId == questionData._id
    );
    let state: string = "Created";

    if (
      questionData.isActive &&
      questionData.state === QUESTION_STATES_ObJ["SUBMITTED"] &&
      editedQuestion
    ) {
      state = "Submitted";
    }

    if (
      !questionData.isActive &&
      questionData.state === QUESTION_STATES_ObJ["SUBMITTED"]
    ) {
      state = "Deleted";
    }
    return state;
  }
}
