import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ProcessInterviewsService } from "@app/projects-v2/process-interviews/process-interviews.service";
import { SurveyService } from "@app/survey/survey.service";

@Component({
  selector: "app-poi-survey-page",
  templateUrl: "./poi-survey-page.component.html",
  styleUrls: ["./poi-survey-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoiSurveyPageComponent implements OnInit {
  preview: any = true;
  stars: number[] = [1, 2, 3, 4, 5];
  timeline: any = [1, 2, 3, 4, 5];
  selected: any = 0;
  isSubmit: boolean = false;
  ClientId: any;
  surveyDetailId: any;
  theSurveyId: any;
  Poi_Id: any;
  poiTemplateId: any;
  interviewID: any;
  processOwners: any;
  isValid: boolean = true;
  toBack: boolean;
  functions: any;
  teams: any;
  selectedTeam: any = "";
  selectedFunction: any = "";
  functionMap: any = new Map();

  //payloads
  createSurveyTemplatePayload: any;
  submitAnswersPayload: any;
  currentDateTime: any;

  constructor(
    private surveyService: SurveyService,
    private processInterviews: ProcessInterviewsService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.ClientId = sessionStorage.getItem("clientId") || "";
    this.surveyDetailId =
      this.route.snapshot.paramMap.get("surveyDetailId") || "";

    this.poiTemplateId = sessionStorage.getItem("POITemplateId") || "";
    let interviewID = sessionStorage.getItem("POIID") || "";
    this.interviewID = interviewID.split(",");

    this.getFunctions();

    if (this.surveyDetailId) {
      this.preview = false;
      this.getSurveyDetails();
    } else {
      this.getTemplate(this.poiTemplateId);
      this.preview = true;
    }

    //setting payload structure
    let futureDay = new Date();
    futureDay.setDate(futureDay.getDate() + 10);
    this.createSurveyTemplatePayload = {
      templates: [
        {
          module: "processOwners",
          templateId: this.poiTemplateId,
        },
      ],
      clientId: this.ClientId,
      startDate: new Date(),
      endDate: futureDay,
      processOwnerIds: this.interviewID,
      module: "processOwners",
    };

    this.submitAnswersPayload = {
      surveyDetailId: this.surveyDetailId,
      dataResponse: this.processOwners,
    };

    //
    //if (this.processOwners.length === this.selected) this.isSubmit = true;
  }

  //Get Data for Survey (Submit)
  getSurveyDetails() {
    this.surveyService
      .getSurveyDetails("surveyDetailId", this.surveyDetailId, this.ClientId)
      .subscribe(
        (res) => {
          console.log("-- Get surveyDetail --", res, res[0].surveyId,"clID",res[0].clientId);
          this.theSurveyId = res[0].surveyId;
          this.Poi_Id = res[0].processOwnerId;
          this.ClientId = res[0].clientId;
          this.getFunctions();
          this.getSurveyTemplate(); // get template ID
          this.cdr.markForCheck();
        },
        (error: any) => {
          console.log("-- surveyDetails Error --", error);
        }
      );
  }

  getSurveyTemplate() {
    this.surveyService.getSurveyById(this.theSurveyId).subscribe(
      (res) => {
        //console.log('!---->>', res.data[0].templates[0].templateId);
        this.poiTemplateId = res.data[0].templates[0].templateId;

        this.getTemplate(this.poiTemplateId);
        this.cdr.markForCheck();
      },
      (error: any) => {
        console.log("--Error --", error);
      }
    );
  }

  getTemplate(id: any) {
    this.surveyService.getPoiTemplate(id).subscribe(
      (res) => {
        //console.log(res);
        //Assign to processOwners
        this.processOwners = res[0]["questions"];

        console.log(this.processOwners.length);
        this.processOwners.forEach((elem: any, i: number) => {
          // let expectedValue = elem.values[2];
          // console.log(expectedValue );

          // elem.values[2].options = this.functions;
          elem.values.forEach((ans: any) => {
            ans.answer = "";

            //To remove empty array options
            if (ans.options) {
              if (ans.options.length === 0) {
                console.log(ans.options.length);
                delete ans.options;
              }
            }
            // elem.values[0].forEach((obj:any,parent:any) => {

            //   if(obj.question === "Function") elem.options = this.functions;
            //   // if(obj.question === "Team")
            //   // this.functions.forEach((obj:any,child:any) => {
            //   //   if(parent === child){

            //   //   }
            //   // })
            // })
          });
        });
        console.log("Mod POI", this.processOwners);
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getFunctions() {
    let functionData: any = [];
    this.teams = [];
    this.surveyService.getFunctions(this.ClientId).subscribe(
      (res) => {
        console.log(res);

        res.forEach((element: any) => {
          if (this.functionMap.has(element.functionName)) {
            let arr = this.functionMap.get(element.functionName);
            arr.push(element.teams[0].teamName);
            this.functionMap.set(element.functionName, arr);
          } else {
            this.functionMap.set(element.functionName, [element.teams[0].teamName]);
          }
        });

        this.functions = [...this.functionMap.keys()];
        console.log(this.functions);
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  getTeams(data: any) {
    this.surveyService.getFunctions(this.ClientId).subscribe(
      (res) => {
        console.log(res);
        res.forEach((ele: any) => {
          if (ele.functionName === data) {
            this.teams = ele.teams;
          }
        });
        console.log(this.teams);
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  dm() {
    this.submit();
  }
  createTemplatePOI() {
    let data = this.createSurveyTemplatePayload;

    this.surveyService.createSurvey(data).subscribe(
      (res) => {
        this.snackBar.open("Survey Created Successfully", "close", {
          duration: 4500,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
        sessionStorage.removeItem("POIID");
        sessionStorage.removeItem("POITemplateId");
        this.router.navigateByUrl(
          "/project/" + this.ClientId + "/process-interviews/responses"
        );
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  submit() {
    let payload = {
      surveyDetailId: this.surveyDetailId,
      dataResponse: this.processOwners,
      clientFunction: this.selectedFunction,
      teamName: this.selectedTeam,
    };
    console.log("POI Payload -->>", payload);
    this.submitTemplatePOI(payload);
  }

  onChange(data: any, selected: any, moduleName: any) {
    console.log(data, selected, moduleName);
    console.log("Modified POI", this.processOwners);
  }

  Check(event: any) {
    this.teams = [];
    this.selectedFunction = event.value;
    this.teams = [...new Set(this.functionMap.get(this.selectedFunction))];
    this.cdr.markForCheck();
  }

  Team(value: any, item: any) {
    console.log(value, item);
    console.log("After team seelction", this.processOwners);
    this.selectedTeam = value;
    this.cdr.markForCheck();
  }

  goBack() {
    this.router.navigateByUrl(
      "/project/" + this.ClientId + "/process-interviews/weightage"
    );
  }

  back(selected: any, data: any, moduleName: any) {
    console.log(data, "data", moduleName, "moduleName --BACK");

    if (moduleName === "Mandatory Quant Questions") {
      alert("Mandatory Quant Questions");
      if (this.toBack) this.selected = this.selected - 2;

      // this.processOwners.forEach((elem: any, i: number) => {
      //     if (elem.subModule === 'Annual Effort Known') {
      //       alert('Annual Effort Known')
      //       console.log(elem,'AEK<<<<<<<<<<');
      //       elem.values.forEach((ans: any) => {
      //         console.log(ans,'<<<<<<<<<<');

      //         if (ans.answer === 'Yes') {
      //         //this.selected = this.selected - 2;
      //         this.cdr.markForCheck();
      //         alert('IN')
      //       }
      //       });
      //     }
      // })
    } else {
      this.selected = this.selected - 1;
    }
    this.cdr.markForCheck();
  }

  nav(getData: any, moduleName: any) {
    this.isValid = true;
    console.log(getData, "getData", moduleName, "moduleName");
    getData.forEach((ele: any) => {
      if (ele.answer.length === 0) {
        this.isValid = false;
        // this.snackBar.open("Please answer all the fields", 'close');
        return;
      }
      // else {
      //   this.isValid = true;
      // }
      if (moduleName === "Decision Point") {
        if (ele.answer === "Yes") this.toBack = true;
        if (ele.answer === "No") this.selected = this.selected + 1;
      }
      if (moduleName === "Annual Effort Known") {
        this.selected = this.selected + 1;
      }
    });
    // if (this.isValid) {

    // }
    this.cdr.markForCheck();
    this.goodToGo(this.isValid);
  }

  goodToGo(bool: boolean) {
    console.log(bool, "Valid-->>>");
    console.log(this.processOwners.length, this.selected, "Matched");
    if (bool) {
      this.selected = this.selected + 1;
      if (this.processOwners.length === this.selected) {
        this.submit();
      }
      this.cdr.markForCheck();
    } else {
      this.snackBar.open("Please answer all the fields", "close");
    }
  }

  submitTemplatePOI(payload: any) {
    this.surveyService.submitMiSurvey(payload).subscribe(
      (res) => {
        //console.log(res);
        // this.snackBar.open("Your Survey is submitted Successfully", 'close',{
        //   duration: 4500,
        //   horizontalPosition: 'center',
        //   verticalPosition: 'top',
        // });
        this.isSubmit = true;
        let Payload: any = {
          status: "Complete",
        };

        this.processInterviews
          .editProcessInterview(this.Poi_Id, Payload)
          .subscribe(
            (res) => {
              console.log(res, "Status completed Success");
            },
            (error) => {
              console.log(error);
            }
          );
        this.cdr.markForCheck();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  close() {
    window.close();
  }

  countStar(question: any, star: any) {
    question.answer = star;
    this.cdr.markForCheck();
    console.log("star", star, question);
  }
}
