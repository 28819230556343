import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { InterviewPopupComponent } from "./interview-popup/interview-popup.component";
import { ManagerInterviewService } from "../manager-interview.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ConfirmationDialogComponent } from "@app/shared/components/confirmation-dialog/confirmation-dialog.component";
import {
  filter,
  distinctUntilChanged,
  debounceTime,
  tap,
  switchMap,
  finalize,
  startWith,
  map,
} from "rxjs";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";

import { DatabaseService } from "@app/database/database.service";

import { Sort } from "@angular/material/sort";
import { BuildFromDatabaseAvaComponent } from "@app/projects-v2/ava-eva/build-from-database-ava/build-from-database-ava.component";
import { WorkflowTemplateService } from "@app/workflow-template/workflow-template.service";
import { SurveyService } from "@app/survey/survey.service";
import { OnboardingService } from "@app/onboarding/onboarding.service";
import { QUESTION_STATES_ObJ } from "@app/resources/question-state";
import { Helper } from "@app/core/classes/helper";
import { getDefinedMap } from "@app/projects-v2/util/util";

@Component({
  selector: "app-interview-setup",
  templateUrl: "./interview-setup.component.html",
  styleUrls: ["./interview-setup.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InterviewSetupComponent implements OnInit {
  step1: boolean = true;
  date = new Date();
  sampleData = [];
  sampleData2 = [];
  datasource1 = new MatTableDataSource<any>(this.sampleData);
  datasource2 = new MatTableDataSource<any>(this.sampleData2);
  dc = [
    "candidate",
    "team",
    "function",
    "team-manager",
    "date",
    "interviewer",
    "template",
    "action",
  ];
  dc2 = ["category", "sub-category", "lens", "question", "action"];
  clientId: any;
  role = ["Delivery Manager", "Manager", "Member"];
  interviews: any = [];
  templates: any = [];
  templateDetails: any;
  isDefaultTemplate: boolean = true;
  SelectedTemplateName: string = "default";
  interviewSelected = new FormControl("", Validators.required);
  templateSelected = new FormControl("");
  //search
  searchCtrl = new FormControl();
  filtered: any;
  isLoading = false;
  errorMsg!: string;
  minLengthTerm = 3;
  selected: any = "";
  selectedTempIndex: number = 0;
  selectedTeam: any = "";

  interviewCreateForm = new FormGroup({
    interviewName: new FormControl("", Validators.required),
    teamName: new FormControl("", Validators.required),
    clientFunction: new FormControl("", Validators.required),
    role: new FormControl("", Validators.required),
    scheduledDate: new FormControl("", Validators.required),
    interviewer: new FormControl("", Validators.required),
  });
  selectedFunction: any;
  editData: any;
  owners: any;
  functions: any;
  teams: any;
  todayDate = new Date();
  type: any = "create";
  selectedInterview: any;
  miDefaultTemplate: any;
  url: any;
  functionMap: any = new Map();

  constructor(
    private activatedroute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private matdialog: MatDialog,
    private interviewService: ManagerInterviewService,
    private workflowTemplateService: WorkflowTemplateService,
    private onboardservice: OnboardingService,
    private surveyService: SurveyService,
    private snackbar: MatSnackBar,
    private http: HttpClient,
    private databaseService: DatabaseService
  ) {
    let url = this.router.url;
    let splitUrl = url.split("/");
    this.clientId = splitUrl[2];
    // console.log("clientId ", this.clientId);
  }

  ngOnInit(): void {
    this.getInterviews();

    const url = window.location.pathname;
    const segments = url.split("/");
    const lastSegment = segments[segments.length - 1];
    // console.log("Last Segment:", lastSegment);
    this.url = lastSegment;
    this.cdr.markForCheck();

    if (lastSegment === "setup2") {
      this.step1 = false;
      this.canClick = true;
      this.steps();
    }
    this.getClientDetails();
    this.getOwners();

    //Set saved/cache data
    if (lastSegment === "setup2") {
      let selected = sessionStorage.getItem("MISelected") || "";
      let retrievedSelec: any = JSON.parse(selected);

      this.selectedInterview = retrievedSelec;
      // console.log(this.selectedInterview, this.miDefaultTemplate);
      this.getTemplates();
    }
  }

  functionSelection(selection: any) {
    // console.log(
    //   selection,
    //   "function",
    //   this.interviewCreateForm.controls.clientFunction.value
    // );
    this.interviewCreateForm.controls.teamName.setValue("");
    this.selectedFunction = selection;
  }

  assignTeam(event: any) {
    // console.log(event);
    this.selectedTeam = event;
    this.cdr.markForCheck();
  }

  assignFunction(event: any) {
    this.teams = [];
    this.selectedFunction = event;
    this.teams = this.functionMap.get(this.selectedFunction);
    this.cdr.markForCheck();
  }

  getClientDetails() {
    this.onboardservice.getOrganisationByClientId(this.clientId).subscribe({
      next: (res: any) => {
        // console.log("client res", res);
        // this.clientDetails = res
        // this.functions = res
        // this.teams = res.map((x:any) => x.teams[0]?.teamName)
        // let functions = res.map((x:any) => x.functionName)

        res.forEach((element: any) => {
          if (this.functionMap.has(element.functionName)) {
            let arr = this.functionMap.get(element.functionName);
            arr.push(element.teams[0].teamName);
            this.functionMap.set(element.functionName, arr);
          } else {
            this.functionMap.set(element.functionName, [
              element.teams[0].teamName,
            ]);
          }
        });

        this.functions = [...this.functionMap.keys()];

        if (this.type == "edit") {
          // this.editData = this.data.editData
          // this.patchValue()
        }
      },
      error: (error: any) => {
        // console.log("client", error);
      },
    });
  }

  getOwners() {
    this.surveyService.getSelectedOwner(this.clientId).subscribe({
      next: (res: any) => {
        this.owners = res[0]["authors"];
        // console.log("owners", this.owners);
      },
      error: (error: any) => {
        // console.log("owner error", error);
      },
    });
  }

  save(type: any) {
    // console.log("type", type);
    this.type = type;
    const { emptyFields } = Helper.getEmptyFields(this.interviewCreateForm);
    this.interviewCreateForm.markAllAsTouched();
    // console.log(
    //   "--",
    //   this.interviewCreateForm,
    //   this.interviewCreateForm.valid,
    //   emptyFields.length
    // );
    if (this.interviewCreateForm.valid) {
      let payload: any = {
        ...this.interviewCreateForm.value,
      };
      payload.clientId = this.clientId;
      payload.clientFunction = payload.clientFunction;
      payload.status = "Created";
      // console.log("payload", payload);
      if (type == "create") {
        // this.spinner = true
        this.interviewService.createInterview(payload).subscribe({
          next: (res: any) => {
            // console.log("interview Create", res, res.status);
            // this.spinner = false
            // if(res.status == 200 > 300 ){
            this.interviewCreateForm.reset();
            this.getInterviews();
            this.snackbar.open("Interview Created Successfully", "close");
            // }
          },
          error: (error: any) => {
            // this.spinner = false
            if (error.error.message) {
              this.snackbar.open(error.error.message, "close", {
                duration: 3500,
                horizontalPosition: "end",
                verticalPosition: "top",
              });
            } else {
              // console.log(`Create interview Error: ${error.error.message}`);
            }
          },
        });
      } else if (type == "update") {
        // this.spinner = true
        this.interviewService
          .editInterview(this.editData["_id"], payload)
          .subscribe({
            next: (res: any) => {
              // console.log("interview Edit", res, res.status);
              // this.spinner = false
              this.interviewCreateForm.reset();
              this.getInterviews();
              this.type = "create";
              this.snackbar.open("Interview Edited Successfully", "close");
            },
            error: (error: any) => {
              // this.spinner = false
              if (error.error.message) {
                this.snackbar.open(error.error.message, "close", {
                  duration: 3500,
                  horizontalPosition: "end",
                  verticalPosition: "top",
                });
              } else {
                // console.log(`Update interview Error: ${error.error.message}`);
              }
            },
          });
      }
    } else {
      this.snackbar.open("Kindly fill all the necessary fields", "close");
    }
  }

  patchValue(data: any) {
    // console.log(data);

    this.editData = data;
    this.type = "update";
    // interviewName : new FormControl('', Validators.required),
    // teamName : new FormControl('', Validators.required),
    // clientFunction : new FormControl('', Validators.required),
    // role : new FormControl('', Validators.required),
    // scheduledDate : new FormControl('', Validators.required),
    // interviewer : new FormControl('', Validators.required),

    const startDate = this.editData.scheduledDate
      .match(/([^T]+)/)[0]
      .split("-")
      .reverse()
      .join("/");

    let selectedFunctionIndex: number = 0;
    this.functions.forEach((x: any, i: number) => {
      if (x.functionName == this.editData.clientFunction) {
        selectedFunctionIndex = i;
        this.selectedFunction = x;
        // console.log("ass", selectedFunctionIndex, this.selectedFunction);
      }
    });
    this.teams = this.functionMap.get(this.editData.clientFunction);
    // console.log(this.editData);

    this.interviewCreateForm.patchValue({
      interviewName: this.editData.interviewName,
      teamName: this.editData.teamName,
      clientFunction: this.editData.clientFunction,
      role: this.editData.role,
      scheduledDate: this.editData.scheduledDate,
      interviewer: this.editData.interviewer,
    });
    this.cdr.markForCheck();
  }

  removeDuplicates(element: any) {
    let array: [] = element.reduce((acc: any[], current: any) => {
      if (!acc.includes(current)) acc.push(current);
      return acc;
    }, []);
    return array;
  }

  steps() {
    // console.log(this.step1, "step");
    this.selectedInterview = [];
    if (!this.canClick) {
      this.snackbar.open("Kindly create a new interview", "close");
      this.step1 = true;
      return;
    }

    if (this.step1) {
      sessionStorage.removeItem("MISelected");
      sessionStorage.removeItem("TemplateMI");
      this.getInterviews();
      this.router.navigateByUrl(
        `/project/${this.clientId}/manager-interview/setup`
      );
    } else {
      this.interviewSelected = new FormControl("", Validators.required);
      this.getInterviews();
      this.getTemplates(true);
      this.cdr.markForCheck();
    }
  }

  removeDulpicates(element: any) {
    let array: [] = element.reduce((acc: any[], current: any) => {
      if (!acc.includes(current)) acc.push(current);
      return acc;
    }, []);
    return array;
  }

  canClick: boolean = false;

  getInterviews() {
    this.interviewService.getInterview(this.clientId).subscribe({
      next: (res: any) => {
        // console.log("get interview", res.body, res.status);
        //  if(res.status == 200 && res.body.length > 0){
        this.datasource1 = res.body;
        this.interviews = res.body.filter(
          (x: { status: string }) => x.status == "Created"
        );
        // console.log(this.interviews.length);

        this.canClick = this.interviews.length > 0 ? true : false;
        // this.interviews = res.body.map((x:any)=> x.interviewName)
        // console.log("interviews", this.interviews);
        // console.log("interviews res", res);

        this.cdr.markForCheck();
        //  }
      },
      error: (error: any) => {
        // console.log("get interview error", error);
      },
    });
  }

  opMaturityMapData:any = [];
  public opMaturityMapping(){
    this.databaseService.getOpMaturyMapping(this.clientId).subscribe((res) => {
      // console.log(res,'Data res<>');
      this.opMaturityMapData = res; //DATA
    })
    return this.opMaturityMapData;
  }

  getTemplates(initialCall: boolean = false) {
    this.interviewService.getTemplatesByModule("managerInterviews").subscribe({
      next: (res: any) => {
        // console.log("temp", res);
        //Mapping

        if (this.opMaturityMapping().length > 0) {
          let changedTemplates = [];

          for (let template of res.data) {
            let changedData: any = { ...template }
            changedData.questions = []
            for (let question of template.questions) {
              let changedQuestion: any = { ...question }
              let modifiedMap = getDefinedMap(this.opMaturityMapData[0], { category: question.category, subCategory: question.subCategory, lens: question.lens })
              changedQuestion.category = modifiedMap.category
              changedQuestion.subCategory = modifiedMap.subCategory
              changedQuestion.lens = modifiedMap.lens

              changedData.questions.push(changedQuestion);
            }
            changedTemplates.push(changedData)
          }
          res.data = [...changedTemplates];
        }

        this.templates = [...res.data];

        if (initialCall) {
          this.selectedTempIndex = this.templates.findIndex(
            (template: any) => template.templateName === "default"
          );
        }

        this.datasource2 = this.templates[this.selectedTempIndex].questions;
        this.templateSelected.setValue(this.templates[this.selectedTempIndex]);
        this.templateDetails = this.templates[this.selectedTempIndex];
        this.isDefaultTemplate =
          this.templateDetails.templateName == "default" ? true : false;

        if (this.url === "setup2") {
          let template = sessionStorage.getItem("TemplateMI") || "";
          let retrievedTemp: any = JSON.parse(template);
          this.templateChange(retrievedTemp);
        }

        this.cdr.markForCheck();
        // console.log("temp", this.templates);
      },
      error: (error: any) => {
        // console.log("templates", error);
      },
    });
  }

  openDeleteDialog(element: any) {
    const dialogRef = this.matdialog.open(ConfirmationDialogComponent, {
      width: "40%",
      height: "auto",
      disableClose: false,
      data: {
        message:
          "Are you sure want to Delete the Interview: " +
          element.interviewName +
          "?",
        buttonText: {
          ok: "Delete",
          cancel: "Cancel",
        },
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!!res) {
        //this.updateInterview(element);
        this.deleteInterview(element._id);
      }
    });
  }

  deleteInterview(element: any) {
    this.interviewService.deleteInterview(element).subscribe({
      next: (res: any) => {
        // console.log("interview Remove", res, res.status);
        this.snackbar.open("Interview Removed Successfully", "close");
        this.getInterviews();
        this.cdr.markForCheck();
      },
      error: (error: any) => {
        // console.log("=== Remove Interview error ==", error);
        if (error) {
          this.snackbar.open(error.error.message, "close", {
            duration: 5000,
            horizontalPosition: "end",
            verticalPosition: "top",
          });
        } else {
          // console.log(`Remove interview Error: ${error.error.message}`);
          this.snackbar.open("Interview Deletion Failed", "close");
        }
      },
    });
  }

  updateInterview(element: any) {
    // console.log("=== Update interview element ==", element);

    let payload: any = {
      interviewName: element["interviewName"],
      clientFunction: element["clientFunction"],
      teamName: element["teamName"],
      role: element["role"],
      interviewer: element["interviewer"],
      clientId: element["clientId"],
      isDeleted: true,
    };
    if (element["templateDetail"])
      payload["templateId"] = element["templateDetail"]["_id"];
    // console.log("=== remove  interview payload ==", payload);

    this.interviewService.editInterview(element["_id"], payload).subscribe({
      next: (res: any) => {
        // console.log("interview Remove", res, res.status);
        this.snackbar.open("Interview Removed Successfully", "close");
        this.getInterviews();
        this.cdr.markForCheck();
      },
      error: (error: any) => {
        // console.log("=== Remove Interview error ==", error);
        if (error.error.message) {
          this.snackbar.open(error.error.message, "close", {
            duration: 5000,
            horizontalPosition: "end",
            verticalPosition: "top",
          });
        } else {
          // console.log(`Remove interview Error: ${error.error.message}`);
          this.snackbar.open("Interview Deletion Failed", "close");
        }
      },
    });
  }

  reloadComponent() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = "reload";
    this.router.navigateByUrl(`/project/${this.clientId}/manager-interview`);
  }

  openPopup(Action: string, element: any) {
    let obj: any = {
      clientId: this.clientId,
      editData: element,
      action: Action,
    };
    let dialogRef: any = this.matdialog.open(InterviewPopupComponent, {
      width: `50vw`,
      disableClose: true,
      data: obj,
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      // console.log("eee", res);
      if (res.event == "createSuccess" || res.event == "editSuccess") {
        this.getInterviews();
        this.cdr.markForCheck();
      }
    });
  }

  templateChange(event: any) {
    this.datasource2 = event.questions;
    this.SelectedTemplateName = event.templateName;
    this.selectedTempIndex = this.templates.findIndex(
      (x: any) => x["_id"] == event._id
    );
    this.templateDetails = event;
    this.isDefaultTemplate =
      this.templateDetails.templateName == "default" ? true : false;
    // console.log("event", event, this.selectedTempIndex);
    this.cdr.markForCheck();
  }

  //search ava

  onSelected() {
    // console.log(this.selected);
    this.selected = this.selected;
  }
  onSelected2() {
    // console.log(this.selected, "sdf", this.searchCtrl.value);
    this.selected = this.selected;
    let selection = this.selected;
    this.addDatabaseQuestion(selection);
    this.cdr.markForCheck();

    this.searchCtrl.setValue(this.selected.question);
  }

  displayWith(value: any) {
    return value?.Title;
  }
  displayWith2(value: any) {
    return value?.question;
  }

  clearSelection() {
    this.selected = "";
    // this.filtered = [];
  }

  opendialog(type: string, element: any, index: any) {
    // console.log("ele", element, index);
    // if(type == 'edit'){}
    // else{
    this.templateSelected.markAsTouched();
    // this.interviewSelected.markAsTouched()
    // }

    if (this.templateSelected.valid) {
      let temp: any = this.templateSelected.value;
      let actionvalue = "";
      if (type == "create") actionvalue = "createQuestion";
      else actionvalue = "editQuestion";
      let dialog = this.matdialog.open(InterviewPopupComponent, {
        width: "62em",
        // height: '450px',
        disableClose: false,
        data: {
          action: actionvalue,
          templateData: temp,
          templateSelected: this.SelectedTemplateName,
          question: element,
        },
      });

      dialog.afterClosed().subscribe((res) => {
        this.getTemplates();
        this.cdr.markForCheck();

        if (res.event == "createQuestion") {
          // let questions: any = { ...res.data };
          // let payload: any = {};
          // payload["templateName"] = temp?.templateName;
          // payload["module"] = temp?.module;
          // temp.questions.push(questions);
          // payload["questions"] = temp.questions;
          // console.log("temp payload rececived", payload);
          // this.addQuestion(temp?._id, payload);
        } else if (res.event == "existingTemplate") {
          // let payload: any = {
          //   question: res.data.question,
          //   questionType: "PERCENTAGE",
          //   min: 0,
          //   max: 0,
          //   category: res.data.category.category,
          //   subCategory: res.data.subCategory,
          //   lens: res.data.lens,
          //   module: "managerInterviews",
          //   questionState: "SUBMITTED",
          // };
          // console.log("updateQuestion payload ", payload, element, type);
          // // this.updateQuestion(element._id,payload,'edit')
          // this.addQuestion(temp, payload);
        } else if (res.event == "newTemplateCreated") {
          // this.getTemplates();
          // this.cdr.markForCheck();
        } else if (res.event == "editQuestion") {
          /*  */
          let editedQuestion = { ...res.data };
          if (!element.isDefault) {
            let payload: any = {
              question: editedQuestion.question,
              lens: editedQuestion.lens,
              category: editedQuestion.category.category,
              subCategory: editedQuestion.subCategory,
              module: element.module,
              oldState: "APPROVED",
              newState: "SUBMITTED",
              questionType: "RATING",
              min: 1,
              max: 5,
            };
            // console.log("---- payload question ----", payload);
            this.updateQuestion(element._id, payload, "edit");
          } else {
            let questions: any = {
              question: editedQuestion.question,
              lens: editedQuestion.lens,
              category: editedQuestion.category.category,
              subCategory: editedQuestion.subCategory,
              module: element.module,
              questionState: "SUBMITTED",
              min: 1,
              max: 5,
              isDefault: element.isDefault,
              _id: element._id,
            };
            let payload = {
              templateName: temp?.templateName,
              module: temp.module,
              questions: [questions],
            };
            // console.log("---- payload template ----", payload);
            this.updateTemplate(temp._id, payload, "edit");
          }
          /*  */
        }
      });
    }
  }

  deletePreview(element: any, index: any) {
    if (element.isDefault) {
      this.deletePreview2(element, index);
    } else {
      this.deletePreview1(element, module);
    }
  }

  deletePreview1(element: any, module: any) {
    const dialogRef = this.matdialog.open(ConfirmationDialogComponent, {
      width: "40%",
      height: "auto",
      disableClose: false,
      data: {
        message: "Are you sure want to Deactivate the Question ?",
        buttonText: {
          ok: "Delete",
          cancel: "Cancel",
        },
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      // console.log("res", res, element);
      if (res) {
        let payload = { ...element };
        delete payload._id;
        delete payload.state;
        delete payload.updatedUser;
        delete payload.__v;
        delete payload.createdAt;
        delete payload.updatedAt;
        payload.module = "managerInterviews";
        payload.isActive = false;
        payload.oldState = QUESTION_STATES_ObJ["APPROVED"];
        payload.newState = QUESTION_STATES_ObJ["SUBMITTED"];

        this.databaseService.editRefData(element._id, payload).subscribe({
          next: (res: any) => {
            this.getTemplates();
            this.cdr.markForCheck();
          },
          error: (error: any) => {
            // console.log(error);
          },
        });
      }
    });
  }

  deletePreview2(element: any, index: any) {
    const dialogRef = this.matdialog.open(ConfirmationDialogComponent, {
      width: "40%",
      height: "auto",
      disableClose: false,
      data: {
        message: "Are you sure want to Delete the Question ?",
        buttonText: {
          ok: "Delete",
          cancel: "Cancel",
        },
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!!res) {
        let temp: any = this.templateSelected.value;
        let payload = {
          templateName: temp?.templateName,
          module: temp.module,
          questions: [],
          removeList: [
            { questionId: element["_id"], isDefault: element["isDefault"] },
          ],
        };
        // console.log(temp._id, payload, "delete");
        // console.log("---- element ----", element);
        // console.log("---- element index ----", element[index]);

        if (element.isDefault) {
          this.updateTemplate(temp._id, payload, "delete");
        } else {
          let payload = {
            question: element["question"],
            questionType: element["questionType"],
            category: element["category"],
            subCategory: element["subCategory"],
            module: element["module"],
            min: 1,
            max: 5,
            isActive: false,
            oldState: "APPROVED",
            newState: "REJECTED",
          };

          this.updateQuestion(element._id, payload, "delete");
        }
      }
    });
  }

  updateTemplate(templateId: string, payload: any, useCase: string) {
    this.interviewService.updateTemplate(templateId, payload).subscribe({
      next: (res: any) => {
        if (useCase == "edit")
          this.snackbar.open("Question Edited Successfully", "Close");
        else if (useCase == "delete")
          this.snackbar.open("Question Deleted Successfully", "Close");
        else if (useCase == "add")
          this.snackbar.open("Question Added Successfully", "Close");
        this.getTemplates();
        this.cdr.markForCheck();
      },
      error: (error: any) => {
        // console.log("Error", error);
      },
    });
  }

  addQuestion(template: any, payload: any) {
    this.databaseService.createRefData(payload).subscribe({
      next: (res: any) => {
        let templateUpdatePayload: any = {
          module: "managerInterviews",
          templateName: template?.templateName,
          questions: {
            questionId: res,
            isApproved: false,
          },
        };
        // console.log("temp payload", templateUpdatePayload);
        if (Object.keys(res).length > 0) {
          this.interviewService
            .updateTemplate(template._id, templateUpdatePayload)
            .subscribe({
              next: (res: any) => {
                // console.log("res", res);
                this.snackbar.open(
                  "Question Sent For Approval Successfully",
                  "close"
                );
                this.getTemplates();
                this.cdr.markForCheck();
              },
              error: (error: any) => {
                // console.log("error ques", error);

                this.snackbar.open("");
              },
            });
        }
      },
      error: (error: any) => {},
    });
  }

  updateQuestion(questionId: any, payload: any, useCase: string) {
    let template: any = this.templateSelected.value;
    // console.log("templateId", template._id);
    // console.log("payload", payload);
    this.databaseService.editRefData(questionId, payload).subscribe({
      next: (res: any) => {
        if (useCase == "edit")
          this.snackbar.open(
            "Question Edited And Sent For Approval Successfully",
            "Close"
          );
        else if (useCase == "delete")
          this.snackbar.open("Removed Successfully", "Close");
        else if (useCase == "add")
          this.snackbar.open(
            "Question Added And Sent For Approval Successfully",
            "Close"
          );
        this.getTemplates();
        this.cdr.markForCheck();
      },
      error: (error: any) => {
        // console.log("qus edit error", error);
      },
    });
  }

  addDatabaseQuestion(selected: any) {
    let temp: any = this.templateSelected.value;
    let qus: any = [],
      allQus: any;
    qus = [{ ...selected }];
    // console.log("qus", qus);
    // console.log("templateSelected", temp.questions);

    allQus = temp.questions.concat(qus);
    // console.log("concat", allQus);

    let payload = {
      templateName: temp.templateName,
      module: "managerInterviews",
      questions: allQus,
    };
    // console.log("addDatabaseQuestion temp", payload);

    this.updateQuestion(temp._id, payload, "add");
    //       this.interviewService.createTemplate(payload).subscribe({
    //         next:(res:any)=>{
    //          console.log('new temp created',res)
    //          this.snackbar.open('Database Question Added Successfully','close')
    //          this.getTemplates()
    //          this.cdr.markForCheck()
    // },
    // error:(error:any)=>{
    //   console.log('addDatabaseQuestion',error)

    // }
    //       })
  }

  preview() {
    this.interviewSelected.markAsTouched();
    if (this.interviewSelected.valid) {
      let temp: any = this.templateSelected.value;
      let interviewValue: any = this.interviewSelected.value;
      let interviewIds = interviewValue.map((x: any) => x._id);
      // console.log("client id", this.clientId);
      // console.log("interview id", interviewIds);
      // console.log("template id", temp._id);
      this.setCache();
      this.storeIds(this.clientId, interviewIds, temp._id);
    } else {
      this.snackbar.open("Kindly select the interview", "close");
    }
  }

  //store needed ID's in session storage
  storeIds(clientId: string, interviewIds: any = [], templateId: string) {
    sessionStorage.setItem("clientId", clientId);
    sessionStorage.setItem("interviewID", interviewIds);
    sessionStorage.setItem("miTemplateId", templateId);

    //navigate to mi-survey page
    this.router.navigateByUrl("mi-survey/preview");
  }

  /* TODO: sort functionality will move to common service.  */
  sortData(sort: Sort) {
    const data = this.interviews.slice();
    if (!sort.active || sort.direction === "") {
      this.datasource1 = data;
      return;
    }

    this.datasource1 = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "candidate":
          return this.compare(a.candidate, b.candidate, isAsc);
        case "team":
          return this.compare(a.team, b.team, isAsc);
        case "function":
          return this.compare(a.function, b.function, isAsc);
        case "team-manager":
          return this.compare(a.role, b.role, isAsc);
        case "date":
          return this.compare(a.date, b.date, isAsc);
        case "interviewer":
          return this.compare(a.interviewer, b.interviewer, isAsc);
        case "template":
          return this.compare(a.template, b.template, isAsc);
        default:
          return 0;
      }
    });
  }

  sortRefData(sort: Sort) {
    const data = this.templates[this.selectedTempIndex].questions.slice();
    if (!sort.active || sort.direction === "") {
      this.datasource2 = data;
      return;
    }

    this.datasource2 = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === "asc";
      switch (sort.active) {
        case "category":
          return this.compare(a.category, b.category, isAsc);
        case "sub-category":
          return this.compare(a.subCategory, b.subCategory, isAsc);
        case "lens":
          return this.compare(a.lens, b.lens, isAsc);
        case "question":
          return this.compare(a.question, b.question, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  buildfromDb(module: string) {
    // console.log(this.templateDetails);
    if (this.templateDetails.templateName !== "default") {
      this.databaseService.buildFromDb_module = module;
      let dialogRef = this.matdialog.open(BuildFromDatabaseAvaComponent, {
        width: "65em",
        height: "80vh",
        disableClose: false,
        data: {
          type: module,
          questionsData: this.datasource2,
        },
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (!!res) {
          if (res.event == "loadQuestion") {
            let payload: any = {
              templateName: this.SelectedTemplateName,
              module: module,
              questions: [...res.data],
            };
            // console.log("load question payload", payload);

            this.workflowTemplateService
              .updateTemplate(this.templateDetails._id, payload)
              .subscribe((updateTemplateRes) => {
                this.snackbar.open("Question loaded Successfully", "close");
                this.cdr.markForCheck();
                this.getTemplates();
              });
          }
        }
      });
    } else {
      this.snackbar.open("Please select your template", "close");
    }
  }

  clearForm() {
    this.type = "create";
    this.interviewCreateForm.reset();
  }

  comparer(o1: any, o2: any): any {
    // console.log(o1, o2);

    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1.teamName === o2.teamName : o2 === o2;
  }

  setCache() {
    const row = JSON.stringify(this.interviewSelected.value);
    sessionStorage.setItem("MISelected", row);

    const cache = JSON.stringify(this.templateSelected.value);
    sessionStorage.setItem("TemplateMI", cache);
  }
}
