import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnInit,
  } from "@angular/core";
  import { MatSnackBar } from "@angular/material/snack-bar";
  import { DatabaseService } from "@app/database/database.service";
  import { SurveyService } from "@app/survey/survey.service";
  
  interface DataObject {
    [key: string]: number | string | undefined;
  }
  
  @Component({
    selector: "app-op-maturity-setup",
    templateUrl: "./op-maturity-setup.component.html",
    styleUrls: ["./op-maturity-setup.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
  })
  export class OpMaturitySetupComponent implements OnInit {
    clientId : string;
    opMaturityData : any;
      displayedColumns: string[] = ["category", "categoryEdit", "subCategory", "subCategoryEdit", "lens", "lensEdit"]
    dataSource2: DataObject[] = [];
    isDefault: boolean = false;
    rowEdited: any = [];
    edited:string;
    constructor(
      private cdr: ChangeDetectorRef,
      private surveyService: SurveyService,
      private databaseService: DatabaseService,
      private snackbar: MatSnackBar
    ) {}
  
    ngOnInit(): void {
      this.clientId = sessionStorage.getItem("clientId") || "";
      this.dataSource2 = [];
      this.getOpMaturityMapping();
    }

    getOpMaturityMapping() : void {
        this.databaseService.getOpMaturyMapping(this.clientId).subscribe((res) => {
            if(res.length === 0) {
                this.databaseService.getOpMaturyMapping("default").subscribe((res) => {
                    this.opMaturityData = JSON.parse(JSON.stringify(res[0]));
                    this.dataSource2 = JSON.parse(JSON.stringify(res[0].defined)); ;
                    this.isDefault = true;
                    this.cdr.markForCheck();
                })
                
            } else {
                this.isDefault = false;
                this.opMaturityData = JSON.parse(JSON.stringify(res[0]));
                this.dataSource2 = JSON.parse(JSON.stringify(res[0].defined)); ;
                this.cdr.markForCheck();
            }
            console.log(this.dataSource2,'d2');
        })
    }

    saveOpMaturity() {
        if(this.isDefault) {
            let data: any = {};
            data.clientId = this.clientId;
            data.system = this.opMaturityData.system;
            data.defined = this.dataSource2;
            this.databaseService.createOpMaturyMapping(this.clientId,data).subscribe((res) => {
                this.isDefault = false;
                this.getOpMaturityMapping();
            })
        } else {
            let data: any = {};
            data.clientId = this.clientId;
            data.system = this.opMaturityData.system;
            data.defined = this.dataSource2;
            this.databaseService.updateOpMaturyMapping(this.clientId,data).subscribe((res) => {
                this.isDefault=false;
                this.getOpMaturityMapping();
            })
        }
    }

    resetOpMaturity() {
        this.databaseService.deleteOpMaturyMapping(this.clientId).subscribe((res) => {
            this.isDefault = true;
            this.getOpMaturityMapping();
        })
    }

    yourModel: any;

    onModelChange(value: any) {
      this.yourModel = value;
      console.log('Model changed:', value);
      // Add your custom logic here
    }

    public isStringInObjectValues(array:any, searchString:string, key: string) {
      for(let arr of array) {
        console.log(arr);
        if(arr[key] == searchString) {
          return true;
        }
      }
      return false;
    }

      updateAllMatchingKeyValues(array:any, keyString:string, newValue:any, prevData:string) {
        array.forEach((obj:any) => {
          if (obj.hasOwnProperty(keyString)) {
            //console.log(prevData === obj[keyString],'<|>');
            if(prevData === obj[keyString]){        
                obj[keyString] = newValue[keyString];
            }
            
          }
          this.cdr.markForCheck();
        });
      }
      

      save(data: any, index: number, key: any) {
          let check = this.isStringInObjectValues(this.opMaturityData.defined, data[key], key)
          if (check) {
              this.snackbar.open("Error, It is a already named convention", "close");
          }
          else {
              //console.log(this.edited,'edited');
              this.updateAllMatchingKeyValues(this.dataSource2, key, data, this.edited)
              //console.log(this.dataSource2,'RRR');
              this.rowEdited[index] = false;
              this.edited = '';
              this.cdr.markForCheck();
          }
      }

      onEdit(element: any, index: number,type:string) {
          if (type === 'category') {
              this.dataSource2[index] = element;
              this.rowEdited[index] = true;
              this.edited = element?.category;
          }
          if (type === 'subCategory') {
            this.dataSource2[index] = element;
            this.rowEdited[index] = true;
            this.edited = element?.subCategory;
        }
        if (type === 'lens') {
            this.dataSource2[index] = element;
            this.rowEdited[index] = true;
            this.edited = element?.lens;
        }
      }

      onCancel(element: any, index: number, event: any, type: string) {
          if (type === 'category') {
              element.category = this.dataSource2[index];
          }
          if (type === 'subCategory') {
              element.subCategory = this.dataSource2[index];
          }
          if (type === 'lens') {
              element.lens = this.dataSource2[index];
          }
          this.rowEdited[index] = false;
          this.edited = '';
      }
    
  }
  