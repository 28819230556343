import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { SnackBarService } from "../core/services/snackbar.service";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { HomeService } from "../home/home/home.service";
import { MatChipInputEvent } from "@angular/material/chips";
import { A, COMMA, ENTER } from "@angular/cdk/keycodes";
import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { ActivatedRoute } from "@angular/router";
import { OnboardingService } from "./onboarding.service";
import { ReplaySubject, Subject, take, takeUntil } from "rxjs";
import { MatSelect } from "@angular/material/select";
import { DatePipe } from "@angular/common";
import { MatStepper } from "@angular/material/stepper";
import { Helper } from "@app/core/classes/helper";

@Component({
  selector: "app-onboarding",
  templateUrl: "./onboarding.component.html",
  styleUrls: ["./onboarding.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  encapsulation: ViewEncapsulation.None,
})
export class OnboardingComponent implements OnInit {

  @ViewChild(MatStepper, { static : false }) stepper: MatStepper;


  selectedIndex = 2;
  index = 0;
  orgStructureData: any;
  clientConfigSectorsData: any;
  functionNameData: any;
  clients: any;
  clientId: any;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  lead_emails: any[] = [];
  users: any;
  singleClientDetails: any;
  updateClientData: any;
  isEditClient: boolean = false;
  isViewClient: boolean = false;
  isUpdateButton: boolean = false;
  isAddNewClient: boolean = false;
  isAddOrgStructure: boolean = false;
  isAddModules: boolean = false;

  
  todayDate = new Date();
  oranganizationForm: boolean;
  reStructured_Org_data: any = []
  isSaveOrganisation:boolean = true;
  isUpdateOrganisation:boolean = false;
  selectedStartDate:any
  client: any;
  selectedSectors:boolean = false;
  selectedFunction:boolean = false;

  constructor(
    private fb: FormBuilder,
    private homeService: HomeService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private onboardingService: OnboardingService,
    private datePipe: DatePipe,
    private cdr : ChangeDetectorRef
  ) {}

   dateRangeValidator: any = (): {
    [key: string]: any;
  } | null => {
    let invalid = false;
    const startDate =
      this.client_details_form &&
      this.client_details_form.get("startDate").value;
      
      !this.clientId ?  this.selectedStartDate =  startDate : ''
      // console.log('selectedStartDate',this.selectedStartDate)
      // console.log('selectedStartDate 22', new Date() )
    const endDate =
      this.client_details_form && this.client_details_form.get("endDate").value;
    if (startDate && endDate) {
      invalid = new Date(startDate).valueOf() > new Date(endDate).valueOf();
    }

    if (invalid && !this.isViewClient && this.client_details_form.status === "INVALID") {
      this.snackBar.open("Start date should be less than End date", "close", {
        duration: 3500,
        horizontalPosition: "center",
        verticalPosition: "top",
      });
      this.snackBar.open(`Start date should be less than End date`, "close", {
        duration: 3500,
        horizontalPosition: "end",
        verticalPosition: "top",
      });
    }

    return invalid ? { invalidRange: { startDate, endDate } } : null;
  };

  client_details_form: any = new FormGroup(
    {
      clientName: new FormControl(null, Validators.required),
      diagnosticName: new FormControl(null, Validators.required),
      details: new FormControl(null, Validators.required),
      sector: new FormControl(null, Validators.required),
      startDate: new FormControl(null, Validators.required),
      endDate: new FormControl(null, Validators.required),
    },
    [Validators.required, this.dateRangeValidator]
  );

  organisation_form: any = new FormGroup({
    functionName: new FormControl(null, Validators.required),
    teamName: new FormControl(null, Validators.required),
    fteManagerCost: new FormControl(null, Validators.required),
    fteManagers: new FormControl(null, Validators.required),
    fteMemberCost: new FormControl(null, Validators.required),
    fteMembers: new FormControl(null, Validators.required),
    days: new FormControl(null, Validators.required),
    hours: new FormControl(null, Validators.required),
    authors: new FormControl(null, Validators.required),
  });

 


  isClientDtailsFormEditable = true;
  organisationId:string;

  // Multi-select with search code begins here

  protected sample: any[] = ["Content A ", "Another Z"];

  protected _onDestroy = new Subject<void>();

  /** control for the MatSelect filter keyword multi-selection */
  public emailMultiFilterCtrl: FormControl<any> = new FormControl<string>("");

  /** list of users filtered by search keyword */
  public filteredemailsMulti: ReplaySubject<any[]> = new ReplaySubject<any[]>(
    1
  );

  @ViewChild("multiSelect", { static: true }) multiSelect: MatSelect;
  @Input() placeholderLabel = "Suchewww";

  /**
   * Sets the initial value after the filteredemails are loaded initially
   */
  protected setInitialValue() {
    this.filteredemailsMulti
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredEmails are loaded initially
        // and after the mat-option elements are available
        this.multiSelect.compareWith = (a: any, b: any) =>
          a && b && a.id === b.id;
      });
  }

  protected filterEmailsMulti() {
    if (!this.users) {
      return;
    }
    // get the search keyword
    let search = this.emailMultiFilterCtrl.value;
    if (!search) {
      this.filteredemailsMulti.next(this.users.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the users
    this.filteredemailsMulti.next(
      this.users.filter(
        (users: any) => users.toLowerCase().indexOf(search) > -1
      )
    );
  }

  ngAfterViewInit() {
    this.setInitialValue();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  ngOnInit(): void {
    this.getUsers();
    this.getClients();
    this.getClientConfigSectors();
    this.getClientFunctions();
    this.getParams();
    this.dateRangeValidator();

  }

  getClients() {
    this.onboardingService.getAllClients().subscribe((res) => {
      this.clients = res;
    });
  }

  getUsers() {
    this.onboardingService.getUsers().subscribe((res) => {
      this.users = res;
    });
  }

  getClientConfigSectors() {
    this.onboardingService.getClientConfigSectors().subscribe((res) => {
      // console.log(res);
      this.clientConfigSectorsData = res;
      // this.functionNameData = res[0].functions;
      //this.functionNameData = res;
      this.clientConfigSectorsData.unshift({configName:'Add New Sector'})
      // console.log(this.clientConfigSectorsData);
      
    });
  }

  getClientFunctions(){
    this.onboardingService.getClientConfigFunctions().subscribe((res) => {
      // console.log(res);
      this.functionNameData = res;
      // console.log(this.functionNameData);
      this.functionNameData.unshift({configName:'Add New Function'})
      // console.log(this.functionNameData); 
    });
  }

  change(event:any){
    // console.log(event.value, 'event');
    if (event.value === 'Add New Sector') {
      this.selectedSectors = !this.selectedSectors 
      this.client_details_form.controls['sector'].setValue('')
    }
    if (event.value === 'Add New Function') {
      this.selectedFunction = !this.selectedFunction 
      this.organisation_form.controls['functionName'].setValue('')
    }
  }

  getOrganisationByClientId(clientId: string) {
    this.onboardingService.getOrganisationByClientId(clientId).subscribe((res) => {
      this.orgStructureData = res;
      this.cdr.markForCheck()
      if(this.orgStructureData.length){
        this.groupingClientData()
      }

    });
  }

  //

  createSector() {
    let val = this.client_details_form.value.sector
    //if (val !== '') {
      let payload = {
        configName: this.client_details_form.value.sector,
        configType: "SECTOR"
      }
      // console.log(payload);

      this.onboardingService.create_sector_or_function(payload).subscribe({
        next: (res: any) => {
          // console.log(res);
          this.snackBar.open('Sector Created Successfully','close')
          this.selectedSectors = false;
          this.getClientConfigSectors();
        },
        error: (error: any) => {
          // console.log(" error", error);
          this.snackBar.open(error.error.message,'close')
        },

      });
    //}
  }

  createFunction(){
    let payload = {
      configName: this.organisation_form.value.functionName,
      configType: 'FUNCTION',
      parent: this.client_details_form.value.sector
    }
    // console.log(payload);

    this.onboardingService.create_sector_or_function(payload).subscribe({
      next: (res: any) => {
        // console.log(res);
        this.snackBar.open('Function Created Successfully','close')
        this.selectedFunction = false;
        this.getClientFunctions();
      },
      error: (error: any) => {
        // console.log("error", error);
        this.snackBar.open(error.error.message,'close')
      },

    });
    
  }

  groupingClientData() {
    this.reStructured_Org_data = [];
    let group: any = {};

    this.orgStructureData.forEach((data: any) => {
      const { functionName } = data;

      data.teams[0]._id = data._id;

      if (group.hasOwnProperty(functionName)) {
        group[data?.functionName].push(...data?.teams);
      } else {
        group[data?.functionName] = [...data?.teams];
      }
    });

    Object.entries(group).forEach(([key, value]) => {
      let obj = {
        type: key,
        value: value,
      };
      this.reStructured_Org_data.push(obj);
      this.cdr.markForCheck();
    });
  }

  getParams() {
    const type = this.route.snapshot.paramMap.get("type");
    this.isEditClient = type === "edit" ? true : false;
    this.isViewClient = type === "view" ? true : false;
    this.isAddNewClient = this.isEditClient || this.isViewClient ? false : true;

    if(this.isViewClient){
      // s

      Object.keys(this.client_details_form.controls).forEach((controlName)=>{
        const control = this.client_details_form.controls[controlName]
        control.disable()
      })
      
    }

    this.clientId = this.route.snapshot.queryParams["id"]
      ? this.route.snapshot.queryParams["id"]
      : this.clientId;

    if (this.isEditClient || this.isViewClient) {
      this.removeValidators(this.client_details_form);
      this.getOrganisationByClientId(this.clientId);
    } else {
      this.getOrganisationByClientId(this.clientId);
    }

    if(this.isViewClient){
      this.organisation_form = new FormGroup({
        functionName: new FormControl({ value: '', disabled: true }),
        teamName: new FormControl({ value: '', disabled: true }),
        fteManagerCost: new FormControl({ value: '', disabled: true }),
        fteManagers: new FormControl({ value: '', disabled: true }),
        fteMemberCost: new FormControl({ value: '', disabled: true }),
        fteMembers: new FormControl({ value: '', disabled: true }),
        days: new FormControl({ value: '', disabled: true }),
        hours: new FormControl({ value: '', disabled: true }),
        authors: new FormControl({ value: '', disabled: true }),
      });
    }

    if (this.clientId) {
      this.getClienByClientId(this.clientId, true, this.isEditClient);
    }
  }

  getClienByClientId(clientId : string, isViewClient: boolean, isEditClient: boolean) {
    this.onboardingService.getClienByClientId(clientId).subscribe((res) => {
      // console.log('client',res);
      this.client = res
      this.selectedStartDate = res[0]['startDate']
      this.client_details_form.patchValue({
        startDate : res[0]['startDate']
      })
      this.singleClientDetails = Object.assign({}, res[0]);

      if (isViewClient || isEditClient) {
          // const startDate = this.singleClientDetails?.startDate
          // .match(/([^T]+)/)[0]
          // .split("-")
          // .reverse()
          // .join("/");
        this.singleClientDetails.startDate = new DatePipe('en-US').transform(this.singleClientDetails?.startDate, 'dd/MM/yyyy');
        this.client_details_form.patchValue(this.singleClientDetails);
        this.isUpdateButton = isEditClient ? true : false;
      }
    });
  }

  createClient(form: any) {
    const clientData = {
      clientName: form.value.clientName,
      diagnosticName: form.value.diagnosticName,
      details: form.value.details,
      clientLogoUrl: "../../assets/icons/lb.jpg",
      sector: form.value.sector,
      endDate: form.value.endDate,
      startDate: form.value.startDate,
    };

    this.onboardingService.createClient(clientData).subscribe({
      next: (res) => {
        sessionStorage.setItem("clientId",res)

        this.clientId = res;

        this.getClienByClientId(this.clientId, false, false);

        this.snackBar.open("Client created successfully", 'close',{
          duration: 3500,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
        this.snackBar.open(
          `Client created successfully`,
          "close",
          {
            duration: 3500,
            horizontalPosition: "end",
            verticalPosition: "top",
          }
        );

        this.getClients();
        // this.getModuleData();
        this.stepper.next()
        this.isAddNewClient = false;
        this.isAddOrgStructure = true;
      },

      error: (err) => {
        if(err.error.message === "Client already present"){
          this.snackBar.open(
            err.error.message,
            "close",
            {
              duration: 3500,
              horizontalPosition: "end",
              verticalPosition: "top",
            }
          );
        }else{
        // console.log(`Create client Error: ${err.error.message}`);

        }
      },

      complete: () => {},
    });
  }

  updateClient(id: string, payload: any) {
    this.dateRangeValidator(this.client_details_form);

    if (id === "true" && payload === "patchUpdate") {
      const updateClientPayload = {
        clientName: this.client_details_form.value.clientName,
        diagnosticName: this.client_details_form.value.diagnosticName,
        details: this.client_details_form.value.details,
        sector: this.client_details_form.value.sector,
        endDate: this.client_details_form.value.endDate,
      };

      this.onboardingService
      .updateClient(this.clientId, updateClientPayload)
      .subscribe({
        next: (res) => {
          this.snackBar.open(
            `client updated successfully`,
            "close",
            {
              duration: 3500,
              horizontalPosition: "end",
              verticalPosition: "top",
            }
          );
        },
        error: (err) => {
          if(err.error.message){
            this.snackBar.open(
              err.error.message,
              "close",
              {
                duration: 3500,
                horizontalPosition: "end",
                verticalPosition: "top",
              }
            );
          }else{
          // console.log(`Create client Error: ${err.error.message}`);
          }
        }
      });
    } else {
      this.onboardingService
      .updateClient(id, payload)
      .subscribe((res: any) => {});
    }
  
  }


   createOrg() {
    
    const organisationStrucureData = {
      functionName: this.organisation_form.value.functionName,
      clientId: this.clientId,
      teams: [
        {
          fteMemberCost: this.organisation_form.value.fteMemberCost,
          fteManagerCost: this.organisation_form.value.fteManagerCost,
          fteMembers: this.organisation_form.value.fteMembers,
          fteManagers: this.organisation_form.value.fteManagers,
          teamName: this.organisation_form.value.teamName,
        },
      ],
    };

    this.createOrganisation(organisationStrucureData);
  }

  createOrganisation(organisationStrucureData: any) {

    const workingDays = this.organisation_form.value.days;
    const workingHours = this.organisation_form.value.hours;
    const authors = this.organisation_form.value.authors;
  
    this.onboardingService
      .createOrganisation(organisationStrucureData)
      .subscribe({
        next: (res) => {},

        error: (err) => {
          if(err.error.message){
          this.snackBar.open(
            err.error.message,
            "close",
            {
              duration: 3500,
              horizontalPosition: "end",
              verticalPosition: "top",
            }
          );
          }
          else{
            console.error(`Create Organisation Error: ${err.error.message}`);
          }
          
        },

        complete: () => {
          this.snackBar.open(
            `Organisation ${organisationStrucureData.functionName} created successfully`,
            "close",
            {
              duration: 3500,
              horizontalPosition: "end",
              verticalPosition: "top",
            }
          );

          if (this.isEditClient || this.isViewClient) {
            this.getOrganisationByClientId(this.clientId);
          } else {
            this.getOrganisationByClientId(this.clientId);
          }

          const updateClientPayload = {
            clientName: this.singleClientDetails.clientName,
            diagnosticName: this.singleClientDetails.diagnosticName,
            details: this.singleClientDetails.details,
            clientLogoUrl: this.singleClientDetails.clientLogoUrl,
            sector: this.singleClientDetails.sector,
            endDate: this.singleClientDetails.endDate,
            workingDays,
            workingHours,
            authors,
          };
      
          this.updateClient(this.singleClientDetails._id, updateClientPayload);

        },
      });
  }

  editOrganisation(organisation: any, team: any) {
    this.isSaveOrganisation = false;
    this.isUpdateOrganisation = true;
    this.organisationId = team._id;
    
    const patchOrgPayload = {
      functionName: organisation.type,
      teamName: team.teamName,
      fteManagerCost: team.fteManagerCost,
      fteManagers: team.fteManagers,
      fteMemberCost: team.fteMemberCost,
      fteMembers: team.fteMembers,
      days: this.singleClientDetails.workingDays,
      hours: this.singleClientDetails.workingHours,
      authors: this.singleClientDetails.authors
    };

    this.organisation_form.patchValue(patchOrgPayload);
  }

  updateOrganisation() {
    if (this.organisation_form.status === "VALID") {
      const updateOrganisationPayload = {
        functionName: this.organisation_form.value.functionName,
        clientId: this.clientId,
        teams: [
          {
            fteMemberCost: this.organisation_form.value.fteMemberCost,
            fteManagerCost: this.organisation_form.value.fteManagerCost,
            fteMembers: this.organisation_form.value.fteMembers,
            fteManagers: this.organisation_form.value.fteManagers,
            teamName: this.organisation_form.value.teamName,
          },
        ],
      };

      this.onboardingService
        .updateOrganisation(this.organisationId, updateOrganisationPayload)
        .subscribe({
          next: (res) => {},
  
          error: (err) => {
            if (err.error.message) {
              this.snackBar.open(err.error.message, "close", {
                duration: 3500,
                horizontalPosition: "end",
                verticalPosition: "top",
              });
            } else {
              // console.log(`Create client Error: ${err.error.message}`);
            }
            
          },
  
          complete: () => {
            this.snackBar.open(`Organisation updated successfully`, "close", {
              duration: 3500,
              horizontalPosition: "end",
              verticalPosition: "top",
            });

            const updateClientPayload = {
              clientName: this.singleClientDetails.clientName,
              diagnosticName: this.singleClientDetails.diagnosticName,
              details: this.singleClientDetails.details,
              sector: this.singleClientDetails.sector,
              endDate: this.singleClientDetails.endDate,
              authors: this.organisation_form.value.authors,
              workingDays: this.organisation_form.value.days,
              workingHours: this.organisation_form.value.hours,
            };

            this.updateClient(this.clientId, updateClientPayload);

            this.getOrganisationByClientId(this.clientId);
  
          },
        });
    } else {
      // console.log(
      //   "Organisation update form validation error:",
      //   this.organisation_form
      // );
    }
  }

   createClientNext() {    
    this.dateRangeValidator(this.client_details_form);
    const { emptyFields } = Helper.getEmptyFields(this.client_details_form);


    if (  emptyFields.length === 0 ) {
     if(this.client_details_form.status === "VALID") { 
      this.createClient(this.client_details_form);
    }
    } else {
        // emptyControls.forEach(control => control.setValue(''))
        this.client_details_form.markAllAsTouched()
    }
  }

  createMoudle(module: any) {
    const payload = {
      clientId: this.clientId,
      moduleId: module.name,
      description: module.description,
    };

    this.onboardingService.createModule(payload).subscribe((res) => {
      // console.log(`module ${module.name} : ${res} created`);
    });
  }

  getModuleData() {
    this.onboardingService.getModuleData("MODULE").subscribe(
      (response: any) => {
        for (let i = 0; i < response.length; i++) {
          response[i]["position"] = i + 1;
          // response[i]["description"] = "Brief description of activity…";

          if (response[i].name === "Op Maturity" || response[i].name === "Clinet Experience" || response[i].name === "Active Value Analyser") {
            response[i]["is_selected"] = true;
            // this.createMoudle(response[i]);
          }
        }
      },
      (error: any) => {
        // console.log("Get Module Data Error:", error);
      }
    );
  }

  editNext(){
    this.stepper.next()
    this.removeValidators(this.organisation_form)
    this.cdr.markForCheck()

  }
 
  save_next() {
    if(this.orgStructureData.length > 0 && !this.isViewClient){
      if (this.isViewClient || this.isEditClient) {
        this.removeValidators(this.organisation_form);
      }
      this.stepper.next()
    }
    if (this.orgStructureData.length === 0 && !this.isViewClient) {
      this.organisation_form.markAllAsTouched()
      this.snackBar.open(`Please create at least one team`, "close", {
        duration: 3500,
        horizontalPosition: "end",
        verticalPosition: "top",
      });
    } else {
      if (this.isViewClient || this.isEditClient) {
        this.removeValidators(this.organisation_form);
        this.stepper.next()
      }

    this.onboardingService.sendID(this.clientId);
    }
  }

 

  public removeValidators(form: any) {
  
    for (const key in form.controls) {
      form.get(key).clearValidators();
      form.get(key).setErrors(null);
      form.get(key).updateValueAndValidity();
    }

    this.cdr.markForCheck();

    this.isClientDtailsFormEditable = false;
  }

  public addValidators(form: any) {
    for (const key in form.controls) {
        form.get(key).setValidators(Validators.required);
        form.get(key).updateValueAndValidity();
    }
  }

  onclick(){
    this.isAddNewClient = true;
      this.isAddOrgStructure = false;

  }

  orgStructureNext() {
    this.addValidators(this.organisation_form)
    const { emptyFields  } = Helper.getEmptyFields(this.organisation_form)
    if (this.organisation_form.status === "VALID" && emptyFields.length == 0 ) {
      this.createOrg();
      
      this.isAddOrgStructure = false;
      this.isAddModules = true;

      // this.organisation_form.reset();

      this.isClientDtailsFormEditable = false;
    } else {
      this.organisation_form.markAllAsTouched()
    }
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || "").trim();

    // Add our data
    if (value) {
      this.lead_emails.push({ name: value });
    }

    // Clear the input value
    event.chipInput!.clear();
  }

  remove(data: any): void {
    const index = this.lead_emails.indexOf(data);

    if (index >= 0) {
      this.lead_emails.splice(index, 1);
    }
  }
}
