import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { data, avaDataCopy } from "../avaData";
import { ChartComponent } from "ng-apexcharts";
import { SurveyService } from "@app/survey/survey.service";
import { CustomService } from "@app/core/services/custom.service";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { group } from "@angular/animations";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
};

export type ChartOptionsBasic = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};

@Component({
  selector: "app-ava-outputs",
  templateUrl: "./ava-outputs.component.html",
  styleUrls: ["./ava-outputs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AvaOutputsComponent implements OnInit {
  overview: any = [
    {
      question: "Phone Calls",
      category: "Non Value Add",
      time: "5%",
      spent: "10 mins a day",
      capacity: "-",
      $$: "-",
    },
    {
      question: "Activity",
      category: "Non nValue Add",
      time: "3%",
      spent: "10 mins a day",
      capacity: "-",
      $$: "-",
    },
  ];

  dc: string[] = [
    "activity",
    "category",
    "time",
    "timespent",
    "capacity",
    "$$",
  ];

  //Breakdown
  form: FormGroup = new FormGroup({});
  selectedOption = "Bar Chart";
  memberSelect = "all";
  filters: any[] = [
    { key: "Activity", Value: "Activity" },
    { key: "Type", Value: "Type" },
    { key: "TimeCategory", Value: "TimeCategory" },
  ];
  @ViewChild("chart2")
  chart2!: ChartComponent;
  public newChartOptions: ChartOptions | any;
  breakdownData: any = [];

  @ViewChild("barLongChart")
  barLongChart!: ChartComponent;
  public barLongChartOptions: ChartOptions | any;

  chartLoad: boolean = false;

  //Variables for new design
  functions: any[] = [];
  teams: any[] = [];

  selectedFunction: String = "All";
  selectedTeam: String = "All";

  selectedFunctions: any[] = ["All"];
  selectedTeams: any[] = ["All"];

  selectedType: any = "All";
  previousFunctions: any[] = ["All"];
  previousTeams: any[] = ["All"];

  xFilter: string = "clientFunction";
  yFilter: string = "Type";
  zFilter: string = "all";
  clientId: string = "";
  surveyData: any = {};
  filterCData: any = [];
  aT: any = [];
  eventType: any = "team";
  isTeamSet: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private surveyService: SurveyService,
    private cs: CustomService,
    private snackbar: MatSnackBar
  ) {
    this.form = fb.group({
      chart: [this.selectedOption, [Validators.required]],
      filter: ["Activity"],
    });

    //Initiating charts here...!!
    this.barLongChartOptions = this.getActivityBar();
    //this.changeData();
  }

  ngOnInit(): void {
    this.clientId = sessionStorage.getItem("clientId") || "";
    //this.getSurvey();
    this.getSurveyStats("ava-eva");

    setTimeout(() => {
      this.tabChanged1(1);
    }, 1000);
  }

  @ViewChild("select") select: MatSelect;
  @ViewChild("select2") select2: MatSelect;

  allSelected_function = false;
  allSelected_team = false;
  toggleAllSelection() {
    if (this.allSelected_function) {
      this.select.options.forEach((item: any) => item.select());
    } else {
      this.select.options.forEach((item: any) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: any) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected_function = newStatus;
  }

  toggleAllSelectionTeam() {
    if (this.allSelected_team) {
      this.select2.options.forEach((item: any) => item.select());
    } else {
      this.select2.options.forEach((item: any) => item.deselect());
    }
  }
  optionClickTeam() {
    let newStatus = true;
    this.select2.options.forEach((item: any) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected_team = newStatus;
  }

  getSurvey() {
    console.log(
      "----- this.clientId -----------------------------",
      this.clientId
    );
    let surveyQuery: object = { clientId: this.clientId, module: "ava-eva" };
    this.surveyService.getSurveyByQuery(surveyQuery).subscribe(
      (surveyResponse) => {
        this.surveyData = surveyResponse["data"][0];
        this.getSurveyStat();
      },
      (error) => {
        console.log("Error while getting survey data: ", error);
      }
    );
  }

  getSurveyStat() {
    this.surveyService.getSurveyStats(this.surveyData["_id"]).subscribe(
      (surveyStats: any) => {
        console.log("OLD Ava Api Response -->>", surveyStats);

        this.filterCData = surveyStats;
        //this.filterCData = data;
        //console.log('AVA Chart After API Output', this.filterCData);
        this.cdr.markForCheck();
      },
      (error) => {
        console.log("Error while getting survey stats: ", error);
      }
    );
  }

  getSurveyStats(module: any) {
    this.surveyService.getSurveyOutputs(module, this.clientId).subscribe(
      (res: any) => {
        console.log("NEW Ava Api Response -->>", res);
        this.filterCData = res;

        // Modifying API DATA began
        this.filterCData.forEach((ele: any) => {
          let Format: any = [];
          ele.ava.forEach((ele: any) => {
            Format.push(ele);
          });

          delete ele.ava;
          let obj = [];
          obj = ele;
          obj.ava = { questions: Format };
          ele = obj;
        });
        // Modifying API DATA complete
        console.log(
          "Modified from NEW Ava Api Response -->>",
          this.filterCData
        );
        //this.teamsData = this.filterCData; // Teams Data

        this.getFunctionsAndTeams();
        this.populateBarData();
        this.cdr.markForCheck();
      },
      (error) => {
        console.log("Error from Output API : ", error);
        this.cdr.markForCheck();
      }
    );
  }

  tabChanged1(tabChangeEvent: any) {
    console.log("index => ", tabChangeEvent);
    this.getSurveyStats("ava-eva");

    this.statsInitiate(); //Initiate stat chart
    this.cdr.markForCheck();
    // }
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    console.log(this.form.value);
  }

  statsInitiate() {
    this.barLongChartOptions = this.getActivityBar();
  }

  getActivityChart() {
    const activities = new Set();
    const actvityMap = new Map();
    let totalTeam = 0;
    for (let d of this.filterCData) {
      console.log(d.total);

      totalTeam += d.total;

      //change
      for (let q of d.ava.questions) {
        let value = 0;
        activities.add(q.Activity);
        if (actvityMap.has(q.Activity)) {
          value = actvityMap.get(q.Activity) + Math.round(q.Answer) * d.total;
          actvityMap.set(q.Activity, value);
        } else {
          value = Math.round(q.Answer) * d.total;
          actvityMap.set(q.Activity, value);
        }
      }
    }

    for (let x of actvityMap.keys()) {
      actvityMap.set(x, actvityMap.get(x) / totalTeam);
    }

    return actvityMap;
  }

  getActivityBar() {
    let barMap = this.getActivityChart();
    let barData: any = {};
    barData.name = "basic";
    barData.data = [];
    const barSeries: any = [];
    for (let key of barMap.keys()) {
      barSeries.push(key);
      barData.data.push(barMap.get(key));
    }
    console.log(barMap);

    let barLongChartOptions = {
      series: [
        {
          name: "basic",
          data: barData.data,
        },
      ],
      chart: {
        type: "bar",
        height: 500,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: barSeries,
      },
    };
    return barLongChartOptions;
  }

  public getXAxis(xKey: string) {
    const xAxis = new Set();
    for (let x of this.filterCData) {
      const xMap = new Map(Object.entries(x));
      xAxis.add(xMap.get(xKey));
    }
    return xAxis;
  }

  public getYAxis(yKey: string) {
    const yAxis = new Set();
    for (let x of this.filterCData) {
      //change
      for (let q of x.ava.questions) {
        const qMap = new Map(Object.entries(q));
        yAxis.add(qMap.get(yKey));
      }
    }
    return yAxis;
  }

  public getResults(grouping: string, xKey: string, yKey: any) {
    console.log(grouping, xKey, yKey);

    const results: any = [];
    const yAxis = this.getYAxis(yKey);
    const xAxis = this.getXAxis(xKey);

    for (let y of yAxis) {
      let result: any = {};
      result.name = y;
      result.data = [];
      for (let x of xAxis) {
        let total = 0;
        let value = 0;
        for (let d of this.filterCData) {
          if (d.clientFunction == x || d.teamName == x) {
            total += d.total;
            let processthis = true;
            if (grouping == "all") {
              processthis = true;
            } else if (grouping == "isManager") {
              processthis = d.isManager;
            } else if (grouping == "member") {
              processthis = !d.isManager;
            }
            if (processthis) {
              //change
              for (let q of d.ava.questions) {
                let qMap = new Map(Object.entries(q));
                if (qMap.get(yKey) == y) {
                  //console.log(Math.round(q.Answer));

                  value += Math.round(q.Answer) * d.total;
                }
              }
            }
          }
        }
        result.data.push(value / total);
      }
      results.push(result);
    }
    console.log(results);
    if (this.setToActivity) {
      this.activityTable = results; //Assign to activities Table
      this.activityTable.forEach((req: any) => {
        const sum = req.data.reduce(
          (partialSum: any, a: any) => partialSum + a,
          0
        );
        req.spent = sum / req.data.length;
      });
      console.log("activityTable-->", this.activityTable);
    }
    const arrayOfObjects = this.activityTable;

    // Define the old key name and the new key name
    const oldKey = "name";
    const newKey = "teamName";

    // Use the map method to create a new array with updated key names
    const updatedArrayOfObjects = arrayOfObjects.map((obj: any) => {
      // Create a new object with the updated key names
      const newObj = { ...obj, [newKey]: obj[oldKey] };

      delete newObj["name"];
      return newObj;
    });

    // Output the updated array of objects
    console.log(updatedArrayOfObjects);

    this.aT = updatedArrayOfObjects;
    console.log(this.aT);
    this.chartLoad = true;
    this.cdr.markForCheck();
    return results;
  }

  //New

  activityTable: any = [{ name: "Phone Calls", data: ["6"] }];
  activityTable2: any = [{ name: "Phone Calls", data: ["6"] }];
  atColumns: any = ["activity", "time"];

  coreData: any;

  setToType: boolean;
  setToActivity: boolean;
  teamsData: any;
  selectionTeams: any = [];
  selectedRow: any = [];

  titleSelected: any;

  getMultiple(id: any) {}

  getByTeam(team: any, row: any) {
    console.log(team);
    console.log(row);
    this.titleSelected = team;
    const foundObject = this.selectionTeams.find(
      (obj: any) => obj.teamName === team
    );
    console.log("Selected data", row);
    // this.filterCData = [];
    // this.filterCData.push(foundObject);
    this.getActivityChart();
    //this.changeData();
    this.cdr.markForCheck();

    this.selectedRow.find((x: any) => x == row)
      ? (this.selectedRow = this.selectedRow.filter((x: any) => x != row))
      : this.selectedRow.push(row);
    console.log(this.selectedRow, "SELECTED");

    if (this.selectedRow.length == 0) {
      this.changeChart(this.selectionTeams);
    } else {
      this.changeChart(this.selectedRow);
    }
  }

  functionSwitch = false;
  memberSwitch = false;

  onFunctionChange(value: any) {
    this.selectedRow = [];

    this.selectionTeams = [];
    this.isTeamSet = false;

    // if (value.checked) this.eventType = "function";
    // if (!value.checked) this.eventType = "team";
    if (value == "team") {
      this.functionSwitch = false;
    } else {
      this.functionSwitch = true;
    }

    console.log(value);
    this.cdr.markForCheck();
    this.populateBarData();
  }

  onMemberChange(value: any) {
    this.selectedRow = [];
    this.selectionTeams = [];
    this.isTeamSet = false;
    console.log(value.checked);
    // this.cdr.markForCheck();
    this.populateBarData();
  }

  getFunctionsAndTeams() {
    this.functions = [];
    if (!this.functions.includes("All")) {
      this.functions.push("All");
    }
    if (!this.teams.includes("All")) {
      this.teams.push("All");
    }
    for (let d of this.filterCData) {
      if (!this.functions.includes(d.clientFunction)) {
        this.functions.push(d.clientFunction);
      }
      if (!this.teams.includes(d.teamName)) {
        this.teams.push(d.teamName);
      }
    }
  }

  changeFunction(event: any) {
    console.log("----changeFunction ------", event);
    this.selectedFunctions = [];

    if(!event.length){
      this.selectedFunctions.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedFunctions.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousFunctions.includes("All")) {
        this.selectedFunctions.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedFunctions.push("All");
      }
    } else {
      this.selectedFunctions.push(...event);
    }
    this.previousFunctions = this.selectedFunctions;
    this.populateBarData();
  }

  changeTeam(event: any) {
    console.log("----changeTeam ------", event);
    this.selectedTeams = [];

    if(!event.length){
      this.selectedTeams.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedTeams.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousTeams.includes("All")) {
        this.selectedTeams.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedTeams.push("All");
      }
    } else {
      this.selectedTeams.push(...event);
    }
    this.previousTeams = this.selectedTeams;
    this.populateBarData();
  }

  changeType(event: any) {
    this.selectedTeam = event;
    this.selectedFunction = "All";
  }

  getType(data: any) {
    this.processSelectedTypes(data);
  }

  populateBarData() {
    let groupSwitch = "team";

    if (this.functionSwitch) {
      groupSwitch = "function";
      this.eventType = "function";
    } else {
      this.eventType = "team";
    }

    let groupMap = new Map();

    let totalCount = 0;
    let actCount = 0;
    let activityMap = new Map();

    for (let data of this.filterCData) {
      let processThis = false;
      let funcProcessThis = false;

      if (
        (this.selectedFunctions.includes(data.clientFunction) ||
          this.selectedFunctions.includes("All")) &&
        (this.selectedTeams.includes(data.teamName) ||
          this.selectedTeams.includes("All"))
      ) {
        funcProcessThis = true;
      }

      if (
        (this.memberSelect == "all" || this.memberSelect == "manager") &&
        data.isManager
      ) {
        processThis = true;
      } else if (
        (this.memberSelect == "all" || this.memberSelect == "member") &&
        !data.isManager
      ) {
        processThis = true;
      }

      console.log(processThis);

      if (processThis && funcProcessThis) {
        totalCount = totalCount + 1;
        let object: any = {};
        let key = "";

        if (groupSwitch == "function") {
          key = data.clientFunction;
        } else {
          key = data.teamName;
        }
        if (groupMap.has(key)) {
          object = groupMap.get(key);
        }
        for (let avaData of data.ava.questions) {
          if (avaData.Type == "Non Value Add") {
            object.nonValueAdd = object.nonValueAdd
              ? parseFloat(object.nonValueAdd) +
                parseFloat(avaData.Answer) * data.total
              : parseFloat(avaData.Answer) * data.total;
          } else if (avaData.Type == "Value Add") {
            object.valueAdd = object.valueAdd
              ? parseFloat(object.valueAdd) +
                parseFloat(avaData.Answer) * data.total
              : parseFloat(avaData.Answer) * data.total;
          } else if (avaData.Type == "Core") {
            object.core = object.core
              ? parseFloat(object.core) +
                parseFloat(avaData.Answer) * data.total
              : parseFloat(avaData.Answer) * data.total;
          }

          // For Activity
          // console.log(avaData);
          if (this.selectedType == "All" || this.selectedType == avaData.Type) {
            actCount = actCount + 1;
            if (activityMap.has(avaData.Activity)) {
              let obj = activityMap.get(avaData.Activity);
              obj.value =
                parseFloat(obj.value) + parseFloat(avaData.Answer) * data.total;
              activityMap.set(avaData.Activity, obj);
            } else {
              let obj: any = {};
              obj.type = avaData.Type;
              obj.value = parseFloat(avaData.Answer) * data.total;
              activityMap.set(avaData.Activity, obj);
            }
          }
        }
        object.fte = object.fte ? object.fte + data.total : data.total;
        groupMap.set(key, object);
      }
    }

    console.log(groupMap);

    // this.teamsData = [];
    this.selectionTeams = [];
    for (let key of groupMap.keys()) {
      let data: any = {};
      const object = groupMap.get(key);
      console.log(object, "FTE & ALL");
      console.log(this.newChartOptions, "Check");
      data.teamName = key;
      data.nonValueAdd = object.nonValueAdd / object.fte;
      data.valueAdd = object.valueAdd / object.fte;
      data.core = object.core / object.fte;
      data.fte = object.fte;
      this.selectionTeams.push(data);
    }
    console.log(this.teamsData, this.selectionTeams, "<>", this.isTeamSet, "<");

    this.changeChart(this.selectionTeams);

    this.isTeamSet = true;

    // For Activities
    this.activityTable2 = [];
    for (let key of activityMap.keys()) {
      let data: any = {};
      let obj = activityMap.get(key);
      //Color coding
      if (obj.type == "Core") {
        data.colorType = "core";
      } else if (obj.type == "Non Value Add") {
        data.colorType = "non-value";
      } else if (obj.type == "Value Add") {
        data.colorType = "value";
      }
      data.name = key;
      data.type = obj.type;
      data.spent = obj.value / totalCount;

      if (data.spent === 5) data.width = "five";
      if (data.spent === 4) data.width = "four";
      if (data.spent === 3) data.width = "three";
      if (data.spent === 2) data.width = "two";
      if (data.spent === 1) data.width = "one";
      //data.colorType = obj.colorType;
      this.activityTable2.push(data);
    }
    console.log("Final", this.activityTable2);
    this.activityTable2.sort((a: any, b: any) => b.spent - a.spent);
    this.activityTable = this.activityTable2;
    this.styleElement = document.createElement("style");
    this.changeColors();
    // console.log("names>>>>", Array.from(this.getXAxis(this.xFilter)));
    // console.log("series>>>>", this.getResults(this.zFilter, this.xFilter, this.yFilter));
    this.cdr.markForCheck();
  }

  changeChart(data: any) {
    //Initialise first
    const outputArray: any = [
      { name: "Core", data: data.map((item: any) => item.core) },
      {
        name: "Non Value Add",
        data: data.map((item: any) => item.nonValueAdd),
      },
      { name: "Value Add", data: data.map((item: any) => item.valueAdd) },
    ];

    const teamNamesArray = data.map((item: any) => item.teamName);

    console.log(teamNamesArray);
    console.log(data);

    console.log(outputArray, teamNamesArray);

    let series = this.getResults(this.zFilter, this.xFilter, this.yFilter);

    let team = Array.from(this.getXAxis(this.xFilter));

    this.newChartOptions = {
      chart: {
        type: "bar",
        series: series,
        width: 850,
        height: 550,
        stacked: true,
        stackType: "100%",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              show: false,
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: teamNamesArray,
        tickAmount: 8,
      },
      yaxis: {
        show: false,
        labels: {
          show: false,
        },
      },
      colors: ["#B3DCF9", "#E0301E", "#175D2D"],
      plotOptions: { bar: { columnWidth: "90", borderRadius: 10 } },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "right",
        // offsetX: 0,
        // offsetY: 50,
      },
    };

    this.newChartOptions.series = outputArray;
    this.newChartOptions.categories = teamNamesArray;
    console.log(this.newChartOptions, "NEWC");
    this.cdr.markForCheck();
  }

  processSelectedTypes(type: string) {
    if (!type || type == "All") {
      this.activityTable2 = this.activityTable;
    } else {
      this.activityTable2 = [];
      for (let x of this.activityTable) {
        if (x.type === type) {
          this.activityTable2.push(x);
        }
      }
      this.activityTable2.sort((a: any, b: any) => b.spent - a.spent);
    }
  }

  styleElement: HTMLStyleElement;
  colors: Array<string> = ["#B3DCF9", "#E0301E", "#175D2D"];

  changeColors() {
    const head = document.getElementsByTagName("head")[0];
    const css = `
  .style1 .mat-progress-bar-fill::after {
    background-color: ${this.colors[0]} !important;
  }

  .style2 .mat-progress-bar-fill::after {
    background-color: ${this.colors[1]} !important;
  }

  .style3 .mat-progress-bar-fill::after {
    background-color: ${this.colors[2]} !important;
  }
  `;
    this.styleElement.innerHTML = "";
    this.styleElement.type = "text/css";
    this.styleElement.appendChild(document.createTextNode(css));
    head.appendChild(this.styleElement);
    console.log(this.styleElement);
  }

  addNewKeyWhenMatched(
    key: any,
    array1: any,
    array2: any,
    newDataKey: any,
    newDataValue: any
  ) {
    for (const obj1 of array1) {
      for (const obj2 of array2) {
        if (obj1[key] === obj2[key]) {
          obj1[newDataKey] = obj1;
          break; // No need to continue checking if a match is found
        }
      }
    }
  }

  changeaT() {
    // Sample arrays of objects
    const array1 = [
      { id: 1, name: "Alice" },
      { id: 2, name: "Bob" },
      { id: 3, name: "Charlie" },
    ];

    const array2 = [
      { id: 2, data: "Data for Bob" },
      { id: 4, data: "Data for Eve" },
      { id: 1, data: "Data for Alice" },
    ];

    // Define the key to compare
    const keyToCompare = "teamName";

    this.addNewKeyWhenMatched;
    // Add a new key "data" with matching data to objects in array1
    this.addNewKeyWhenMatched(
      keyToCompare,
      this.aT,
      this.filterCData,
      "type",
      "Data not found"
    );

    // Output the modified array1
    console.log(array1);
  }

  handleMemberClick(type: any) {
    this.memberSelect = type;
    this.populateBarData();
  }

  getDivColour(type: any) {
    if (type == this.memberSelect) {
      return "black";
    } else {
      return "white";
    }
  }
}
