import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { CustomService } from "@app/core/services/custom.service";
import { DatabaseService } from "@app/database/database.service";
import { SurveyService } from "@app/survey/survey.service";

@Component({
  selector: "app-response-edit",
  templateUrl: "./response-edit.component.html",
  styleUrls: ["./response-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponseEditComponent implements OnInit {
  datasource: any = [];
  dc = ["category", "subCategory", "lens", "question", "Answer"];

  @ViewChild('myinput',{read:ElementRef}) myInput:ElementRef;

  datasource2: any = [];
  dc2 = ["question", "description", "action"];
  POI:boolean = false;
  Survey:boolean = false;
  Mi_Fg:boolean = false;
  panelOpenState = false;
  selectedTab: number = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResponseEditComponent>,
    private surveyService: SurveyService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    // console.log(this.data);
    this.datasource = this.data.dataResponse;
    this.Mi_Fg = true;

    this.datasource.forEach((ele:any) => {
      if(ele.subModule === 'Process Information Required') {
        this.POI = true;
        this.Mi_Fg = false;
        this.datasource2 = this.datasource;
        // console.log(this.datasource2,'<<<<<<<<<<<<<<<<<<<<<<<<<');
        this.cdr.markForCheck();
        return
      }
      else if(ele.ava) {
        this.Survey = true;
        // this.datasource = this.data.dataResponse[0].ava;
        this.tabChange(0)
        this.dc = ["category","question", "Answer"]
        setTimeout(() => {
          this.myInputField.nativeElement.focus();
        }, 1000);
        this.cdr.markForCheck(); 
        return
      }
      else{
        //this.Mi_Fg = true;
      }
    });
  }



  @ViewChild("myinput") myInputField: ElementRef;
  ngAfterViewInit() {
    //this.myInputField.nativeElement.focus();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  tabChange(tabIndex: any) {
    if (tabIndex == 0) {
      this.dc = ["category","question", "Answer"]
      this.datasource = this.data.dataResponse[0].ava;      
      this.cdr.markForCheck()
    }
    else{
      this.dc = ["category", "subCategory", "lens", "question", "Answer"]
      this.datasource = this.data.dataResponse[0].eva;
      this.cdr.markForCheck()
    }
  }

  onSubmit(): void {
    let payload = this.data;
    payload.dataResponse = this.datasource;
    payload.surveyResponseId = this.data._id;

    delete payload._id;

    // console.log(payload);

    // console.log("---- Survey Final Submit payload ----", payload);

    this.surveyService.createSurveyResponse(payload).subscribe((res) => {
      // console.log("----- SURVEY Submitted Response ----", res);

      this.dialogRef.close();
    });
    this.dialogRef.close();
  }
}
