import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { WorkflowTemplateService } from '@app/workflow-template/workflow-template.service';
import { SurveyService } from '@app/survey/survey.service';
import { OnboardingService } from '@app/onboarding/onboarding.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-survey-add',
  templateUrl: './survey-add.component.html',
  styleUrls: ['./survey-add.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false },
    },
  ],
  encapsulation: ViewEncapsulation.None,
})

export class SurveyAddComponent implements OnInit {
  @ViewChild("toggleElement") ref: ElementRef;

  survey_form: any = new FormGroup({
    surveyName: new FormControl(null, Validators.required),
    module: new FormControl(null, Validators.required),
    templateName: new FormControl(null),
  });

  displayedColumns: string[] = ['templates', 'select'];
  modules: any = ["Activity Value Analyser", "Team Leader 1:1", "Op Maturity Assessment", "Performance Variation", "Governance & Change Roadmaps", "Benchmarking", "Process Mapping", "KPI Adherence", "Automation Assessment"];
  datas: any[] = [
    {item: 'Demo Template', cost: 4},
    {item: 'Another Template', cost: 5},
    {item: 'Sample Template', cost: 2},
    {item: 'Survey Template', cost: 4},
    {item: 'Diagnostic Template', cost: 25},
    {item: 'Suit Template', cost: 15},
  ];
  checked:boolean = false;
  clientId:any;
  singleClientDetails: any;
  moduleData:any;
  templateData:any;
  templateId:any;
  avaTemplateId: string = "";
  evaTemplateId: string = "";
 
  constructor(
    private surveyService: SurveyService,
    private workflowTemplateService: WorkflowTemplateService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private onboardingService : OnboardingService,
    private snackBar:MatSnackBar
  ){}
  
  ngOnInit(): void {
    this.clientId = this.activeRoute.snapshot.paramMap.get('id');
    this.getSingleClient(this.clientId);
    this.getModuleData();
    // console.log('AVA', this.avaTemplateId, 'EVA', this.evaTemplateId);
    
  }

  goTo(){
    if(this.survey_form.status === "VALID"){
    this.createSurvey();
    
    }else{
      // console.log("Please fill survey form:",this.survey_form);
    }
  }

  onRowClick(data:any){
    this.survey_form.value.templateName = data.templateName;
    this.templateId = data._id
  }

  updateFunc(e:any) {
    // console.log(e);
    
   e.checked = true
  
    if (e.checked) {
      // console.log('Template Selected:',e);
      // this.ref.checked = false;

    }
  }

  getSingleClient(id: string) {
    this.surveyService.getSingleClient(id).subscribe((res) => {
      this.singleClientDetails = res[0];
    });
  }

  getModuleData(){
    this.onboardingService.getModuleData('MODULE').subscribe((response) => {
      this.moduleData = response;
    },
    (error)=>{
      // console.log('Error on get module list: ', error);
      
    });
  }

  getTemplatesByModule(module:any) {
    this.workflowTemplateService
      .getTemplatesByModule(module)
      .subscribe((res) => {
        this.templateData = res.data
      });
  }

  createSurvey() {
    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const payload = {
      surveyName: this.survey_form.value.surveyName,
      templateId: this.templateId,
      clientId: this.clientId,
      startDate: today,
      endDate: tomorrow,
      moduleId:this.survey_form.value.module
    };

    this.surveyService.createSurvey(payload).subscribe((res) => {
      
      this.snackBar.open("Survey Created Successfully", 'close',{
        duration: 4500,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      this.router.navigate([
        `/survey/${res}/preview`,
      ]);
    });
  }

  onStateSelect(selection: any){
    this.getTemplatesByModule(selection.value);
  }

}
