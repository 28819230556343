<table mat-table [dataSource]="dataSource2" class="mat-elevation-z8">

  
    <ng-container matColumnDef="category">
      <th mat-header-cell *matHeaderCellDef> Category </th>
      <td mat-cell *matCellDef="let element;let i= index">
        <span *ngIf="rowEdited[i]">
          <mat-form-field appearance="outline">
            <input type="text" matInput [(ngModel)]="element.category">
          </mat-form-field>
        </span>
        <span *ngIf="!rowEdited[i]">{{element.category}}</span>
      </td>
    
    </ng-container>
    
    <ng-container matColumnDef="categoryEdit">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element;let i= index">
        <mat-icon (click)="onEdit(element, i,'category')" *ngIf="!rowEdited[i]" matTooltip="Edit"> edit </mat-icon>
        <mat-icon (click)="save(element, i, 'category')" matTooltip="Save" *ngIf="rowEdited[i]"> save </mat-icon>
        <mat-icon *ngIf="rowEdited[i]" matTooltip="Cancel" (click)="onCancel(element, i, $event,'category')"> cancel
        </mat-icon>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="subCategory">
      <th mat-header-cell *matHeaderCellDef> Sub Category </th>
      <td mat-cell *matCellDef="let element;let i= index">
        <span *ngIf="rowEdited[i]">
          <mat-form-field appearance="outline">
            <input type="text" matInput [(ngModel)]="element.subCategory">
          </mat-form-field>
        </span>
        <span *ngIf="!rowEdited[i]">{{element.subCategory}}</span>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="subCategoryEdit">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element;let i= index">
        <mat-icon (click)="onEdit(element, i,'subCategory')" *ngIf="!rowEdited[i]" matTooltip="Edit"> edit </mat-icon>
        <mat-icon (click)="save(element, i ,'subCategory')" matTooltip="Save" *ngIf="rowEdited[i]"> save </mat-icon>
        <mat-icon *ngIf="rowEdited[i]" matTooltip="Cancel" (click)="onCancel(element, i, $event,'subCategory')"> cancel
        </mat-icon>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="lens">
    
      <th mat-header-cell *matHeaderCellDef> Lens </th>
      <td mat-cell *matCellDef="let element;let i = index">
        <span *ngIf="rowEdited[i]">
          <mat-form-field appearance="outline" id="mfw">
            <input type="text" matInput [(ngModel)]="element.lens">
          </mat-form-field>
        </span>
        <span *ngIf="!rowEdited[i]">{{element.lens}}</span>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="lensEdit">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element;let i= index">
        <mat-icon (click)="onEdit(element, i,'lens')" *ngIf="!rowEdited[i]" matTooltip="Edit"> edit </mat-icon>
        <mat-icon (click)="save(element, i, 'lens')" matTooltip="Save" *ngIf="rowEdited[i]"> save </mat-icon>
        <mat-icon *ngIf="rowEdited[i]" matTooltip="Cancel" (click)="onCancel(element, i, $event,'lens')"> cancel </mat-icon>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table> 

  <br>
  <br>

<div id="flxC">
  <button class="active-solid-lrg size" (click)="saveOpMaturity()">Save</button>
  <button class="active-solid-lrg size" *ngIf="!isDefault" (click)="resetOpMaturity()">Reset</button>
</div>
