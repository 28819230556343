import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DatabaseService } from '@app/database/database.service';
import { Subject, debounceTime, distinctUntilChanged, filter, startWith, switchMap, takeUntil, tap } from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import { MatTableDataSource } from '@angular/material/table';
@Component({
    selector: 'app-build-from-database-ava',
    templateUrl: './build-from-database-ava.component.html',
    styleUrls: ['./build-from-database-ava.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class BuildFromDatabaseAvaComponent implements OnInit {
    
    searchInputText = new FormControl('')

    allRefQuestion: any;
    page: number = 0
    perpage: number = 100;
    searchTextChanged = new Subject<string>();
    destroyed$ = new Subject<void>();
    questions: any[] = []
    @ViewChild('autocompleteTrigger') matACTrigger: MatAutocompleteTrigger;
    checkboxSelection: number = -1;

    displayedColumns: string[] = ["activity", "category","select"]; // Add more column names as needed
    dataSource :any;
    selectedRows: any[] = [];
    
    constructor(
        @Inject(MAT_DIALOG_DATA) public data:any,
        private databaseService: DatabaseService,
        private cdr: ChangeDetectorRef,
        private dialogRef: MatDialogRef<BuildFromDatabaseAvaComponent>
    ) {
        
    }
    ngOnInit(): void {
        // console.log('buildFromDb_module', this.databaseService.buildFromDb_module);

        // this.getSearchRefQuestion(this.perpage, this.page, this.databaseService.buildFromDb_module, '')


        // this.searchInputText.valueChanges.pipe(
        //     takeUntil(this.destroyed$),
        //     debounceTime(300),
        //     distinctUntilChanged(),
        //     startWith(''),
        //     filter((q: any) => typeof q === 'string'),
        //     switchMap((searchText: string) => {
        //         console.log('SwitchMap searchText:', searchText); // Check the logged value
        //         this.page = 0
        //         return this.getSearchRefQuestion(this.perpage, this.page, this.databaseService.buildFromDb_module, searchText)
        //     }),
        // ).subscribe((res) => {
        //     res.data.forEach((x: any) => x.selected = false)
        //     this.allRefQuestion = res.data
        //     console.log(this.allRefQuestion);
        //     if (this.questions.length > 0) {
        //         this.questions.forEach((item: any) => {
        //             this.allRefQuestion.forEach((element: any) => {
        //                 if (item.question == element.question) element.selected = true
        //             })
        //         })
        //     }
        //     this.dataSource = this.allRefQuestion;
        //     this.cdr.markForCheck()
        // })
        this.getData(); 
    }

    getSearchRefQuestion(perpage: number, page: number, module: string, searchtext: string) {
        let refDataList = this.databaseService.getSearchRefData(perpage, page, module, searchtext);
        return refDataList
    }
    onScroll() {
        this.page++
        const searchText = this.searchInputText.value || ''
        this.getSearchRefQuestion(this.perpage, this.page, this.databaseService.buildFromDb_module, searchText).subscribe((res) => {
            res.data.forEach((x: any) => x.selected = false)
            this.allRefQuestion = this.allRefQuestion.concat(res.data)
            if (this.questions.length > 0) {
                this.questions.forEach((item: any) => {
                    this.allRefQuestion.forEach((element: any) => {
                        if (item.question == element.question) element.selected = true
                    })
                })
            }
            this.cdr.markForCheck()
        })
    }
    checkbox(index: number, question: any) {
        // console.log('question', question);
        this.searchInputText.setValue('')
        if (!question.selected) {
            this.questions.push(question)
        } else {
            const index = this.questions.indexOf(question)
            this.questions.splice(index, 1)
        }
        question.selected = !question.selected
        this.checkboxSelection = index
        // console.log('questions', this.questions);
        //this.dataSource = this.questions;
        // console.log('table', this.dataSource);
        this.cdr.markForCheck();

    }

    selected(event: MatAutocompleteSelectedEvent): void {
        // const newValue = event.option.viewValue;
        // console.log('newValue',newValue);

        // this.questions.push(newValue)
        // console.log('questions',this.questions);
        // if (this.questions.includes(newValue)) {
        //   this.questions = [...this.questions.filter(fruit=>fruit !== newValue)];
        // } else {
        //   this.questions.push(event.option.viewValue);
        // }
        this.searchInputText.setValue('')
        // this.fruitCtrl.setValue(null);
        // keep the autocomplete opened after each item is picked.
        requestAnimationFrame(() => {
            this.openAuto(this.matACTrigger);
        })
    }

    cancel() {
        this.dialogRef.close()
    }

    openAuto(trigger: MatAutocompleteTrigger) {
        trigger.openPanel();
        // this.searchInputText.nativeElement.focus();
        // console.log(trigger);
    }


    getData(){
        // console.log(this.data);
        if(this.data.type === "processOwners"){

            this.dataSource = this.data.questionsData;
            this.cdr.markForCheck();
        } else{

            this.databaseService.getRefDatabyModule(this.data.type).subscribe((res) => {
                // console.log(res);
    
                const keysSet = new Set(this.data.questionsData.map((obj:any) => obj["_id"]));
                const filteredArray = res.filter((obj:any) => !keysSet.has(obj["_id"]));
                // console.log(filteredArray);
                this.dataSource = filteredArray;
                this.cdr.markForCheck();
            })
        }
    }

    //TABLE CHECKBOX
    isAllSelected() {
        return this.selectedRows.length === this.dataSource.length;
      }
    
      selectAll(event: any) {
        if (event.checked) {
          this.selectedRows = this.dataSource;
          this.questions = this.selectedRows;
        //   console.log(this.questions);
          this.cdr.markForCheck();
          
        } else {
          this.selectedRows = [];
        }
      }
    
      selectRow(row: any) {
        if (this.isSelected(row)) {
          this.selectedRows = this.selectedRows.filter(r => r !== row);
        } else {
          this.selectedRows.push(row);
        }
        this.questions = this.selectedRows;
        // console.log(this.questions);
        this.cdr.markForCheck();
        
      }
    
      isSelected(row: any) {
        return this.selectedRows.includes(row);
      }
  //TABLE CHECKBOX

  loadQuestions() {
    let question: any = this.questions
    question.forEach((element: any) => {
        if(this.data.type != "processOwners"){
            delete element._id;
        }
        delete element.selected;
        delete element.isActive;
        delete element.templateId;
    })

    // console.log('load', question, this.questions);
    this.dialogRef.close({ event: 'loadQuestion', data: question })

}

    ngOnDestroy() {
        this.destroyed$.next()
        this.destroyed$.complete()
    }
}