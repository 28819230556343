
export const getFullMapping = (data: any, type: string, key : string , keyValue : string) => {
    let system = data.system;
    let defined = data.defined;
  
    let systemMap = new Map();
    let definedMap = new Map()
  
  
    for( let sys of system) {
      let def = defined.find( (x: { opId: any; }) => x.opId = sys.opId);
  
      if(def) {
        if(!systemMap.has('category-' + sys.category)) {
          systemMap.set('category-' + sys.category,def.category)
        }
        if(!systemMap.has('subCategory-' + sys.subCategory)) {
          systemMap.set('subCategory-' + sys.subCategory,def.subCategory)
        }
        if(!systemMap.has('lens-' + sys.lens)) {
          systemMap.set('lens-' + sys.lens,def.lens)
        }
      } else {
        if(!systemMap.has('category-' + sys.category)) {
          systemMap.set('category-' + sys.category,sys.category)
        }
        if(!systemMap.has('subCategory-' + sys.subCategory)) {
          systemMap.set('subCategory-' + sys.subCategory,sys.subCategory)
        }
        if(!systemMap.has('lens-' + sys.lens)) {
          systemMap.set('lens-' + sys.lens,sys.lens)
        }
      }
    }
  
  
  
    for( let sys of defined) {
      let def = system.find( (x: { opId: any; }) => x.opId = sys.opId);
  
      if(def) {
        if(!definedMap.has('category-' + sys.category)) {
          definedMap.set('category-' + sys.category,def.category)
        }
        if(!definedMap.has('subCategory-' + sys.subCategory)) {
          definedMap.set('subCategory-' + sys.subCategory,def.subCategory)
        }
        if(!definedMap.has('lens-' + sys.lens)) {
          definedMap.set('lens-' + sys.lens,def.lens)
        }
      } else {
   if(!definedMap.has('category-' + sys.category)) {
          definedMap.set('category-' + sys.category,sys.category)
        }
        if(!definedMap.has('subCategory-' + sys.subCategory)) {
          definedMap.set('subCategory-' + sys.subCategory,sys.subCategory)
        }
        if(!definedMap.has('lens-' + sys.lens)) {
          definedMap.set('lens-' + sys.lens,sys.lens)
        }
      }
    }
  
    if(type === 'system') {
      let val = key+'-'+keyValue;
      return definedMap.get(val)
    } else {
      let val = key+'-'+keyValue;
      return systemMap.get(val)
    }
  
  }

// getSubCategory(type:'ystem',category:"") {
//   return 'Dummy1'
// }

export const getCategory = (type:'ystem',category:"") => {
  return 'Dummy1'
}

// getLens(type:'ystem',category:"") {
//   return 'Dummy1'
// }

export const getLensMap = (data:any) => {
  let outData: any[] = [];
  for (let item of data.defined) {
    let catIndex = outData.findIndex((x) => x.category === item.category);
    if (catIndex < 0) {
      outData.push({
        category: item.category,
        subCategory: [item.subCategory],
        lens: [{ subCategory: item.subCategory, lens: [item.lens] }],
      });
    } else {
      let catObj: any = outData[catIndex];
      console.log(catObj);
      let subCatIndex = catObj.subCategory.findIndex(
        (x: string) => x === item.subCategory
      );

      if (subCatIndex < 0) {
        catObj.subCategory.push(item.subCategory);
      }

      let lensObjIndex = catObj.lens.findIndex(
        (x: { subCategory: string }) => x.subCategory === item.subCategory
      );

      if (lensObjIndex < 0) {
        catObj.lens.push({ lens: [item.lens], subCategory: item.subCategory });
      } else {
        let lensIndx = catObj.lens[lensObjIndex].lens.findIndex(
          (x: string) => x === item.lens
        );
        if (lensIndx < 0) {
          catObj.lens[lensObjIndex].lens.push(item.lens);
        }
        //console.log(catObj.lens[lensObjIndex]);
      }
      outData[catIndex] = catObj;
    }
  }
  return outData;
};


// export const getLensMap  = (data:any)  => {
//     let outData: any[] = []
//     for(let item of data.defined) {
//         let catIndex = outData.findIndex(x => x.category === item.category)
//         if(catIndex < 0) {
//                         outData.push({"category" : item.category , "subCategory" : [item.subCategory], "lens" : [{"subCategory" : item.subCategory , "lens" : [item.lens]}]})

//         } else {
        
//         let catObj: any = outData[catIndex];
//             let subCatIndex = catObj.subCategory.findIndex( (x: string) => x === item.subCategory )
//             if(subCatIndex < 0) {
//                 catObj.subCategory.push(item.subCategory)
//             }
//             let allLens = catObj.lens.find((x: { subCategory: string; }) => x.subCategory === item.subCategory);

//             if (allLens) {
//               let lensIndx = allLens.lens.findIndex( (x: string) => x === item.lens );
//               if(lensIndx < 0) {
//                 catObj.lens.push(item.lens)
//               }
//             } else {
//               catObj.lens.push({ lens : [item.lens] , subCategory : item.subCategory})
//             }
//             outData[catIndex] = catObj;
//         }
//     }
//     return outData;
// }

export const getSystemMap = (data:any,dataMap: any) => {

    let id = data.defined.findIndex( (x:any) => x.category === dataMap.category && x.subCategory === dataMap.subCategory && x.lens === dataMap.lens)
    if(id) {
        let obtainedMap = data.system[id]
        return obtainedMap;
    } else {
        return dataMap;
    }
}

export function getCategoryMap(data:any) {
  let catMap = new Map();
  for(let obj of data.system) {
      let xobj : any = {}
     xobj = data.defined.find((x: { opId: any; }) => x.opId === obj.opId);
     catMap.set(obj.category,xobj.category);
  }
  return catMap;
}

export function getDefinedMap (data:any,dataMap: any) {
    let id = data.system.findIndex( (x:any) => x.category === dataMap.category && x.subCategory === dataMap.subCategory && x.lens === dataMap.lens)
    if(id > -1) {
        let obtainedMap = data.defined[id]
        return obtainedMap;
    } else {
        return dataMap;
    }
}

export const getSettingId = (data:any, dataMap: any, setting : string ) => {
    if(setting == 'defined') {
        let id = data.defined.find( (x: { category: any; subCategory: any; lens: any; }) => x.category === dataMap.category && x.subCategory === dataMap.subCategory && x.lens === dataMap.lens)
        return id ? id.opId : "Not found"
    } else {
        let id = data.system.find( (x: { category: any; subCategory: any; lens: any; }) => x.category === dataMap.category && x.subCategory === dataMap.subCategory && x.lens === dataMap.lens)
        return id ? id.opId : "Not found"
    }
}

export const getMapById = (data:any, opId: string, setting : string) => {
    if(setting == 'defined') {
        let id = data.defined.find( (x: { opId: string; }) => x.opId === opId)
        return id
    } else {
        let id = data.system.find( (x: { opId: string; }) => x.opId === opId)
        return id
    }
}


const sampleMap = [
    {
        "_id": "661dfb575bac164ac951632d",
        "category": "Customer and Purpose",
        "subCategory": [
            "Value"
        ],
        "lens": [
            {
                "lens": [
                    "Customer Journeys",
                    "Customer feedback",
                    "Understanding customer value",
                    "Team Purpose",
                    "Improvement plans",
                    "Voice of business"
                ],
                "subCategory": "Value"
            }
        ]
    },
    {
        "_id": "661dfb575bac164ac9516331",
        "category": "Performance",
        "subCategory": [
            "Vision & KPIs",
            "Visual Management & Agile Operating Rhythm",
            "Root Cause Problem Solving",
            "AVA"
        ],
        "lens": [
            {
                "lens": [
                    "KPIs",
                    "Targets",
                    "MI",
                    "Quality management"
                ],
                "subCategory": "Vision & KPIs"
            },
            {
                "lens": [
                    "Visual management",
                    "Visual management Huddles - Occurrence",
                    "Huddles - Content & Facilitation",
                    "Tiered system"
                ],
                "subCategory": "Visual Management & Agile Operating Rhythm"
            },
            {
                "lens": [
                    "Structured Problem Solving",
                    "Triggers",
                    "Escalation process",
                    "Failure demand / rework"
                ],
                "subCategory": "Root Cause Problem Solving"
            }
        ]
    },
    {
        "_id": "661dfb585bac164ac951633b",
        "category": "Intelligent Automation and Process",
        "subCategory": [
            "Process Flow",
            "Process Automation",
            "Process Adherence"
        ],
        "lens": [
            {
                "lens": [
                    "Visibility of processes and ownership",
                    "Process design",
                    "Inbound quality",
                    "Systems enablement / workflow",
                    "Risk management"
                ],
                "subCategory": "Process Flow"
            },
            {
                "lens": [
                    "Selection criteria and process for new automation opportunities",
                    "RPA & workflow Implementation status",
                    "AI and advanced technology implementation status",
                    "Automation supplier relationships"
                ],
                "subCategory": "Process Automation"
            },
            {
                "lens": [
                    "Use of standards",
                    "Knowledge management of standards and process confirmation",
                    "Process confirmation and adherence",
                    "Work organisation"
                ],
                "subCategory": "Process Adherence"
            }
        ]
    },
    {
        "_id": "661dfb585bac164ac9516343",
        "category": "Problem Solving",
        "subCategory": [
            "Operational Capacity Management"
        ],
        "lens": [
            {
                "lens": [
                    "Forecast",
                    "Control",
                    "Review",
                    "Continuous improvement culture",
                    "Continuous improvement governance",
                    "Structured problem solving"
                ],
                "subCategory": "Operational Capacity Management"
            }
        ]
    },
    {
        "_id": "661dfb585bac164ac9516347",
        "category": "People and Capability",
        "subCategory": [
            "Capability",
            "Motivation & Engagement",
            "Leadership"
        ],
        "lens": [
            {
                "lens": [
                    "Deskside coaching",
                    "Coaching / 1-1s",
                    "Awareness & visibility of skills",
                    "Training & development plans",
                    "Demand and capacity management"
                ],
                "subCategory": "Capability"
            },
            {
                "lens": [
                    "Successes captured",
                    "Working styles",
                    "Team building",
                    "Colleague engagement"
                ],
                "subCategory": "Motivation & Engagement"
            },
            {
                "lens": [
                    "Programme sponsorship",
                    "Middle management visibility",
                    "Senior management visibility",
                    "Role models and behaviors"
                ],
                "subCategory": "Leadership"
            }
        ]
    }
  ]