<mat-toolbar class="lg">
    <div class="navbar-logo-div">
        <a class="navbar-logo-link lg-space">
            <img width="60px" height="24px" src="../../assets/icons/logo.svg" alt="pwc">
        </a>
    </div></mat-toolbar>

<br>

<div class="survey-poi" *ngIf="preview">
    <mat-tab-group mat-align-tabs="center" class="invisible-tabs" [selectedIndex]="selected">
        <mat-tab *ngIf="preview">
            <section class="poi">
                <div class="sticky">
                    <mat-card class="right nill">
                        <span class="surveyhead flex">Process Owner Interview</span>
                        <p class="para">
                            Input process information
                        </p>
                    </mat-card>
                    <br>
                    <mat-card *ngFor="let data of processOwners">
                  
                        <div *ngFor="let item of data.values">
                         
                            <div class="flex-full">
                                <div>
                                    <mat-label>{{item.question}}
                                    </mat-label>
                                </div>
                                <div>
                                    <mat-icon [matTooltip]="item.description" [matTooltipPosition]="'right'" *ngIf="item.description"
                                            class="ic-size">help_outline</mat-icon>
                                   </div>
                            </div>
                            
                            <br>
                            <mat-form-field appearance="outline" class="size" *ngIf="!item.options" >
                                <input matInput placeholder="{{item.placeholder}}" readonly>
                            </mat-form-field>
                            <br>
                            <mat-form-field appearance="outline" class="size" *ngIf="item.options" >
                                <mat-label>Select</mat-label>
                                <mat-select >
                                    <mat-option *ngFor="let value of item.options" [value]="value.textAnswer" [disabled]="true"> {{ value.textAnswer }}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </mat-card>

                    <br>

                    <div class="sticky submit flex">
                        <button class="active-solid-lrg but-style" (click)="goBack()">Back</button>
                        <button class="active-solid-lrg but-style" (click)="createTemplatePOI()">Create</button>
                    </div>
                </div>
                <app-footer></app-footer>

            </section>
        </mat-tab>
    </mat-tab-group>

</div>

<div class="survey-poi" *ngIf="!preview">
    <mat-tab-group mat-align-tabs="center" class="invisible-tabs" [selectedIndex]="selected">
        <mat-tab *ngFor="let data of processOwners">
            <section class="poi" *ngIf="data.subModule !== 'Op maturity'">
                <div class="sticky" *ngIf="!isSubmit">
                    <mat-card class="right">
                        <span class="surveyhead flex">Process Owner Interview</span>
                        <p class="para">
                            {{data.subModule}}
                        </p>
                    </mat-card>
                    <br>
                    <mat-card >
                        <!-- <mat-label>{{data.subModule}}</mat-label>
                        <br> -->
                        <div class="right" *ngFor="let item of data.values;">
                            <section *ngIf="item.question !== 'Function' && item.question !=='Team'">
                                <span class="tool">
                                    <mat-label>{{item.question}}</mat-label>
                                    <mat-icon [matTooltip]="item.description" *ngIf="item.description"
                                     class="ic-size">help_outline</mat-icon>
                                </span>
                                <br>
                                <mat-form-field appearance="outline" class="size" *ngIf="!item.options">
                                    <input *ngIf="item.type !='number'" matInput [(ngModel)]="item.answer" placeholder="Input" 
                                    type="text" required>
                                    <input *ngIf="item.type =='number'" 
                                    matInput [(ngModel)]="item.answer" placeholder="Input" type="number" appNumericOnly required>

                                </mat-form-field>
                                <br>
                                <mat-form-field appearance="outline" class="size" *ngIf="item.options">
                                    <mat-label>Select</mat-label>
                                    <mat-select [(ngModel)]="item.answer" (selectionChange)="onChange($event.value,selected,data.subModule)"
                                        required>
                                        <span *ngFor="let value of item.options">
                                            <span *ngIf="value.textAnswer == 'None'">
                                                <mat-option [value]="0"> {{ value.textAnswer }}
                                                </mat-option>
                                            </span>
                                            <span *ngIf="value.answer && value.textAnswer != 'None'">
                                                <mat-option [value]="value.answer"> {{ value.textAnswer }}
                                                </mat-option>
                                            </span>
                                            <span *ngIf="!value.answer && value.textAnswer != 'None'">
                                                <mat-option [value]="value.textAnswer"> {{ value.textAnswer }}
                                                </mat-option>
                                            </span>
                                        </span>
                                    </mat-select>
                                </mat-form-field>
                            </section>

                            <!-- Function addition -->
                            <section *ngIf="item.question === 'Function'">
                                <span class="tool">
                                    <mat-label>{{item.question}}</mat-label>
                                    <mat-icon [matTooltip]="item.description" *ngIf="item.description" class="ic-size">help_outline</mat-icon>
                                </span>
                                <br>
                                <mat-form-field appearance="outline" class="size">
                                    <mat-label>Select</mat-label>
                                    <mat-select [(ngModel)]="item.answer" (selectionChange)="Check($event)"
                                        required>
                                        <span *ngFor="let value of functions">
                                            <span *ngIf="value">
                                                <mat-option [value]="value"> {{ value }}
                                                </mat-option>
                                            </span>
                                            <span *ngIf="!value">
                                                <mat-option [value]="value"> {{ value }}
                                                </mat-option>
                                            </span>
                                        </span>
                                    </mat-select>
                                </mat-form-field>
                            </section>
                            <!-- Team addition -->
                            <section *ngIf="item.question === 'Team'">
                                <span class="tool">
                                    <mat-label>{{item.question}}</mat-label>
                                        <mat-icon [matTooltip]="item.description" *ngIf="item.description" class="ic-size">help_outline</mat-icon>
                                </span>
                                                                <br>
                                <mat-form-field appearance="outline" class="size">
                                    <mat-label>Select</mat-label>
                                    <mat-select [(ngModel)]="item.answer" (selectionChange)="Team($event.value,item)" required>
                                        <span *ngFor="let value of teams">
                                            <span *ngIf="value">
                                                <mat-option [value]="value"> {{ value }}</mat-option>
                                            </span>
                                             <span *ngIf="!value">
                                                <mat-option [value]="value"> {{ value }}</mat-option>
                                            </span>
                                        </span>
                                    </mat-select>
                                </mat-form-field>
                            </section>
                        </div>
                    </mat-card>
 
                    <br>

                    <div class="sticky submit flex">
                        <button *ngIf="data.subModule !='Process Information Required'" class="active-solid-lrg but-style" (click)="back(selected,data.values,data.subModule)">Back</button>
                        <button class="active-solid-lrg but-style" (click)="nav(data.values,data.subModule)" *ngIf="!isSubmit">Next</button>
                        <button class="active-solid-lrg but-style" (click)="dm()" *ngIf="isSubmit">Create</button>
                    </div>
                </div>
                <div class="sticky" *ngIf="isSubmit">
                    <mat-card class="right nill">
                        <!-- <span class="surveyhead flex">Process Owner Interview</span> -->
                        <span class="surveyhead flex">Thank you!</span>
                        <p class="para">You have completed all of the questions,</p>
                        <p class="para">You can now close the survey.</p>
                    </mat-card>
                    <br>
                    <button class="active-solid-lrg " style="width: 30rem;" (click)="close()">
                        Close Tab
                    </button>
                </div>
            </section>
            <section class="poi" *ngIf="data.subModule === 'Op maturity'">
                <div class="sticky" *ngIf="!isSubmit">
                    <mat-card class="right">
                        <span class="surveyhead flex">Process Owner Interview</span>
                        <p class="para">
                            {{data.subModule}}
                        </p>
                    </mat-card>
                    <br>
                    <mat-card>
                        <div class="row">
                            <div class="right" style="height: 10vh;">
                                <div class="col-sm-11 center descStars">
                                    <div class="desc">
                                        <div class="">
                                            <section class='alignDescStars'>
                                                <div class="star-container">
                                                    <div class="star-text">Strongly Disagree</div>
                                                    <div class="star-text"> Disagree</div>
                                                    <div class="star-text">Neither</div>
                                                    <div class="star-text">Agree</div>
                                                    <div class="star-text">Strongly Agree</div>
                    
                                                </div>
                                                <div class="star-container">
                                                    <i *ngFor="let item of timeline" class="fa fa-star str star"></i>
                                                </div>
                    
                                            </section>
                    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </mat-card>
                    <br>
                    <mat-card >
                        <div class="right" *ngFor="let item of data.values;">
                            <section>
                                <span class="tool">
                                    <mat-label>{{item.question}}</mat-label>
                                    <mat-icon [matTooltip]="item.description" *ngIf="item.description" class="ic-size">help_outline</mat-icon>
                                </span>
                                <!-- <br> -->
                                <!-- // Rating Input-->
                                <div class="">
                                    <div class="">
                                        <ul class="list-inline rating-list " [ngClass]="preview ? 'rating-disabled' : ''"
                                            *ngFor="let star of stars" style="display: inline-block">
                                            <li (click)="countStar(item,star)" [ngClass]="{'selected': (star <= item?.answer)}">
                                                <i class="fa fa-star "></i>
                                            </li>
                                            <br>
                                        </ul>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </mat-card>
 
                    <br>

                    <div class="sticky submit flex">
                        <button class="active-solid-lrg but-style" (click)="back(selected,data.values,data.subModule)">Back</button>
                        <button class="active-solid-lrg but-style" (click)="nav(data.values,data.subModule)" *ngIf="!isSubmit">Submit</button>
                        <button class="active-solid-lrg but-style" (click)="dm()" *ngIf="isSubmit">Create</button>
                    </div>
                </div>
                <div class="sticky" *ngIf="isSubmit">
                    <mat-card class="right nill">
                        <!-- <span class="surveyhead flex">Process Owner Interview</span> -->
                        <span class="surveyhead flex">Thank you!</span>
                        <p class="para">You have completed all of the questions,</p>
                        <p class="para">You can now close the survey.</p>
                    </mat-card>
                    <br>
                    <button class="active-solid-lrg " style="width: 30rem;" (click)="close()">
                        Close Tab
                    </button>
                </div>
            </section>
        </mat-tab>
    </mat-tab-group>
    <app-footer></app-footer>

</div>
