import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { Router } from "@angular/router";
import { DragScrollComponent } from "ngx-drag-scroll";
import { HomeService } from "./home.service";
import { SnackBarService } from "@app/core/services/snackbar.service";
import { AuthService } from "@app/core/services/auth.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
  spinner: boolean = false;
  @ViewChild("nav", { read: DragScrollComponent, static: true })
  ds: DragScrollComponent;
  leftNavDisabled = false;
  rightNavDisabled = false;
  xDisabled: any;
  yDisabled: any;
  index = 0;
  role: any;

  clients: any;
  homeScreenContents: any;

  timer: number = 10;
  stopwatch: NodeJS.Timeout;

  constructor(
    private homeService: HomeService,
    public router: Router,
    private cdr: ChangeDetectorRef,
    public snackBar: SnackBarService,
    private authService: AuthService

  ) {
    this.yDisabled = true;
  }

  ngOnInit(): void {
    this.spinner = true;
    this.getClients();
    this.clock();
    this.role = localStorage.getItem("role");
    sessionStorage.setItem("CLIENTID", "empty");
    sessionStorage.clear();
    setInterval(() => {
      const user = this.authService.getCurrentUserValue();
      if(user && user.expired) {
        this.authService.userManager.signinSilent().then(newUser => {
          // console.log("Refreshed")
        }).catch(error => {
          // console.log("Error Refreshing", error);
        })
      }
    }, 60000)
  }

  clock() {
    this.stopwatch = setInterval(() => {
      if (this.timer > 0) {
        this.timer = this.timer - 1;
        this.cdr.markForCheck();
      } else {
        clearInterval(this.stopwatch);
      }
    }, 1000);
    this.spinner = false;
  }

  editClient(isEditClientData: boolean, element: any) {
    this.router.navigate(["onboard/edit"], {
      queryParams: {
        id: element._id,
      },
    });
  }

  viewClient(isEditClientData: boolean, element: any) {
    this.router.navigate(["onboard/view"], {
      queryParams: {
        id: element._id,
      },
    });
  }

  getClients() {
    const role = localStorage.getItem("role");
    if (role != "client") {
      this.homeService.getAllClients().subscribe((res) => {
        this.clients = res;
      });
    } else {
      this.homeService.getClientId().subscribe(
        (res) => {
          // console.log(res);
          sessionStorage.setItem("clientId", res);
          this.router.navigate([`/dashboard/${res}/overview`]);
          this.homeService.getClients(res).subscribe((res) => {
            this.clients = res;
          });
        },
        (err) => {
          this.snackBar.open(err, "close");
        }
      );
    }
  }

  toggleXDisable() {
    this.xDisabled = !this.xDisabled;
  }
  toggleYDisable() {
    this.yDisabled = !this.yDisabled;
  }

  moveLeft() {
    this.ds.moveLeft();
  }

  moveRight() {
    this.ds.moveRight();
  }

  moveTo(index: any) {
    this.ds.moveTo(index);
  }

  leftBoundStat(reachesLeftBound: boolean) {
    this.leftNavDisabled = reachesLeftBound;
  }

  rightBoundStat(reachesRightBound: boolean) {
    this.rightNavDisabled = reachesRightBound;
  }

  onSnapAnimationFinished() {}

  onIndexChanged(idx: any) {
    this.index = idx;
  }

  onDragScrollInitialized() {}

  onSetupModule(clientId: string) {
    this.router.navigate([`client/${clientId}/template`]);
  }

  goToSurvey(clientData: any) {
    this.router.navigate([`/add-survey/${clientData._id}`]);
  }

  viewProject(clientData: any) {
    sessionStorage.setItem("clientId", clientData._id);
    this.router.navigate([`/project/${clientData._id}/overview`]);
    sessionStorage.setItem("CLIENTID", clientData._id);
  }

  // View;
}
