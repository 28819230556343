import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  OnDestroy,
} from "@angular/core";
import { AuthService } from "../services/auth.service";
import { ActivatedRoute, NavigationStart, Router } from "@angular/router";
import { ProjectService } from "@app/projects/project.service";
import { HomeService } from "@app/home/home/home.service";
import { filter, of } from "rxjs";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { ReqHistoryComponent } from "@app/masterdata/req-history/req-history.component";
@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  @ViewChild("appDrawer") appDrawer: ElementRef;

  isAdmin: boolean = false;
  master: boolean = false;
  databaseMenu: boolean = false;
  token: any = localStorage.getItem("encodedRole") || "";
  modules: boolean = false;
  subModules: boolean = false;
  AVA: boolean = false;
  opMaturtiy: boolean = false;
  managerInterview: boolean = false;
  focusGroup: boolean = false;
  processInterview: boolean = false;

  modulesList: any = {
    "Operational Maturity": false,
    "Team Leader Interviews": false,
    "Process Owner Interviews": false,
    "DATA FEED: PRODUCTIVITY": false,
    "DATA FEED: PROCESS": false,
    "CLIENT EXPERIENCE": false,
    "Focus Groups": false,
    "Surveys": false,
    "PERFORMANCE VARIABILITY ANALYSIS": false,
    "PROCESS DISCOVERY": false,
    "DATA AUTOMATION": false,
    "AI HEAT MAPPING": false,
    "DATA DIAGNOSTICS": false,
  };
  clientModulesData: any;
  clientId: any;
  clientData: any;
  showProject: boolean = false;
  displaySelection: any;
  url: any;

  role: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private projectService: ProjectService,
    private homeService: HomeService,
    private cdr: ChangeDetectorRef,
    private location: Location,
    private matdialog: MatDialog,
  ) {
    let url = router.url;
    this.role = localStorage.getItem("role");
    let split: any = url.split("/");
    console.log("route name", split);
    let split2 = split.at(-1);
    let view = split2.split("?");
    this.displaySelection = split.at(-1).includes("?")
      ? view.at(0)
      : split.at(-1);
    if (split.length == 5)
      this.displaySelection = split.at(-2) + " " + split.at(-1);
    this.displaySelection = this.displaySelection
      .replace(/-/g, " ") // Replace hyphens with spaces
      .replace(/\b\w/g, (match: string) => match.toUpperCase());
    if (split[1] == "project") {
      this.showProject = true;
      this.modules = true;
      this.clientId = split[2];
      this.getClientData();
      this.getModulesByClientId();
    } else if (split[1] == "dashboard") {
      this.showProject = true;
      this.modules = true;
      this.clientId = split[2];
      this.getClientData();
      this.getModulesByClientId();
      //  this.showProject = false
     }

    this.router.events.subscribe((res: any) => {
      if (res instanceof NavigationStart) {
        console.log('showProject', res instanceof NavigationStart)
        console.log('event', res.url)
        let split: any = res.url.split('/')
        let split2 = split.at(-1)
        let view = split2.split('?')
        this.displaySelection = split.at(-1).includes('?') ? view.at(0) : split.at(-1)
        if (split.length == 5) this.displaySelection = split.at(-2) + ' ' + split.at(-1)
        this.displaySelection = this.displaySelection.replace(/-/g, ' ') // Replace hyphens with spaces
          .replace(/\b\w/g, (match: string) => match.toUpperCase())
        console.log('route name', split)
        if (split[1] == 'project') {
          this.showProject = true
          this.modules = true
          this.clientId = split[2]
          this.getClientData()
          this.getModulesByClientId()
        } else if (split[1] == 'dashboard') {
          this.showProject = true
          this.modules = true
          this.clientId = split[2]
          this.getClientData()
          this.getModulesByClientId()
          //  this.showProject = false
        }
        else {
          this.showProject = false
          this.modules = true
        }
        this.cdr.markForCheck();
        if (split[3] == "overview") {
          this.setTofalse(); // set to false once we land in overview screen
        }

        if(this.displaySelection === "Ava Eva Setup") this.displaySelection = "Survey Setup";
        if(this.displaySelection === "Ava Eva Outputs") this.displaySelection = "Survey Outputs";
        if(this.displaySelection === "Ava Eva Responses") this.displaySelection = "Survey Responses";
      }
    })
  }

 

  ngOnInit(): void {
    this.isAdmin = this.authService.checkAdminRights(this.token);
    this.Check();
  }

  dash() {
    let url = this.router.url;
    let split: any = url.split("/");
    console.log("DASH", split);
    this.showProject = true;
    this.modules = true;
    this.clientId = split[2];
    this.getClientData();
    this.getModulesByClientId();
    this.cdr.markForCheck();
    this.router.navigateByUrl(`dashboard/${this.clientId}/overview`);
  }

  Check() {
    this.url = this.router.url;
    console.log("url", this.url);
    // let value:any = sessionStorage.getItem('CLIENTID')
    //  if(value == 'empty') this.clientId = 'empty'
    //  else{
    //   this.clientId = value
    //   this.getClientData()
    //     this.getModulesByClientId()
    //  }
    this.cdr.markForCheck();
  }

  setTofalse(){
    this.modulesList = {
      "Operational Maturity": false,
      "Team Leader Interviews": false,
      "Process Owner Interviews": false,
      "DATA FEED: PRODUCTIVITY": false,
      "DATA FEED: PROCESS": false,
      "CLIENT EXPERIENCE": false,
      "Focus Groups": false,
      "Surveys": false,
      "PERFORMANCE VARIABILITY ANALYSIS": false,
      "PROCESS DISCOVERY": false,
      "DATA AUTOMATION": false,
      "AI HEAT MAPPING": false,
      "DATA DIAGNOSTICS": false,
    }

    this.AVA = false
    this.opMaturtiy = false
    this.managerInterview = false
    this.focusGroup = false
    this.processInterview = false


  }

  getClientData(){
    this.homeService.getClients(this.clientId).subscribe(res=>{
      this.clientData = res[0]
      this.cdr.markForCheck()
    })
  }

  getModulesByClientId() {
    this.projectService.getModulesByClientId(this.clientId).subscribe((res) => {
      res.map((modules: any) => {
        const endDate = new Date(modules.endDate).getTime();
        const today = new Date().getTime();
        if (endDate > today) {
          modules.workflowStatus = "Completed";
        } else {
          modules.workflowStatus = "Pending";
        }
      });
      this.clientModulesData = res;
      //reset modulesList
      this.modulesList = {
        "Operational Maturity": false,
        "Team Leader Interviews": false,
        "Process Owner Interviews": false,
        "DATA FEED: PRODUCTIVITY": false,
        "DATA FEED: PROCESS": false,
        "CLIENT EXPERIENCE": false,
        "Focus Groups": false,
        "Surveys": false,
        "PERFORMANCE VARIABILITY ANALYSIS": false,
        "PROCESS DISCOVERY": false,
        "DATA AUTOMATION": false,
        "AI HEAT MAPPING": false,
        "DATA DIAGNOSTICS": false,
      };
      //reset done
      Object.entries(this.modulesList).forEach(([key, value]) => {
        this.clientModulesData.forEach((selection: any) => {
          if (key == selection.moduleId) {
            console.log("selection matched");
            this.modulesList[key] = true;
          }
        });
      });
      console.log("client module", this.clientModulesData);
      console.log("selected module", this.modulesList);
    });
  }

  toggle() {
    this.master = !this.master;
  }

  databaseToggle() {
    this.databaseMenu = !this.databaseMenu;
  }

  onSignOut() {
    this.projectService.userLogout().subscribe((res) => {
    localStorage.clear();
    sessionStorage.clear();
    this.authService.logout();
    });
  }

  construction() {
    this.router.navigate([`/project/${this.clientId}/construction`]);
  }

  action(value: string) {
    if (value == "back") this.location.back();
    if (value == "front") this.location.forward();
  }

  @HostListener("window:storage", ["$event"])
  onStorageChange(event: StorageEvent) {
    console.log("StorageEvent", event);
  }

  //
  openHistory() {
    let dialog = this.matdialog.open(ReqHistoryComponent, {
      width: "95em",
      // height: '750px',
      disableClose: false,
      data: "Initiate",
    });
  }

  reset() {
    this.opMaturtiy = false;
    this.processInterview = false;
    this.managerInterview = false;
    this.AVA = false;
    this.focusGroup = false;
  }
}
