import { Component, OnInit, inject } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "../core/services/auth.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { User } from "oidc-client-ts";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  loginForm: any;
  readonly authService = inject(AuthService)
  readonly router = inject(Router)

  messages: string[] = [];

  currentUser: User | null = null;

  get currentUserJson(): string {
    return JSON.stringify(this.currentUser, null, 2);
  }
  login() {
    // this.oauthService.initImplicitFlow();
  }

  logout() {
    this.authService.logout();
  }

  hide: boolean = true;

  signinForm: any = new FormGroup({
    email: new FormControl(null, [Validators.required]),
    password: new FormControl(null, [Validators.required]),
  });

  ngOnInit(): void {      
    if(localStorage.getItem("encodedRole")) {
      this.router.navigate(["/home"]);
    }
  }

  get loginFormControls() {
    return this.signinForm.controls;
  }

  async onAuthorize() {
    // const user = await this.auth.getUser();
    // console.log(user);
    
  }

  onSignIn() {
    
    this.authService
    .login().catch((err) => {
      // console.log(err);
    });
    // if (!this.signinForm.invalid) {
    //   this.authService
    //     .login(
    //       this.loginFormControls.email.value,
    //       this.loginFormControls.password.value
    //     ).catch((err) => {
    //       console.log(err);
    //     });
    //     // .subscribe({
    //     //   next: (data: any) => {
    //     //     this.router.navigate(["/home"]);
    //     //     //Navigate to dashboard url with clientId, if role is client here..
    //     //     //if(data) {}
    //     //   },
    //     //   error: (err: any) => {
    //     //     this.snackbar.open("Invalid User Credentials", "close");
    //     //   },
    //     // });
    // }
  }
}
