import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { ActivatedRoute, Router } from '@angular/router';
import { HomeService } from '@app/home/home/home.service';
import { OnboardingService } from '@app/onboarding/onboarding.service';
import { WorkflowTemplateService } from '@app/workflow-template/workflow-template.service';
import * as apex from 'ng-apexcharts';
import { TemplateAddComponent } from '@app/workflow-template/template-add/template-add.component';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-eva',
  templateUrl: './eva.component.html',
  styleUrls: ['./eva.component.scss']
})
export class EvaComponent implements OnInit {

  //Radar

  series: apex.ApexAxisChartSeries;
  chart: apex.ApexChart;
  xaxis: apex.ApexXAxis;
  title: apex.ApexTitleSubtitle;
  public chartLabels: string[] = [];

  //Chart

  series_bar: apex.ApexAxisChartSeries;
  chart_bar: apex.ApexChart;
  xaxis_bar: apex.ApexXAxis;
  title_bar: apex.ApexTitleSubtitle;


  //Doughnut

  series_dn: apex.ApexNonAxisChartSeries;
  chart_dn: apex.ApexChart;
  responsive_dn: apex.ApexResponsive[];
  labels: any;
 

  selected:any = 0;
  selected1:any = 0;
  moduleList: any = [];
  search = new FormControl('');
  searchText: string = "";
  searchModule = new FormControl('');
  categoryList: any = [];
  searchCategory = new FormControl('');

  /* TODO: Need to be finalize which colum should be shown on table. */
  // displayedColumns: string[] = ['category','sub-category','lens','role','function','team','subteam','ID','score','question','freetext', 'action'];
  displayedColumns: string[] = ['ID','category','sub-category','lens','question', 'action'];
  dataSource: any =  [];
  selectedTemplate: any = {};
  isDefaultTemplate: boolean = true;
 
  dataSource2 =  [
    {position: 1, name: 'phone calls', weight: 'non-value-add', time: '5%',timespent:'10 mins a day',capacity:'nill',$$:'$$'},
    {position: 2, name: 'admin', weight: 'non-value-add', time: '3%',timespent:'5 mins a day',capacity:'nill',$$:'$$'},
  ];  
  response = [
    {position: 1, name: 'Hydrogen', weight: 1.0079, symbol: 'H'},
    {position: 2, name: 'Helium', weight: 4.0026, symbol: 'He'},
    {position: 3, name: 'Lithium', weight: 6.941, symbol: 'Li'},
    {position: 4, name: 'Beryllium', weight: 9.0122, symbol: 'Be'},
    {position: 5, name: 'Boron', weight: 10.811, symbol: 'B'},
  ];
  displayedColumns2: any[] = ['division','function','team','role','responses']
  dc: string[] = ['position', 'name', 'weight', 'time','timespent', 'capacity', '$$'];

  selectedOption = 'Bar Chart';
    
  type: any[] = [
    {value: '1', viewValue: 'Bar Chart'},
    {value: '2', viewValue: 'Radar Chart'},
    {value: '3', viewValue: 'Pie Chart'},
  ];
    
  form: FormGroup = new FormGroup({});
  clientId :string;


  clientData:any;
  organisationData:any;
  startDate:any;
  endDate:any;
  evaTemplateList: any = [];
  evaDefaultTemplate: any = [];
  dialog: any;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private fb: FormBuilder,
    private homeService:HomeService,
    private onboardingService:OnboardingService,
    private workflowTemplateService: WorkflowTemplateService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute
  ) { 
    this.form = fb.group({
      chart: [this.selectedOption, [Validators.required]],
    })
  }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get("id") || "";
    this.initializeChartOptions();
    this.initiateBarChart();

    this.getEVATemplates();
    this.getClientById();
    this.getOrganisationByClientId();   


    // this.series = [
    //   {
    //     name: 'Team-A',
    //     data: [2.4, 2.6, 2.8, 4.5, 2.9, 4.3, 3.1, 1.4, 5, 2],
    //   },
    //   {
    //     name: 'Team-B',
    //     data: [4.4, 3.6, 1.8, 4, 3.4, 4.5, 3.4, 2.3, 4.3, 2.3],
    //   },
    // ];

    // this.chartLabels = [
    //   'Training & Develop',
    //   'Understanding',
    //   'Visibility',
    //   'Team performance',
    // ];

    // this.chartOptions = {
    //   series: this.series,
    //   chart: {
    //     height: 350,
    //     type: 'radar',
    //   },
    //   title: {
    //     text: 'Radar Chart',
    //   },
    //   xaxis: {
    //     categories: this.chartLabels,
    //   },
    // };
    
  }

  get f(){
    return this.form.controls;
  }
      
  submit(){
    // console.log(this.form.value);
  }

  onChartChange(selection: any){
    // console.log(selection.value);
    this.selectedOption = selection.value;
    this.cdr.markForCheck();
  }

  getClientById(){
    this.homeService.getClients(this.clientId).subscribe(res=>{
      this.clientData = res[0];
      this.startDate = this.clientData.startDate.replace(/T.*/,'').split('-').reverse().join('-')
      this.endDate = this.clientData.endDate.replace(/T.*/,'').split('-').reverse().join('-')
    })
  }

  getOrganisationByClientId(){
    this.onboardingService.getOrganisationByClientId(this.clientId).subscribe(res=>{
      this.organisationData = res[0]
    })
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // console.log('index => ', tabChangeEvent.index);
    if (tabChangeEvent.index === 1) {
      this.initializeDoughnutChartOptions();
    }
    else if (tabChangeEvent.index === 2) {
      this.initializeChartOptions();
      this.initiateBarChart();
    }
  }

  tabChanged1 = (tabChangeEvent: MatTabChangeEvent): void => {
    // console.log('index => ', tabChangeEvent.index);
    if (tabChangeEvent.index === 1) {
      this.initializeChartOptions();
      this.initiateBarChart();
    }
  }

  reload(){
    return this.initializeChartOptions(),this.initializeDoughnutChartOptions();
  }

  private initializeChartOptions(): void {
    this.title = {
      text: 'Radar Chart'
    };

    this.series = [
     {
        name: 'Team-A',
        data: [2.4, 2.6, 2.8, 4.5, 2.9, 4.3, 3.1, 1.4, 5, 2],
      },
      {
        name: 'Team-B',
        data: [4.4, 3.6, 1.8, 4, 3.4, 4.5, 3.4, 2.3, 4.3, 2.3],
      }
    ];

    this.chart = {
      type: "radar",
      height: 350,
    }

    // this.responsive = [
    //   {
    //     breakpoint: 1200,
    //     options: {
    //       legend: {
    //         position: "bottom",
    //         offsetX: -10,
    //         offsetY: 0
    //       }
    //     }
    //   }
    // ]

    this.xaxis = {
      categories: this.chartLabels,
    }

    // this.fill = {
    //   opacity: 1
    // }

    // this.legend = {
    //   position: "right",
    //   offsetX: 0,
    //   offsetY: 50
    // }

    this.cdr.markForCheck();
    // console.log('IN');
  }

  private initiateBarChart(): void {
    this.title = {
      text: 'Bar Chart'
    };

    this.series_bar = [
      {
        name: 'Team-A',
        data: [2.4, 2.6, 2.8, 4.5, 2.9, 4.3, 3.1, 1.4, 5, 2],
      },
      {
        name: 'Team-B',
        data: [4.4, 3.6, 1.8, 4, 3.4, 4.5, 3.4, 2.3, 4.3, 2.3],
      }
    ];

    this.chart_bar = {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%"
    }

    this.xaxis_bar = {
      categories: [],
    }

    const data = [
      {
        team: 'Team-A',
        count: 10,
        questions: [
          {
            Activity: 'Complaince',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 10,
          },
          {
            Activity: 'First Aid',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 10,
          },
          {
            Activity: 'Following Standard Processes',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 15,
          },
          {
            Activity: 'Core work',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 16,
          },
          {
            Activity:
              'Escalations (e.g. Any problems that require escalating for support)',
            Type: 'Non Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 5,
          },
          {
            Activity: 'Archiving',
            Type: 'Non Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 15,
          },
          {
            Activity: 'Archiving',
            Type: 'Meetings',
            'Time Category': 'Value Add',
            Answer: 10,
          },
          {
            Activity: 'Archiving',
            Type: 'Reports',
            'Time Category': 'Value Add',
            Answer: 15,
          },
          {
            Activity: 'Team Manager Support - Cover',
            Type: 'Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 4,
          },
          {
            Activity: 'Team Manager Support - Other',
            Type: 'Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 13,
          },
        ],
      },
      {
        team: 'Team-B',
        questions: [
          {
            Activity: 'Complaince',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 10,
          },
          {
            Activity: 'First Aid',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 10,
          },
          {
            Activity: 'Following Standard Processes',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 15,
          },
          {
            Activity: 'Core work',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 16,
          },
          {
            Activity:
              'Escalations (e.g. Any problems that require escalating for support)',
            Type: 'Non Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 5,
          },
          {
            Activity: 'Archiving',
            Type: 'Non Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 15,
          },
          {
            Activity: 'Archiving',
            Type: 'Meetings',
            'Time Category': 'Value Add',
            Answer: 10,
          },
          {
            Activity: 'Archiving',
            Type: 'Reports',
            'Time Category': 'Value Add',
            Answer: 15,
          },
          {
            Activity: 'Team Manager Support - Cover',
            Type: 'Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 4,
          },
          {
            Activity: 'Team Manager Support - Other',
            Type: 'Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 13,
          },
        ],
      },
      {
        team: 'Team-C',
        questions: [
          {
            Activity: 'Complaince',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 15,
          },
          {
            Activity: 'First Aid',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 10,
          },
          {
            Activity: 'Following Standard Processes',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 15,
          },
          {
            Activity: 'Core work',
            Type: 'Core',
            'Time Category': 'Core Activity',
            Answer: 16,
          },
          {
            Activity:
              'Escalations (e.g. Any problems that require escalating for support)',
            Type: 'Non Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 5,
          },
          {
            Activity: 'Archiving',
            Type: 'Non Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 15,
          },
          {
            Activity: 'Archiving',
            Type: 'Meetings',
            'Time Category': 'Value Add',
            Answer: 10,
          },
          {
            Activity: 'Archiving',
            Type: 'Reports',
            'Time Category': 'Value Add',
            Answer: 15,
          },
          {
            Activity: 'Team Manager Support - Cover',
            Type: 'Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 4,
          },
          {
            Activity: 'Team Manager Support - Other',
            Type: 'Value Add',
            'Time Category': 'Non Core Activity',
            Answer: 13,
          },
        ],
      },
    ];
    const seriesData: any[] = [];

    data.forEach((teamData) => {
      const seriesItem = {
        name: teamData.team,
        data: [0],
      };

      seriesItem.data = [];

      teamData.questions.forEach((question) => {
        seriesItem.data.push(question.Answer);
      });

      seriesData.push(seriesItem);
    });

    this.series_bar = seriesData;
    this.xaxis_bar.categories = data[0].questions.map(
      (question) => question.Type
    );


    this.cdr.markForCheck();
  }

  private initializeDoughnutChartOptions(): void {
    this.series_dn = [44, 55, 13, 43, 22]

    this.chart_dn =  {
      type: "donut"
    }

    this.labels = ["Team A", "Team B", "Team C", "Team D", "Team E"],
    this.responsive_dn =[
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: "bottom"
          }
        }
      }
    ]
  }

  public getEVATemplates(){
    this.workflowTemplateService.getTemplateByQuery("module", "EVA").subscribe((response) =>{
      // console.log('---- Get all eva template list ----', response);
      this.evaTemplateList = response.data;

      let defaultTemplate = this.evaTemplateList.filter((template: any) => template.templateName == 'default');
      this.workflowTemplateService.getTemplateByQuery("id", defaultTemplate[0]['_id']).subscribe((templateData) =>{

        this.evaDefaultTemplate = response.data && response.data.length ? response.data[0] : [];
        this.selectedTemplate = this.evaDefaultTemplate;
        this.isDefaultTemplate = this.selectedTemplate.templateName == 'default' ? true : false;
        this.dataSource = this.evaDefaultTemplate['questions'];

        /* TODO: Below line holds the EVA templateId to create survey.
            This component need to render before the EVA toggle switched, unless we can't get the EVA templateId 
        */
        sessionStorage.setItem('evaTemplateId', this.selectedTemplate['_id']);
      });
    }, (error) =>{
      // console.log('---- Get all eva template Error ----', error);
    });
  }

  openDialog() {
    // this.isDefaultTemplate = this.selectedTemplate.templateName == 'default' ? true : false;
    this.dialog = this.matDialog.open(TemplateAddComponent, {
      width: '70em',
      // height: '450px',
      disableClose: true,
      data: {from: 'eva-template', isDefaultTemplate: this.isDefaultTemplate, templateDetail: this.selectedTemplate},
    });

    this.dialog.afterClosed().subscribe((submit: any) => {
      this.getEVATemplates();

      if(!this.isDefaultTemplate){
        this.workflowTemplateService.getTemplateByQuery('id', this.selectedTemplate['_id']).subscribe((response) => {

          this.dataSource = response.data[0]['questions'];
          this.cdr.markForCheck();
          // console.log('========== On Close this.selectedTemplate =========', this.selectedTemplate);
        });
      }
    });
  }

  onTemplateSelect(selection: any){

    let templateIndex = this.evaTemplateList.findIndex((template: any) => template.templateName === selection.value);
    this.selectedTemplate = this.evaTemplateList[templateIndex];
    this.isDefaultTemplate = this.selectedTemplate.templateName == 'default' ? true : false;
    this.dataSource = this.selectedTemplate['questions'];

    /* TODO: Below line holds the selected EVA templateId to create survey.
        This component need to render before the EVA toggle switched, unless we can't get the EVA templateId 
    */
    sessionStorage.setItem('evaTemplateId', this.selectedTemplate['_id']);
  }

  onRemoveQuestion(selectedQuestion: any){
    
    let setData = Object.assign({}, this.selectedTemplate);
    let templateId: string = this.selectedTemplate['_id'];
    const questionIndex = setData.questions.findIndex((question: any) => {
      return question.questionId === selectedQuestion.questionId;
    });

    if (questionIndex !== -1) {
      setData.questions.splice(questionIndex, 1);
    }

    delete setData['_id'];

    this.workflowTemplateService.updateTemplate(templateId, setData)
    .subscribe((res) => {
      // console.log('---- Template Update Response ----', res);
      
      this.snackBar.open("Removed Successfully", 'close',{
        duration: 4500,
        horizontalPosition: 'center',
        verticalPosition: 'top',
      });

      this.workflowTemplateService.getTemplateByQuery('id', this.selectedTemplate['_id']).subscribe((response) => {
          
        this.dataSource = response.data[0]['questions'];
        this.cdr.markForCheck();
      });
    });
  }

  public evaSurvey(){
    this.router.navigate([`eva/survey-page/${ this.selectedTemplate['_id']}`]); /* TODO: Dynamically send the surveyID */
  }

  public addSurvey(){
    this.router.navigate([`/project/${this.clientId}/add-survey`]);
  }
}