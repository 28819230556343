import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
  AfterContentChecked,
} from "@angular/core";
import * as apex from "ng-apexcharts";
import { SurveyService } from "@app/survey/survey.service";
import { ActivatedRoute } from "@angular/router";
import { WorkflowTemplateService } from "@app/workflow-template/workflow-template.service";
import { MatTableDataSource } from "@angular/material/table";
import { CustomService } from "@app/core/services/custom.service";
import { BehaviorSubject, Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { ResponseEditComponent } from "@app/core/response-edit/response-edit.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ResponseSelectComponent } from "@app/core/response-select/response-select.component";

@Component({
  selector: "app-response-management",
  templateUrl: "./response-management.component.html",
  styleUrls: ["./response-management.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResponseManagementComponent
  implements OnInit, AfterContentChecked
{
  message: Subject<string> = new BehaviorSubject("loading :(");
  dataTable: any;
  displayedColumns2: any[] = [
    "name",
    "date",
    "role",
    "responses",
    "status",
    "link",
  ];

  response = [
    { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
    { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
    { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
    { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
    { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
  ];

  series_dn: apex.ApexNonAxisChartSeries;
  chart_dn: apex.ApexChart;
  responsive_dn: apex.ApexResponsive[];
  labels: any;
  surveyId: string = "";
  surveyDetailList: any = [];
  clientId: string = "";
  surveyData: any = [];
  interval: any;
  functionCount: any;
  teamsCount: any;
  isFlag: boolean = false;
  teamCountMap: any = new Map();
  surveyTeamsMap: any = new Map();
  constructor(
    private route: ActivatedRoute,
    private customService: CustomService,
    private workflowTemplateService: WorkflowTemplateService,
    private cdr: ChangeDetectorRef,
    private surveyService: SurveyService,
    private matdialog: MatDialog,
    private snackbar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.clientId = sessionStorage.getItem("clientId") || "";
    this.overAllCounts();
    this.getSurveyData();
    this.interval = setInterval(() => {
      this.getSurveyData();
    }, 12000);
  }

  ngAfterContentChecked() {
    // console.log("-All done loading in res management-", this.clientId);
    this.message.next("all done loading :)");
    this.cdr.detectChanges();
  }

  overAllCounts() {
    this.customService.getClientFunctions(this.clientId).subscribe({
      next: (res: any) => {
        // console.log("overallCounts :----->", res);
        let functionsCount: any = [];
        let teamsCount: any = 0;
        let fteManagers: any = 0;
        let fteMembers: any = 0;

        res.forEach((ele: any) => {
          // console.log(ele);
          functionsCount.push(ele.functionName);
          teamsCount = teamsCount + ele.teams.length;

          //Managers & Members Count
          ele.teams.forEach((data: any) => {
            fteManagers = fteManagers + data.fteManagers;
            fteMembers = fteMembers + data.fteMembers;
            this.teamCountMap.set(
              data.teamName,
              data.fteManagers + data.fteMembers
            );
          });
        });

        this.cdr.markForCheck();
      },
      error: (error: any) => {
        // console.log("get counts error", error);
      },
    });
  }

  getSurveyData() {
    //this.dataTable = []
    this.surveyDetailList = [];
    // this.surveyData = []

    let surveyQuery: object = { clientId: this.clientId, module: "ava-eva" };
    this.surveyService.getSurveyByQuery(surveyQuery).subscribe(
      (surveyResponse) => {
        // console.log(
        //   "--- surveyResponse calling ---",
        //   surveyResponse,
        //   surveyResponse["data"]
        // );

        if (surveyResponse && surveyResponse["data"].length) {
          this.surveyData = [];
          this.surveyData = surveyResponse["data"];
          this.surveyData.forEach((data: any) => {
            //this.surveyId = data._id;
            this.getSurveyDetails(data._id);
          });
          this.cdr.markForCheck();
        }
      },
      (error) => {
        // console.log("Error while getting survey data: ", error);
      }
    );
  }

  getSurveyDetails(surveyId: any) {
    this.surveyService
      .getSurveyDetails("surveyId", surveyId, this.clientId)
      .subscribe(
        (surveyDetails) => {
          this.cdr.markForCheck();
          // console.log(
          //   "-------- *****Get surveyDetails**** -----------",
          //   surveyDetails
          // );
          this.surveyDetailList.push(surveyDetails[0]);
          // console.log(
          //   "-------- >> surveyDetailList**** -----------",
          //   this.surveyDetailList
          // );
          this.cdr.markForCheck();
          // surveyDetails.forEach((surveyDetail: any) => {
          //   this.getSurveyResponseCount(surveyDetail);
          // });
          this.getActivity(surveyDetails[0].activityId);
          //this.findCounts();
        },
        (error) => {
          // console.log("-------- surveyDetails Error -----------", error);
        }
      );
  }

  getActivity(id: any) {
    this.workflowTemplateService.getActivityById(this.clientId, id).subscribe(
      (response: any) => {
        // console.log("---- Res ----", response, response[0].surveyName);
        this.surveyDetailList.forEach((element: any) => {
          if (response[0]._id === element.activityId) {
            // console.log("IN");

            element["surveyName"] = response[0].surveyName;
            this.surveyTeamsMap.set(response[0].surveyName, response[0].teams);
            element["owner"] = response[0].owner;
          }
        });
        this.dataTable = new MatTableDataSource(this.surveyDetailList);
        this.dataTable._data._value.sort(
          (a: any, b: any) => b.clicks - a.clicks
        );
        this.isFlag = true;
        this.cdr.markForCheck();

        // console.log(
        //   "-------- >> surveyDetailList**** -----------",
        //   this.surveyDetailList
        // );
        // if(this.dataTable._data._value.length !== this.surveyDetailList.length){
        //   this.dataTable = [];
        //   this.dataTable = new MatTableDataSource(this.surveyDetailList)
        // }

        // console.log("-------- >> dT**** -----------", this.dataTable);
        this.cdr.markForCheck();
      },
      (error: any) => {
        // console.log("---- Error ----", error);
      }
    );
  }

  getSurveyResponseCount(surveyDetail: any = {}) {
    this.surveyService.getSurveyResponses(surveyDetail["_id"]).subscribe({
      next: (surveyResponses: any) => {
        /* TODO: This logic need to implement on API side. If its done then remove this block. */
        let surveyIndex = this.surveyDetailList.findIndex(
          (surveyData: any) => surveyData["_id"] === surveyDetail["_id"]
        );
        this.surveyDetailList[surveyIndex]["responseCount"] =
          surveyResponses.data.length || 0;
      },
      error: (error: any) => {
        // console.log("Error while getting survey response count: ", error);
      },
    });
    return true;
  }

  findCounts() {
    this.functionCount = [];
    this.teamsCount = [];
    this.surveyDetailList.forEach((ele: any) => {
      this.functionCount.push(ele.clientFunction);
      this.teamsCount.push(ele.teamName);
    });
    this.functionCount = [...new Set(this.functionCount)];
    this.teamsCount = [...new Set(this.teamsCount)];
    // console.log(this.functionCount.length, this.teamsCount.length);
  }

  goToSurveySubmit(link: string) {
    window.open(link, "_blank");
  }

  ngOnDestroy() {
    clearInterval(this.interval);
  }
  getTotalCount(element: any) {
    let total = 0;
    let teams = this.surveyTeamsMap.get(element.surveyName);
    if (teams) {
      for (let team of teams) {
        total = total + this.teamCountMap.get(team.teamName);
      }
    }
    return total;
  }

  getStatus(element: any) {
    let endDate: Date = element.surveyData
      ? new Date(element.surveyData.endDate)
      : new Date();
    let currentDate = new Date();
    if (endDate.valueOf() >= currentDate.valueOf()) {
      return "In Progress";
    } else {
      return "Completed";
    }
  }

  copyLink(link: string) {
    const selBox = document.createElement("textarea");
    selBox.style.position = "fixed";
    selBox.style.left = "0";
    selBox.style.top = "0";
    selBox.style.opacity = "0";
    selBox.value = link;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand("copy");
    document.body.removeChild(selBox);
  }

  download(element: any) {
    this.surveyService
      .downloadData(element.clientId, "ava-eva", element._id)
      .subscribe(
        (data: Blob) => {
          // console.log("--- surveyResponse output ---");
          const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.href = url;
          a.download = "output.xlsx";
          a.click();
          window.URL.revokeObjectURL(url);
        },
        (error) => {
          // console.log("Error while getting survey Outputs: ", error);
        }
      );
  }

  getAllResponse(element: any) {
    this.surveyService.getSurveyDetails("surveyDetailId", element._id).subscribe(
      (response: any) => {
        // console.log(response);

        let dialog = this.matdialog.open(ResponseSelectComponent, {
          width: "45em",
          disableClose: false,
          data: response,
        });
        
        // this.surveyService.getSurveyResponses(response[0]._id).subscribe(
        //   (res2: any) => {
        //     let dialog = this.matdialog.open(ResponseEditComponent, {
        //       width: "95em",
        //       // height: '750px',
        //       disableClose: false,
        //       data: res2.data[0],
        //     });
        //   },
        //   (err2: any) => {
        //     this.snackbar.open(err2.message, "close");
        //   }
        // );
      },
      (err: any) => {
        // console.log(err);
      }
    );
  }
}
