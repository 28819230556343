import { HttpClient } from "@angular/common/http";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { UsePagination } from "@app/core/classes/use-pagination";
import { QUESTION_STATES_ObJ } from "@app/resources/question-state";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import { InterviewPopupComponent } from "@app/projects-v2/manager-interview/interview-setup/interview-popup/interview-popup.component";
import { ManagerInterviewService } from "@app/projects-v2/manager-interview/manager-interview.service";
import { ConfirmationDialogComponent } from "@app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { DatabaseService } from "../database.service";
import { QuestionAddDialogComponent } from "@app/masterdata/question-add-dialog/question-add-dialog.component";
import { ReqHistoryComponent } from "@app/masterdata/req-history/req-history.component";

@Component({
  selector: "app-db-manager-interview",
  templateUrl: "./db-manager-interview.component.html",
  styleUrls: ["./db-manager-interview.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DbManagerInterviewComponent implements OnInit {
  templates: any[];
  templateSelected = new FormControl("", Validators.required);
  selectedTempIndex: number = 0;
  SelectedTemplate: string;
  datasource2 = [];
  dc2 = ["category", "sub-category", "lens", "question", "state", "action"];
  category: any;
  subcategory: any;
  lens: any;
  filteredQuestions: any = [];
  selectedCat: string = "";
  selectedCategoryData: any;
  selectedSubCat: string = "";
  selectedLens: string = "";
  questionLens: any;
  questionCategories: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  totalLength: number = 0;
  questionPagination = new UsePagination({
    page: 0,
    perPage: 100,
    total: this.totalLength,
  });
  Pagination: any = {
    length: 0,
    pageIndex: 0,
    pageSize: 5,
    previousPageIndex: 0,
  };
  spinner: boolean = true;
  isEmpty: boolean = true;
  approvedQuestions: any = [];
  questionTypes: any;
  state: string = QUESTION_STATES_ObJ["APPROVED"];
  dialog: any;
  selectedTab: number = 0;

  constructor(
    private activatedroute: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private matDialog: MatDialog,
    private interviewService: ManagerInterviewService,
    private snackbar: MatSnackBar,
    private http: HttpClient,
    private databaseService: DatabaseService
  ) {}

  ngOnInit(): void {
    this.getTemplates();
    this.tabChange(0);
    this.getCategories();
  }

  getTemplates() {
    this.interviewService.getTemplatesByModule("managerInterviews").subscribe({
      next: (res: any) => {
        // console.log("temp", res);
        this.templates = [...res.data];
        //this.datasource2 = this.templates[this.selectedTempIndex].questions
        this.templateSelected.setValue(this.templates[this.selectedTempIndex]);
        this.cdr.markForCheck();
        // console.log("temp", this.templates);
        // console.log("DTA CHECk", this.datasource2);
      },
      error: (error: any) => {
        // console.log("templates", error);
      },
    });
  }

  getCategories() {
    this.databaseService.getDropDown("CATEGORY", "managerInterview").subscribe({
      next: (res: any) => {
        if (res.length > 0) this.questionCategories = res;
      },
      error: (error: any) => {
        // console.log("type dropdown", error);
      },
    });
  }

  removeDulpicates(element: any) {
    let array: [] = element.reduce((acc: any[], current: any) => {
      if (!acc.includes(current)) acc.push(current);
      return acc;
    }, []);
    return array;
  }

  tabChange(tabIndex: any) {
    this.spinner = true;
    // console.log("tab", tabIndex);
    this.selectedTab = tabIndex;
    if (tabIndex == 0) {
      this.state = QUESTION_STATES_ObJ["APPROVED"];
    } else {
      this.state = QUESTION_STATES_ObJ["DRAFT"];
    }
    this.getApprovedQuestions();
  }

  // Table Pagination

  getPageEvent(pageData: PageEvent) {
    this.spinner = true;
    // this.Pagination = pageData
    // console.log("pageData", pageData);
    this.questionPagination.doPagination(pageData);
    setTimeout(() => {
      this.getApprovedQuestions();
    }, 2000);
  }

  getApprovedQuestions() {
    this.databaseService
      .getRefData(
        this.questionPagination.perPage,
        this.questionPagination.page,
        "managerInterviews",
        this.state
      )
      .subscribe({
        next: (res: any) => {
          this.spinner = false;
          // console.log("approved managerInterviews question", res);
          if (res.data.length == 0) {
            this.isEmpty = true;
            this.datasource2 = [];
          } else {
            this.datasource2 = res.data;
            this.approvedQuestions = res.data;
            this.totalLength = res.metadata[0].total;
            this.cdr.markForCheck();

            // console.log("type", this.questionTypes);

            this.isEmpty = false;
          }
        },
        error: (error: any) => {
          this.spinner = false;
          // console.log(error);
        },
      });
  }

  templateChange(event: any, type: string) {
    if (type == "template") {
      // this.datasource2 = event.questions
      // this.SelectedTemplate = event.templateName
      // this.selectedTempIndex = this.templates.findIndex((x:any) => x['_id'] == event._id)
      // console.log("event", event, this.selectedTempIndex);

      //
      let res = this.templates.findIndex(
        (template: any) => template.templateName === event
      );
      // console.log(this.templates[res]);
      this.datasource2 = this.templates[res].questions;
      this.filteredQuestions = this.datasource2;
      this.approvedQuestions = this.datasource2;

      this.selectedCat = "";
      this.selectedSubCat = "";
      this.selectedLens = "";
      this.cdr.markForCheck();
    }
    // else{
    //   this.filterSearch()

    // }
    else if (type == "category") {
      // console.log("category event", event, this.selectedTempIndex);
      this.selectedCat = event;
      this.selectedCategoryData = this.questionCategories.find((categoryDetail: any) => categoryDetail.category == event);
      
      if (this.filteredQuestions.length > 0) {
        this.filteredQuestions = this.filteredQuestions.filter(
          (cat: any) => cat.category == event
        );
      } else {
        this.filteredQuestions = this.templates[
          this.selectedTempIndex
        ].questions.filter((cat: any) => cat.category == event);
      }
      this.datasource2 = this.filteredQuestions;
      this.lens = [];
      this.cdr.markForCheck();
    } else if (type == "subcategory") {
      // console.log("category event", event, this.selectedTempIndex);
      this.selectedSubCat = event;
      if (this.filteredQuestions.length > 0) {
        this.filteredQuestions = this.filteredQuestions.filter(
          (subcat: any) => subcat.subCategory == event
        );
      } else {
        this.filteredQuestions = this.templates[
          this.selectedTempIndex
        ].questions.filter((subcat: any) => subcat.subCategory == event);
      }
      this.datasource2 = this.filteredQuestions;
      this.lens = this.selectedCategoryData.lens.find((lensDetail: any) => lensDetail.subCategory == event).lens;
      this.cdr.markForCheck();
    } else if (type == "lens") {
      this.selectedLens = event;
      // console.log("category event", event, this.selectedTempIndex);
      if (this.filteredQuestions.length > 0) {
        this.filteredQuestions = this.filteredQuestions.filter(
          (x: any) => x.lens == event
        );
      } else {
        this.filteredQuestions = this.templates[
          this.selectedTempIndex
        ].questions.filter((x: any) => x.lens == event);
      }
      this.datasource2 = this.filteredQuestions;
      this.cdr.markForCheck();
    }
  }

  filterSearch() {
    if (this.selectedCat == "" && this.selectedSubCat == "") {
      this.filteredQuestions = this.templates[
        this.selectedTempIndex
      ].questions.filter((x: any) => x.lens == this.selectedLens);
    } else if (this.selectedCat == "" && this.selectedLens == "") {
      this.filteredQuestions = this.templates[
        this.selectedTempIndex
      ].questions.filter((x: any) => x.subCategory == this.selectedSubCat);
    } else if (this.selectedSubCat == "" && this.selectedLens == "") {
      this.filteredQuestions = this.templates[
        this.selectedTempIndex
      ].questions.filter((x: any) => x.category == this.selectedCat);
    }
    if (this.selectedCat == "") {
      this.filteredQuestions = this.templates[
        this.selectedTempIndex
      ].questions.filter(
        (x: any) =>
          x.lens == this.selectedLens && x.subCategory == this.selectedSubCat
      );
    } else if (this.selectedSubCat == "") {
      this.filteredQuestions = this.templates[
        this.selectedTempIndex
      ].questions.filter(
        (x: any) =>
          x.lens == this.selectedLens && x.category == this.selectedCat
      );
    } else if (this.selectedLens == "") {
      this.filteredQuestions = this.templates[
        this.selectedTempIndex
      ].questions.filter(
        (x: any) =>
          x.subCategory == this.selectedSubCat && x.category == this.selectedCat
      );
    } else {
      this.filteredQuestions = this.templates[
        this.selectedTempIndex
      ].questions.filter(
        (x: any) =>
          x.lens == this.selectedLens &&
          x.category == this.selectedCat &&
          x.subCategory == this.selectedSubCat
      );
    }

    this.datasource2 = this.filteredQuestions;
    this.cdr.markForCheck();

    if (this.filteredQuestions.length == 0) {
      this.datasource2 = this.templates[this.selectedTempIndex].questions;
      this.cdr.markForCheck();
    }
    this.cdr.markForCheck();
  }

  clearFilters() {
    this.selectedCat = "";
    this.selectedSubCat = "";
    this.selectedLens = "";
    this.selectedTempIndex = 0;
    this.SelectedTemplate = "";
    this.filteredQuestions = [];
    this.getTemplates();
    this.getApprovedQuestions();
    this.cdr.markForCheck();
  }

  openDialog(type: string, element: any) {

    if(typeof element === 'object') {

      element.status = "";
      let categoryIndex = this.questionCategories.findIndex((categoryData: any) => categoryData.category == element.category);
      this.lens = this.questionCategories[categoryIndex].lens.find((lensDetail: any) => lensDetail.subCategory == element.subCategory).lens;
    }
    this.dialog = this.matDialog.open(QuestionAddDialogComponent, {
      width: "70em",
      disableClose: true,
      data: {
        from: type == "create" ? "MI-Ref-Create" : "MI-Ref-Edit",
        category: this.questionCategories,
        type: this.questionTypes,
        // subcategory: this.subcategory,
        question: element,
        state: this.state,
        lens: this.lens,
      },
    });

    this.dialog.afterClosed().subscribe((res: any) => {
      // console.log("res", res);

      if (
        res.event == "avaQusCreate" ||
        res.event == "avaQusEdit" ||
        res.event == "avaDraft"
      ) {
        this.getApprovedQuestions();
      }
    });
  }

  deletePreview(element: any) {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: "40%",
      height: "auto",
      disableClose: false,
      data: {
        message: "Are you sure want to Delete the Question ?",
        buttonText: {
          ok: "Delete",
          cancel: "Cancel",
        },
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        let payload = { ...element };
        delete payload._id;
        delete payload.state;
        delete payload.updatedUser;
        payload.module = "managerInterviews";
        payload.isActive = false;
        payload.oldState = QUESTION_STATES_ObJ["APPROVED"];
        payload.newState = QUESTION_STATES_ObJ["SUBMITTED"];

        this.databaseService.editRefData(element._id, payload).subscribe({
          next: (res: any) => {
            this.snackbar.open("Question Deactivated Successfully", "close");
            this.getApprovedQuestions();
            this.cdr.markForCheck();
          },
          error: (error: any) => {
            // console.log(error);
          },
        });
      }
    });
  }

  openHistory(data: any) {
    this.databaseService.getQuestionHistory(data._id).subscribe(
      (res) => {
        // console.log(data);
        let obj: any = {
          question: data.question,
          author: data.user,
          status: data.state,
          comments: data.comment,
        };
        let result: any = [];
        result.push(obj);
        for (let r of res) {
          let newObj: any = {
            question: r.question,
            status: r.state,
            comments: r.comment,
          };
          result.push(newObj);
        }
        let dialog = this.matDialog.open(ReqHistoryComponent, {
          width: "95em",
          // height: '750px',
          disableClose: false,
          data: result,
        });
      },
      (error) => {
        // console.log(error);
      }
    );
  }
}
