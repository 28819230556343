import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router , NavigationEnd} from '@angular/router';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { HomeService } from '@app/home/home/home.service';

@Component({
  selector: 'app-projects-v2',
  templateUrl: './projects-v2.component.html',
  styleUrls: ['./projects-v2.component.scss']
})
export class ProjectsV2Component implements OnInit {

  clientId: string = "";
  clientData:any;
  currentUrl: string;
  selectedTabIndex:any;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private homeService:HomeService
  ) { }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get("id") || "";

    this.getClientData();

    //Selecting the exact tab by checking the URL
  
    this.currentUrl = window.location.href;
    //console.log('>>',this.currentUrl.split('/')[5]);

    if (this.currentUrl.includes('overview')) {
      this.selectedTabIndex = 0;
    } else if (this.currentUrl.includes('ava-eva')) {
      this.selectedTabIndex = 1;
    } else if (this.currentUrl.includes('op-maturity')) {
      this.selectedTabIndex = 2;
    } else if (this.currentUrl.includes('manager-interview')) {
      this.selectedTabIndex = 3;
    } else if (this.currentUrl.includes('focus-groups')) {
      this.selectedTabIndex = 4;
    }else if (this.currentUrl.includes('power-bi')) {
      this.selectedTabIndex = 6;
    }
    
    
  }

  getClientData(){
    this.homeService.getClients(this.clientId).subscribe(res=>{
      this.clientData = res[0]
    })
  }

  selectTabBasedOnUrl() {
    // Logic to determine which tab to select based on the currentUrl
    // For example:
    if (this.currentUrl.includes('/tab1')) {
      // Select the first tab
    } else if (this.currentUrl.includes('/tab2')) {
      // Select the second tab
    } else if (this.currentUrl.includes('/tab3')) {
      // Select the third tab
    }
  }


  projectTab(index: any) {
    // console.log(index);
    if (index === 0) {
      this.router.navigateByUrl(`project/${this.clientId}/overview`)
    }
    else if (index === 1) {
      this.router.navigateByUrl(`project/${this.clientId}/ava-eva`)
    }
    else if (index === 2) {
      this.router.navigateByUrl(`project/${this.clientId}/op-maturity`)
    }
    else if (index === 3) {
      this.router.navigateByUrl(`project/${this.clientId}/manager-interview`)
      // this.router.navigateByUrl(`manager-interview`)
      // console.log('this.router.navigate([`projectsv2/manager-interview/${}`]) called');
      // ,{queryParams : { clientid : this.clientId }}
    }
    else if (index === 4) {
      this.router.navigateByUrl(`project/${this.clientId}/focus-groups`)
    } else if (index === 6) {
      this.router.navigateByUrl(`project/${this.clientId}/power-bi`)
    }

  }


}
