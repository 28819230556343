import { DatePipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { ManagerInterviewService } from "@app/projects-v2/manager-interview/manager-interview.service";
import { SurveyService } from "@app/survey/survey.service";
import { WorkflowTemplateService } from "@app/workflow-template/workflow-template.service";

export interface miSubmitSurvey {
  surveyDetailId: string;
  dataResponse: [];
}

@Component({
  selector: "app-mi-survey-page",
  templateUrl: "./mi-survey-page.component.html",
  styleUrls: ["./mi-survey-page.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiSurveyPageComponent implements OnInit {
  preview: boolean = true;
  stars: number[] = [1, 2, 3, 4, 5];
  val: string;
  ValueFromEvent: string;
  timeline: any = [1, 2, 3, 4, 5];
  managerInterviews: any = [];

  ClientId: any;
  surveyDetailId: any;
  theSurveyId: any;
  miTemplateId: any;
  interviewID: any;

  //payloads
  createSurveyTemplatePayload: any;
  submitAnswersPayload: any;
  currentDateTime: any;
  selected: any;
  interviewDetails: any;

  constructor(
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private interviewService: ManagerInterviewService,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef,
    private router: Router,
    public datepipe: DatePipe
  ) {
    this.currentDateTime = this.datepipe.transform(new Date(), "dd/MM/yyyy");
  }

  ngOnInit(): void {
    // console.log("mi survey cmpnt called");

    this.ClientId = sessionStorage.getItem("clientId") || "";
    this.surveyDetailId =
      this.route.snapshot.paramMap.get("surveyDetailId") || "";

    this.miTemplateId = sessionStorage.getItem("miTemplateId") || "";
    let interviewID = sessionStorage.getItem("interviewID") || "";
    this.interviewID = interviewID.split(",");
    if (this.surveyDetailId) {
      this.preview = false;
      this.getSurveyDetails();
    } else {
      this.getTemplateMI(this.miTemplateId);
      this.preview = true;
    }

    // console.log("clientId :--", this.ClientId);
    // console.log(
    //   "surveyDetailId :--",
    //   this.surveyDetailId,
    //   "preview>>>",
    //   this.preview
    // );
    // console.log("managerInterviewTemplateId :--", this.miTemplateId);
    // console.log("interviewID :--", this.interviewID);

    //setting payload structure
    let futureDay = new Date();
    futureDay.setDate(futureDay.getDate() + 10);
    this.createSurveyTemplatePayload = {
      templates: [
        {
          module: "managerInterviews",
          templateId: this.miTemplateId,
        },
      ],
      clientId: this.ClientId,
      startDate: new Date(),
      endDate: futureDay,
      interviewIds: this.interviewID,
      module: "managerInterviews",
    };

    this.submitAnswersPayload = {
      surveyDetailId: this.surveyDetailId,
      dataResponse: this.managerInterviews,
    };
  }

  ngOnDestroy() {
    // sessionStorage.clear();
  }

  getInterviewDetails() {
    this.interviewService
      .getInterview(this.ClientId, this.interviewID)
      .subscribe(
        (res) => {
          // console.log("Interview Details---->");
          this.interviewDetails = res.body[0];
        },
        (error) => {
          this.snackBar.open(error, "close", {
            duration: 4500,
            horizontalPosition: "center",
            verticalPosition: "top",
          });
        }
      );
  }

  getTemplateMI(id: any) {
    this.surveyService.getMiTemplate(id).subscribe(
      (res) => {
        //console.log(res, res.data[0]['questions']);
        //Assign to managerInterviews
        this.managerInterviews = res.data[0]["questions"];

        this.managerInterviews.forEach((elem: any, i: number) => {
          elem.answer = 0;
          elem.comments = "";
        });
        this.cdr.markForCheck();
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  getSurveyDetails() {
    this.surveyService
      .getSurveyDetails("surveyDetailId", this.surveyDetailId, this.ClientId)
      .subscribe(
        (res) => {
          // console.log("-- Get surveyDetail --", res);
          this.theSurveyId = res[0].surveyId;
          this.interviewID = res[0].interviewId;
          this.getSurveyTemplate();
          this.getInterviewDetails();
          this.cdr.markForCheck();
        },
        (error) => {
          // console.log("-- surveyDetails Error --", error);
        }
      );
  }

  getSurveyTemplate() {
    this.surveyService.getSurveyById(this.theSurveyId).subscribe(
      (res) => {
        //console.log('!---->>', res.data[0].templates[0].templateId);
        this.miTemplateId = res.data[0].templates[0].templateId;

        this.getTemplateMI(this.miTemplateId);
        this.cdr.markForCheck();
      },
      (error) => {
        // console.log("--Error --", error);
      }
    );
  }

  createTemplateMI() {
    let data = this.createSurveyTemplatePayload;

    this.surveyService.createSurvey(data).subscribe(
      (res) => {
        this.snackBar.open("Survey Created Successfully", "close", {
          duration: 4500,
          horizontalPosition: "center",
          verticalPosition: "top",
        });

        this.updateInterview();

        this.router.navigate([
          "/project/" + this.ClientId + "/manager-interview/responses",
        ]);
        this.cdr.markForCheck();
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  updateInterview() {
    // console.log(this.interviewDetails);

    this.interviewService
      .getInterview(this.ClientId, this.interviewID)
      .subscribe(
        (res) => {
          this.interviewDetails = res.body[0];
          let payload: any = {
            interviewName: this.interviewDetails["interviewName"],
            clientFunction: this.interviewDetails["clientFunction"],
            teamName: this.interviewDetails["teamName"],
            role: this.interviewDetails["role"],
            interviewer: this.interviewDetails["interviewer"],
            clientId: this.interviewDetails["clientId"],
            status: "InProgress",
          };

          this.interviewService
            .editInterview(this.interviewDetails["_id"], payload)
            .subscribe({
              next: (res: any) => {
                this.cdr.markForCheck();
              },
              error: (error: any) => {
                // console.log("=== Remove Interview error ==", error);
                if (error.error.message) {
                } else {
                  // console.log(`Remove interview Error: ${error.error.message}`);
                }
              },
            });
        },
        (err) => {
          // console.log(err);
        }
      );
  }

  submit() {
    // console.log(this.managerInterviews);

    let checking = this.managerInterviews.map((x: any) => x.answer);
    if (checking.includes(0)) {
      let index: number = checking.indexOf(0);
      // console.log("element index", index);
      this.snackBar.open(` Question No ${index + 1} is Not Answered `, "close");
      return;
    }

    let payload: miSubmitSurvey = {
      surveyDetailId: this.surveyDetailId,
      dataResponse: this.managerInterviews,
    };
    this.submitTemplateMI(payload);
  }

  back() {
    this.router.navigateByUrl(
      "/project/" + this.ClientId + "/manager-interview/setup2"
    );
  }

  submitTemplateMI(payload: any) {
    this.surveyService.submitMiSurvey(payload).subscribe(
      (res) => {
        let updatePayload: any = {};
        updatePayload.interviewName = this.interviewDetails.interviewName;
        updatePayload.status = "Complete";
        updatePayload.role = this.interviewDetails.role;
        updatePayload.teamName = this.interviewDetails.teamName;
        updatePayload.interviewer = this.interviewDetails.interviewer;
        updatePayload.clientFunction = this.interviewDetails.clientFunction;
        updatePayload.clientId = this.interviewDetails.clientId;

        this.interviewService
          .editInterview(this.interviewDetails._id, updatePayload)
          .subscribe(
            (res) => {
              // console.log(res);
            },
            (error) => {
              // console.log(res);
            }
          );
        this.snackBar.open("Your Survey is submitted Successfully", "close", {
          duration: 4500,
          horizontalPosition: "center",
          verticalPosition: "top",
        });

        this.selected = 2;
        this.cdr.markForCheck();
      },
      (error) => {
        // console.log(error);
      }
    );
  }

  countStar(question: any, star: any) {
    //console.log('eva star',star, question)
    question.answer = star;
    this.cdr.markForCheck();
  }

  getInput(event: any, item: any) {
    item.comments = event.target.value;
    //console.log(event.target.value);
    this.ValueFromEvent = event.target.value;
  }

  close() {
    window.close();
    this.router.navigate([]);
  }
}
