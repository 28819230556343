import { Component, OnInit } from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import { QuestionAddDialogComponent } from '../question-add-dialog/question-add-dialog.component';
import { MasterDataService } from '../master-data.service';
import { PageEvent } from '@angular/material/paginator';
import { UsePagination } from '@app/core/classes/use-pagination';
import { FormControl } from '@angular/forms';
import { OnboardingService } from '@app/onboarding/onboarding.service';
import { QUESTION_STATES } from '@app/resources/question-state';
@Component({
  selector: 'app-data',
  templateUrl: './data.component.html',
  styleUrls: ['./data.component.scss']
})
export class DataComponent implements OnInit {

  displayedColumns: string[] = ['question', 'action'];
  dataSource: any;
  dialog: any;
  masterDataList: any = [];
  totalLength = 0;
  clientsPagination = new UsePagination({
    page: 0,
    perPage: 100,
    total: this.totalLength,
  });
  search = new FormControl('');
  searchText: string = "";
  searchModule = new FormControl('');
  selectedModuleName:string = "";
  searchCategory = new FormControl('');
  selectedCategoryName: string = "";
  searchState = new FormControl('');
  selectedStateName: string = "";
  questionStates: any = [];
  moduleList: any = [];
  categoryList: any = [];

  constructor(
    private matDialog: MatDialog,
    private masterDataService: MasterDataService,
    private onboardingService: OnboardingService,
  ) { }

  ngOnInit(): void {
    this.questionStates = QUESTION_STATES;
    this.getMasterData();
    this.getModuleData();
    this.getCategoryData();
  }

  // openDialog() {
  //   const dialogRef = this.dialog.open();

  //   dialogRef.afterClosed().subscribe(result => {
  //     console.log(`Dialog result: ${result}`);
  //   });
  // }

  openDialog() {
    this.dialog = this.matDialog.open(QuestionAddDialogComponent, {
      width: '70em',
      // height: '450px',
      disableClose: true,
      data: {from: 'data-create'},
    });

    this.dialog.afterClosed().subscribe((submit: any) => {
      this.getMasterData()
    });
  }

  getMasterData(){

    this.masterDataService.getMasterData(
      this.clientsPagination.page,
      this.clientsPagination.perPage,
      this.searchText,
      this.selectedModuleName,
      this.selectedCategoryName,
      this.selectedStateName
    ).subscribe((response: any) => {
// console.log('--- response ----', response);

      this.masterDataList = response.data;
      this.dataSource = response.data;
      this.totalLength = response.metadata && response.metadata.length ? response.metadata[0].total : this.totalLength;
    },
    (error: any) => {
      // console.log('-- error Master --', error);

      this.masterDataList = [];
      this.dataSource = [];
      this.totalLength = 0;
    });
  }

  getModuleData(){
    this.onboardingService.getModuleData('MODULE').subscribe((response) => {
      this.moduleList = response;
    },
    (error)=>{
      // console.log('Error on get module list: ', error);
      
    });
  }
  
  getCategoryData(){
    this.onboardingService.getModuleData('CATEGORY').subscribe((response) => {
      this.categoryList = response;
    },
    (error)=>{
      // console.log('Error on get Category list: ', error);

    });
  }

  getPageEvent(pageData: PageEvent) {
    this.clientsPagination.doPagination(pageData);
    this.getMasterData();
  }

  onSearchChange(data: any){
    this.searchText = data.target.value;
    this.getMasterData()
  }

  onModuleSelect(selection: any){
    this.selectedModuleName = selection.value;
    this.getMasterData();
  } 
  
  onCategorySelect(selection: any){
    this.selectedCategoryName = selection.value;
    this.getMasterData();
  }

  onStateSelect(selection: any){
    this.selectedStateName = selection.value;
    this.getMasterData();
  }
}
