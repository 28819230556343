import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from "@angular/core";

@Component({
  selector: "app-chart-types",
  templateUrl: "./chart-types.component.html",
  styleUrls: ["./chart-types.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChartTypesComponent implements OnInit {
  @Input() element: any;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    // console.log(this.element);
    if (this.element) {
      this.inside_table.series = [
        {
          name: "Core",
          data: [this.element.core],
        },
        {
          name: "Value-Add",
          data: [this.element.valueAdd],
        },
        {
          name: "Non-value Add",
          data: [this.element.nonValueAdd],
        },
      ];
      this.cdr.markForCheck();
    } else {
      this.inside_table.series = [];
    }
  }

  inside_table: any = {
    series: [
      {
        name: "Core",
        data: [20],
      },
      {
        name: "Value-Add",
        data: [50],
      },
      {
        name: "Non-value Add",
        data: [30],
      },
    ],
    chart: {
      type: "bar",
      height: 75,
      minheight: "50px",
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    colors: ["#51D9F5", "#6BC4A8","#D24833"],
    stroke: {
      width: 0,
      colors: ["#fff"],
    },
    xaxis: {
      show: false,
      // categories: [], // Replace with your category labels
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    tooltip: {
      // y: {
      //   formatter: function(val:any) {
      //     return val + "K";
      //   }
      // }
    },
    fill: {
      // opacity: 1
    },
    legend: {
      show: false,
    },
  };
}
