import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';

import { MatTabChangeEvent } from '@angular/material/tabs';

import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-manager-interiews',
  templateUrl: './manager-interiews.component.html',
  styleUrls: ['./manager-interiews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagerInteriewsComponent implements OnInit {
  render:boolean =false
  selectedTab: number = 0
  clientId: any;

  constructor(
    private activatedroute : ActivatedRoute,
    private router : Router,
    private cdr : ChangeDetectorRef
  ) { 
    let url = this.router.url
    let splitUrl = url.split('/')
    this.clientId = splitUrl[2]
    // console.log('clientId ',this.clientId);
  }

  ngOnInit(): void {
    // console.log('ManagerInteriewsComponent loaded');
    // this.clientId = this.activatedroute.snapshot.paramMap.get('id')
    // console.log('selected tab',this.selectedTab, 'this.clientId -------------------',this.clientId )
    // this.tabChange(0)
    // this.router.navigateByUrl(`project/${this.clientId}/manager-interview/setup`)
  }

  tabChange(index:any){
     this.selectedTab = index
     if(this.selectedTab == 0){
       this.router.navigateByUrl(`project/${this.clientId}/manager-interview/setup`)
       this.cdr.markForCheck()
     }else if(this.selectedTab == 1){

     }else if (this.selectedTab == 2 ){
        this.render = true;
      this.cdr.markForCheck();
      // console.log(this.render);
     }
  }

}
