import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ChartComponent } from "ng-apexcharts";
import { eva as data } from "../avaData";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { SurveyService } from "@app/survey/survey.service";
import { M } from "@angular/cdk/keycodes";
import { MatSelect } from "@angular/material/select";
import { DatabaseService } from "@app/database/database.service";
import { getDefinedMap, getCategoryMap} from "@app/projects-v2/util/util";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};

@Component({
  selector: "app-eva-outputs",
  templateUrl: "./eva-outputs.component.html",
  styleUrls: ["./eva-outputs.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EvaOutputsComponent implements OnInit {
  @ViewChild("chart")
  chart2!: ChartComponent;
  public chartOptions: ChartOptions | any;
  data: any = [];
  setColor: any = "_";

  //Table-1
  displayedColumns: string[] = ["team", "score"];
  dataTable: any[] = [];

  //Table-2
  // displayedColumnsTable2: string[] = [
  //   "title",
  //   "sd",
  //   "d",
  //   "n",
  //   "agree",
  //   "sa",
  //   "average",
  // ];
  displayedColumnsTable2: string[] = ["title", "sd"];
  questionsColumns: string[] = ["title"];
  dataSource: any = [];
  keysCopydata: any = [];

  // Overview
  dataSource2: any;
  ds2: string[] = ["team", "sd", "d", "n", "agree", "sa", "average"];

  //Filters
  selectedOption = "Bar Chart";
  filters: any[] = [
    { Value: "Activity" },
    { Value: "Type" },
    { Value: "TimeCategory" },
  ];
  xFilter: string = "clientFunction";
  yFilter: string = "TimeCategory";
  zFilter: string = "all";
  grouping: any = "clientFunction";
  role: string = "all";
  lensFilterOption: string = "question";

  surveyData: any;
  clientId: any;
  Response: any;

  // New Data

  functions: any[] = [];
  teams: any[] = [];

  selectedFunction: String = "All";
  selectedTeam: String = "All";

  selectedFunctions: any[] = ["All"];
  selectedTeams: any[] = ["All"];

  previousTeams: any[] = ["All"];
  previousFunctions: any[] = ["All"];

  checked = false;
  mystatus = "one";

  possiblePoints = 5;

  chartCategoryScore: any = new Map();

  constructor(
    private cdr: ChangeDetectorRef,
    private surveyService: SurveyService,
    private databaseService: DatabaseService
  ) {}

  ngOnInit(): void {
    this.clientId = sessionStorage.getItem("clientId") || "";
    this.opMaturityMapping();
    this.changeRoleData();
    this.populateDataSource();
    //this.getSurvey();  //Get API Data
    this.getSurveyStats("ava-eva");
    // console.log(this.data, "GETTING MOCK eva");
    //this.initiateSingleChart();
    this.EVACHART();

    //Calls

    this.teamFilter("teamName");
    this.roleFilter("isManager");
    this.lensFilter("question");

    setTimeout(() => {
      this.tab(1);
    }, 1000);

    // this.hideProgressBar = this.chartValue ? false : true;
    // this.new = (this._chartText / 5) * 100;
    // console.log(this._chartText)
  }

  tab = (tabChangeEvent: any): void => {
    if (tabChangeEvent === 1) {
      this.getAllCategories();
    }
  };

  //DataMappings

  dataMapping(){
    // getDefinedMap,getMapById,getLensMap,getSettingId,getSystemMap
  }

  //API Integration

  getSurvey() {
    let surveyQuery: object = { clientId: this.clientId, module: "ava-eva" };
    this.surveyService.getSurveyByQuery(surveyQuery).subscribe(
      (surveyResponse) => {
        this.surveyData = surveyResponse["data"][0];
        this.getSurveyStat();
        this.cdr.markForCheck();
      },
      (error) => {
        // console.log("Error: ", error);
      }
    );
  }

  getSurveyStat() {
    this.surveyService.getSurveyStats(this.surveyData["_id"]).subscribe(
      (surveyStats: any) => {
        // console.log("API Eva response :--->", surveyStats);
        this.data = surveyStats; //Assign api-data to chart-data
        this.cdr.markForCheck();
      },
      (error) => {
        // console.log("Error: ", error);
      }
    );
  }

  opMaturityMapData:any;
  public opMaturityMapping(){
    this.databaseService.getOpMaturyMapping(this.clientId).subscribe((res) => {
      // console.log(res,'Data res<>');
      this.opMaturityMapData = res; //DATA
    })
  }


  getSurveyStats(module: any) {
    this.surveyService.getSurveyOutputs(module, this.clientId).subscribe(
      (res: any) => {
        // console.log("NEW API Eva Response -->", res);
        this.opMaturityMapping();
        //Mapping 
        let changedOutputs: any = [];
        if (this.opMaturityMapData.length > 0) {
          for (let template of res) {
            let changedData: any = [];
            for (let question of template.eva) {
              let changedOutputData: any = question
              // console.log(question);
              let modifiedMap = getDefinedMap(this.opMaturityMapData[0], { category: question.category, subCategory: question.subCategory, lens: question.lens })
              changedOutputData.category = modifiedMap.category
              changedOutputData.subCategory = modifiedMap.subCategory
              changedOutputData.lens = modifiedMap.lens

              changedData.push(changedOutputData);

            }
            changedOutputs.push(changedData)
          }
          res.forEach((ele: any) => {
            ele.eva = changedOutputs[0];
          });
        }       

        this.data = res; //Assign api-data to chart-data

        // Modifying API DATA
        this.data.forEach((ele: any) => {
          let Format: any = [];
          ele.eva.forEach((ele: any) => {
            Format.push(ele);
          });

          delete ele.eva;
          let obj = [];
          obj = ele;
          obj.eva = { questions: Format };
          ele = obj;
        });

        // console.log("Modified API eva : ", this.data);
        this.processData();
        // Modifying API DATA complete
        this.cdr.markForCheck();
      },
      (error) => {
        // console.log("Error from Output API : ", error);
        this.cdr.markForCheck();
      }
    );
  }

  chartInitiateEva(series: any, categories: any) {
    this.chartOptions = {
      series: series,
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            //this.cdr.markForCheck();
            const seriesIndex = config.seriesIndex;
            const dataPointIndex = config.dataPointIndex;
            const value =
              chartContext?.w.globals.series[seriesIndex][dataPointIndex];
            const legendName = chartContext?.w.config.series[seriesIndex].name;
            // console.log("Clicked data:", value, legendName);
          },
        },
      },
      colors: ["#D34717", "#E45256", "#A5A5A5", "#8FD150", "#67B14F"],
      plotOptions: {
        bar: {
          horizontal: true,
          distributed: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      title: {
        text: "",
      },
      xaxis: {
        categories: categories,
      },
      tooltip: {
        y: {
          formatter: function (val: any) {
            return val + "K";
          },
        },
      },
      legend: {
        position: "top",
        horizontalAlign: "left",
        offsetX: 40,
      },
    };
  }

  //Filter functions for team,role,type/time-category,lens

  teamFilter(data: any) {
    this.grouping = data;
    this.changeRoleData();
  }

  roleFilter(data: any) {
    this.role = data;
    // console.log(data);
    this.changeRoleData();
  }

  getAllCategories() {
    this.databaseService.getCategoriesByType("CATEGORY").subscribe(
      (res: any) => {
        
        let cats = new Map();

        if (this.opMaturityMapData.length > 0) {
          cats = getCategoryMap(this.opMaturityMapData[0]);
        }

        let populateInitData = false;
        if (this.chartDataArray.length == 0) {
          populateInitData = true;
        }

        for (let cat of res) {
          if(cats.has(cat.category)) {
            
            this.chartCategoryScore.set(cats.get(cat.category), 0);

          } else {
            this.chartCategoryScore.set(cat.category, 0);

          }
          const originalObject: any = {
            series: "",
            name: "",
          };
          if (populateInitData) {
            originalObject.series = 0;
            originalObject.name = cat.category;
            this.chartDataArray.push(originalObject);
          }
        }
        this.getSurveyStats("ava-eva"); //Get API Data
        this.changeRoleData();
        // console.log(this.data, "GETTING MOCK eva");
        //Calls

        this.teamFilter("teamName");
        this.roleFilter("isManager");
        this.lensFilter("question");

        //New Charts

        this.getFunctionsAndTeams();
        this.populateEvaData();

        this.cdr.markForCheck();
      },
      (error) => {
        // console.log("Error from Categories API : ", error);
      }
    );
  }

  changeRoleData() {
    if (this.grouping == "clientFunction") {
      const tableData: any[] = [];
      const functionTeamCount = new Map();
      const resultMap = new Map();
      const rating1Map = new Map();
      const rating2Map = new Map();
      const rating3Map = new Map();
      const rating4Map = new Map();
      const rating5Map = new Map();

      let processData = true;

      const ratingCat = [];
      for (let x of this.data) {
        if (this.role == "manager") {
          processData = x.manager;
        } else if (this.role == "member") {
          processData = !x.manager;
        } else {
          processData = true;
        }
        if (processData) {
          let value = 0;
          let qCount = 0;

          for (let q of x.eva.questions) {
            value = value + q.answer * x.total;
            qCount = qCount + 1;

            const rating = q.ratingCount;
            if (rating1Map.has(x.clientFunction)) {
              rating1Map.set(
                x.clientFunction,
                rating1Map.get(x.clientFunction) + rating.one
              );
            } else {
              rating1Map.set(x.clientFunction, rating.one);
            }

            if (rating2Map.has(x.clientFunction)) {
              rating2Map.set(
                x.clientFunction,
                rating2Map.get(x.clientFunction) + rating.two
              );
            } else {
              rating2Map.set(x.clientFunction, rating.two);
            }

            if (rating3Map.has(x.clientFunction)) {
              rating3Map.set(
                x.clientFunction,
                rating3Map.get(x.clientFunction) + rating.three
              );
            } else {
              rating3Map.set(x.clientFunction, rating.three);
            }

            if (rating4Map.has(x.clientFunction)) {
              rating4Map.set(
                x.clientFunction,
                rating4Map.get(x.clientFunction) + rating.four
              );
            } else {
              rating4Map.set(x.clientFunction, rating.four);
            }

            if (rating5Map.has(x.clientFunction)) {
              rating5Map.set(
                x.clientFunction,
                rating5Map.get(x.clientFunction) + rating.five
              );
            } else {
              rating5Map.set(x.clientFunction, rating.five);
            }
          }

          if (resultMap.has(x.clientFunction)) {
            functionTeamCount.set(
              x.clientFunction,
              x.total + functionTeamCount.get(x.clientFunction)
            );
            resultMap.set(
              x.clientFunction,
              value / qCount + resultMap.get(x.clientFunction)
            );
          } else {
            functionTeamCount.set(x.clientFunction, x.total);
            resultMap.set(x.clientFunction, value / qCount);
          }
        }
      }
      for (let key of resultMap.keys()) {
        ratingCat.push(key);
        tableData.push({
          team: key,
          score: (resultMap.get(key) / functionTeamCount.get(key)).toFixed(2),
        });
      }
      this.dataTable = tableData;
      // console.log("<><><><><><><><>", this.dataTable);
      const seriesData: any[] = [];
      seriesData.push({ name: "1", data: Array.from(rating1Map.values()) });
      seriesData.push({ name: "2", data: Array.from(rating2Map.values()) });
      seriesData.push({ name: "3", data: Array.from(rating3Map.values()) });
      seriesData.push({ name: "4", data: Array.from(rating4Map.values()) });
      seriesData.push({ name: "5", data: Array.from(rating5Map.values()) });
      // console.log(seriesData);
      this.chartInitiateEva(seriesData, ratingCat);
    } else {
      const tableData: any[] = [];
      const teamCount = new Map();
      const resultMap = new Map();
      const rating1Map = new Map();
      const rating2Map = new Map();
      const rating3Map = new Map();
      const rating4Map = new Map();
      const rating5Map = new Map();

      let processData = true;
      const ratingCat = [];

      for (let x of this.data) {
        if (this.role == "manager") {
          processData = x.manager;
        } else if (this.role == "member") {
          processData = !x.manager;
        } else {
          processData = true;
        }
        if (processData) {
          let value = 0;
          let qCount = 0;
          for (let q of x.eva.questions) {
            value = value + q.answer * x.total;
            qCount = qCount + 1;

            const rating = q.ratingCount;
            if (rating1Map.has(x.teamName)) {
              rating1Map.set(
                x.teamName,
                rating1Map.get(x.teamName) + rating.one
              );
            } else {
              rating1Map.set(x.teamName, rating.one);
            }

            if (rating2Map.has(x.teamName)) {
              rating2Map.set(
                x.teamName,
                rating2Map.get(x.teamName) + rating.two
              );
            } else {
              rating2Map.set(x.teamName, rating.two);
            }

            if (rating3Map.has(x.teamName)) {
              rating3Map.set(
                x.teamName,
                rating3Map.get(x.teamName) + rating.three
              );
            } else {
              rating3Map.set(x.teamName, rating.three);
            }

            if (rating4Map.has(x.teamName)) {
              rating4Map.set(
                x.teamName,
                rating4Map.get(x.teamName) + rating.four
              );
            } else {
              rating4Map.set(x.teamName, rating.four);
            }

            if (rating5Map.has(x.teamName)) {
              rating5Map.set(
                x.teamName,
                rating5Map.get(x.teamName) + rating.five
              );
            } else {
              rating5Map.set(x.teamName, rating.five);
            }
          }

          if (resultMap.has(x.teamName)) {
            teamCount.set(x.teamName, x.total + teamCount.get(x.teamName));
            resultMap.set(
              x.teamName,
              value / qCount + resultMap.get(x.teamName)
            );
          } else {
            teamCount.set(x.teamName, x.total);
            resultMap.set(x.teamName, value / qCount);
          }
        }
      }
      for (let key of resultMap.keys()) {
        ratingCat.push(key);
        tableData.push({
          team: key,
          score: (resultMap.get(key) / teamCount.get(key)).toFixed(2),
        });
      }
      this.dataTable = tableData;
      const seriesData: any[] = [];
      seriesData.push({ name: "1", data: Array.from(rating1Map.values()) });
      seriesData.push({ name: "2", data: Array.from(rating2Map.values()) });
      seriesData.push({ name: "3", data: Array.from(rating3Map.values()) });
      seriesData.push({ name: "4", data: Array.from(rating4Map.values()) });
      seriesData.push({ name: "5", data: Array.from(rating5Map.values()) });
      this.chartInitiateEva(seriesData, ratingCat);
      // console.log("this.dataTable ", this.dataTable);
      this.cdr.markForCheck();
    }
  }

  typeTimeCatFilter(data: any) {
    // console.log(data);
  }

  lensFilter(data: any) {
    this.lensFilterOption = data;
    this.populateDataSource();
  }

  populateDataSource() {
    const titles = new Set();

    const titleMap = new Map();
    const score1Map = new Map();
    const score2Map = new Map();
    const score3Map = new Map();
    const score4Map = new Map();
    const score5Map = new Map();

    for (let d of this.data) {
      for (let q of d.eva.questions) {
        const rating = q.ratingCount;
        const total =
          rating.one + rating.two + rating.three + rating.four + rating.five;
        let key = "";
        if (this.lensFilterOption == "lens") {
          titles.add(q.lens);
          key = q.lens;
        } else {
          titles.add(q.question);
          key = q.question;
        }

        if (score1Map.has(key)) {
          score1Map.set(key, rating.one + score1Map.get(key));
        } else {
          score1Map.set(key, rating.one);
        }

        if (score2Map.has(key)) {
          score2Map.set(key, rating.two + score2Map.get(key));
        } else {
          score2Map.set(key, rating.two);
        }

        if (score3Map.has(key)) {
          score3Map.set(key, rating.three + score3Map.get(key));
        } else {
          score3Map.set(key, rating.three);
        }

        if (score4Map.has(key)) {
          score4Map.set(key, rating.four + score4Map.get(key));
        } else {
          score4Map.set(key, rating.four);
        }

        if (score5Map.has(key)) {
          score5Map.set(key, rating.five + score5Map.get(key));
        } else {
          score5Map.set(key, rating.five);
        }
      }
    }
    // console.log(score1Map, "RV");

    const newSource = [];

    for (let title of titles) {
      let total =
        score1Map.get(title) +
        score2Map.get(title) +
        score3Map.get(title) +
        score4Map.get(title) +
        score5Map.get(title);
      let score1 = ((score1Map.get(title) / total) * 100).toFixed(2);
      let score2 = ((score2Map.get(title) / total) * 100).toFixed(2);
      let score3 = ((score3Map.get(title) / total) * 100).toFixed(2);
      let score4 = ((score4Map.get(title) / total) * 100).toFixed(2);
      let score5 = ((score5Map.get(title) / total) * 100).toFixed(2);

      let newData: any = {};
      newData.title = title;
      newData.score1 = score1 + "%";
      newData.score2 = score2 + "%";
      newData.score3 = score3 + "%";
      newData.score4 = score4 + "%";
      newData.score5 = score5 + "%";
      newSource.push(newData);
    }
    //this.dataSource = newSource;
    // console.log(this.dataSource, "////////////////////////");
    // console.log("LEFT", this.chartOptions);

    //inside-chart

    this.cdr.markForCheck();
  }

  @ViewChild("single") single: ChartComponent;
  public SinglechartOptions: any;

  overallCategories: any;
  overallCategoryScore: any;
  managerCount: any;
  memberCount: any;
  functionsCount: any;
  teamsCount: any;

  public chartDataArray: any = [
    {
      series: [],
      chart: {
        height: 200,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
          },
          dataLabels: {
            show: true,
            name: {
              show: false,
            },
            value: {
              show: true,
              fontSize: "17px",
              fontFamily: "Hanken Grotesk",
              fontWeight: 600,
              textAlign: "center",
            },
          },
        },
      },
      colors: ["#5C2344"],
      name: "Sample",
    },
  ];

  initiateSingleChart() {
    let avgScore = this.average;
    // console.log(avgScore);
    this.SinglechartOptions = {
      series: [this.averageChart],
      chart: {
        height: 300,
        type: "radialBar",
        // marginLeft: 10,
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "60%",
          },
          dataLabels: {
            show: false,
            name: {
              show: false,
              fontSize: "22px",
              fontFamily: "Hanken Grotesk",
              fontWeight: 800,
              textAlign: "center",
            },
            value: {
              formatter: function (val: any) {
                return val;
                // return val.replace(/%/g, '');
              },
              show: true,
            },
          },
        },
      },
      // dataLabels: {
      //   enabled: false // <--- HERE
      // },
      colors: ["#0C0209"],
      name: avgScore,
    };
    this.cdr.markForCheck();
  }

  EVACHART() {
    // console.log(this.chartOptions, "GET SERIES");

    this.Response = {
      series: this.chartOptions.series,
      chart: {
        type: "bar",
        height: 450,
        // width:450,
        stacked: true,
        stackType: "100%",
      },
      yaxis: {
        show: false, // Hide the Y-axis
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      stroke: {
        width: 1,
        colors: ["#fff"],
      },
      fill: {
        opacity: 1,
      },
      xaxis: {
        show: false,
      },
    };

    //this.Response.xaxis.categories = ["Question 1", "Question 2", "Question 3"]
    // console.log(this.Response, "right chart <<<<<<<<<!");

    this.cdr.markForCheck();
  }

  average: any;
  averageChart: any;

  processData() {
    const catMap = new Map();

    let avgTotal = 0;
    let avgEva = 0;

    for (let response of this.data) {
      for (let q of response.eva.questions) {
        let ratingCount = q.ratingCount;

        let value =
          ratingCount.one * 1 +
          ratingCount.two * 2 +
          ratingCount.three * 3 +
          ratingCount.four * 4 +
          ratingCount.five * 5;
        let total =
          ratingCount.one +
          ratingCount.two +
          ratingCount.three +
          ratingCount.four +
          ratingCount.five;

        avgEva = avgEva + value;
        avgTotal = avgTotal + total;

        if (catMap.has(q.category)) {
          let obj = catMap.get(q.category);
          obj.count = obj.count + total;
          obj.value = value + parseInt(obj.value);
          catMap.set(q.category, obj);
        } else {
          let obj: any = {};
          obj.count = total;
          obj.value = value;
          catMap.set(q.category, obj);
        }
      }
    }

    const catResult = new Map();
    let totalValue = 0;

    for (let key of catMap.keys()) {
      let obj = catMap.get(key);
      totalValue = obj.value + totalValue;
    }

    for (let key of catMap.keys()) {
      let obj = catMap.get(key);
      catResult.set(key, (obj.value / obj.count).toFixed(1));
    }

    this.average = (avgEva / avgTotal).toFixed(1);
    this.averageChart = (this.average / this.possiblePoints) * 100;

    //To get overall categories & its score

    this.initiateSingleChart();
    this.cdr.markForCheck();

    let resulttt: any = [];

    for (let key of this.chartCategoryScore.keys()) {
      let catObj: any = {};
      catObj.name = key;
      if (catResult.has(key)) {
        catObj.value = catResult.get(key);
      } else {
        catObj.value = 0;
      }
      this.chartCategoryScore.set(key, catObj.value);
    }

    this.overallCategories = Array.from(this.chartCategoryScore.keys());
    this.overallCategoryScore = Array.from(this.chartCategoryScore.values());

    this.overallCategoryScore.forEach((ele: never, index: any) => {
      const originalObject = {
        series: [],
        chart: {
          height: 200,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
                fontSize: "17px",
                fontFamily: "Hanken Grotesk",
                fontWeight: 600,
                textAlign: "center",
              },
              value: {
                formatter: function (val: any) {
                  return val.replace(/%/g, "");
                },
                show: true,
              },
            },
          },
        },
        colors: ["#5C2344"],
        name: "",
      };
      //console.log(originalObject);

      originalObject.series.push(ele);

      this.overallCategories.forEach((cat: any, targetIndex: any) => {
        if (index === targetIndex) {
          originalObject.name = cat;
        }
      });

      resulttt.push(originalObject);
      // console.log("CCCCCC||", resulttt);
    });

    // console.log(">>>>>||", resulttt);
    this.chartDataArray = resulttt;

    //To get team,function,member,manager overall counts

    const teamsCount = this.data.map((obj: any) => obj.teamName);
    this.teamsCount = teamsCount.length;
    const functionCount = this.data.map((obj: any) => obj.clientFunction);
    this.functionsCount = functionCount.length;
    const managerCount = this.data.map((obj: any) => obj.isManager);
    let counts: any = 0;
    let countsMember: any = 0;
    managerCount.forEach((count: any) => {
      if (count === true) counts = counts + 1;
      if (count === false) countsMember = countsMember + 1;
    });
    this.managerCount = counts;
    this.memberCount = countsMember;

    this.cdr.markForCheck();
  }

  isMember = false;
  isFunction = false;
  onValChange(value: any) {
    this.isMember = value.checked;
    // console.log(this.isMember);
    // if (value.checked) this.roleFilter("member");
    // if (!value.checked) this.roleFilter("isManager");
    // this.cdr.markForCheck();
    if (this.mystatus == "one") {
      this.mystatus = "two";
    } else if (this.mystatus == "two") {
      this.mystatus = "three";
    } else if (this.mystatus == "three") {
      this.mystatus = "one";
    }
    // console.log(this.mystatus);
    //this.populateEvaData();
  }

  getFunctionsAndTeams() {
    this.functions = [];
    if (!this.functions.includes("All")) {
      this.functions.push("All");
    }
    if (!this.teams.includes("All")) {
      this.teams.push("All");
    }
    for (let d of this.data) {
      if (!this.functions.includes(d.clientFunction)) {
        this.functions.push(d.clientFunction);
      }
      if (!this.teams.includes(d.teamName)) {
        this.teams.push(d.teamName);
      }
    }
  }
  onFunctionChange(value: any) {
    if (value == "team") {
      this.isFunction = false;
    } else {
      this.isFunction = true;
    }
    this.populateEvaData();
  }

  changeFunction(event: any) {
    this.selectedFunctions = [];

    if (!event.length) {
      this.selectedFunctions.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedFunctions.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousFunctions.includes("All")) {
        this.selectedFunctions.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedFunctions.push("All");
      }
    } else {
      this.selectedFunctions.push(...event);
    }
    this.previousFunctions = this.selectedFunctions;
    this.populateEvaData();
  }

  changeTeam(event: any) {
    // console.log("----changeTeam ------", event);
    this.selectedTeams = [];

    if (!event.length) {
      this.selectedTeams.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedTeams.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousTeams.includes("All")) {
        this.selectedTeams.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedTeams.push("All");
      }
    } else {
      this.selectedTeams.push(...event);
    }
    this.previousTeams = this.selectedTeams;
    this.populateEvaData();
  }

  @ViewChild("select") select: MatSelect;
  @ViewChild("select2") select2: MatSelect;

  allSelected_function = false;
  allSelected_team = false;
  toggleAllSelection() {
    if (this.allSelected_function) {
      this.select.options.forEach((item: any) => item.select());
    } else {
      this.select.options.forEach((item: any) => item.deselect());
    }
  }
  optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: any) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected_function = newStatus;
  }

  toggleAllSelectionTeam() {
    if (this.allSelected_team) {
      this.select2.options.forEach((item: any) => item.select());
    } else {
      this.select2.options.forEach((item: any) => item.deselect());
    }
  }
  optionClickTeam() {
    let newStatus = true;
    this.select2.options.forEach((item: any) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected_team = newStatus;
  }

  getType(data: any) {
    // console.log(data);
    this.processSelected(data);
  }

  populateEvaData() {
    for (let key of this.chartCategoryScore.keys()) {
      this.chartCategoryScore.set(key, "N/A");
    }
    // console.log("Populating New Teams Data");
    // console.log(this.data);

    let avgEva = 0;
    let avgTotal = 0;

    let categoryMap = new Map();
    let tableMap = new Map();
    let questionMap = new Map();

    for (let d of this.data) {
      let manProcessThis = false;
      let funcProcessThis = false;

      if (
        (this.memberSelect == "all" || this.memberSelect == "manager") &&
        d.isManager
      ) {
        manProcessThis = true;
      } else if (
        (this.memberSelect == "all" || this.memberSelect == "member") &&
        !d.isManager
      ) {
        manProcessThis = true;
      }

      if (
        (this.selectedFunctions.includes(d.clientFunction) ||
          this.selectedFunctions.includes("All")) &&
        (this.selectedTeams.includes(d.teamName) ||
          this.selectedTeams.includes("All"))
      ) {
        funcProcessThis = true;
      }

      if (manProcessThis && funcProcessThis) {
        for (let q of d.eva.questions) {
          // Overall Avg

          avgEva = avgEva + parseInt(q.answer) * parseInt(d.total);
          avgTotal = avgTotal + d.total;

          //Process Category
          let object: any = {};
          if (categoryMap.has(q.category)) {
            object = categoryMap.get(q.category);
            object.count = object.count + d.total;
            object.value =
              parseInt(object.value) + parseInt(q.answer) * parseInt(d.total);
          } else {
            object.count = d.total;
            object.value = parseInt(q.answer) * parseInt(d.total);
          }
          categoryMap.set(q.category, object);

          //Process Table
          let tableKey;
          if (this.isFunction) {
            tableKey = d.clientFunction;
          } else {
            tableKey = d.teamName;
          }

          if (tableMap.has(tableKey)) {
            let ratings = tableMap.get(tableKey);
            ratings.one = parseInt(ratings.one) + parseInt(q.ratingCount.one);
            ratings.two = parseInt(ratings.two) + parseInt(q.ratingCount.two);
            ratings.three =
              parseInt(ratings.three) + parseInt(q.ratingCount.three);
            ratings.four =
              parseInt(ratings.four) + parseInt(q.ratingCount.four);
            ratings.five =
              parseInt(ratings.five) + parseInt(q.ratingCount.five);
            tableMap.set(tableKey, ratings);
          } else {
            let ratings: any = {};
            ratings.one = parseInt(q.ratingCount.one);
            ratings.two = parseInt(q.ratingCount.two);
            ratings.three = parseInt(q.ratingCount.three);
            ratings.four = parseInt(q.ratingCount.four);
            ratings.five = parseInt(q.ratingCount.five);
            tableMap.set(tableKey, ratings);
          }

          //Process Quesions

          if (questionMap.has(q.question)) {
            let ratings = questionMap.get(q.question);
            ratings.one = parseInt(ratings.one) + parseInt(q.ratingCount.one);
            ratings.two = parseInt(ratings.two) + parseInt(q.ratingCount.two);
            ratings.three =
              parseInt(ratings.three) + parseInt(q.ratingCount.three);
            ratings.four =
              parseInt(ratings.four) + parseInt(q.ratingCount.four);
            ratings.five =
              parseInt(ratings.five) + parseInt(q.ratingCount.five);
            questionMap.set(q.question, ratings);
          } else {
            let ratings: any = {};
            ratings.one = parseInt(q.ratingCount.one);
            ratings.two = parseInt(q.ratingCount.two);
            ratings.three = parseInt(q.ratingCount.three);
            ratings.four = parseInt(q.ratingCount.four);
            ratings.five = parseInt(q.ratingCount.five);
            questionMap.set(q.question, ratings);
          }
        }
      }
    }

    //All values, need to change to correct series
    // console.log(avgEva / avgTotal);

    this.average = (avgEva / avgTotal).toFixed(1);

    // let categories = [];
    // for (let key of categoryMap.keys()) {
    //   let obj: any = {};
    //   let dat = categoryMap.get(key);
    //   obj.name = key;
    //   obj.value = dat.value / dat.count;
    //   obj.value = obj.value.toFixed(2);
    //   categories.push(obj);
    // }

    // console.log(categories, "CAT");
    // this.overallCategories = categories;
    // this.overallCategoryScore = categories;
    // this.cdr.markForCheck();

    let resulttt: any = [];

    for (let key of this.chartCategoryScore.keys()) {
      let catObj: any = {};
      catObj.name = key;
      if (categoryMap.has(key)) {
        let val = categoryMap.get(key);
        catObj.value = (val.value / val.count).toFixed(1);
        // console.log("value", categoryMap.get(key));
      } else {
        catObj.value = 0;
      }
      this.chartCategoryScore.set(key, catObj.value);
    }

    this.overallCategories = Array.from(this.chartCategoryScore.keys());
    this.overallCategoryScore = Array.from(this.chartCategoryScore.values());

    this.overallCategoryScore.forEach((ele: never, index: any) => {
      // console.log(ele);

      const originalObject = {
        series: [],
        chart: {
          height: 200,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "60%",
            },
            dataLabels: {
              show: true,
              name: {
                show: false,
                fontSize: "17px",
                fontFamily: "Hanken Grotesk",
                fontWeight: 600,
                textAlign: "center",
              },
              value: {
                formatter: function (val: any) {
                  return val.replace(/%/g, "");
                },
                show: true,
              },
            },
          },
        },
        colors: ["#5C2344"],
        name: "",
      };
      //console.log(originalObject);

      originalObject.series.push(ele);

      this.overallCategories.forEach((cat: any, targetIndex: any) => {
        if (index === targetIndex) {
          originalObject.name = cat;
        }
      });

      resulttt.push(originalObject);
      // console.log("CCCCCC||", resulttt);
    });

    // console.log(">>>>>||", resulttt);
    this.chartDataArray = resulttt;

    let tableEntries = [];
    for (let key of tableMap.keys()) {
      let obj: any = {};
      obj.name = key;
      let dat = tableMap.get(key);
      obj.one = dat.one;
      obj.two = dat.two;
      obj.three = dat.three;
      obj.four = dat.four;
      obj.five = dat.five;

      obj.avg =
        (obj.one * 1 +
          obj.two * 2 +
          obj.three * 3 +
          obj.four * 4 +
          obj.five * 5) /
        (obj.one + obj.two + obj.three + obj.four + obj.five);
      tableEntries.push(obj);
    }

    // console.log("TE", tableEntries);
    this.dataSource2 = tableEntries;

    let questionEntries = [];
    for (let key of questionMap.keys()) {
      let obj: any = {};
      obj.name = key;
      let dat = questionMap.get(key);
      let total = dat.one + dat.two + dat.three + dat.four + dat.five;
      // console.log(total);
      obj.one = (dat.one / total) * 100;
      obj.two = (dat.two / total) * 100;
      obj.three = (dat.three / total) * 100;
      obj.four = (dat.four / total) * 100;
      obj.five = (dat.five / total) * 100;

      obj.avg =
        (dat.one * 1 +
          dat.two * 2 +
          dat.three * 3 +
          dat.four * 4 +
          dat.five * 5) /
        total;
      questionEntries.push(obj);
    }
    // console.log(questionEntries, this.inside_table);
    this.dataSource = questionEntries;
    this.keysCopydata = this.dataSource;

    let IT: any = [];
    this.dataSource.forEach((element: any) => {
      // console.log(element);
      const inside_table = {
        series: [
          {
            name: element.name,
            data: [element.one],
          },
          {
            name: element.name,
            data: [element.two],
          },
          {
            name: element.name,
            data: [element.three],
          },
          {
            name: element.name,
            data: [element.four],
          },
          {
            name: element.name,
            data: [element.five],
          },
        ],
        chart: {
          type: "bar",
          height: 110,
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        colors: ["#BB0204", "#F97529", "#51D9F5", "#6BC4A8", "#386C5C"],
        stroke: {
          width: 2,
          colors: ["#fff"],
        },
        xaxis: {
          show: false,
          // categories: [], // Replace with your category labels
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
        },
        tooltip: {
          // y: {
          //   formatter: function(val:any) {
          //     return val + "K";
          //   }
          // }
        },
        fill: {
          // opacity: 1
        },
        legend: {
          show: false,
        },
      };

      element.chart = inside_table;

      IT.push(inside_table);
    });
    // console.log(this.dataSource, "dataSource");
    this.initiateSingleChart();
    this.cdr.markForCheck();
  }

  processSelected(type: any) {
    let arr: any = [];
    arr = [...this.keysCopydata];
    // set other value to zero based on key selection
    //change chart as well
    arr.forEach((element: any) => {
      let inside_table = {
        series: [
          {
            name: element.name,
            data: [element.one],
          },
          {
            name: element.name,
            data: [element.two],
          },
          {
            name: element.name,
            data: [element.three],
          },
          {
            name: element.name,
            data: [element.four],
          },
          {
            name: element.name,
            data: [element.five],
          },
        ],
        chart: {
          type: "bar",
          height: 110,
          stacked: true,
          stackType: "100%",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        colors: ["#BB0204", "#F97529", "#51D9F5", "#6BC4A8", "#386C5C"],
        stroke: {
          width: 2,
          colors: ["#fff"],
        },
        xaxis: {
          show: false,
          // categories: [], // Replace with your category labels
          labels: {
            show: false,
          },
        },
        yaxis: {
          show: false,
          labels: {
            show: false,
          },
        },
        tooltip: {},
        fill: {
          // opacity: 1
        },
        legend: {
          show: false,
        },
      };

      if (type === "one") {
        inside_table.colors = [];
        inside_table.colors = [
          "#BB0204",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
        ];
      }
      if (type === "two") {
        inside_table.colors = [];
        inside_table.colors = [
          "#FFFFFF",
          "#F97529",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
        ];
      }
      if (type === "three") {
        inside_table.colors = [];
        inside_table.colors = [
          "#FFFFFF",
          "#FFFFFF",
          "#51D9F5",
          "#FFFFFF",
          "#FFFFFF",
        ];
      }
      if (type === "four") {
        inside_table.colors = [];
        inside_table.colors = [
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#6BC4A8",
          "#FFFFFF",
        ];
      }
      if (type === "five") {
        inside_table.colors = [];
        inside_table.colors = [
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#FFFFFF",
          "#386C5C",
        ];
      }

      element.chart = inside_table;
    });

    if (type === "all") {
      this.dataSource = this.keysCopydata;
    } else {
      this.dataSource = arr;
    }
    // console.log(arr, "arr");
    this.cdr.markForCheck();
  }

  responseTable: any = [
    {
      question: "QWETY",
      average: 20,
    },
    {
      question: "RRR",
      average: 21,
    },
    {
      question: "POIWETY",
      average: 22,
    },
  ];
  responseTableColumns: any = ["questions", "rating", "average"];

  // new
  public _chartText: any = 5;
  new: any = 5;
  @Input() get chartText() {
    return this._chartText;
  }
  set chartText(value: string) {
    this._chartText = value;
  }

  private _chartValue: number;
  @Input() get chartValue() {
    return this._chartValue;
  }
  set chartValue(value: number) {
    this._chartValue = value;
  }

  hideProgressBar: boolean = false;

  inside_table: any = {
    series: [
      {
        name: "Marine Sprite",
        data: [44],
      },
      {
        name: "Striking Calf",
        data: [53],
      },
      {
        name: "Tank Picture",
        data: [12],
      },
      {
        name: "Bucket Slope",
        data: [9],
      },
      {
        name: "Reborn Kid",
        data: [25],
      },
    ],
    chart: {
      type: "bar",
      height: 150,
      stacked: true,
      stackType: "100%",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    stroke: {
      width: 1,
      colors: ["#fff"],
    },
    xaxis: {
      show: false,
      // categories: [], // Replace with your category labels
      labels: {
        show: false,
      },
    },
    yaxis: {
      show: false,
      labels: {
        show: false,
      },
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return val + "K";
        },
      },
    },
    fill: {
      opacity: 1,
    },
    legend: {
      show: false,
    },
  };

  memberSelect: any = "all";
  handleMemberClick(type: any) {
    this.memberSelect = type;
    this.populateEvaData();
  }

  getDivColour(type: any) {
    if (type == this.memberSelect) {
      return "black";
    } else {
      return "white";
    }
  }
}
