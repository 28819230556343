import { HttpClient } from "@angular/common/http";
import { environment } from "@env/environment";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class DatabaseService {
  staticURL: string = environment.STATIC_API_URL;
  constructor(private httpClient: HttpClient) {}
  buildFromDb_module: string = "";
  

  getOpMaturityQuestions() {
    let questionList = [
      {
        category: "Customer & Purpose",
        subCategory: "Value",
        lens: "Customer Journeys",
        question:
          "Does the organisation have a clear, documented view of the end to end client journey? 1",
        notInPlace: "No customer journeys exist",
        designedYetNotInPlace:
          "These are monitored, yet not yet driving desired behaviours & outcomes...",
        inPlaceAndNotAddingValue:
          "Customer journeys exist, though may be unclear or cumbersome in nature, and are used ad-hoc to inform of pain points but there is no review process in place 1",
        inPlaceAndAddingValue:
          "Standard procedures and/or work instructions are fit for purpose and practiced with clear governance and regular updates to monitor continued improvement",
        InPlaceAddingValueAndContinuouslyReviewed:
          "Customer journeys are clearly defined with pain points, and are regularly used to inform decisions on improving customer experience. Journeys are sometimes reviewed",
      },
      {
        category: "Customer & Purpose",
        subCategory: "Value",
        lens: "Customer Journeys",
        question:
          "Does the organisation have a clear, documented view of the end to end client journey? 2",
        notInPlace:
          "Customer journeys exist, though may be unclear or cumbersome in nature, and are used ad-hoc to inform of pain points but there is no review process in place 2",
        designedYetNotInPlace:
          "These are monitored, yet not yet driving desired behaviours & outcomes...",
        inPlaceAndNotAddingValue: "No customer journeys exist",
        inPlaceAndAddingValue:
          "Standard procedures and/or work instructions are fit for purpose and practiced with clear governance and regular updates to monitor continued improvement",
        InPlaceAddingValueAndContinuouslyReviewed:
          "Customer journeys are clearly defined with pain points, and are regularly used to inform decisions on improving customer experience. Journeys are sometimes reviewed",
      },
      {
        category: "Customer & Purpose",
        subCategory: "Value",
        lens: "Customer Journeys",
        question:
          "Does the organisation have a clear, documented view of the end to end client journey? 3",
        notInPlace: "No KPIs in place",
        designedYetNotInPlace:
          "These are monitored, yet not yet driving desired behaviours & outcomes...",
        inPlaceAndNotAddingValue:
          "Individual & team performance and/or process KPIs are in place with a balance of lag & lead; volume / value / quality.",
        inPlaceAndAddingValue:
          "Standard procedures and/or work instructions are fit for purpose and practiced with clear governance and regular updates to monitor continued improvement",
        InPlaceAddingValueAndContinuouslyReviewed:
          "Customer journeys are clearly defined with pain points, and are regularly used to inform decisions on improving customer experience. Journeys are sometimes reviewed",
      },
      {
        category: "Customer & Purpose",
        subCategory: "Value",
        lens: "Customer Journeys",
        question:
          "Does the organisation have a clear, documented view of the end to end client journey? 4",
        notInPlace: "No KPIs in place",
        designedYetNotInPlace:
          "These are monitored, yet not yet driving desired behaviours & outcomes...",
        inPlaceAndNotAddingValue:
          "Individual & team performance and/or process KPIs are in place with a balance of lag & lead; volume / value / quality. ",
        inPlaceAndAddingValue:
          "Standard procedures and/or work instructions are fit for purpose and practiced with clear governance and regular updates to monitor continued improvement",
        InPlaceAddingValueAndContinuouslyReviewed:
          "Customer journeys are clearly defined with pain points, and are regularly used to inform decisions on improving customer experience. Journeys are sometimes reviewed",
      },
      {
        category: "Performance",
        subCategory: "Vision & KPIs",
        lens: "KPIs",
        question:
          "What KPIs does your team have in place and how reliable are performance data reports, ie format, relevance, accuracy, timeliness etc? 1",
        notInPlace: "No KPIs in place",
        designedYetNotInPlace:
          "These are monitored, yet not yet driving desired behaviours & outcomes...",
        inPlaceAndNotAddingValue:
          "These are monitored, yet not yet driving desired behaviours & outcomes (coaching, problem solving, team work).",
        inPlaceAndAddingValue:
          "Standard procedures and/or work instructions are fit for purpose and practiced with clear governance and regular updates to monitor continued improvement",
        InPlaceAddingValueAndContinuouslyReviewed:
          "Customer journeys are clearly defined with pain points, and are regularly used to inform decisions on improving customer experience. Journeys are sometimes reviewed",
      },
      {
        category: "Performance",
        subCategory: "Vision & KPIs",
        lens: "KPIs",
        question:
          "What KPIs does your team have in place and how reliable are performance data reports, ie format, relevance, accuracy, timeliness etc? 2",
        notInPlace: "No customer journeys exist",
        designedYetNotInPlace:
          "These are monitored, yet not yet driving desired behaviours & outcomes...",
        inPlaceAndNotAddingValue:
          "These are monitored, yet not yet driving desired behaviours & outcomes (coaching, problem solving, team work).",
        inPlaceAndAddingValue:
          "Standard procedures and/or work instructions are fit for purpose and practiced with clear governance and regular updates to monitor continued improvement",
        InPlaceAddingValueAndContinuouslyReviewed:
          "Customer journeys are clearly defined with pain points, and are regularly used to inform decisions on improving customer experience. Journeys are sometimes reviewed",
      },
      {
        category: "Intelligent Automation and Process",
        subCategory: "Process Adherence",
        lens: "Use of standards",
        question:
          "What KPIs does your team have in place and how reliable are performance data reports, ie format, relevance, accuracy, timeliness etc?",
        notInPlace: "No customer journeys exist",
        designedYetNotInPlace:
          "These are monitored, yet not yet driving desired behaviours & outcomes...",
        inPlaceAndNotAddingValue:
          "These are monitored, yet not yet driving desired behaviours & outcomes (coaching, problem solving, team work).",
        inPlaceAndAddingValue:
          "Standard procedures and/or work instructions are fit for purpose and practiced with clear governance and regular updates to monitor continued improvement",
        InPlaceAddingValueAndContinuouslyReviewed:
          "Customer journeys are clearly defined with pain points, and are regularly used to inform decisions on improving customer experience. Journeys are sometimes reviewed",
      },
      {
        category: "Intelligent Automation and Process",
        subCategory: "Process Adherence",
        lens: "Use of standards",
        question:
          "What KPIs does your team have in place and how reliable are performance data reports, ie format, relevance, accuracy, timeliness etc?",
        notInPlace: "No customer journeys exist",
        designedYetNotInPlace:
          "These are monitored, yet not yet driving desired behaviours & outcomes...",
        inPlaceAndNotAddingValue:
          "These are monitored, yet not yet driving desired behaviours & outcomes (coaching, problem solving, team work).",
        inPlaceAndAddingValue:
          "Standard procedures and/or work instructions are fit for purpose and practiced with clear governance and regular updates to monitor continued improvement",
        InPlaceAddingValueAndContinuouslyReviewed:
          "Customer journeys are clearly defined with pain points, and are regularly used to inform decisions on improving customer experience. Journeys are sometimes reviewed",
      },
      {
        category: "Intelligent Automation and Process",
        subCategory: "Process Adherence",
        lens: "Use of standards",
        question:
          "What KPIs does your team have in place and how reliable are performance data reports, ie format, relevance, accuracy, timeliness etc?",
        notInPlace: "No customer journeys exist",
        designedYetNotInPlace:
          "These are monitored, yet not yet driving desired behaviours & outcomes...",
        inPlaceAndNotAddingValue:
          "These are monitored, yet not yet driving desired behaviours & outcomes (coaching, problem solving, team work).",
        inPlaceAndAddingValue:
          "Standard procedures and/or work instructions are fit for purpose and practiced with clear governance and regular updates to monitor continued improvement",
        InPlaceAddingValueAndContinuouslyReviewed:
          "Customer journeys are clearly defined with pain points, and are regularly used to inform decisions on improving customer experience. Journeys are sometimes reviewed",
      },
      {
        category: "Intelligent Automation and Process",
        subCategory: "Process Adherence",
        lens: "Use of standards",
        question:
          "What KPIs does your team have in place and how reliable are performance data reports, ie format, relevance, accuracy, timeliness etc?",
        notInPlace: "No customer journeys exist",
        designedYetNotInPlace:
          "These are monitored, yet not yet driving desired behaviours & outcomes...",
        inPlaceAndNotAddingValue:
          "These are monitored, yet not yet driving desired behaviours & outcomes (coaching, problem solving, team work).",
        inPlaceAndAddingValue:
          "Standard procedures and/or work instructions are fit for purpose and practiced with clear governance and regular updates to monitor continued improvement",
        InPlaceAddingValueAndContinuouslyReviewed:
          "Customer journeys are clearly defined with pain points, and are regularly used to inform decisions on improving customer experience. Journeys are sometimes reviewed",
      },
    ];

    return questionList;
  }

  getAVAQuestions() {
    let questionList = [
      {
        category: "Core Activity",
        activity: "Compliance",
        type: "Core",
      },
      {
        category: "Core Activity",
        activity: "First Aid",
        type: "Core",
      },
      {
        category: "Core Activity",
        activity: "Following Standard Processes",
        type: "Core",
      },
      {
        category: "Core Activity",
        activity: "Core work",
        type: "Core",
      },
      {
        category: "Non Core Activity",
        activity:
          "Escalations (e.g. Any problems that require escalating for support)",
        type: "Non Value Add",
      },
      {
        category: "Non Core Activity",
        activity: "Archiving",
        type: "Non Value Add",
      },
      {
        category: "Non Core Activity",
        activity: "Meetings",
        type: "Value Add",
      },
      {
        category: "Non Core Activity",
        activity: "Reports",
        type: "Value Add",
      },
      {
        category: "Non Core Activity",
        activity: "Team Manager Support - Cover",
        type: "Value Add",
      },
      {
        category: "Non Core Activity",
        activity: "Team Manager Support - Other",
        type: "Value Add",
      },
    ];

    return questionList;
  }

  getEVAQuestions() {
    let questionList = [
      {
        category: "People & Capability",
        subCategory: "Capability",
        lens: "Training & development plans",
        questionId: "ES1",
        question:
          "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
      },
      {
        category: "People & Capability",
        subCategory: "Capability",
        lens: "Training & development plans",
        questionId: "ES2",
        question:
          "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
      },
      {
        category: "People & Capability",
        subCategory: "Capability",
        lens: "Training & development plans",
        questionId: "ES1",
        question:
          "As a People Manager I receive formal training and coaching that equips me to manage and guide my team.",
      },
      {
        category: "Customer & Purpose",
        subCategory: "Value",
        lens: "Understanding customer value",
        questionId: "ES2",
        question:
          "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
      },
      {
        category: "Customer & Purpose",
        subCategory: "Value",
        lens: "Understanding customer value",
        questionId: "ES2",
        question:
          "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
      },
      {
        category: "Customer & Purpose",
        subCategory: "Value",
        lens: "Understanding customer value",
        questionId: "ES2",
        question:
          "I am aware of the different types of internal & external customers we serve, and how their needs differ.",
      },
      {
        category: "Intelligent Automation and Process",
        subCategory: "Process Flow",
        lens: "Visibility of processes and ownership",
        questionId: "ES7",
        question:
          "Handovers between our team and other teams are smooth and there is minimal rework required.",
      },
      {
        category: "Intelligent Automation and Process",
        subCategory: "Process Flow",
        lens: "Visibility of processes and ownership",
        questionId: "ES7",
        question:
          "Handovers between our team and other teams are smooth and there is minimal rework required.",
      },
      {
        category: "Performance",
        subCategory: "Vision & KPIs",
        lens: "KPIs",
        questionId: "ES21",
        question:
          "Team performance and feedback is visible to me and looked at regularly.",
      },
      {
        category: "Performance",
        subCategory: "Vision & KPIs",
        lens: "KPIs",
        questionId: "ES21",
        question:
          "Team performance and feedback is visible to me and looked at regularly.",
      },
    ];

    return questionList;
  }

  getOpMaturyMapping(clientId: string) {
    return this.httpClient.get<any>(
      `${this.staticURL}config/opMaturity?clientId=${clientId}`
    );
  }

  createOpMaturyMapping(clientId: string, data: any) {
    return this.httpClient.post<any>(
      `${this.staticURL}config/opMaturity/${clientId}` , data
    );
  }

  updateOpMaturyMapping(clientId: string, data: any) {
    return this.httpClient.put<any>(
      `${this.staticURL}config/opMaturity/${clientId}`, data
    );
  }

  deleteOpMaturyMapping(clientId: string) {
    return this.httpClient.delete<any>(
      `${this.staticURL}config/opMaturity/${clientId}`
    );
  }



  getTemplateByModule(module: string) {
    return this.httpClient.get<any>(
      `${this.staticURL}template?module=${module}&size=100&pageNumber=0`
    );
  }

  getTemplateByQuery(templateName: string = "", module: string = "") {
    let payload;
    if (templateName) {
      payload = `templateName=${templateName}`;
    }

    if (module) {
      payload = `module=${module}`;
    }

    if (templateName && module) {
      payload = `templateName=${templateName}&module=${module}`;
    }

    return this.httpClient.get<any>(
      `${this.staticURL}template/query?${payload}`
    );
  }

  getModuleData(type: string = "", name: string = "") {
    let payload;

    if (type) {
      payload = `type=${type}`;
    }

    if (name) {
      payload = `name=${name}`;
    }

    if (type && name) {
      payload = `type=${type}&name=${name}`;
    }

    return this.httpClient.get<any>(`${this.staticURL}category?${payload}`);
  }

  getCategoriesByType(type: string) {
    return this.httpClient.get<any>(
      `${this.staticURL}category?type=${type}&size=1000&pageNumber=0`
    );
  }

  // RefData
  getRefData(perPage: any, page: any, module: string, state: string) {
    // console.log("state,state", state);
    // &module=${module}
    return this.httpClient.get<any>(
      `${this.staticURL}refData?size=${perPage}&pageNumber=${page}&module=${module}&state=${state}`
    );
  }

  getRefDatabyModule(module: string) {
    return this.httpClient.get<any>(
      `${this.staticURL}refData/search?module=${module}`
    );
  }

  getRefDataByQuery(
    module: string = "",
    state: string = "",
    text: string = ""
  ) {
    let query: string = "";
    if (module) {
      query = query.length ? `${query}&module=${module}` : `module=${module}`;
    }

    if (state) {
      query = query.length ? `${query}&state=${state}` : `state=${state}`;
    }

    if (text) {
      query = query.length ? `${query}&text=${text}` : `text=${text}`;
    }

    let url: string = query.length
      ? `${this.staticURL}refData/query?${query}`
      : `${this.staticURL}refData/query`;
    return this.httpClient.get<any>(url);
  }

  createRefData(payload: any) {
    return this.httpClient.post<any>(`${this.staticURL}refData`, payload);
  }

  editRefData(questionId: any, payload: any) {
    return this.httpClient.put<any>(
      `${this.staticURL}refData/${questionId}`,
      payload
    );
  }

  //ref dropdown data's
  getDropDown(type: string, module: string, parent: string = "") {

    let url: string = `${this.staticURL}category?type=${type}&module=${module}&size=1000&pageNumber=0`;
    if (parent) {
      url = `${this.staticURL}category?type=${type}&module=${module}&parent=${parent}&size=1000&pageNumber=0`;
    }
    return this.httpClient.get<any>(url);
  }

  getSearchRefData(
    perPage: any,
    page: any,
    module: string,
    searchText: string
  ) {
    // console.log("state,state", searchText);
    // &module=${module}
    return this.httpClient.get<any>(
      `${this.staticURL}refData?size=${perPage}&pageNumber=${page}&text=${searchText}&module=${module}`
    );

    // return this.httpClient.get<any>(`${this.staticURL}refData/search?size=${perPage}&pageNumber=${page}&text=${searchText}&module=${module}`)
  }

  getDistinctLens() {
    return this.httpClient.get<any>(`${this.staticURL}refData/distinctLens`);
  }

  updateTemplate(templateId: string, payload: any) {
    return this.httpClient.put<any>(
      `${this.staticURL}template/${templateId}`,
      payload
    );
  }

  getQuestionHistory(questionId: string = "") {
    let url: string = `${this.staticURL}refData/audit`;
    if (questionId) {
      url = `${url}?id=${questionId}`;
    }
    return this.httpClient.get<any>(url);
  }
}
