import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  inject,
  OnInit,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { MasterDataService } from "../master-data.service";
import { OnboardingService } from "@app/onboarding/onboarding.service";
import {
  TAGS,
  TAGS_ObJ,
  QUESTION_STATES_ObJ,
} from "@app/resources/question-state";
import { WorkflowTemplateService } from "@app/workflow-template/workflow-template.service";
import { DatabaseService } from "@app/database/database.service";
import { Helper } from "@app/core/classes/helper";
import { CustomService } from "@app/core/services/custom.service";

@Component({
  selector: "app-question-add-dialog",
  templateUrl: "./question-add-dialog.component.html",
  styleUrls: ["./question-add-dialog.component.scss"],
})
export class QuestionAddDialogComponent implements OnInit {
  selectedValue: string;
  moduleList: any = [];
  categoryList: any = [];
  selected = "";
  tagList: any[] = TAGS;
  isSurvey: boolean = false;
  isTemplate: boolean = false;
  templateDetail: any;
  receivedData: any;
  questionTypes: any;
  questionCategories: any;

  avaRefQuestionform: any = new FormGroup({
    question: new FormControl(null, Validators.required),
    questionType: new FormControl(null, Validators.required),
    type: new FormControl(null, Validators.required),
    category: new FormControl(null, Validators.required),
  });
  evaRefQuestionform: any = new FormGroup({
    question: new FormControl(null, Validators.required),
    category: new FormControl(null, Validators.required),
    lens: new FormControl(null, Validators.required),
    subCategory: new FormControl(null, Validators.required),
    questionType: new FormControl(null, Validators.required),
  });

  miRefQuestionform: any = new FormGroup({
    question: new FormControl(null, Validators.required),
    category: new FormControl(null, Validators.required),
    lens: new FormControl(null, Validators.required),
    subCategory: new FormControl(null, Validators.required),
    questionType: new FormControl(null, Validators.required),
  });

  fgRefQuestionform: any = new FormGroup({
    question: new FormControl(null, Validators.required),
    category: new FormControl(null, Validators.required),
    lens: new FormControl(null, Validators.required),
    subCategory: new FormControl(null, Validators.required),
    questionType: new FormControl(null, Validators.required),
  });

  poiRefQuestionForm: any = new FormGroup({
    question: new FormControl(null, Validators.required),
    category: new FormControl(null, Validators.required),
    lens: new FormControl(null, Validators.required),
    subCategory: new FormControl(null, Validators.required),
    questionType: new FormControl(null, Validators.required),
  });

  question_To_Edit: any;
  type: any;
  module: any;
  state: string;
  evaSelectedCategory: any;
  miSelectedCategory: any;
  fgSelectedCategory: any;
  poiSelectedCategory: any;
  questionLens: any;
  editQuestion: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private cdr: ChangeDetectorRef,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private masterDataService: MasterDataService,
    private onboardingService: OnboardingService,
    public dialogRef: MatDialogRef<QuestionAddDialogComponent>,
    private activeRoute: ActivatedRoute,
    private workflowTemplateService: WorkflowTemplateService,
    private databaseService: DatabaseService,
    private customService: CustomService
  ) {}

  ngOnInit(): void {
    // this.getModuleData();
    // this.getCategoryData();

    this.type = this.data.from;
    this.state = this.data.state;
    this.findModule();
    this.receivedData = this.data;
    // console.log("question add templateId :", this.data.templateId, this.data);

    if (this.type == "AVA-Ref-Create") {
      this.questionTypes = this.data.type;
      this.questionCategories = this.data.category;
    }
    if (this.type == "AVA-Ref-Edit") {
      this.question_To_Edit = this.data.question;
      this.questionTypes = this.data.type;
      this.questionCategories = this.data.category;
      this.editQuestion = true;
      this.editAVARef();
    }
    if (this.type == "EVA-Ref-Create") {
      this.questionCategories = this.data.category;
    }
    if (this.type == "EVA-Ref-Edit") {
      this.questionCategories = this.data.category;
      this.questionLens = this.data.lens;
      this.question_To_Edit = this.data.question;
      this.editQuestion = true;
      this.patchValue_EVA(this.question_To_Edit);
    }
    if (this.type == "MI-Ref-Create") {
      // console.log(this.data);

      this.questionTypes = this.data.type;
      this.questionCategories = this.data.category;
    }
    if (this.type == "MI-Ref-Edit") {
      this.questionCategories = this.data.category;
      this.questionLens = this.data.lens;
      this.question_To_Edit = this.data.question;
      this.editQuestion = true;
      this.patchValue_MI(this.question_To_Edit);
    }

    if (this.type == "FG-Ref-Create") {
      this.questionCategories = this.data.category;
    }
    if (this.type == "FG-Ref-Edit") {
      this.questionCategories = this.data.category;
      this.questionLens = this.data.lens;
      this.question_To_Edit = this.data.question;
      this.editQuestion = true;
      this.patchValue_FG(this.question_To_Edit);
    }

    if (this.type == "POI-Ref-Create") {
      this.questionCategories = this.data.category;
    }
    if (this.type == "POI-Ref-Edit") {
      this.questionCategories = this.data.category;
      this.questionLens = this.data.lens;
      this.question_To_Edit = this.data.question;
      this.editQuestion = true;
      this.patchValue_POI(this.question_To_Edit);
    }

    if (this.type === "survey") {
      this.isSurvey = true;
      this.getSurveyById(this.data.templateId);
    } else {
      this.isSurvey = false;
    }

    if (this.type === "template") {
      this.isTemplate = true;
      this.getTemplateData(this.data.templateId);
    } else {
      this.isTemplate = false;
    }
  }

  findModule() {
    this.type == "AVA-Ref-Create" || this.type == "AVA-Ref-Edit"
      ? (this.module = "AVA")
      : this.type == "EVA-Ref-Create" || this.type == "EVA-Ref-Edit"
      ? (this.module = "EVA")
      : this.type == "MI-Ref-Create" || this.type == "MI-Ref-Edit"
      ? (this.module = "managerInterviews")
      : this.type == "FG-Ref-Create" || this.type == "FG-Ref-Edit"
      ? (this.module = "focusGroup")
      : this.type == "POI-Ref-Create" || this.type == "POI-Ref-Edit"
      ? (this.module = "processOwners")
      : "";
    // console.log("module", this.module);
  }

  //     question: new FormControl(null, Validators.required),
  // category: new FormControl(null, Validators.required),
  // lens: new FormControl(null, Validators.required),
  // subCategory: new FormControl(null, Validators.required),
  // questionType: new FormControl(null, Validators.required)
  patchValue_EVA(question: any) {
    // console.log("eva pacth data", question);
    // console.log("asdad", this.data);
    let selectedCatIndex: number = 0;
    let selectedSubCatIndex: number = 0;
    this.data.category.forEach((x: any, i: any) => {
      if (x.category == question.category) {
        selectedCatIndex = i;
        return (this.evaSelectedCategory = x);
      }
    });
    //  this.evaSelectedCategory.forEach((subCat:any, i :any)=>{
    //   if(subCat == question.subCategory ){
    //     selectedSubCatIndex = i
    //   }
    //  })
    // console.log("category", question.category, question.subCategory);
    // console.log("evaSelectedCategory", this.evaSelectedCategory);
    this.evaRefQuestionform.patchValue({
      question: question.question,
      category: this.data.category[selectedCatIndex],
      subCategory: question.subCategory,
      lens: question.lens,
      questionType: question.questionType,
    });
  }

  patchValue_MI(question: any) {
    // console.log("eva pacth data", question);
    // console.log("asdad", this.data);
    let selectedCatIndex: number = 0;
    let selectedSubCatIndex: number = 0;
    this.data.category.forEach((x: any, i: any) => {
      if (x.category == question.category) {
        selectedCatIndex = i;
        return (this.miSelectedCategory = x);
      }
    });

    // console.log("category", question.category, question.subCategory);
    // console.log("miSelectedCategory", this.miSelectedCategory);
    this.miRefQuestionform.patchValue({
      question: question.question,
      category: this.data.category[selectedCatIndex],
      subCategory: question.subCategory,
      lens: question.lens,
      questionType: question.questionType,
    });
  }

  patchValue_FG(question: any) {
    // console.log("fg pacth data", question);
    // console.log("FG", this.data);
    let selectedCatIndex: number = 0;
    let selectedSubCatIndex: number = 0;
    this.data.category.forEach((x: any, i: any) => {
      if (x.category == question.category) {
        selectedCatIndex = i;
        return (this.fgSelectedCategory = x);
      }
    });

    // console.log("category", question.category, question.subCategory);
    // console.log("fgSelectedCategory", this.fgSelectedCategory);
    this.fgRefQuestionform.patchValue({
      question: question.question,
      category: this.data.category[selectedCatIndex],
      subCategory: question.subCategory,
      lens: question.lens,
      questionType: question.questionType,
    });
  }


  patchValue_POI(question: any){

    // console.log("poi pacth data", question);
    // console.log("POI", this.data);
    let selectedCatIndex: number = 0;
    let selectedSubCatIndex: number = 0;
    this.data.category.forEach((x: any, i: any) => {
      if (x.category == question.category) {
        selectedCatIndex = i;
        return (this.poiSelectedCategory = x);
      }
    });

    // console.log("category", question.category, question.subCategory);
    // console.log("poiSelectedCategory", this.poiSelectedCategory);
    this.poiRefQuestionForm.patchValue({
      question: question.question,
      category: this.data.category[selectedCatIndex],
      subCategory: question.subCategory,
      lens: question.lens,
      questionType: question.questionType
    });
  }

  onSaveDraft2(): any {
    let payload = this.avaRefQuestionform.value;

    payload = { ...payload, questionState: QUESTION_STATES_ObJ["DRAFT"] };
    payload["min"] = payload["min"] || 0;
    payload["max"] = payload["max"] || 0;
    delete payload.note;
    delete payload.tag;

    this.onCreateMasterData(payload);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  editAVARef() {
    // console.log("received data", this.receivedData, this.data);
    // console.log("question_To_Edit", this.question_To_Edit);

    this.avaRefQuestionform.patchValue({
      question: this.question_To_Edit.question,
      questionType: this.question_To_Edit.questionType,
      type: this.question_To_Edit.type,
      category: this.question_To_Edit.category,
    });
    this.cdr.markForCheck();
  }

  submit() {
    // console.log("module", this.module);
    if (this.module == "AVA") {
      this.avaRefQuestionform.markAllAsTouched();
      const { emptyFields } = Helper.getEmptyFields(this.avaRefQuestionform);

      if (this.avaRefQuestionform.valid && emptyFields.length == 0) {
        if (this.state == "APPROVED") {
          if (this.type == "AVA-Ref-Create") {
            let payload: any = { ...this.avaRefQuestionform.value };
            // console.log("ava form payload for create", payload);
            this.create_Question(payload);
          } else {
            let payload: any = { ...this.avaRefQuestionform.value };
            // console.log("ava form payload edit", payload);
            this.edit_Question(payload);
          }
        } else {
          if (this.type == "AVA-Ref-Create") {
            let payload: any = { ...this.avaRefQuestionform.value };
            delete payload.questionState;
            payload.oldState = QUESTION_STATES_ObJ["APPROVED"];
            payload.newState = QUESTION_STATES_ObJ["SUBMITTED"];
            // console.log("ava form payload", payload);
            // this.create_Question(payload)
            this.edit_Question(payload);
          } else {
            let payload: any = { ...this.avaRefQuestionform.value };
            delete payload.questionState;
            payload.oldState = QUESTION_STATES_ObJ["APPROVED"];
            payload.newState = QUESTION_STATES_ObJ["DRAFT"];
            // console.log("ava form payload", payload);
            this.cdr.markForCheck();
            this.edit_Question(payload);
          }
        }
      } else {
        this.avaRefQuestionform.markAllAsTouched();
      }
    }

    if (this.module == "EVA") {
      const { emptyFields } = Helper.getEmptyFields(this.evaRefQuestionform);

      this.evaRefQuestionform.markAllAsTouched();
      if (this.evaRefQuestionform.valid && emptyFields.length == 0) {
        let payload: any = { ...this.evaRefQuestionform.value };
        payload.category = payload.category.category;
        payload.subCategory = payload.subCategory;
        if (this.type == "EVA-Ref-Create") {
          // console.log("eva form payload create", payload);
          this.create_Question(payload);
        } else if (this.type == "EVA-Ref-Edit") {
          // console.log("eva form payload edit", payload);
          this.edit_Question(payload);
        }
      } else {
        this.evaRefQuestionform.markAllAsTouched();
      }
    }

    if (this.module == "managerInterviews") {
      this.miRefQuestionform.markAllAsTouched();
      // console.log("state", this.state);
      // console.log("type", this.type);
      const { emptyFields } = Helper.getEmptyFields(this.miRefQuestionform);
      this.miRefQuestionform.markAllAsTouched();
      if (this.miRefQuestionform.valid && emptyFields.length == 0) {
        let payload: any = { ...this.miRefQuestionform.value };
        payload.category = payload.category.category;
        // payload.module = "managerInterviews"
        // payload.questionState = "WAITING_APPROVAL"
        this.cdr.markForCheck();
        // console.log("PL ->>", payload);
        if (this.type == "MI-Ref-Create") {
          // console.log("MI form payload create", payload);
          this.create_Question(payload);
        } else if (this.type == "MI-Ref-Edit") {
          // console.log("MI form payload edit", payload);
          this.edit_Question(payload);
        }
      } else {
        this.miRefQuestionform.markAllAsTouched();
      }
    }

    if (this.module == "focusGroup") {
      const { emptyFields } = Helper.getEmptyFields(this.fgRefQuestionform);

      this.fgRefQuestionform.markAllAsTouched();
      if (this.fgRefQuestionform.valid && emptyFields.length == 0) {
        let payload: any = { ...this.fgRefQuestionform.value };
        payload.category = payload.category.category;
        payload.subCategory = payload.subCategory;
        if (this.type == "FG-Ref-Create") {
          // console.log("fg form payload create", payload);
          this.create_Question(payload);
        } else if (this.type == "FG-Ref-Edit") {
          // console.log("fg form payload edit", payload);
          this.edit_Question(payload);
        }
      } else {
        this.fgRefQuestionform.markAllAsTouched();
      }
    }

    if (this.module == "processOwners") {
      const { emptyFields } = Helper.getEmptyFields(this.poiRefQuestionForm);

      this.poiRefQuestionForm.markAllAsTouched();
      this.poiRefQuestionForm.controls.questionType.setValue("RATING");
      this.selected = "RATING";
      
      if (this.poiRefQuestionForm.valid && emptyFields.length == 0) {
        let payload: any = { ...this.poiRefQuestionForm.value };
        payload.category = payload.category.category;
        payload.subCategory = payload.subCategory;
        if (this.type == "POI-Ref-Create") {
          // console.log("poi form payload create", payload);
          this.create_Question(payload);
        } else if (this.type == "POI-Ref-Edit") {
          // console.log("poi form payload edit", payload);
          this.edit_Question(payload);
        }
      } else {
        this.poiRefQuestionForm.markAllAsTouched();
      }
    }
  }

  onSaveDraft() {
    this.avaRefQuestionform.markAllAsTouched();
    let payload: any = {};
    if (
      this.avaRefQuestionform.valid ||
      this.evaRefQuestionform.valid ||
      this.miRefQuestionform.valid ||
      this.fgRefQuestionform.valid ||
      this.poiRefQuestionForm.valid
    ) {
      if (this.module == "AVA") {
        payload = { ...this.avaRefQuestionform.value };
      } else if (this.module == "EVA") {
        payload = { ...this.evaRefQuestionform.value };
        payload.category = payload.category.category;
        payload.subCategory = payload.subCategory;
      } else if (this.module == "managerInterviews") {
        payload = { ...this.miRefQuestionform.value };
        payload.category = payload.category.category;
        payload.subCategory = payload.subCategory;
      } else if (this.module == "focusGroup") {
        payload = { ...this.fgRefQuestionform.value };
        payload.category = payload.category.category;
        payload.subCategory = payload.subCategory;
      } else if (this.module == "processOwners") {
        payload = { ...this.poiRefQuestionForm.value };
        payload.category = payload.category.category;
        payload.subCategory = payload.subCategory;
      }
      payload.module = this.module;

      if (
        (this.selected && this.selected == "RATING") ||
        payload.questionType == "RATING"
      ) {
        payload.min = 1;
        payload.max = 5;
      } else {
        payload.min = 0;
        payload.max = 0;
      }

      if (
        this.type == "AVA-Ref-Create" ||
        this.type == "EVA-Ref-Create" ||
        this.type == "MI-Ref-Create" ||
        this.type == "FG-Ref-Create"
      ) {
        payload.questionState = QUESTION_STATES_ObJ["DRAFT"];
        this.createDraft(payload);
        return;
      }

      payload.oldState = this.receivedData["state"];
      payload.newState = QUESTION_STATES_ObJ["DRAFT"];

      // console.log("form draft payload", payload);
      this.editDraft(payload);
      return;
    }
  }

  createDraft(payload: any) {
    this.customService
      .checkQuestion(this.module, [payload.question])
      .subscribe((response): any => {
        if (response) {
          this.snackBar.open("Question Already Exist", "close");
          return false;
        }

        this.databaseService.createRefData(payload).subscribe({
          next: (res: any) => {
            this.snackBar.open("Question Sent Successfully For Draft", "close");
            this.dialogRef.close({ event: "avaDraft" });
          },
          error: (error: any) => {
            // console.log(error);
            this.dialogRef.close({ event: "error" });
          },
        });
      });
  }

  editDraft(payload: any) {
    this.databaseService
      .editRefData(this.question_To_Edit._id, payload)
      .subscribe({
        next: (res: any) => {
          this.snackBar.open("Question Sent Successfully For Draft", "close");
          this.dialogRef.close({ event: "avaDraft" });
        },
        error: (error: any) => {
          // console.log(error);

          if (error.error.message) {
            this.snackBar.open(error.error.message, "close", {
              duration: 3500,
              horizontalPosition: "end",
              verticalPosition: "top",
            });
          } else {
            // console.log(`Update question Error: ${error.error.message}`);
          }
          //  this.dialogRef.close({event:'error'})
        },
      });
  }

  create_Question(payload: any) {
    if (this.selected == "RATING") {
      (payload.min = 1), (payload.max = 5);
    } else {
      (payload.min = 0), (payload.max = 0);
    }
    payload.module = this.module;
    payload.questionState = QUESTION_STATES_ObJ["SUBMITTED"];

    // console.log("database create payload", payload);

    this.customService
      .checkQuestion(this.module, [payload.question])
      .subscribe((response): any => {
        if (response) {
          this.snackBar.open("Question Already Exist", "close");
          return false;
        }

        this.databaseService.createRefData(payload).subscribe({
          next: (res: any) => {
            this.snackBar.open(
              "Question Sent Successfully For Approval",
              "close"
            );
            this.dialogRef.close({ event: "avaQusCreate" });
          },
          error: (error: any) => {
            this.dialogRef.close({ event: "error" });
            // console.log(error);
          },
        });
      });
  }

  edit_Question(payload: any) {
    // console.log("edit qus called", this.selected);
    if (
      (this.selected && this.selected == "RATING") ||
      payload.questionType == "RATING"
    ) {
      payload.min = 1;
      payload.max = 5;
    } else {
      payload.min = 0;
      payload.max = 0;
    }
    payload.module = this.module;
    payload.oldState = this.receivedData.state;
    payload.newState = QUESTION_STATES_ObJ["SUBMITTED"];

    // console.log("==== payload ====", payload);

    this.databaseService
      .editRefData(this.question_To_Edit?._id, payload)
      .subscribe({
        next: (res: any) => {
          this.snackBar.open(
            "Question Edited and  Sent Successfully For Approval",
            "close"
          );
          this.dialogRef.close({ event: "avaQusEdit" });
        },
        error: (error: any) => {
          // console.log(error);
          if (error.error.message) {
            this.snackBar.open(error.error.message, "close", {
              duration: 3500,
              horizontalPosition: "end",
              verticalPosition: "top",
            });
          } else {
            // console.log(`Update question Error: ${error.error.message}`);
          }
          // this.dialogRef.close({event:'error'})
        },
      });
  }

  evaCategoryChange(event: any) {
    // console.log("evaSelectedCategory", event.value);

    this.evaSelectedCategory = event.value;
    this.questionLens = [];
  }

  evaSubCategoryChange(event: any){
    this.questionLens = this.evaSelectedCategory.lens.find((lensDetail: any) => lensDetail.subCategory == event).lens;
  }

  miCategoryChange(event: any) {
    // console.log("miSelectedCategory", event);

    this.miSelectedCategory = event.value;
    this.questionLens = [];
  }

  miSubCategoryChange(event: any) {
    this.questionLens = this.miSelectedCategory.lens.find((lensDetail: any) => lensDetail.subCategory == event).lens;
  }

  fgCategoryChange(event: any) {
    // console.log("fgSelectedCategory", event.value);

    this.fgSelectedCategory = event.value;
  }

  fgSubCategoryChange(event: any) {
    this.questionLens = this.fgSelectedCategory.lens.find((lensDetail: any) => lensDetail.subCategory == event).lens;
  }

  poiCategoryChange(event: any) {
    // console.log("poiSelectedCategory", event.value);

    this.poiSelectedCategory = event.value;
    this.questionLens = [];
  }

  poiSubCategoryChange(event: any){
    this.questionLens = this.poiSelectedCategory.lens.find((lensDetail: any) => lensDetail.subCategory == event).lens;
  }

  onCreateMasterData(payload: Object = {}) {
    this.masterDataService.createMasterData(payload).subscribe(
      (response: any) => {
        this.snackBar.open("Submitted Successfully", "close", {
          duration: 4500,
          horizontalPosition: "center",
          verticalPosition: "top",
        });
        this.dialogRef.close();

        if (
          this.avaRefQuestionform.value.tag &&
          (this.isTemplate || this.isSurvey)
        ) {
          let tagData = [];
          const isTagPush = this.templateDetail.tags.includes(
            this.avaRefQuestionform.value.tag
          );

          if (isTagPush) {
            tagData = [...this.templateDetail.tags];
          } else {
            tagData = [
              ...this.templateDetail.tags,
              this.avaRefQuestionform.value.tag,
            ];
          }

          const questionData = {
            tag: this.avaRefQuestionform.value.tag,
            refId: response._id,
          };
          let question = this.templateDetail.questions;
          question.push(questionData);

          const payload = {
            templateName: this.templateDetail.templateName,
            moduleId: this.templateDetail.moduleId,
            tags: tagData,
            questions: question,
          };

          this.workflowTemplateService
            .updateTemplate(this.templateDetail._id, payload)
            .subscribe((res) => {});
        }
      },
      (error: any) => {
        // console.log("Create Master Data Error:", error);
      }
    );
  }

  getModuleData() {
    this.onboardingService.getModuleData("MODULE").subscribe(
      (response) => {
        this.moduleList = response;
      },
      (error) => {
        // console.log("Error on get module list: ", error);
      }
    );
  }

  getCategoryData() {
    this.onboardingService.getModuleData("CATEGORY").subscribe(
      (response) => {
        this.categoryList = response;
      },
      (error) => {
        // console.log("Error on get Category list: ", error);
      }
    );
  }

  Selection() {
    // console.log(this.selected);

    if (this.selected === "RATING") {
      // console.log(this.selected);
    }
  }

  getTemplateData(templateId: any) {
    this.workflowTemplateService.getTemplateByQuery("id", templateId).subscribe(
      (response) => {
        this.templateDetail = response.data[0];
      },
      (error) => {
        // console.log("ERROR template response:", error);
      }
    );
  }

  getSurveyById(surveyId: any) {
    this.workflowTemplateService.getSurveyById(surveyId).subscribe((res) => {
      this.getTemplateData(res.data[0].templateId);
    });
  }
}
