import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { ActivatedRoute, Router } from "@angular/router";
import { HomeService } from "@app/home/home/home.service";
import { OnboardingService } from "@app/onboarding/onboarding.service";
import * as apex from "ng-apexcharts";
import { WorkflowTemplateService } from "@app/workflow-template/workflow-template.service";
import { MatDialog } from "@angular/material/dialog";
import { TemplateAddComponent } from "@app/workflow-template/template-add/template-add.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Observable } from "rxjs";
import { data } from "./avaData";
import { SurveyService } from "@app/survey/survey.service";
import { SURVEY_DETAIL_DATA } from "@app/resources/question-state";

import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
} from "ng-apexcharts";
import * as ApexCharts from "apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
};

export type ChartOptionsBasic = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
};

@Component({
  selector: "app-ava-eva",
  templateUrl: "./ava-eva.component.html",
  styleUrls: ["./ava-eva.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AVAEVAComponent implements OnInit {
  //Radar

  series: apex.ApexAxisChartSeries;
  chart: apex.ApexChart;
  xaxis: apex.ApexXAxis;
  title: apex.ApexTitleSubtitle;
  public chartLabels: string[] = [];

  //Chart

  series_bar: apex.ApexAxisChartSeries;
  chart_bar: apex.ApexChart;
  xaxis_bar: apex.ApexXAxis;
  title_bar: apex.ApexTitleSubtitle;

  //Doughnut

  series_dn: apex.ApexNonAxisChartSeries;
  chart_dn: apex.ApexChart;
  responsive_dn: apex.ApexResponsive[];
  labels: any;

  selected: any = 0;
  selected1: any = 0;
  moduleList: any = [];
  search = new FormControl("");
  searchText: string = "";
  searchModule = new FormControl("");
  categoryList: any = [];
  searchCategory = new FormControl("");
  AvaTemplateList: any = [];
  AvaDefaultTemplate: any = [];
  dialog: any;
  displayedColumns: string[] = ["activity", "type", "timeCategory", "action"];
  dataSource: any = [];
  selectedTemplate: any = {};
  isDefaultTemplate: boolean = true;

  dataSource2 = [];
  overview:any = [
    {  activity:'Phone Calls',category:'Non Value Add',time:'5%',spent:'10 mins a day',capacity:'-',$$:'-' },
    {  activity:'Activity',category:'Non nValue Add',time:'3%',spent:'10 mins a day',capacity:'-',$$:'-' },
  ]
  response = [
    { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
    { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
    { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
    { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
    { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
  ];
  displayedColumns2: any[] = [
    "division",
    "function",
    "team",
    "role",
    "responses",
  ];
  dc: string[] = [
    "activity",
    "category",
    "time",
    "timespent",
    "capacity",
    "$$",
  ];

  evaTemplateList: any = [];
  evaDefaultTemplate: any = [];

  clientId: string;

  clientData: any;
  organisationData: any;
  startDate: any;
  endDate: any;
  surveyData: any;
  surveyDetail: any;
  selectedOption = "Bar Chart";

  type: any[] = [
    { value: "1", viewValue: "Bar Chart" },
    { value: "2", viewValue: "Radar Chart" },
    { value: "3", viewValue: "Pie Chart" },
  ];

  filters: any[] = [
    { Value: "Activity" },
    { Value: "Type" },
    { Value: "TimeCategory" },
  ];

  form: FormGroup = new FormGroup({});
  filterCData: any = data;
  @ViewChild("chart2")
  chart2!: ChartComponent;
  public newChartOptions: ChartOptions | any;

  public basicChartOptions: ChartOptionsBasic | any;
  @ViewChild("chartbasic") chartbasic!: ChartComponent;

  @ViewChild("barLongChart")
  barLongChart!: ChartComponent;
  public barLongChartOptions: ChartOptions | any;

  xFilter: string = "functionName";
  yFilter: string = "TimeCategory";
  zFilter: string = "all";

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private fb: FormBuilder,
    private homeService: HomeService,
    private onboardingService: OnboardingService,
    private workflowTemplateService: WorkflowTemplateService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private surveyService: SurveyService
  ) {
    this.form = fb.group({
      chart: [this.selectedOption, [Validators.required]],
      filter: ["Activity"],
    });
    this.barLongChartOptions = this.getActivityBar();
    this.newChartOptions = {
      // series: [
      //   {
      //     name: "Value Add",
      //     data: [31.875, 23.125, 15.625],
      //   },
      //   {
      //     name: "Value Add",
      //     data: [38.25, 27.75, 18.75],
      //   },
      //   {
      //     name: "Value Add",
      //     data: [38.25, 27.75, 18.75],
      //   },
      // ],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        events: {
          dataPointSelection: (event: any, chartContext: any, config: any) => {
            this.cdr.markForCheck();
            const seriesIndex = config.seriesIndex;
            const dataPointIndex = config.dataPointIndex;
            const value =
              chartContext?.w.globals.series[seriesIndex][dataPointIndex];
            const legendName = chartContext?.w.config.series[seriesIndex].name;
            // console.log("Clicked data:", value, legendName);
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: ["Risk", "Center", "Back"],
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "right",
        offsetX: 0,
        offsetY: 50,
      },
    };

    this.basicChartOptions = {
      series: [
        {
          name: "basic",
          data: [400, 430, 448, 470, 540, 580, 690, 1100, 1200, 1380],
        },
      ],
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: [
          "Compliance",
          "First Aid",
          "Following Standard Processes",
          "Core work",
          "Escalations (e.g. Any problems that require escalating for support)",
          "Archiving",
          "Team Manager Support - Cover",
          "Team Manager Support - Other",
        ],
      },
    };
  }

  ngOnInit(): void {
    this.clientId = this.route.snapshot.paramMap.get("id") || "";
    this.initializeChartOptions();
    this.initiateBarChart();

    this.getClientById();
    this.getOrganisationByClientId();

    this.getSurvey();
    this.getSurveyDetail();
  }

  onChartClick(event: any) {
    // console.log(
    //   event,
    //   event.dataPointIndex,
    //   this.newChartOptions?.w.config.series
    // );

    const clickedBarIndex = event.dataPointIndex;
    const clickedBarName =
      this.newChartOptions?.w.config.series[clickedBarIndex];

    // console.log("Clicked bar name:", clickedBarName);
  }
  // Timeline

  

  getClientById() {
    this.homeService.getClients(this.clientId).subscribe((res) => {
      this.clientData = res[0];
      this.startDate = this.clientData.startDate
        .replace(/T.*/, "")
        .split("-")
        .reverse()
        .join("-");
      this.endDate = this.clientData.endDate
        .replace(/T.*/, "")
        .split("-")
        .reverse()
        .join("-");
    });
  }

  getOrganisationByClientId() {
    this.onboardingService
      .getOrganisationByClientId(this.clientId)
      .subscribe((res) => {
        this.organisationData = res[0];
      });
    this.getAVATemplates();
    this.getEVATemplates();
  }


  getSurvey(){
    // console.log('----- this.clientId -----------------------------', this.clientId);
    let surveyQuery: object = {clientId: this.clientId, module: "ava-eva"};
    this.surveyService.getSurveyByQuery(surveyQuery).subscribe((surveyResponse)=>{
      this.surveyData = surveyResponse['data'][0];
    }, (error) => {
      // console.log('Error while getting survey data: ', error);
    });
  }

  getSurveyDetail() {
    /* this.surveyService.getSurveyDetailByQuery('clientId', this.surveyId).subscribe((surveyDetailResponse)=>{
      console.log('--- surveyDetailResponse ---', surveyDetailResponse);
      
      this.surveyDetail = surveyDetailResponse['data'][0];
    }, (error) => {
      console.log('Error while getting survey data: ', error);
    }); */

    /* TODO: need to remove this once the above API received. */
    this.surveyDetail = SURVEY_DETAIL_DATA;
  }

  tabChanged = (tabChangeEvent: MatTabChangeEvent): void => {
    // console.log("index => ", tabChangeEvent.index);
    if (tabChangeEvent.index === 1) {
      this.initializeDoughnutChartOptions();
      this.initiateBarChart();
    } else if (tabChangeEvent.index === 2) {
      this.initializeChartOptions();
      this.initiateBarChart();
    }
  };

  tabChanged1 = (tabChangeEvent: MatTabChangeEvent): void => {
    // console.log("index => ", tabChangeEvent.index);
    if (tabChangeEvent.index === 1) {
      this.initializeChartOptions();
      this.initiateBarChart();
      this.filterChart("TimeCategory"); // Initiate the Stacked bar chart with activity data(filter)
      this.statsInitiate(); //Initiate stat chart
      this.cdr.markForCheck();
    }
  };

  reload() {
    return this.initializeChartOptions(), this.initializeDoughnutChartOptions();
  }

  get f() {
    return this.form.controls;
  }

  submit() {
    // console.log(this.form.value);
  }

  //Chart Filter

  values: any = [
    {
      name: "Team-A",
      data: [2.4, 2.6, 2.8, 4.5, 2.9, 4.3, 3.1, 1.4, 5, 2],
    },
    {
      name: "Team-B",
      data: [4.4, 3.6, 1.8, 4, 3.4, 4.5, 3.4, 2.3, 4.3, 2.3],
    },
  ];
  results: any = ["Core Activity", "Non Core Activity", "Value Add"];

  toggle(data: any) {
    // console.log(data.value);
  }

  tab = (tabChangeEvent: MatTabChangeEvent): void => {
    // console.log("ava/eva-index => ", tabChangeEvent.index);
    this.selected = tabChangeEvent.index;
  };

  filterChart(key: any) {
    // console.log(key);
    this.yFilter = key;
    this.changeData();
  }

  changexType(arg0: any) {
    this.xFilter = arg0;
    this.changeData();
  }
  filterTeam(arg0: any) {
    this.zFilter = arg0;
    this.changeData();
  }

  changeData() {
    this.newChartOptions.series = this.getResults(
      this.zFilter,
      this.xFilter,
      this.yFilter
    );
    this.newChartOptions.categories = Array.from(this.getXAxis(this.xFilter));
    const element = document.querySelector("#chart2");
    // console.log(element);
    const chart2 = new ApexCharts(element, this.newChartOptions);
    chart2.updateOptions(this.newChartOptions);

    const element2 = document.querySelector("#barLongChart");

    const barchart = new ApexCharts(element2, this.barLongChartOptions);

    const barMap = this.getActivityChart();
    let barData: any = {};
    barData.name = "basic";
    barData.data = [];
    const barSeries: any = [];
    for (let key of barMap) {
      barSeries.push(key);
      barData.data.push(barMap.get(key));
    }

    this.barLongChartOptions.series = barData;
    this.barLongChartOptions.categories = barSeries;

    barchart.updateOptions(this.barLongChartOptions);

    this.cdr.markForCheck();
    // this.axisWiseFilter(data, key);
  }

  getActivityChart() {
    const activities = new Set();
    const actvityMap = new Map();
    let totalTeam = 0;
    for (let d of data) {
      totalTeam += d.total;
      for (let q of d.questions) {
        let value = 0;
        activities.add(q.Activity);
        if (actvityMap.has(q.Activity)) {
          value = actvityMap.get(q.Activity) + q.Answer * d.total;
          actvityMap.set(q.Activity, value);
        } else {
          value = q.Answer * d.total;
          actvityMap.set(q.Activity, value);
        }
      }
    }

    for (let x of actvityMap.keys()) {
      actvityMap.set(x, actvityMap.get(x) / totalTeam);
    }

    return actvityMap;
  }

  getActivityBar() {
    let barMap = this.getActivityChart();
    let barData: any = {};
    barData.name = "basic";
    barData.data = [];
    const barSeries: any = [];
    for (let key of barMap.keys()) {
      barSeries.push(key);
      barData.data.push(barMap.get(key));
    }
    // console.log(barMap);

    let barLongChartOptions = {
      series: [
        {
          name: "basic",
          data: barData.data,
        },
      ],
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: barSeries,
      },
    };
    return barLongChartOptions;
  }

  // filterChart(key: any) {
  //   console.log(key);
  //   const chart2 = new ApexCharts(
  //     document.querySelector("#chart2"),
  //     this.newChartOptions
  //   );

  //   const data = this.filterCData;
  //   // This gives the entries
  //   const values = new Set();
  //   for (let x of data) {
  //     const entries = x.questions.map((x: any) => x[key]);
  //     for (let entry of entries) {
  //       values.add(entry);
  //     }
  //   }
  //   this.values = values;
    // console.log(values)

  //   //This gives values

  //   const results = [];
  //   for (let x of data) {
  //     let result: any = {};
  //     result.name = x.team;
  //     result.data = [];
  //     for (let value of values) {
  //       let sum = x.questions
  //         .filter((y: any) => y[key] === value)
  //         .reduce((sum: any, current: any) => sum + current.Answer, 0);
  //       result.data.push(sum);
  //     }
  //     results.push(result);
  //   }
  //   this.results = results;
  //   this.newChartOptions.series = results;

  //   this.cdr.markForCheck();
  //   this.axisWiseFilter(data, key);
  // }

  axisWiseFilter(data: any, key: any) {
    const functions = new Set();
    for (let x of data) {
      functions.add(x.functionName);
    }
    // console.log("X-axis", functions);

    const dataPoints = new Set();
    //  const key = 'Time Category'
    for (let x of data) {
      const entries = x.questions.map((x: any) => x[key]);
      for (let entry of entries) {
        dataPoints.add(entry);
      }
    }
    // console.log("Y-axis", dataPoints);
    //this.groupFilter(functions,dataPoints,key)
    this.cdr.markForCheck();
  }

  groupFilter(functions: any, dataPoints: any, key: any) {
    const data = this.filterCData;
    // Get the data, to be displayed in bar
    const results: any = [];
    let group = "member";
    for (let fn of functions) {
      let result: any = {};
      let dataMap = new Map();
      let values: any = [];
      result.name = fn;
      let totalCount = 0;
      for (let x of data) {
        if (x.functionName == fn) {
          totalCount += x.total;
          for (let dataPoint of dataPoints) {
            let processThis = true;
            if (group == "member" && x.manager) {
              processThis = false;
            }
            if (group == "manager" && !x.manager) {
              processThis = false;
            }
            if (processThis) {
              for (let question of x.questions) {
                if (question[key] == dataPoint) {
                  if (dataMap.has(dataPoint)) {
                    dataMap.set(
                      dataPoint,
                      question.Answer * x.total + dataMap.get(dataPoint)
                    );
                  } else {
                    dataMap.set(dataPoint, question.Answer * x.total);
                  }
                }
              }
            }
          }
        }
      }
      for (let dataPoint of dataPoints) {
        values.push(dataMap.get(dataPoint) / totalCount);
      }
      result.data = values;
      results.push(result);
    }

    // console.log("Grouped:", results);
  }

  onChartChange(selection: any) {
    // console.log(selection.value);
    this.selectedOption = selection.value;

    this.barLongChartOptions = this.getActivityBar();

    this.newChartOptions = {
      series: [
        {
          name: "Value Add",
          data: [31.875, 23.125, 15.625],
        },
        {
          name: "Value Add",
          data: [38.25, 27.75, 18.75],
        },
        {
          name: "Value Add",
          data: [38.25, 27.75, 18.75],
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: ["Risk", "Center", "Back"],
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "right",
        offsetX: 0,
        offsetY: 50,
      },
    };

    this.cdr.markForCheck();
  }

  chartInitiate() {
    this.newChartOptions = {
      series: [
        {
          name: "Value Add",
          data: [31.875, 23.125, 15.625],
        },
        {
          name: "Value Add",
          data: [38.25, 27.75, 18.75],
        },
        {
          name: "Value Add",
          data: [38.25, 27.75, 18.75],
        },
      ],
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        stackType: "100%",
        events: {
          legendClick: function (
            chartContext: any,
            seriesIndex: any,
            config: any
          ) {
            // console.log(chartContext);
          },
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
            },
          },
        },
      ],
      xaxis: {
        categories: ["Risk", "Center", "Back"],
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "right",
        offsetX: 0,
        offsetY: 50,
      },
    };
  }

  statsInitiate() {
    this.barLongChartOptions = this.getActivityBar();
  }

  private initializeChartOptions(): void {
    // this.title = {
    //   text: 'Radar Chart'
    // };

    this.series = [
      {
        name: "Team-A",
        data: [2.4, 2.6, 2.8, 4.5, 2.9, 4.3, 3.1, 1.4, 5, 2],
      },
      {
        name: "Team-B",
        data: [4.4, 3.6, 1.8, 4, 3.4, 4.5, 3.4, 2.3, 4.3, 2.3],
      },
    ];

    this.chart = {
      type: "radar",
      height: 350,
    };

    // this.responsive = [
    //   {
    //     breakpoint: 1200,
    //     options: {
    //       legend: {
    //         position: "bottom",
    //         offsetX: -10,
    //         offsetY: 0
    //       }
    //     }
    //   }
    // ]

    this.xaxis = {
      categories: this.results,
    };

    // this.fill = {
    //   opacity: 1
    // }

    // this.legend = {
    //   position: "right",
    //   offsetX: 0,
    //   offsetY: 50
    // }

    this.cdr.markForCheck();
    // console.log("IN");
  }

  private initiateBarChart(): void {
    // this.title = {
    //   text: 'Bar Chart'
    // };

    this.series_bar = [
      {
        name: "Team-A",
        data: [2.4, 2.6, 2.8, 4.5, 2.9, 4.3, 3.1, 1.4, 5, 2],
      },
      {
        name: "Team-B",
        data: [4.4, 3.6, 1.8, 4, 3.4, 4.5, 3.4, 2.3, 4.3, 2.3],
      },
    ];

    this.chart_bar = {
      type: "bar",
      height: 350,
      stacked: true,
      stackType: "100%",
    };

    this.xaxis_bar = {
      categories: [],
    };

    const data = this.filterCData;
    const seriesData: any[] = [];

    data.forEach((teamData: any) => {
      const seriesItem = {
        name: teamData.team,
        data: [0],
      };

      seriesItem.data = [];

      teamData.questions.forEach((question: any) => {
        seriesItem.data.push(question.Answer);
      });

      seriesData.push(seriesItem);
    });

    this.series_bar = seriesData;
    this.xaxis_bar.categories = data[0].questions.map((question: any) => {
      question.Type;
    });

    this.cdr.markForCheck();
  }

  private initializeDoughnutChartOptions(): void {
    this.series_dn = [44, 55, 13, 43, 22];

    this.chart_dn = {
      type: "donut",
    };

    (this.labels = ["Team A", "Team B", "Team C", "Team D", "Team E"]),
      (this.responsive_dn = [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ]);
  }

  public getAVATemplates(isRerender: boolean = false) {
    this.workflowTemplateService.getTemplateByQuery("module", "AVA").subscribe(
      (response) => {

        this.AvaTemplateList = response.data;

        if(!isRerender){
          let templateIndex = this.AvaTemplateList.findIndex(
            (template: any) => template.templateName === "default"
          );
          if (templateIndex != -1) {
            this.AvaDefaultTemplate = this.AvaTemplateList[templateIndex];
            this.selectedTemplate = this.AvaDefaultTemplate;
            this.isDefaultTemplate = this.selectedTemplate.templateName == "default" ? true : false;
            this.dataSource = this.AvaDefaultTemplate["questions"];

            sessionStorage.setItem("avaTemplateId", this.selectedTemplate["_id"]);
          }
        }
      },
      (error) => {
        // console.log("---- Get all ava template Error ----", error);
      }
    );
  }

  public getEVATemplates() {
    this.workflowTemplateService.getTemplateByQuery("module", "EVA").subscribe(
      (response) => {
        // console.log("---- Get all eva template list ----", response);
        this.evaTemplateList = response.data;

        let defaultTemplate = this.evaTemplateList.filter(
          (template: any) => template.templateName == "default"
        );
        this.workflowTemplateService
          .getTemplateByQuery("id", defaultTemplate[0]["_id"])
          .subscribe((templateData) => {
            this.evaDefaultTemplate =
              response.data && response.data.length ? response.data[0] : [];
            this.selectedTemplate = this.evaDefaultTemplate;
            this.isDefaultTemplate =
              this.selectedTemplate.templateName == "default" ? true : false;
            this.dataSource2 = this.evaDefaultTemplate["questions"];

            sessionStorage.setItem(
              "evaTemplateId",
              this.selectedTemplate["_id"]
            );
          });
      },
      (error) => {
        // console.log("---- Get all eva template Error ----", error);
      }
    );
  }

  openDialog() {
    // this.isDefaultTemplate = this.selectedTemplate.templateName == 'default' ? true : false;
    this.dialog = this.matDialog.open(TemplateAddComponent, {
      width: "70em",
      disableClose: true,
      data: {
        from: "ava-template",
        isDefaultTemplate: this.isDefaultTemplate,
        templateDetail: this.selectedTemplate,
      },
    });

    this.dialog.afterClosed().subscribe((submit: any) => {
      this.getAVATemplates(true);

      if (!this.isDefaultTemplate) {
        this.workflowTemplateService
          .getTemplateByQuery("id", this.selectedTemplate["_id"])
          .subscribe((response) => {

            this.dataSource = response.data[0]["questions"];
            this.isDefaultTemplate = this.selectedTemplate.templateName == 'default' ? true : false;
            sessionStorage.setItem("avaTemplateId", this.selectedTemplate["_id"]);
            this.cdr.markForCheck();
          });
      }
    });
  }

  onTemplateSelect(selection: any) {
    let templateIndex = this.AvaTemplateList.findIndex(
      (template: any) => template.templateName === selection.value
    );
    this.selectedTemplate = this.AvaTemplateList[templateIndex];
    this.isDefaultTemplate =
      this.selectedTemplate.templateName == "default" ? true : false;
    this.dataSource = this.selectedTemplate["questions"];

    sessionStorage.setItem("avaTemplateId", this.selectedTemplate["_id"]);
  }

  onRemoveQuestion(selectedQuestion: any) {
    let setData = Object.assign({}, this.selectedTemplate);
    let templateId: string = this.selectedTemplate["_id"];
    const questionIndex = setData.questions.findIndex((question: any) => {
      return question.activity === selectedQuestion.activity;
    });

    // console.log("---- questionIndex ----", questionIndex);

    if (questionIndex !== -1) {
      setData.questions.splice(questionIndex, 1);
    }

    // console.log("---- Final setData ----", setData);
    delete setData["_id"];

    this.workflowTemplateService
      .updateTemplate(templateId, setData)
      .subscribe((res) => {
        // console.log("---- Template Update Response ----", res);

        this.snackBar.open("Removed Successfully", "close", {
          duration: 4500,
          horizontalPosition: "center",
          verticalPosition: "top",
        });

        // console.log(
        //   "---- Selected Template Id ----",
        //   this.selectedTemplate["_id"]
        // );

        this.workflowTemplateService
          .getTemplateByQuery("id", this.selectedTemplate["_id"])
          .subscribe((response) => {
            this.dataSource = response.data[0]["questions"];
            this.cdr.markForCheck();
          });
      });
  }

  public avaSurvey() {
    this.router.navigate([
      `ava/survey-page/${this.selectedTemplate["_id"]}`,
    ]); /* TODO: Dynamically send the surveyID */
  }

  public addSurvey() {
    this.router.navigate([`/project/${this.clientId}/add-survey`]);
  }

  public getXAxis(xKey: string) {
    const xAxis = new Set();
    for (let x of data) {
      const xMap = new Map(Object.entries(x));
      xAxis.add(xMap.get(xKey));
    }
    return xAxis;
  }

  public getYAxis(yKey: string) {
    const yAxis = new Set();
    for (let x of data) {
      for (let q of x.questions) {
        const qMap = new Map(Object.entries(q));
        yAxis.add(qMap.get(yKey));
      }
    }
    return yAxis;
  }

  public getResults(grouping: string, xKey: string, yKey: any) {
    const results: any = [];
    const yAxis = this.getYAxis(yKey);
    const xAxis = this.getXAxis(xKey);

    for (let y of yAxis) {
      let result: any = {};
      result.name = y;
      result.data = [];
      for (let x of xAxis) {
        let total = 0;
        let value = 0;
        for (let d of data) {
          if (d.functionName == x || d.team == x) {
            total += d.total;
            let processthis = true;
            if (grouping == "all") {
              processthis = true;
            } else if (grouping == "manager") {
              processthis = d.manager;
            } else if (grouping == "member") {
              processthis = !d.manager;
            }
            if (processthis) {
              for (let q of d.questions) {
                let qMap = new Map(Object.entries(q));
                if (qMap.get(yKey) == y) {
                  value += q.Answer * d.total;
                }
              }
            }
          }
        }
        result.data.push(value / total);
      }
      results.push(result);
    }
    // console.log(results);
    return results;
  }
}
