import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
} from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CustomService } from "@app/core/services/custom.service";
import { DatabaseService } from "@app/database/database.service";
import { SurveyService } from "@app/survey/survey.service";
import { ResponseEditComponent } from "../response-edit/response-edit.component";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'app-response-select',
  templateUrl: './response-select.component.html',
  styleUrls: ['./response-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ResponseSelectComponent implements OnInit {
  datasource: any = [];
  dc = ['response','responses','action'];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ResponseSelectComponent>,
    private surveyService: SurveyService,
    private snackbar: MatSnackBar,
    private matdialog: MatDialog
  ) { }

  ngOnInit(): void {
    // console.log(this.data);
    this.datasource = this.data
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(data:any){
    // let payload = this.data;
    // console.log(data._id);
    
        this.surveyService.getSurveyResponses(data._id).subscribe(
          (res2: any) => {
            this.dialogRef.close();
            let dialog2 = this.matdialog.open(ResponseEditComponent, {
              width: "95em",
              disableClose: false,
              data: res2.data[0],
            });
          },
          (err2: any) => {
            this.snackbar.open(err2.message, "close");
          }
        );

  }


}
