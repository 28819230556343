// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// export const environment = {
//   production: false,
//   STATIC_API_URL: "https://wezakqtpadwa001.azurewebsites.net/",
//   AUTH_API_URL: "https://wezakqtpadwa002.azurewebsites.net/",
//   // AUTH_API_URL: "http://localhost:3001/",
//   CLIENT_API_URL: "https://wezakqtpadwa003.azurewebsites.net/",
//   SURVEY_API_URL: "https://wezakqtpadwa005.azurewebsites.net/",
// };


export const environment = {
  production: false,
  STATIC_API_URL: "https://wezakqtpadwa001.azurewebsites.net/",
  AUTH_API_URL: "https://wezakqtpadwa002.azurewebsites.net/",
  CLIENT_API_URL: "https://wezakqtpadwa003.azurewebsites.net/",
  SURVEY_API_URL: "https://wezakqtpadwa005.azurewebsites.net/",
  CLIENT_ID : "urn:dev.productivitysuite.uk.pwc.com",
  CLIENT_SECRET: "1XRt0wgBAXTWYPl2Uw5N",
  AUTHORITY : "https://login-stg.pwcinternal.com:443/openam/oauth2",
  TOKEN_ENDPOINT : "https://login-stg.pwc.com/openam/oauth2/access_token",
  AUTHORIZATION_ENDPOINT : "https://login-stg.pwc.com/openam/oauth2/authorize",
  USERINFO_ENDPOINT : "https://login-stg.pwc.com/openam/oauth2/userinfo",
  END_SESSION_ENDPOINT : "https://login-stg.pwc.com:443/openam/UI/Logout",
};

// Dev - Local
// export const environment = {
//   production: false,
//   STATIC_API_URL: "http://localhost:3000/",
//   AUTH_API_URL: "http://localhost:3001/",
//   CLIENT_API_URL: "http://localhost:3002/",
//   SURVEY_API_URL: "http://localhost:3003/",
//   CLIENT_ID : "urn:localhost:4200:app",
//   CLIENT_SECRET: "y8oEKW6sZLc3jJJcxd9B",
//   AUTHORITY : "https://login-stg.pwcinternal.com:443/openam/oauth2",
//   TOKEN_ENDPOINT : "https://login-stg.pwc.com/openam/oauth2/access_token",
//   AUTHORIZATION_ENDPOINT : "https://login-stg.pwc.com/openam/oauth2/authorize",
//   USERINFO_ENDPOINT : "https://login-stg.pwc.com/openam/oauth2/userinfo",
//   END_SESSION_ENDPOINT : "https://login-stg.pwc.com:443/openam/UI/Logout",
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
