import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { SurveyService } from "@app/survey/survey.service";
import { WorkflowTemplateService } from "@app/workflow-template/workflow-template.service";
@Component({
  selector: "app-survey-landing-page",
  templateUrl: "./survey-landing-page.component.html",
  styleUrls: ["./survey-landing-page.component.scss"],
})
export class SurveyLandingPageComponent implements OnInit {
  selected: any;
  // overall percentage counter
  width: any = 50;
  public progress: string;
  public progress2: string;
  public percentage: number = 25;
  public percentage2: number = 25;
  private updateSliderToggle: boolean = false;
  moduleType: string = "";
  clientId: string = "";
  modificationValue = new FormControl(0);

  avaQuestions: any = [];
  evaQuestions: any = [];
  grouped_AVAQuestions: any = [];
  avaSum: number = 0;
  avaTemplateId: string = "";
  evaTemplateId: string = "";
  isReadOnly: boolean = true;
  surveyDetailId: string = "";
  surveyDetail: any = {};
  surveyId: any = {};
  surveyData: any = {};
  paramsData: any;
  timeline: any = [1, 2, 3, 4, 5];
  selectedTeam: any = "";
  selectedFunction: any = "";
  selectedRole: any = "";
  isManager: boolean = false;
  activityId: any;
  teams: any = [];
  functions: any = [];
  role: any = ["Team Manager", "Team Member"];

  startUpdateSlider() {
    this.updateSliderToggle = true;
  }

  endUpdateSlider() {
    this.updateSliderToggle = false;
  }

  updateSlider(event: any) {
    if (this.updateSliderToggle) {
      this.percentage = Math.floor(
        (event.layerX / (event.target.offsetWidth - 3)) * 100
      );

      if (this.percentage > 100) {
        this.percentage = 100;
      } else if (this.percentage < 0) {
        this.percentage = 0;
      }

      this.progress = this.percentage + "%";
    }
  }

  constructor(
    private route: ActivatedRoute,
    private surveyService: SurveyService,
    private snackBar: MatSnackBar,
    private workflowService: WorkflowTemplateService,
    private cdr: ChangeDetectorRef,
    private router: Router
  ) {
    this.route.queryParams.subscribe((res: any) => {
      // console.log("surveydata", res);
      this.paramsData = res;
    });
  }

  ngOnInit(): void {
    this.moduleType = "ava";
    this.clientId = sessionStorage.getItem("clientId") || "";
    this.surveyDetailId =
      this.route.snapshot.paramMap.get("surveyDetailId") || "";

    if (this.surveyDetailId) {
      this.isReadOnly = false;
      this.getSurveyDetails();
    } else {
      this.selected = 1;
    }

    this.avaTemplateId = sessionStorage.getItem("avaTemplateId") || "";
    this.evaTemplateId = sessionStorage.getItem("evaTemplateId") || "";
    if (this.avaTemplateId) this.getAvaTemplateDetail();
    if (this.evaTemplateId) this.getEvaTemplateDetail();

    let jsonString: any = localStorage.getItem("Survey");
    let retrievedObject: any = JSON.parse(jsonString);
    this.paramsData = retrievedObject;
    // console.log("PD", this.paramsData);
  }

  ngOnDestroy() {
    sessionStorage.removeItem("avaTemplateId");
    sessionStorage.removeItem("evaTemplateId");
    localStorage.removeItem("Survey");
  }

  begin() {
    if (
      this.selectedFunction != "" ||
      this.selectedTeam != "" ||
      this.selectedRole != ""
    ) {
      this.selected = 1;
    } else {
      this.snackBar.open("Kindly select all fields", "close");
    }
  }

  assignTeam(event: any) {
    // console.log(event);
    this.selectedTeam = event;
    this.cdr.markForCheck();
  }

  assignFunction(event: any) {
    this.teams = [];
    this.selectedFunction = event.value;
    this.teams = this.functionMap.get(this.selectedFunction);
    this.cdr.markForCheck();
  }

  assignRole(event: any) {
    this.selectedRole = event;
    if (this.selectedRole === "Team Manager") {
      this.isManager = true;
    } else {
      this.isManager = false;
    }
    // console.log(this.isManager);
    this.cdr.markForCheck();
  }

  isSurveyActive: boolean = true;

  functionMap: any = new Map();

  getActivity(id: any) {
    this.workflowService.getActivityById(this.clientId, id).subscribe(
      (response: any) => {
        // console.log("---- Res ----", response);
        response.forEach((ele: any) => {
          ele.teams.forEach((element: any) => {
            // console.log("---- element ----", element);
            if (this.functionMap.has(element.function)) {
              let arr = this.functionMap.get(element.function);
              arr.push(element.teamName);
              this.functionMap.set(element.function, arr);
            } else {
              this.functionMap.set(element.function, [element.teamName]);
            }
          });
        });

        this.functions = [...this.functionMap.keys()];
        // console.log("---- F&T ----", this.functions, this.teams);
        this.cdr.markForCheck();
      },
      (error: any) => {
        // console.log("---- Error ----", error);
      }
    );
  }

  getStatus(element: any) {
    let endDate: Date = element.surveyData
      ? new Date(element.surveyData.endDate)
      : new Date();
    let currentDate = new Date();
    if (endDate.valueOf() >= currentDate.valueOf()) {
      return "In Progress";
    } else {
      return "Completed";
    }
  }

  getSurveyDetails() {
    this.surveyService
      .getSurveyDetails("surveyDetailId", this.surveyDetailId)
      .subscribe(
        (surveyDetails) => {
          // console.log(
          //   "-------- *****Get surveyDetail Data**** -----------",
          //   surveyDetails[0].surveyData
          // );

          let status = this.getStatus(surveyDetails[0]);

          if (status != "Completed") {
            let actualData = surveyDetails[0].surveyData.templates;
            // this.surveyDetail = surveyDetails;
            this.surveyId =
              surveyDetails && surveyDetails[0]
                ? surveyDetails[0].surveyId
                : {};
            this.activityId = surveyDetails[0].activityId;
            this.clientId = surveyDetails[0].clientId;
            let avaIndex = actualData.findIndex(
              (res: any) => res.module === "AVA"
            );
            let evaIndex = actualData.findIndex(
              (res: any) => res.module === "EVA"
            );
            if (avaIndex != -1) {
              this.avaQuestions = actualData[avaIndex].questions?.questions;
            }

            if (evaIndex != -1) {
              this.evaQuestions = actualData[evaIndex].questions?.questions;
            }

            /* AVA block */
            this.avaQuestions.forEach((elem: any) => {
              (elem.answer = 0), (elem.displayValue = 0), (elem.max = 100000);
            });
            let groupedQues: any = {};
            this.avaQuestions.forEach((qus: any) => {
              const { type } = qus;
              if (groupedQues.hasOwnProperty(type)) {
                groupedQues[type].push(qus);
              } else {
                groupedQues[type] = [qus];
              }
            });
            Object.entries(groupedQues).forEach(([key, value]) => {
              let object = {
                type: key,
                questions: value,
              };
              this.grouped_AVAQuestions.push(object);
            });
            /*  */

            /* EVA block */
            if (this.surveyDetailId) {
              this.evaQuestions.forEach((elem: any, i: number) => {
                (elem.answer = 0), (elem.textareaValue = "");
              });
            } else {
              this.evaQuestions.forEach((elem: any, i: number) => {
                if (i != 5) elem.answer = 5 - i;
                else elem.answer = 0 + 1;
              });
            }
            /*  */

            this.getActivity(this.activityId);
          } else {
            this.isSurveyActive = false;
          }
        },
        (error) => {
          // console.log("-------- surveyDetails Error -----------", error);
        }
      );
  }

  getAvaTemplateDetail() {
    // console.log("--- AVA Template ID ----", this.avaTemplateId);
    this.workflowService
      .getTemplateByQuery("id", this.avaTemplateId)
      .subscribe({
        next: (res: any) => {
          // console.log(
          //   "--------------- -- AVA Question -- ---------------",
          //   res
          // );

          this.avaQuestions = res.data[0]["questions"];
          this.avaQuestions.forEach((elem: any) => {
            (elem.answer = 0), (elem.displayValue = 0), (elem.max = 100000);
          });

          //grouping
          if (this.moduleType == "ava") {
            let groupedQues: any = {};
            this.avaQuestions.forEach((qus: any) => {
              const { type } = qus;
              //console.log(type, qus,'type')
              if (groupedQues.hasOwnProperty(type)) {
                groupedQues[type].push(qus);
              } else {
                groupedQues[type] = [qus];
              }
            });
            Object.entries(groupedQues).forEach(([key, value]) => {
              let object = {
                type: key,
                questions: value,
              };
              this.grouped_AVAQuestions.push(object);
            });
            //console.log('grouped_AVAQuestions',this.grouped_AVAQuestions);
          }
        },
        error: (err: any) => {
          // console.log("error line 104", err);
        },
      });
  }

  getEvaTemplateDetail() {
    this.workflowService
      .getTemplateByQuery("id", this.evaTemplateId)
      .subscribe({
        next: (res: any) => {
          // console.log(
          //   "--------------- -- EVA Question -- ---------------",
          //   res
          // );

          this.evaQuestions = res.data[0]["questions"];
          if (this.surveyDetailId) {
            this.evaQuestions.forEach((elem: any, i: number) => {
              (elem.answer = 0), (elem.textareaValue = "");
            });
          } else {
            this.evaQuestions.forEach((elem: any, i: number) => {
              if (i != 5) elem.answer = 5 - i;
              else elem.answer = 0 + 1;
            });
          }
        },
        error: (err: any) => {
          // console.log("error line 104", err);
        },
      });
  }

  answer(event: any, element: any) {
    // console.log(event.value, element, this.avaSum);
    this.avaSum = 0;
    element.answer = event.value;
    element.displayValue = event.value / 1000;
    this.avaSum = this.grouped_AVAQuestions.reduce((acc: any, obj: any) => {
      obj.questions.forEach((question: any) => {
        acc += question.displayValue;
      });
      return acc;
    }, 0);
    this.progress = this.avaSum + "%";

    // if(this.avaSum === 100){
    //    this.snackBar.open('Total Value Reached 100%','close')
    //    element.max = element.answer
    // }
    if (this.avaSum > 100) {
      //  this.avaSum -= event.value / 1000
      let valueIn100 = event.value / 1000;
      //  let total = this.avaSum + valueIn100
      let extra = Math.abs(100 - this.avaSum);

      element.displayValue = Math.abs(valueIn100 - extra);
      element.answer = element.displayValue * 1000;
      this.avaSum = 100;
      this.progress = this.avaSum + "%";
      this.snackBar.open("Total Value Reached 100%", "close");
      this.cdr.markForCheck();
    }
    // console.group('total selection',this.avaSum)
    // console.group('current selection ',element.displayValue)
    this.cdr.markForCheck();
  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + "%";
    }
    return value;
  }

  number = 0;

  increaseNumber(element: any) {
    //console.log('element',element, this.avaSum);
    element.answer = element.answer + 1000;
    element.displayValue = element.displayValue + 1;
    this.avaSum = this.avaSum + 1;
    // this.number++;
    // let structure = {
    //   value:this.number.toFixed(0) + '000'
    // }
    // this.answer(structure,element)
    this.cdr.markForCheck();
  }

  decreaseNumber(element: any) {
    if (this.avaSum > 0 && element.displayValue > 0) {
      element.answer = element.answer - 1000;
      element.displayValue = element.displayValue - 1;
      this.avaSum = this.avaSum - 1;
      // this.number--;
      // let structure = {
      //   value:this.number.toFixed(0) + '000'
      // }
      // this.answer(structure,element)
      this.cdr.markForCheck();
    }
  }

  //Rating
  stars: number[] = [1, 2, 3, 4, 5];
  descstars: any[] = [
    "Strongly Disagree",
    "Disagree",
    "Neither Agree or Disagree",
    "Agree",
    "Strongly Agree",
  ];
  selectedValue: number;
  countStar(question: any, star: any) {
    // console.log("eva star", star, question);
    question.answer = star;
    this.cdr.markForCheck();
  }

  onConfirmAva(): any {
    if (this.avaSum < 100) {
      return this.snackBar.open(
        "Kindly fill details upto Total must be equal to 100",
        "close"
      );
    } else {
      this.moduleType = "eva";
      // console.log("data for next", this.grouped_AVAQuestions);
    }
  }

  nextPreview() {
    this.moduleType = "eva";
  }

  updateActivityAndCreate() {
    let id = this.paramsData._id;
    delete this.paramsData._id;
    delete this.paramsData.__v;
    delete this.paramsData.updatedAt;
    delete this.paramsData.createdAt;
    let payload = this.paramsData;
    payload.status = "Started";
    this.workflowService.updateActivity(id, payload).subscribe(
      (response) => {
        // console.log("---- New Change success ----", response);
        this.onCreateSurvey(id);
      },
      (error) => {
        // console.log("---- Error ----", error);
      }
    );
  }

  onCreateSurvey(surveyId: any) {
    // console.log("===== AVA Survey Answer =====", this.grouped_AVAQuestions);
    // console.log("===== EVA Survey Answer =====", this.evaQuestions);
    const today = new Date();
    const payload = {
      templates: [
        {
          templateId: this.avaTemplateId,
          module: "AVA",
        },
        {
          templateId: this.evaTemplateId,
          module: "EVA",
        },
      ],
      clientId: this.clientId,
      startDate: this.paramsData.startDate,
      endDate: this.paramsData.endDate,
      surveyIds: [surveyId],
      module: "ava-eva",
    };

    // console.log("---- SURVEY payload ---", payload);

    this.surveyService.createSurvey(payload).subscribe((res) => {
      // console.log("----- SURVEY create Response ----", res);

      sessionStorage.removeItem("avaTemplateId");
      sessionStorage.removeItem("evaTemplateId");

      this.snackBar.open("Survey Created Successfully", "close", {
        duration: 4500,
        horizontalPosition: "center",
        verticalPosition: "top",
      });

      this.router.navigate([`/project/${this.clientId}/ava-eva/responses`]);
    });
  }

  onSubmitSurvey() {
    // console.log("===== AVA Survey Answer =====", this.grouped_AVAQuestions);
    // console.log("===== EVA Survey Answer =====", this.evaQuestions);

    // let checking:any = []
    // checking = this.evaQuestions.map((x:any) => x.answer)
    // if(checking.includes(0)){
    //   let index:number = checking.indexOf(0)
    // console.log('element index',index);
    //     this.snackBar.open(` Question No ${index + 1} is Not Answered `,'close')
    // }else{
    //   this.selected = 2
    // }

    let finalAvaResponse: any = [];
    this.grouped_AVAQuestions.forEach((submittedQuestions: any) => {
      let avaSubmitResponse = submittedQuestions.questions.map(
        (question: any) => {
          question["Answer"] = question["displayValue"];
          question["Activity"] = question["question"];
          question["Type"] = question["type"];
          question["TimeCategory"] = question["timeCategory"];

          delete question.displayValue;
          delete question.answer;
          delete question.activity;
          delete question.type;
          delete question.timeCategory;

          return question;
        }
      );
      // console.log("=== submittedQuestions ===", avaSubmitResponse);
      finalAvaResponse = finalAvaResponse.concat(avaSubmitResponse);
    });
    // console.log("---- finalAvaResponse ----", finalAvaResponse);

    let payload = {
      surveyDetailId: this.surveyDetailId,
      clientFunction: this.selectedFunction,
      teamName: this.selectedTeam,
      isManager: this.isManager,
      dataResponse: {
        ava: finalAvaResponse,
        eva: this.evaQuestions,
      },
    };

    // console.log("---- Survey Final Submit payload ----", payload);

    this.surveyService.createSurveyResponse(payload).subscribe((res) => {
      // console.log("----- SURVEY Submitted Response ----", res);
      let payload: any = {};
      payload.status = "In Progress";
      this.workflowService.updateActivity(this.activityId, payload).subscribe(
        (response) => {
          // console.log("---- New Change success ----", response);
        },
        (error) => {
          // console.log("---- Error ----", error);
        }
      );

      // this.snackBar.open("Survey Submitted Successfully", 'close',{
      //   duration: 3000,
      //   horizontalPosition: 'center',
      //   verticalPosition: 'top',
      // });

      this.selected = 3;
    });
  }

  evaSelection() {
    let checking: any = [];
    checking = this.evaQuestions.map((x: any) => x.answer);
    if (checking.includes(0)) {
      let index: number = checking.indexOf(0);
      // console.log("element index", index);
      this.snackBar.open(` Question No ${index + 1} is Not Answered `, "close");
    } else {
      this.selected = 2;
      this.onSubmitSurvey();
    }
  }

  maxWords = 100;
  textareaValue: string = "";
  remainingWords: number | null = null;

  onTextareaInput(element: any): any {
    const words = element.textareaValue.trim().split(/\s+/);
    const currentWordCount = words.length;

    if (currentWordCount >= this.maxWords) {
      let truncatedWord = words.slice(0, this.maxWords);
      element.textareaValue = truncatedWord.join(" ");
    }

    element.remainingWords = this.maxWords - currentWordCount;
  }

  goToProject() {
    this.router.navigate([`/project/${this.clientId}/ava-eva/setup-II`]);
  }

  goToAva() {
    this.moduleType = "ava";
  }

  close() {
    window.open("", "_blank", "");
    window.close();
  }
}
