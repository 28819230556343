import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { of } from "rxjs";
import { ManagerInterviewService } from "../manager-interview.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-mi-deep-dive",
  templateUrl: "./mi-deep-dive.component.html",
  styleUrls: ["./mi-deep-dive.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MiDeepDiveComponent implements OnInit {
  displayedColumns = ["names", "lines", "average"];
  displayedColumns2 = ["lead", "team", "function", "date", "status", "action"];
  // dataSource = [
  //   {position: 1, name: 'Senior Leads are Present?', },
  //   {position: 2, name: 'Are you prepared for interview ?', },
  //   {position: 3, name: 'Another Question ?', },
  //   {position: 4, name: 'Sample Question ?', },
  //   {position: 5, name: 'Test Question ?', },
  // ];
  dataSource2: any = [{ name: "Group 1" }];
  selectedValue: any;

  ClientId: any;

  timeline: any = [1, 2, 4, 5];
  timelineAvg: any = [3];
  canShow: boolean;

  mockdataTimeline: any;

  functions: any = ["All"];
  teams: any = ["All"];
  interviews: any = ["All"];

  selectedFunctions: any = ["All"];
  selectedTeams: any = ["All"];
  selectedInterviews: any = ["All"];

  previousTeams: any[] = ["All"];
  previousFunctions: any[] = ["All"];
  previousInterviews: any[] = ["All"];

  dataSource: any;
  selectedIndex: any = 0;
  selectedRowIndex: any = -1;
  selectedRow: any;
  hoverContent: any = [];
  selectedHoverIndex: number = 0;

  constructor(
    private matdialog: MatDialog,
    private miService: ManagerInterviewService,
    private cdr: ChangeDetectorRef,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.ClientId = sessionStorage.getItem("clientId") || "";
    this.getData();

    // console.log("selected row", this.selectedRow);
  }

  hover: boolean;

  highlight(index: number, row: any) {
    // console.log("highlight row", index, row);
    this.selectedIndex = index;
    this.selectedRow = row;
    this.hoverContent = this.selectedRow.answers;
  }

  isContentVisible: boolean = false;

  showContent(ind: any, answer: any) {
    // console.log("parent data", answer);
    this.selectedHoverIndex = ind;
    this.hoverContent = [];
    this.hoverContent = answer;
    this.hoverContent.forEach((element: any) => {
      // console.log("element", element);
    });
    // console.log("pind,ind", answer, this.hoverContent);
    if (answer.length > 0 || ind == 5) answer.show = true;
    this.isContentVisible = true;
  }

  hideContent(answer: any) {
    // this.hoverContent = []
    this.selectedHoverIndex = -1;
    this.hoverContent = this.selectedRow.answers;
    answer.show = false;
    this.isContentVisible = false;
  }

  mouseEnter() {
    // console.log("in");

    this.hover = true;
  }

  mouseLeave() {
    // console.log("out");
    this.hover = false;
  }

  //  open(type:any){
  //   let dialogRef:any = this.matdialog.open(FocusGroupEditPopupComponent, {
  //     width : `30vw`,
  //     disableClose : true,
  //     data :{
  //       type:type,
  //       data:''
  //     }
  //   })

  //   dialogRef.afterClosed().subscribe((res:any) => {
  //     console.log('POPUP',res);
  //     // this.cdr.markForCheck();

  //   })
  // }

  //Output Data

  changeTeam(event: any) {
    this.selectedTeams = [];

    if (!event.length) {
      this.selectedTeams.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedTeams.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousTeams.includes("All")) {
        this.selectedTeams.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedTeams.push("All");
      }
    } else {
      this.selectedTeams.push(...event);
    }
    this.previousTeams = this.selectedTeams;
    this.getData();
  }

  changeFunction(event: any) {
    this.selectedFunctions = [];

    if (!event.length) {
      this.selectedFunctions.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.selectedFunctions.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousFunctions.includes("All")) {
        this.selectedFunctions.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedFunctions.push("All");
      }
    } else {
      this.selectedFunctions.push(...event);
    }
    this.previousFunctions = this.selectedFunctions;
    this.getData();
  }

  changeInterview(event: any) {
    this.selectedInterviews = [];

    if (!event.length) {
      this.selectedInterviews.push("All");
    }
    if (event.includes("All") && event.length == 1) {
      this.previousInterviews.push("All");
    } else if (event.includes("All") && event.length > 1) {
      if (this.previousInterviews.includes("All")) {
        this.selectedInterviews.push(
          ...event.filter((x: any) => {
            return x != "All";
          })
        );
      } else {
        this.selectedInterviews.push("All");
      }
    } else {
      this.selectedInterviews.push(...event);
    }
    this.previousInterviews = this.selectedInterviews;
    this.getData();
    this.clearSelect();
  }

  clearSelect() {
    this.selectedValue = null; // Set the selectedValue to null
  }

  getCommentData(item: any) {
    return item?.comments == "" ? "No Comments" : item?.comments;
  }

  getData() {
    this.canShow = false;
    this.selectedRowIndex = -1;
    this.miService.getMI("managerInterviews", this.ClientId).subscribe({
      next: (res: any) => {
        // console.log("SELECT", res);
        let tableData: any = [];
        this.cdr.markForCheck();
        res.forEach((ele: any) => {
          tableData.push(ele.interviewData);
        });

        res.forEach((element: any) => {
          if (element.clicks !== 0) {
            element.interviewData.status = "Complete";
          } else {
            element.interviewData.status = "Incomplete";
          }
        });

        for (let d of tableData) {
          if (
            !this.functions.includes(d.clientFunction) &&
            d.status == "Complete"
          ) {
            this.functions.push(d.clientFunction);
          }
          if (!this.teams.includes(d.teamName) && d.status == "Complete") {
            this.teams.push(d.teamName);
          }
          if (
            !this.interviews.includes(d.interviewName) &&
            d.status == "Complete"
          ) {
            this.interviews.push(d.interviewName);
          }
        }
        this.processTableData(tableData);
        this.cdr.markForCheck();
      },
    });
  }

  processTableData(tableData: any) {
    let data: any = [];
    for (let d of tableData) {
      let funProcess = false;
      let teamProcess = false;
      let intProccess = false;

      if (
        this.selectedFunctions.includes("All") ||
        this.selectedFunctions.includes(d.clientFunction)
      ) {
        funProcess = true;
      }
      if (
        this.selectedTeams.includes("All") ||
        this.selectedTeams.includes(d.teamName)
      ) {
        teamProcess = true;
      }
      if (
        this.selectedInterviews.includes("All") ||
        this.selectedInterviews.includes(d.interviewName)
      ) {
        intProccess = true;
      }

      if (funProcess && teamProcess && intProccess) {
        if (d.status == "Complete") {
          data.push(d);
        }
      }
    }
    this.dataSource2 = data;
  }

  showData(index: number, row: any) {
    // console.log(row._id);
    this.selectedRow = [];
    this.selectedIndex = 0;
    this.selectedRowIndex = index;
    this.mockdataTimeline = [];
    this.cdr.markForCheck();
    this.getMIData(row._id);
  }

  getMIData(id: any) {
    // console.log(id);

    this.miService
      .getOutput1("managerInterviews", this.ClientId, id)
      .subscribe({
        next: (res: any) => {
          // console.log("MI API Output :----->", res);

          this.mockdataTimeline = res;
          if (
            this.mockdataTimeline === "No Responses received" ||
            this.mockdataTimeline === "Please Setup the Survey for Focus Group"
          ) {
            this.canShow = false;
          } else {
            this.canShow = true;
            this.setOutput();
          }
          this.cdr.markForCheck();
        },
        error: (error: any) => {
          // console.log("get mi error", error);
        },
      });
  }

  setOutput() {
    // this.mockdataTimeline.forEach((element: any) => {
    //   if (element.average > 1 && element.average < 2) element.margin = 10;
    //   if (element.average > 2 && element.average < 3) element.margin = 18;
    //   if (element.average > 3 && element.average < 4) element.margin = 21;
    //   if (element.average > 4 && element.average < 5) element.margin = 23;
    // });
    this.mockdataTimeline.forEach((ele: any) => {
      let ratings: any = {
        one: [],
        two: [],
        three: [],
        four: [],
        five: [],
        six: [],
      };
      let questionRes: any;
      let Average: number = ele.average;
      of(ele).forEach((value: any) => {
        value.answers.forEach((item: any) => {
          if (item.answer == 0 || item.answer == 1) {
            ratings.one.push(item);
          }
          if (item.answer == 2) {
            ratings.two.push(item);
          }
          if (item.answer == 3) {
            ratings.three.push(item);
          }
          if (item.answer == 4) {
            ratings.four.push(item);
          }
          if (item.answer == 5) {
            ratings.five.push(item);
          }
        });
        let getArray: any = [];
        getArray = Object.values(ratings);
        ele.rating = [...getArray];
      });
    });
    // console.log("mockdataTimeline", this.mockdataTimeline);

    this.dataSource = this.mockdataTimeline;
    this.selectedRow = this.mockdataTimeline[0];
  }

  ngOnDestroy() {
    sessionStorage.removeItem("interviewId");
  }

  calculateMargin(ans: any, idx: any) {
    if (ans.length > 0) {
      return (ans[0].answer / 5) * 100 - 20 + "%";
    } else {
      return 0;
    }
  }

  getAnswer(ans: any) {
    // console.log("solid: ", ans[0].answer);
    return ans.length;
  }
}
