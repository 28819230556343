import { DatePipe } from "@angular/common";
import { HttpClient } from "@angular/common/http";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { DatabaseService } from "@app/database/database.service";
import { OnboardingService } from "@app/onboarding/onboarding.service";
import { ConfirmationDialogComponent } from "@app/shared/components/confirmation-dialog/confirmation-dialog.component";
import { SurveyService } from "@app/survey/survey.service";
import { TemplateAddComponent } from "@app/workflow-template/template-add/template-add.component";
import { WorkflowTemplateService } from "@app/workflow-template/workflow-template.service";
import { BuildFromDatabaseAvaComponent } from "../build-from-database-ava/build-from-database-ava.component";
import { MatTabGroup } from "@angular/material/tabs";
import { QUESTION_STATES_ObJ } from "@app/resources/question-state";
import { Helper } from "@app/core/classes/helper";
import { getLensMap, getDefinedMap } from "@app/projects-v2/util/util";

@Component({
  selector: "app-setup",
  templateUrl: "./setup.component.html",
  styleUrls: ["./setup.component.scss"],
})
export class SetupComponent implements OnInit {
  surveyData: any = null;
  surveyDetail: any;
  surveySetupForm: FormGroup<any>;
  is_surveyCreated: boolean = false;
  editSurveyStarted: boolean = false;
  selectedOwners: any = [];
  teamsName: any;
  selectedTab:any;

  clientId: string;

  dataSource_ava: any = [];
  dataSource_eva: any = [];
  dataSource_survey: any = [];
  displayedColumns_ava: string[] = [
    "activity",
    "type",
    "timeCategory",
    "action",
  ];
  displayedColumns_eva: string[] = [
    "category",
    "sub-category",
    "lens",
    "question",
    "action",
  ];
  displayedColumns_survey: string[] = [
    "name",
    "owner",
    "start",
    "end",
    "action",
  ];

  searchModule = new FormControl("");
  AvaTemplateList: any = [];
  AvaDefaultTemplate: any = [];
  moduleList: any = [];
  moduleListEva: any = [];

  dialog: any;
  selectedTemplateAva: any = {};
  isDefaultTemplateAva: boolean = true;
  selectedTemplateEva: any = {};
  isDefaultTemplateEva: boolean = true;

  //EVA
  evaTemplateList: any = [];
  evaDefaultTemplate: any = [];
  searchModule_eva = new FormControl("");

  //search AVA
  filtered: any;
  isLoading = false;
  errorMsg!: string;
  minLengthTerm = 3;
  selected: any = "";
  organisationData: any;
  avaTemplateIndex: number = 0;
  evaTemplateIndex: number = 0;

  todayDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  endMinDate = this.datePipe.transform(new Date(), "yyyy-MM-dd");
  endDate: any;
  surveyId: any;
  isSurveyCreated: boolean = false;
  activityShow: boolean = true;
  selectedRow: any | null = null;
  type: any = "create";
  editActivityID: any;
  clientFunctions: any;
  teamSelected = new FormControl("", Validators.required);
  getAct: any = [];
  selectedActivity: any;
  surveyDetailList: any = new Set();
  isSelect: boolean;
  url:any;

  @ViewChild("tabGroup") tabGroup: MatTabGroup;

  constructor(
    private surveyService: SurveyService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private matDialog: MatDialog,
    private router: Router,
    private workflowTemplateService: WorkflowTemplateService,
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private http: HttpClient,
    private databaseService: DatabaseService
  ) {}

  ngOnInit(): void {
    //URL CHECK
    let urlset = this.router.url;
    console.log(urlset);
    let split: any = urlset.split("/");
    this.url = split.at(-1);
    console.log("answer", this.url );

    //surveysetupform
    this.surveySetupForm = this.fb.group({
      startDate: new FormControl("", Validators.required),
      endDate: new FormControl("", Validators.required),
      surveyName: new FormControl("", Validators.required),
      owner: new FormControl("", Validators.required),
    });

    this.clientId = sessionStorage.getItem("clientId") || "";
    console.log(
      "------- - - - this.clientId Setup - - - - ----------",
      this.clientId
    );
    this.getAVATemplates();
    this.getEVATemplates();
    // this.getOrganisationByClientId();
    this.getTeams();
    this.getowner();
    this.getSurvey();
    this.getSurveyData();

    this.getActivity();
    this.getTeamSelection();

    //Set saved/cache data
    if(this.url  === 'setup-II'){
      //this.selectedActivity = [] 
      let selectRow = sessionStorage.getItem("Row") || "";
      let selections = sessionStorage.getItem("Selections") || "";
      let payload = sessionStorage.getItem("payload") || "";

      let retrieveRow :any = JSON.parse(selectRow);
      let retrievedSelec: any = JSON.parse(selections);
      let retrvpl :any = JSON.parse(payload)

      this.selectedRow = retrieveRow;
      this.isSelect = true;
      this.selectedActivity = retrievedSelec;
      this.getAct = retrvpl

      this.goTo('ava');
      console.log(this.selectedActivity,this.selectedRow,this.getAct);
    }
    
  }

  clear(){
    this.selectedActivity = []; // empty selection
  }

  back(){
    sessionStorage.removeItem('TemplateAva')
    sessionStorage.removeItem('TemplateEva')
    sessionStorage.removeItem('Selections')
    sessionStorage.removeItem('Row') //clear caches

    this.teamSelected.reset();
    this.activityShow = true;
    this.router.navigate([`/project/${this.clientId}/ava-eva/setup`])
  }

  getTeamSelection() {
    this.workflowTemplateService.getClientFunctions(this.clientId).subscribe(
      (response) => {
        console.log("---- CF ----", response);
        this.clientFunctions = response;
      },
      (error) => {
        console.log("---- Error ----", error);
      }
    );
  }

  //SELECTION
  // Function to handle row selection
  selectRow(row: Element) {
    this.isSelect = true;
    this.selectedRow = this.selectedRow === row ? null : row;
    console.log(this.selectedRow);
    this.surveyDetailList.forEach((ele: any) => {
      console.log(this.selectedRow._id, ele.activityId, "!!!!!!");

      if (this.selectedRow._id === ele.activityId) {
        this.snackBar.open("Kindly select a new survey", "close");
        this.isSelect = false;
        this.cdr.markForCheck();
      }
    });
  }

  change(index: any) {
    this.selectedTab = index;
    if (index === 0) {
      this.getAVATemplates();
    } else {
      this.getEVATemplates();
    }
    this.cdr.markForCheck();
  }

  //Activity
  getActivity() {
    this.workflowTemplateService.getActivity(this.clientId).subscribe(
      (response) => {
        console.log("---- Res ----", response);
        this.dataSource_survey = response;
      },
      (error) => {
        console.log("---- Error ----", error);
      }
    );
  }

  clearForm() {
    this.type = "create";
    this.surveySetupForm.reset();
  }

  createActivity(type: any) {
    this.type = type;
    let payload = { ...this.surveySetupForm.value };
    payload["clientId"] = this.clientId;
    const { emptyFields } = Helper.getEmptyFields(this.surveySetupForm);

    this.surveySetupForm.markAllAsTouched();
    console.log('--', this.surveySetupForm, this.surveySetupForm.valid, emptyFields.length);
    
    if (!this.surveySetupForm.valid || emptyFields.length) {
      this.snackBar.open("Kindly fill all the necessary fields", "close");
    } 
    else{
      
      if (this.type === "create") {
        console.log(payload);
        payload.status = "Created";

        this.workflowTemplateService.createActivity(payload).subscribe(
          (response) => {
            console.log("---- Res ----", response);
            this.snackBar.open("Created Successfully", "close");
            this.surveySetupForm.reset();
            this.getActivity();
          },
          (error) => {
            console.log("---- Error ----", error);

            if (error.error.message) {
              this.snackBar.open(error.error.message, "close", {
                duration: 3500,
                horizontalPosition: "end",
                verticalPosition: "top",
              });
            } else {
              console.log(`Create survey Error: ${error.error.message}`);
            }
          }
        );
      } else{
        // this.editActivity(this.editActivityID, payload);
      } 
    }
  }

  editActivity() {
    // this.type = 'create';
    let payloads = { ...this.surveySetupForm.value };
    payloads["clientId"] = this.clientId;
    this.workflowTemplateService.updateActivity(this.editActivityID, payloads).subscribe(
      (response) => {
        console.log("---- Res ----", response);
        this.snackBar.open("Updated Successfully", "close");
        this.surveySetupForm.reset();
        this.type = "create";
        this.getActivity();
      },
      (error) => {
        console.log("---- Error ----", error);

        if (error.error.message) {
          this.snackBar.open(error.error.message, "close", {
            duration: 3500,
            horizontalPosition: "end",
            verticalPosition: "top",
          });
        } else {
          console.log(`Update survey Error: ${error.error.message}`);
        }
      }
    );
  }

  cancel() {
    this.type = "create";
    this.surveySetupForm.reset();
  }

  patchActivity(data: any) {
    let startDate = new Date(data?.startDate);
    startDate.setMinutes(startDate.getMinutes());
    let endDate = new Date(data?.endDate);
    endDate.setMinutes(endDate.getMinutes());
    this.surveySetupForm.patchValue({
      surveyName: data.surveyName,
      startDate: startDate.toISOString().split("T")[0],
      endDate: endDate.toISOString().split("T")[0],
      owner: data.owner,
    });

    this.editActivityID = data._id;
    this.type = "update";
  }

  comparer(o1: any, o2: any): any {
    // if possible compare by object's name, and not by reference.
    return o1 && o2 ? o1.teams[0].teamName === o2.teams[0].teamName : o2 === o2;
  }

  setTeamstoActivity(data: any) {
    console.log("---- setTeamstoActivity ----", data);
    // data.owner.teams[0].teamName
    console.log(this.teamSelected);
    this.selectedActivity = data.value
    
    this.getAct = [];

    this.clientFunctions.forEach((ele: any) => {
      data.value.forEach((res: any) => {
        console.log(res);
        
        if (res.teams[0].teamName === ele.teams[0].teamName) {
          this.getAct.push({ function: ele.functionName, teamName: res.teams[0].teamName });
        }
      });
    });
    console.log(this.getAct,this.selectedActivity);
  }

  deleteActivity(data: any) {
    console.log(data);
    this.workflowTemplateService.deleteActivity(data._id).subscribe(
      (res) => {
        this.snackBar.open("Activity deleted");
        this.getActivity();
      },
      (error) => {
        if (error.error.message) {
          this.snackBar.open(error.error.message, "close", {
            duration: 5000,
            horizontalPosition: "end",
            verticalPosition: "top",
          });
        } else {
          this.snackBar.open("Error Deleting Activity");
        }
      }
    );
  }

  //search ava

  onSelected() {
    //console.log(this.selected.question);
    this.selected = this.selected.question;
  }

  displayWith(value: any) {
    return value?.Title;
  }

  clearSelection() {
    this.selected = "";
    this.filtered = [];
  }

  questionRecall(module: any) {
    let id: string;
    console.log("Module:::", this.selectedTemplateAva);
    if (module == "AVA") {
      id = this.selectedTemplateAva["_id"];
    } else {
      id = this.selectedTemplateEva["_id"];
    }

    this.workflowTemplateService
      .getTemplateByQuery_DB("id", id, module)
      .subscribe((response) => {
        this.dataSource_ava = response.data[0]["questions"];
        if (module == "AVA")
          this.dataSource_ava = response.data[0]["questions"];
        else this.dataSource_eva = response.data[0]["questions"];
        this.cdr.markForCheck();
      });
  }

  goTo(module: any) {
    if(!this.isSelect) {
      this.snackBar.open("Kindly select a new survey", "close");
      return;
    }

    if (this.selectedRow === null || this.selectedRow === "") {
      this.snackBar.open("Kindly select the survey in the table", "close");
    } else {
      this.activityShow = false;
      if (module == "ava") {
        this.change(0);
      } else {
        this.change(1);
      }
      console.log(this.selectedRow);
    }
  }

  getTeams() {
    this.surveyService.getFocusGroupTeams("", this.clientId).subscribe(
      async (res) => {
        console.log(res);
        this.teamsName = await res.map((team: any) => team.customName);
        this.teamsName = this.teamsName.toString();

        this.cdr.markForCheck();
        this.getAVATemplates();
        this.getEVATemplates();
      },
      (error) => {
        console.log("--Error --", error);
      }
    );
  }

  getowner() {
    this.surveyService.getSelectedOwner(this.clientId).subscribe({
      next: (res: any) => {
        this.selectedOwners = res[0]["authors"];
        console.log("getowner", this.selectedOwners);
      },
      error: (error: any) => {
        console.log("getowner error", error);
      },
    });
  }

  setSurveySetup(surveyData: any) {
    let startDate = new Date(surveyData?.startDate);
    startDate.setMinutes(startDate.getMinutes());
    let endDate = new Date(surveyData?.endDate);
    endDate.setMinutes(endDate.getMinutes());

    this.surveySetupForm.patchValue({
      startdate: startDate.toISOString().split("T")[0],
      enddate: endDate.toISOString().split("T")[0],
      // owner: this.selectedOwners[0],
    });
    this.cdr.markForCheck();
    console.log("surveySetupForm", this.surveySetupForm);
  }

  dateTransform(dateReceived: any) {
    const date = new Date(dateReceived);
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
    const convertedDate = this.datePipe.transform(date, "dd-MM-yyyy ");
    return convertedDate;
  }

  editSurvey() {
    this.editSurveyStarted = true;
    this.cdr.markForCheck();
    console.log("editsurvey called", this.editSurveyStarted);
  }
  saveSurveyEdit() {
    this.surveySetupForm.markAllAsTouched();
    if (this.surveySetupForm.valid) {
      this.editSurveyStarted = false;
      console.log("survey edit values for save", this.surveySetupForm.value);
      this.cdr.markForCheck();
    }
  }

  editQuestion(element: any, index: number, module: string) {
    let isDefaultTemp: boolean = true;
    console.log("element", element);

    if (module == "ava-template") {
      isDefaultTemp =
        this.selectedTemplateAva.templateName == "default" ? true : false;
      element.selectedTemp = this.selectedTemplateAva.templateName;
    } else {
      isDefaultTemp =
        this.selectedTemplateEva.templateName == "default" ? true : false;
      element.selectedTemp = this.selectedTemplateEva.templateName;
    }

    this.dialog = this.matDialog.open(TemplateAddComponent, {
      width: "65em",
      // height: '450px',
      disableClose: false,
      data: {
        edit_qus: true,
        from: module,
        isDefaultTemplate: isDefaultTemp,
        templateDetail: element,
      },
    });
    this.dialog.afterClosed().subscribe((submit: any) => {
      console.log("edit submit", submit);
      if (submit.event == "editsubmitAVA") {
        console.log("data", submit.data, element);

        /*  */
        let templateDetail = this.selectedTemplateAva;
        if (!element.isDefault) {
          let payload: any = {
            question: submit.data.activity,
            type: submit.data.type,
            category: submit.data.category,
            module: "AVA",
            oldState: "APPROVED",
            newState: "SUBMITTED",
            questionType: "PERCENTAGE",
            min: 0,
            max: 0,
          };

          console.log("---- payload question ----", payload);
          this.updateDatabaseQuestion(element._id, payload, "AVA");
        } else {
          let questions: any = {
            question: submit.data.activity,
            type: submit.data.type,
            category: submit.data.category,
            module: "AVA",
            questionState: "SUBMITTED",
            questionType: "PERCENTAGE",
            min: 0,
            max: 0,
            isDefault: element.isDefault,
            _id: element._id,
          };
          let payload = {
            templateName: templateDetail?.templateName,
            module: templateDetail.module,
            questions: [questions],
          };
          console.log("---- payload template ----", payload);
          this.updateQuestion(templateDetail._id, payload, "edit");
        }
        /*  */

        // let modifiedQus: any = {
        //   question: submit.data?.activity,
        //   category: submit.data?.category,
        //   type: submit.data?.type
        // }

        // let payload:any = {
        //   question : submit.data.activity,
        //   type : submit.data.type,
        //   category : submit.data.category,
        //   module:  'AVA',
        //   oldState : "APPROVED",
        //   newState : "SUBMITTED",
        //   questionType: "PERCENTAGE",
        //   min : 0,
        //   max : 0

        // }
        // console.log('edit payload for update ',payload, element._id)
        // this.updateDatabaseQuestion( element._id, payload,'AVA')
      } else if (submit.event == "editsubmitEVA") {
        console.log("data", submit.data);

        /*  */
        let templateDetail = this.selectedTemplateEva;
        if (!element.isDefault) {
          let payload: any = {
            question: submit.data.question,
            lens: submit.data.lens,
            category: submit.data.category.category,
            subCategory: submit.data.subCategory,
            module: "EVA",
            oldState: "APPROVED",
            newState: "SUBMITTED",
            questionType: "RATING",
            min: 1,
            max: 5,
          };

          console.log("---- payload question ----", payload);
          this.updateDatabaseQuestion(element._id, payload, "EVA");
        } else {
          let questions: any = {
            question: submit.data.question,
            lens: submit.data.lens,
            category: submit.data.category.category,
            subCategory: submit.data.csubCategory,
            module: "EVA",
            questionState: "SUBMITTED",
            questionType: "RATING",
            min: 1,
            max: 5,
            isDefault: element.isDefault,
            _id: element._id,
          };
          let payload = {
            templateName: templateDetail?.templateName,
            module: templateDetail.module,
            questions: [questions],
          };
          console.log("---- payload template ----", payload);
          this.updateQuestion(templateDetail._id, payload, "edit");
        }
        /*  */

        // let payload :any = {
        //   question : submit.data.question,
        //   lens : submit.data.lens,
        //   category : submit.data.category.category,
        //   subCategory : submit.data.csubCategory,
        //   module:  'EVA',
        //   oldState : "APPROVED",
        //   newState : "SUBMITTED",
        //   questionType: "RATING",
        //   min : 1,
        //   max : 5
        // }
        // this.updateDatabaseQuestion( element._id, payload,'EVA')
      }
    });
  }

  updateDatabaseQuestion(questionId: string, payload: object, module: string) {
    this.databaseService.editRefData(questionId, payload).subscribe({
      next: (res: any) => {
        console.log("db question updated", res);
        this.snackBar.open("Question Sent For Approval Successfully", "close");
        this.questionRecall(module);
      },
      error: (error: any) => {},
    });
  }

  buildFromDb(module: string) {
    let QuestionsData: any;
    if (module == "AVA") QuestionsData = this.dataSource_ava;
    if (module == "EVA") QuestionsData = this.dataSource_eva;
    this.databaseService.buildFromDb_module = module;
    let dialogRef = this.matDialog.open(BuildFromDatabaseAvaComponent, {
      width: "75em",
      height: "80vh",
      disableClose: false,
      data: {
        type: module,
        questionsData: QuestionsData,
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!!res) {
        if (res.event == "loadQuestion") {
          let templateId: string =
            module == "AVA"
              ? this.selectedTemplateAva._id
              : this.selectedTemplateEva._id;
          let templateName: string =
            module == "AVA"
              ? this.selectedTemplateAva.templateName
              : this.selectedTemplateEva.templateName;
          let payload: Object = {
            templateName: templateName,
            module: module,
            questions: [...res.data],
          };
          console.log("load question payload", payload);

          this.workflowTemplateService
            .updateTemplate(templateId, payload)
            .subscribe((updateTemplateRes) => {
              this.snackBar.open("Question loaded Successfully", "close");
              this.questionRecall(module);
              this.cdr.markForCheck();
            });
        }
      }
    });
  }

  updateQuestion(tempId: any, payload: any, useCase: string) {
    console.log("payload", payload, tempId);
    this.workflowTemplateService.updateTemplate(tempId, payload).subscribe({
      next: (res: any) => {
        console.log("edit res", res);
        if (useCase == "edit")
          this.snackBar.open("Question Edited Successfully", "Close");
        else if (useCase == "delete")
          this.snackBar.open("Question Deleted Successfully", "Close");
        else if (useCase == "add")
          this.snackBar.open("Question Added Successfully", "Close");

        this.questionRecall(payload["module"]);
      },
      error: (error: any) => {
        console.log("question update error", error);
      },
    });
  }

  onRemoveQuestionAva(selectedQuestion: any) {
    let payload = {
      question: selectedQuestion["question"],
      questionType: selectedQuestion["questionType"],
      category: selectedQuestion["category"],
      module: selectedQuestion["module"],
      isActive: false,
      oldState: "APPROVED",
      newState: "REJECTED",
    };

    this.databaseService
      .editRefData(selectedQuestion["_id"], payload)
      .subscribe({
        next: (res: any) => {
          this.snackBar.open("Removed Successfully", "close", {
            duration: 4500,
            horizontalPosition: "center",
            verticalPosition: "top",
          });

          this.workflowTemplateService
            .getTemplateByQuery_DB("id", this.selectedTemplateAva["_id"], "AVA")
            .subscribe((response) => {
              this.dataSource_ava = response.data[0]["questions"];
              this.isDefaultTemplateAva =
                this.selectedTemplateAva.templateName == "default"
                  ? true
                  : false;
              this.cdr.markForCheck();
            });
        },
        error: (error: any) => {
          console.log("delete error ", error);
        },
      });
  }

  getSurvey() {
    let surveyQuery: object = { clientId: this.clientId, module: "ava-eva" };
    this.surveyService.getSurveyByQuery(surveyQuery).subscribe(
      (surveyResponse) => {
        this.is_surveyCreated =
          surveyResponse["data"]?.length > 0 ? true : false;
        console.log(
          "--- surveyResponse ---",
          "surevyData",
          surveyResponse,
          this.is_surveyCreated
        );

        if (this.is_surveyCreated) {
          this.surveyData = surveyResponse["data"][0];
          this.setSurveySetup(this.surveyData);
        }
        this.cdr.markForCheck();
      },
      (error) => {
        console.log("Error while getting survey data: ", error);
      }
    );
  }

  openDialogAva() {
    this.dialog = this.matDialog.open(TemplateAddComponent, {
      width: "65em",
      // height: '450px',
      disableClose: false,
      data: {
        from: "ava-template",
        isDefaultTemplate: this.isDefaultTemplateAva,
        templateDetail: this.selectedTemplateAva,
      },
    });

    this.dialog.afterClosed().subscribe((submit: any) => {
      this.getAVATemplates(true);

      if (!this.isDefaultTemplateAva) {
        this.workflowTemplateService
          .getTemplateByQuery_DB("id", this.selectedTemplateAva["_id"], "AVA")
          .subscribe((response) => {
            this.dataSource_ava = response.data[0]["questions"];
            this.isDefaultTemplateAva =
              this.selectedTemplateAva.templateName == "default" ? true : false;
            this.cdr.markForCheck();
          });
      }
    });
  }

  public getAVATemplates(isRerender: boolean = false) {
    this.workflowTemplateService.getTemplateByQuery("module", "AVA").subscribe(
      (response) => {
        this.AvaTemplateList = response.data;

        if (!isRerender) {
          if (!this.AvaDefaultTemplate) {
            this.avaTemplateIndex = this.AvaTemplateList.findIndex(
              (template: any) => template.templateName === "default"
            );
          }
          this.AvaDefaultTemplate = this.AvaTemplateList[this.avaTemplateIndex];
          this.selectedTemplateAva = this.AvaDefaultTemplate;
          this.isDefaultTemplateAva =
            this.selectedTemplateAva.templateName == "default" ? true : false;
          this.dataSource_ava = this.AvaDefaultTemplate["questions"];
        }
        console.log(this.AvaDefaultTemplate);

        //Set saved/cache data
        if(this.url  === 'setup-II'){
          
          let templateAva = sessionStorage.getItem("TemplateAva") || "";
          let retrievedTempAva: any = JSON.parse(templateAva);
          this.AvaDefaultTemplate = retrievedTempAva;
          this.selectedTemplateAva = retrievedTempAva;
          let obj = {value:this.AvaDefaultTemplate.templateName}
          this.onTemplateSelectAva(obj)
          console.log(this.AvaDefaultTemplate);
          
        }      
        
        this.cdr.markForCheck();
      },
      (error) => {
        console.log("---- Get all ava template Error ----", error);
      }
    );
  }

  onTemplateSelectAva(selection: any) {
    this.avaTemplateIndex = this.AvaTemplateList.findIndex(
      (template: any) => template.templateName === selection.value
    );
    this.selectedTemplateAva = this.AvaTemplateList[this.avaTemplateIndex];
    this.isDefaultTemplateAva =
      this.selectedTemplateAva.templateName == "default" ? true : false;
    this.dataSource_ava = this.selectedTemplateAva["questions"];

    console.log(this.selectedTemplateAva);
    this.cdr.markForCheck();
  }

  public addSurveyc() {
    console.log(this.teamSelected.value);
  }

  setCache(){
    const row = JSON.stringify(this.selectedRow);
    sessionStorage.setItem("Row",row)

    const cache = JSON.stringify(this.selectedTemplateAva);
    sessionStorage.setItem("TemplateAva", cache);

    const cache2 = JSON.stringify(this.selectedTemplateEva);
    sessionStorage.setItem("TemplateEva", cache2);

    const cache3 = JSON.stringify(this.selectedActivity);
    sessionStorage.setItem("Selections", cache3);

    const payload = JSON.stringify(this.getAct);
    sessionStorage.setItem("payload", payload);
  }

  public addSurvey() {
    // if(this.is_surveyCreated){
    //     this.snackBar.open('Survey Already Created', 'Close')
    // }else{
    //this.surveySetupForm.markAllAsTouched();
    this.teamSelected.markAsTouched();

    if (
      this.selectedRow != null &&
      this.teamSelected.valid &&
      this.getAct.length > 0
    ) {
      // if(this.editSurveyStarted){
      //    this.snackBar.open('Please Complete the Edit Survey Before Preview', 'close')
      //    return
      //  }

      this.setCache(); //save prev-data

      sessionStorage.setItem("avaTemplateId", this.selectedTemplateAva["_id"]);
      sessionStorage.setItem("evaTemplateId", this.selectedTemplateEva["_id"]);

      this.getAct.forEach((ele: any) => {
        this.selectedRow?.teams.push(ele);
      });

      this.selectedRow["avaTemplateId"] = this.selectedTemplateAva["_id"];
      this.selectedRow["evaTemplateId"] = this.selectedTemplateEva["_id"];

      let startDate = new Date(this.selectedRow?.startDate);
      startDate.setMinutes(startDate.getMinutes());
      let endDate = new Date(this.selectedRow?.endDate);
      endDate.setMinutes(endDate.getMinutes());

      this.selectedRow.startDate = startDate.toISOString().split("T")[0];
      this.selectedRow.endDate = endDate.toISOString().split("T")[0];

      console.log("survey payload ", this.selectedRow);
      const jsonString = JSON.stringify(this.selectedRow);
      localStorage.setItem("Survey", jsonString);
      this.router.navigate([`/project/${this.clientId}/add-survey`], {
        queryParams: this.selectedRow,
      });
    } else {
      this.snackBar.open("Kindly fill all the necessary fields", "close");
      return;
    }

    console.log("--------- this.clientId ---------", this.clientId);

    // this.router.navigate([`/project/${this.clientId}/add-survey`]);
    //  }
  }

  //EVA

  openDialogEva() {
    // this.isDefaultTemplate = this.selectedTemplate.templateName == 'default' ? true : false;
    this.dialog = this.matDialog.open(TemplateAddComponent, {
      width: "65em",
      // height: '450px',
      disableClose: false,
      data: {
        from: "eva-template",
        isDefaultTemplate: this.isDefaultTemplateEva,
        templateDetail: this.selectedTemplateEva,
      },
    });

    this.dialog.afterClosed().subscribe((submit: any) => {
      this.getEVATemplates(true);

      if (!this.isDefaultTemplateEva) {
        this.workflowTemplateService
          .getTemplateByQuery_DB("id", this.selectedTemplateEva["_id"], "EVA")
          .subscribe((response) => {
            this.dataSource_eva = response.data[0]["questions"];
            this.isDefaultTemplateEva =
              this.selectedTemplateEva.templateName == "default" ? true : false;
            this.cdr.markForCheck();
          });
      }
    });
  }  

  opMaturityMapData:any = [];
  public opMaturityMapping(){
    this.databaseService.getOpMaturyMapping(this.clientId).subscribe((res) => {
      console.log(res,'Data res<>');
      this.opMaturityMapData = res; //DATA
    })
    return this.opMaturityMapData;
  }

  public getEVATemplates(isRerender: boolean = false) {
    this.workflowTemplateService.getTemplateByQuery("module", "EVA").subscribe(
      (response) => {
        console.log("---- Get all eva template list ----", response);
        this.evaTemplateList = response.data;
        //Mapping
        if (this.opMaturityMapping().length > 0) {
        let changedTemplates = [];

        for(let template of this.evaTemplateList) {
          let changedData : any = {...template}
          changedData.questions=[]
          for(let question of template.questions) {
            let changedQuestion : any = {...question}
            let modifiedMap = getDefinedMap(this.opMaturityMapData,{category: question.category , subCategory: question.subCategory, lens : question.lens})
             changedQuestion.category = modifiedMap.category
             changedQuestion.subCategory = modifiedMap.subCategory
             changedQuestion.lens = modifiedMap.lens
            
             changedData.questions.push(changedQuestion);

          }
          changedTemplates.push(changedData)
        }

        this.evaTemplateList = []
        this.evaTemplateList = [...changedTemplates]
       }
        console.log(this.evaTemplateList)
        //
        if (!isRerender) {
          if (!this.evaDefaultTemplate) {
            this.evaTemplateIndex = this.evaTemplateList.findIndex(
              (template: any) => template.templateName === "default"
            );
          }

          this.evaDefaultTemplate = this.evaTemplateList[this.evaTemplateIndex];
          this.selectedTemplateEva = this.evaDefaultTemplate;
          this.isDefaultTemplateEva =
            this.selectedTemplateEva.templateName == "default" ? true : false;
          this.dataSource_eva = this.evaDefaultTemplate["questions"];

          //Set saved/cache data
          if(this.url  === 'setup-II'){
          
            let templateEva = sessionStorage.getItem("TemplateEva") || "";
            let retrievedTempEva: any = JSON.parse(templateEva);
            this.evaDefaultTemplate = retrievedTempEva;
            this.selectedTemplateEva = retrievedTempEva;
            let obj = {value:this.evaDefaultTemplate.templateName}
            this.onTemplateSelectEva(obj)
            console.log(this.evaDefaultTemplate);
            
          }

          this.cdr.markForCheck();
          /* TODO: Below line holds the EVA templateId to create survey.
            This component need to render before the EVA toggle switched, unless we can't get the EVA templateId 
        */
        }
      },
      (error) => {
        console.log("---- Get all eva template Error ----", error);
      }
    );
  }

  deletePreview(element: any, index: any, module: string) {
    if (element.isDefault) {
      this.deletePreview2(element, index, module);
    } else {
      this.deletePreview1(element, module);
    }
  }

  deletePreview1(element: any, module: any) {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: "40%",
      height: "auto",
      disableClose: false,
      data: {
        message: "Are you sure want to Deactivate the Question ?",
        buttonText: {
          ok: "Delete",
          cancel: "Cancel",
        },
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      console.log("res", res, element);
      if (res) {
        let payload = { ...element };
        delete payload._id;
        delete payload.state;
        delete payload.updatedUser;
        delete payload.__v;
        delete payload.createdAt;
        delete payload.updatedAt;
        payload.module = module;
        payload.isActive = false;
        payload.oldState = QUESTION_STATES_ObJ["APPROVED"];
        payload.newState = QUESTION_STATES_ObJ["SUBMITTED"];

        this.databaseService.editRefData(element._id, payload).subscribe({
          next: (res: any) => {
            if (module == "AVA") {
              this.getAVATemplates();
              this.getAVATemplates();
              this.cdr.markForCheck();
            } else {
              this.getEVATemplates();
              this.getEVATemplates();
              this.cdr.markForCheck();
            }
          },
          error: (error: any) => {
            console.log(error);
          },
        });
      }
    });
  }

  deletePreview2(element: any, index: any, module: string) {
    const dialogRef = this.matDialog.open(ConfirmationDialogComponent, {
      width: "40%",
      height: "auto",
      disableClose: false,
      data: {
        message: "Are you sure want to Delete the Question ?",
        buttonText: {
          ok: "Delete",
          cancel: "Cancel",
        },
      },
    });

    dialogRef.afterClosed().subscribe((res: any) => {
      //For isDefault payload
      let templateUpdatePayloadAva = {
        templateName: this.selectedTemplateAva.templateName,
        module: module,
        questions: [],
        removeList: [
          { questionId: element["_id"], isDefault: element["isDefault"] },
        ],
      };
      let templateUpdatePayloadEva = {
        templateName: this.selectedTemplateEva.templateName,
        module: module,
        questions: [],
        removeList: [
          { questionId: element["_id"], isDefault: element["isDefault"] },
        ],
      };

      if (!!res) {
        if (module == "AVA") {
          if (element.isDefault) {
            this.updateQuestion(
              this.selectedTemplateAva._id,
              templateUpdatePayloadAva,
              "delete"
            );
          } else {
            this.onRemoveQuestionAva(element);
          }
          /* Will use debounce to solve multiple call ava data. */
          this.getAVATemplates();
          this.getAVATemplates();
          this.cdr.markForCheck();
        } else {
          if (element.isDefault) {
            this.updateQuestion(
              this.selectedTemplateEva._id,
              templateUpdatePayloadEva,
              "delete"
            );
          } else {
            this.onRemoveQuestionEva(element);
          }
          /* Will use debounce to solve multiple call eva data. */
          this.getEVATemplates();
          this.getEVATemplates();
          this.cdr.markForCheck();
        }
      }
    });
  }

  onRemoveQuestionEva(selectedQuestion: any) {
    console.log("check---- ", selectedQuestion);
    let payload = {
      question: selectedQuestion["question"],
      questionType: selectedQuestion["questionType"],
      category: selectedQuestion["category"],
      module: selectedQuestion["module"],
      isActive: false,
      min: 1,
      max: 5,
      oldState: "APPROVED",
      newState: "REJECTED",
    };

    this.databaseService
      .editRefData(selectedQuestion["_id"], payload)
      .subscribe({
        next: (res: any) => {
          this.snackBar.open("Removed Successfully", "close", {
            duration: 4500,
            horizontalPosition: "center",
            verticalPosition: "top",
          });

          this.workflowTemplateService
            .getTemplateByQuery_DB("id", this.selectedTemplateAva["_id"], "AVA")
            .subscribe((response) => {
              this.dataSource_ava = response.data[0]["questions"];
              this.cdr.markForCheck();
            });

          this.workflowTemplateService
            .getTemplateByQuery_DB("id", this.selectedTemplateEva["_id"], "EVA")
            .subscribe((response) => {
              this.dataSource_eva = response.data[0]["questions"];
              this.cdr.markForCheck();
            });
        },
        error: (error: any) => {
          console.log("delete error ", error);
        },
      });
  }

  onTemplateSelectEva(selection: any) {
    this.evaTemplateIndex = this.evaTemplateList.findIndex(
      (template: any) => template.templateName === selection.value
    );
    this.selectedTemplateEva = this.evaTemplateList[this.evaTemplateIndex];
    this.isDefaultTemplateEva =
      this.selectedTemplateEva.templateName == "default" ? true : false;
    this.dataSource_eva = this.selectedTemplateEva["questions"];

    /* TODO: Below line holds the selected EVA templateId to create survey.
        This component need to render before the EVA toggle switched, unless we can't get the EVA templateId 
    */
    // sessionStorage.setItem('evaTemplateId', this.selectedTemplateEva['_id']);
  }

  getSurveyData() {
    let surveyQuery: object = { clientId: this.clientId, module: "ava-eva" };
    this.surveyService.getSurveyByQuery(surveyQuery).subscribe(
      (surveyResponse) => {
        console.log("--- surveyResponse calling ---", surveyResponse);

        if (surveyResponse && surveyResponse["data"].length) {
          this.surveyData = surveyResponse["data"];
          this.surveyData.forEach((data: any) => {
            //this.surveyId = data._id;
            this.getSurveyDetails(data._id);
          });
        }
      },
      (error) => {
        console.log("Error while getting survey data: ", error);
      }
    );
  }

  getSurveyDetails(surveyId: any) {
    this.surveyService
      .getSurveyDetails("surveyId", surveyId, this.clientId)
      .subscribe(
        (surveyDetails) => {
          this.surveyDetailList.add(surveyDetails[0]);
          this.cdr.markForCheck();
          console.log(
            "-------- *****Get surveyDetailsList**** -----------",
            this.surveyDetailList
          );
          // surveyDetails.forEach((surveyDetail: any) => {
          //   this.getSurveyResponseCount(surveyDetail);
          // });
          // this.surveyDetailList = surveyDetails;
          // surveyDetails.forEach((surveyDetail: any) => {
          //   this.getSurveyResponseCount(surveyDetail);
          // });
        },
        (error) => {
          console.log("-------- surveyDetails Error -----------", error);
        }
      );
  }

  handleClick(event: any) {
    this.endMinDate = this.datePipe.transform(
      event.value ? event.value : new Date(),
      "yyyy-MM-dd"
    );
    console.log(this.endMinDate);
  }
}
