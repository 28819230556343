import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewEncapsulation,
} from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute, Router } from "@angular/router";
import { OnboardingService } from "@app/onboarding/onboarding.service";

export interface PeriodicElement {
  position: number;
  module: string;
  description: string;
}

@Component({
  selector: "app-modules",
  templateUrl: "./modules.component.html",
  styleUrls: ["./modules.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class ModulesComponent implements OnInit {
  descModified: boolean = false;
  clients: any;
  author: any;
  constructor(
    private onboardingService: OnboardingService,
    private route: ActivatedRoute,
    public router: Router,
    private snackBar: MatSnackBar,
    private cdr: ChangeDetectorRef
  ) {}

  isSetupModules: boolean = true;
  activeValueAnalyser: boolean = true;
  teamLeader: boolean = true;
  performanceVariation: boolean = false;
  governanceAndRoadmaps: boolean = false;
  opMaturityAssessment: boolean = false;
  benchmarking: boolean = false;
  processMapping: boolean = false;
  kpiAdherence: boolean = false;
  automationAssessment: boolean = false;

  moduleContents: any = [];

  displayedColumns: string[] = ["position", "module", "description"];
  modules_data_source: any;
  clickedRows = new Set<PeriodicElement>();

  modules_details_form: any = new FormGroup({
    teamActivities: new FormControl(null, Validators.required),
    allocation1: new FormControl(null, Validators.required),

    question: new FormControl(null, Validators.required),
    inputType1: new FormControl(null, Validators.required),

    managerActivities: new FormControl(null, Validators.required),
    allocation2: new FormControl(null, Validators.required),

    managerOnlyQuestions: new FormControl(null, Validators.required),
    inputType2: new FormControl(null, Validators.required),
  });

  selectedValue: string;
  clientModulesData: any;
  selectedCar: string;
  clientSelectedModulesData: any;
  selectedModules: any[] = [];
  isEditClient: boolean = false;
  isViewClient: boolean = false;

  allocations1: any[] = [
    { value: "team activitis 1", viewValue: "team activitis 1" },
    { value: "team activitis 2", viewValue: "team activitis 2" },
    { value: "team activitis 3", viewValue: "team activitis 3" },
  ];

  allocations2: any[] = [
    { value: "manager activitis 1", viewValue: "manager activitis 1" },
    { value: "manager activitis 2", viewValue: "manager activitis 2" },
    { value: "manager activitis 3", viewValue: "manager activitis 3" },
  ];

  inputTypes1: any[] = [
    { value: "test question 1", viewValue: "test question 1" },
    { value: "test question 2", viewValue: "test question 2" },
    { value: "test question 3", viewValue: "test question 3" },
  ];

  inputTypes2: any[] = [
    { value: "manager qst 1", viewValue: "manager qst 1" },
    { value: "manager qst 2", viewValue: "manager qst 2" },
    { value: "manager qst 3", viewValue: "manager qst 3" },
  ];

  moduleList: any = [];
  clientId: any;
  singleClientData: any;

  ngOnInit(): void {
    const type = this.route.snapshot.paramMap.get("type");

    this.isEditClient = type === "edit" ? true : false;
    this.isViewClient = type === "view" ? true : false;
    this.clientId = sessionStorage.getItem("clientId");
    // console.log("clientId", localStorage.getItem("clientId"));
    // console.log("clientId", this.clientId);

    if (this.isEditClient || this.isViewClient) {
      this.clientId =
        this.isEditClient || this.isViewClient
          ? this.route.snapshot.queryParams["id"]
          : localStorage.getItem("clientId");
    }

    if (this.clientId) {
      this.getClientSelectedModulesByClientId();
      this.getClient();
    } else {
      this.getModuleData();
    }
  }

  getClient() {
    this.onboardingService
      .getClienByClientId(this.clientId)
      .subscribe((res) => {
        this.author = res?.[0]?.authors?.[0];
      });
  }

  onClick(id: string) {
    if (id === "performanceVariation") {
      this.performanceVariation = !this.performanceVariation;
    } else if (id === "governanceAndRoadmaps") {
      this.governanceAndRoadmaps = !this.governanceAndRoadmaps;
    } else if (id === "opMaturityAssessment") {
      this.opMaturityAssessment = !this.opMaturityAssessment;
    } else if (id === "benchmarking") {
      this.benchmarking = !this.benchmarking;
    } else if (id === "processMapping") {
      this.processMapping = !this.processMapping;
    } else if (id === "kpiAdherence") {
      this.kpiAdherence = !this.kpiAdherence;
    } else if (id === "automationAssessment") {
      this.automationAssessment = !this.automationAssessment;
    } else {
      // console.log(`${id} is not valid`);
    }
  }

  selectParticipants() {
    this.createClient({
      teamActivities: this.modules_details_form.value.teamActivities,
      allocation1: this.modules_details_form.value.allocation1,
      question: this.modules_details_form.value.question,
      inputType1: this.modules_details_form.value.inputType1,
      managerActivities: this.modules_details_form.value.managerActivities,
      allocation2: this.modules_details_form.value.allocation2,
      managerOnlyQuestions:
        this.modules_details_form.value.managerOnlyQuestions,
      inputType2: this.modules_details_form.value.inputType2,
    });
    this.modules_details_form.reset();
  }

  createClient(modulesData: object) {
    this.moduleContents.push(modulesData);
  }

  selectModule(selectedValue: any) {
    let isFoundedModule = false;

    if (
      this.clientSelectedModulesData &&
      this.clientSelectedModulesData.length
    ) {
      // for (var i = 0; i < this.clientSelectedModulesData.length; i++) {
      //   if (this.clientSelectedModulesData[i].moduleId === selectedValue.name) {
      //     isFoundedModule = true;
      //     break;
      //   }
      // }
    }

    if (isFoundedModule) {
      // console.log(`Module ${selectedValue.name} already created`);
    } else {
      let index: any;

      for (let i = 0; i < this.selectedModules.length; i++) {
        if (this.selectedModules[i].moduleId === selectedValue.name) {
          index = i;
        }
      }

      if (index > -1) {
        this.selectedModules.splice(index, 1);
      } else {
        this.clientId = this.clientId
          ? this.clientId
          : localStorage.getItem("clientId");
        const payload = {
          clientId: this.clientId,
          moduleId: selectedValue.name,
          description: selectedValue.description,
        };
        this.selectedModules.push(payload);
      }
    }
  }

  module(element: any) {
    element.selected = !element.selected;
    this.cdr.markForCheck();
  }

  createMoudle() {
    this.clientId = sessionStorage.getItem("clientId");
    let modified = this.modules_data_source.filter(
      (x: any) => x.checked
    );
    // modified.push({ name: "Data Diagnostic", description: "" });

    // let payload = {...modified}
    if (!this.isViewClient) {
      // console.log("modified", modified);
      // console.log("selectedModules", this.selectedModules);
      // console.log("selectedModules", this.descModified);
      if (modified.length) {
        modified.forEach((element: any) => {
          let payload: any = {};
          payload.clientId = this.clientId;
          payload.moduleId = element.name;
          payload.description = element.description;

          // console.log("module create payload", payload);

          this.onboardingService.createModule(payload).subscribe((res) => {
            this.getClientSelectedModulesByClientId();
          });
        });

        this.snackBar.open(`Selected modules created`, "close", {
          duration: 2500,
          horizontalPosition: "end",
          verticalPosition: "top",
        });

        localStorage.removeItem("clientId");
        sessionStorage.removeItem("clientId");
        sessionStorage.removeItem("clientId");

        this.router.navigate(["home"]);
      } else {
        this.router.navigate(["home"]);
        // this.snackBar.open(`Please Select At least one Module`, "close", {
        //   duration: 2500,
        //   horizontalPosition: "end",
        //   verticalPosition: "top",
        // });
      }
    } else {
      this.router.navigate(["home"]);
    }
  }

  getClientSelectedModulesByClientId() {
    this.onboardingService
      .getClientSelectedModulesByClientId(this.clientId)
      .subscribe((res) => {

        this.clientSelectedModulesData = res.map((selectedModule: any) => {
          selectedModule.checked = true;
          return selectedModule
        });
        this.getModuleData();
      });
  }

  getModuleData() {
    this.onboardingService.getModuleData("MODULE").subscribe(
      (response: any) => {
        // console.log(response,'<<||<<<');
        for (let i = 0; i < response.length; i++) {
          response[i]["position"] = i + 1;
          // response[i]["previousDesc"] = response[i]["description"]
          // response[i]["description"] = "";
          response[i]["modified"] = false;
          response[i]["clientModuleId"] = response[i]._id;

          if (response[i].name === "Operational Maturity") {
            response[i]["checked"] = true;
            response[i]["modified"] = true;
          }
          if (response[i].name === "Team Leader Interviews") {
            response[i]["checked"] = true;
            response[i]["modified"] = true;
          }
          if (response[i].name === "Surveys") {
            response[i]["checked"] = true;
            response[i]["modified"] = true;
          }

          if ( this.clientSelectedModulesData && this.clientSelectedModulesData.length ) {
            for (let j = 0; j < this.clientSelectedModulesData.length; j++) {
              if ( response[i].name === this.clientSelectedModulesData[j].moduleId ) {
                response[i]["checked"] = true;
                response[i]["clientModuleId"] = this.clientSelectedModulesData[j]._id;
                response[i]["description"] = this.clientSelectedModulesData[j].description;
              }
            }
          }
        }
        this.modules_data_source = response;
        // console.log(this.modules_data_source,'<<');
        
      },
      (error: any) => {
        // console.log("Get Module Data Error:", error);
      }
    );
  }

  onChangeModule(element: any){
    element.checked = !element.checked;
    this.cdr.markForCheck();
  }

  editModule() {

    let modified = this.checkChanges();
    if(!modified || (!modified.addList.length && !modified.removeList.length)){
      this.router.navigate(["home"]);
      return;
    }

    let modifiedModule: any = [];
    modified.addList.forEach((element: any) => {
      let obj: any = {};
      obj.clientId = this.clientId;
      obj.moduleId = element.name;
      obj.description = element?.description ?? "";
      obj.startDate = new Date();
      obj.endDate = new Date();
      obj.owners = this.author;
      modifiedModule.push(obj);
    });

    let payload: any = {clientId: this.clientId, moduleId: "update"};
    if(modifiedModule && modifiedModule.length) payload.addList = modifiedModule;
    if(modified.removeList && modified.removeList.length) payload.removeList = modified.removeList;

    this.onboardingService
        .editModule(payload)
        .subscribe((res: any) => {
          localStorage.removeItem("clientId");

          this.router.navigate(["home"]);
          this.snackBar.open("Module Details Updated Successfully", "close");
          return;
        });
  }

  checkChanges(){

      let modifiedModule: any = {addList: [], removeList: []};
      if(this.clientSelectedModulesData.length != this.modules_data_source.length){
          let res = this.modules_data_source.filter((el: any) => {
            return !this.clientSelectedModulesData.find((element: any) => {
              return element.moduleId === el.name;
            }) && el.checked;
        });
        modifiedModule.addList = [...res];
      }
      for (const currentData of this.modules_data_source) {
        let changedData = this.clientSelectedModulesData.find((selectedModule: any) => {
          return selectedModule["moduleId"] === currentData["name"] && currentData.checked != selectedModule.checked 
        });

        if(changedData) modifiedModule.removeList.push(changedData["_id"]);
      }
      return modifiedModule;
  }
  descModify(element: any, event: any) {
    const description: any = event.target.value;
    element.description = description;
    // if(element.previousDesc !== element.description)this.descModified = true, element.descModified = true
  }
}
