import { Component, OnInit } from "@angular/core";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { QuestionAddDialogComponent } from "@app/masterdata/question-add-dialog/question-add-dialog.component";
import { OnboardingService } from "@app/onboarding/onboarding.service";
import { HomeService } from "@app/home/home/home.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TAGS, TAGS_ObJ } from "@app/resources/question-state";
import { MasterDataService } from "@app/masterdata/master-data.service";
import { FormControl, Validators } from "@angular/forms";
import { WorkflowTemplateService } from "../workflow-template.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-create-template",
  templateUrl: "./create-template.component.html",
  styleUrls: ["./create-template.component.scss"],
})
export class CreateTemplateComponent implements OnInit {
  constructor(
    private onboardingService: OnboardingService,
    private homeService: HomeService,
    private activeRoute: ActivatedRoute,
    private masterDataService: MasterDataService,
    private workflowTemplateService: WorkflowTemplateService,
    private matDialog: MatDialog,
    private router: Router,
    private snackBar: MatSnackBar,
  ) {}

  moduleList: any = [];
  categoryList: any = [];
  clientDetail: any = [];
  clientId: any = "";
  clientModuleData: any = [];
  tagList: any = [];
  referenceData: any = [];
  templateNameControl = new FormControl(null, Validators.required);
  isNoQuestionSelected: boolean = false;
  selectedModuleName: string = "";
  createdTemplateId: string = "";

  ngOnInit(): void {
    this.clientId = this.activeRoute.snapshot.paramMap.get("id");
    this.tagList = TAGS;

    this.getClientDetail();
    this.getModuleData();
    // this.getTagData();
  }

  dialog: any;
  TabIndex: any = 0;
  modules: any = [];
  category: any = [
    "Extra cheese",
    "Mushroom",
    "Onion",
    "Pepperoni",
    "Sausage",
    "Tomato",
  ];
  questionArray: any = [];
  questionPayload: any = [];

  next() {
    this.TabIndex = 1;
  }

  Questions = []; /* TODO: find and resolve this why its used on drop(). */
  /* TODO: This need to set dynamically. */
  Tag1 = [];
  Tag2 = [];
  Tag3 = [];
  Tag4 = [];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.Questions, event.previousIndex, event.currentIndex);
  }

  onDrop(event: CdkDragDrop<any>) {
    this.questionArray.push(event.container.data);

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  goTo() {
    this.dialog = this.matDialog.open(QuestionAddDialogComponent, {
      width: "70em",
      // height: '450px',
      disableClose: false,
      data: {
        from: "survey",
      },
    });

    this.dialog.afterClosed().subscribe((submit: any) => {});
  }

  getClientDetail() {
    this.homeService.getClients(this.clientId).subscribe(
      (response: any) => {
        this.clientDetail = response[0];
      },
      (error: any) => {
        // console.log("-- Error while get client detail API --", error);
      }
    );
  }

  getModuleData() {
    this.onboardingService.getModuleData("MODULE").subscribe(
      (response) => {
        this.clientModuleData = response;
      },
      (error) => {
        // console.log("Error on get module list: ", error);
      }
    );
  }

  onModuleSelect(selection: any) {
    this.selectedModuleName = selection.value;
    this.getMasterData(this.selectedModuleName);
  }

  getMasterData(moduleName: string = "") {
    this.masterDataService.getMasterDataByQuery("module", moduleName).subscribe(
      (response: any) => {
        this.referenceData = response.data;
      },
      (error: any) => {
        // console.log("-- Error on get Master data API --", error);
      }
    );
  }

  onSave() {
    let questionPayload: any = [];

    this.questionArray.map((e1: any) => {
      e1.map((e2: any) => { 
        if(!questionPayload.includes(e2.question))
          questionPayload.push(e2.question);
      });
    });

    if (this.templateNameControl.status === "INVALID") {
      this.templateNameControl.markAllAsTouched();
      return false;
    }

    if (
      !this.Tag1.length &&
      !this.Tag2.length &&
      !this.Tag3.length &&
      !this.Tag4.length
    ) {
      this.isNoQuestionSelected =
        true; /* TODO: Display the error message on UI, minimum select one question. */
      return false;
    }

    let questionData: any = [];
    let tagData = [];
    if (this.Tag1.length) {
      tagData.push(TAGS_ObJ["AVA"]);
      let avaTag = this.Tag1.map((tag) => {
        return { tag: TAGS_ObJ["AVA"], refId: tag["_id"] };
      });
      questionData = [...questionData, ...avaTag];
    }
    if (this.Tag2.length) {
      tagData.push(TAGS_ObJ["EVA"]);
      let evaTag = this.Tag2.map((tag) => {
        return { tag: TAGS_ObJ["EVA"], refId: tag["_id"] };
      });

      questionData = [...questionData, ...evaTag];
    }
    if (this.Tag3.length) {
      tagData.push(TAGS_ObJ["FREETEXT"]);
      let freeTextTag = this.Tag3.map((tag) => {
        return { tag: TAGS_ObJ["FREETEXT"], refId: tag["_id"] };
      });
      questionData = [...questionData, ...freeTextTag];
    }
    if (this.Tag4.length) {
      tagData.push(TAGS_ObJ["MANAGERSONLY"]);
      let managerOnlyTag = this.Tag4.map((tag) => {
        return { tag: TAGS_ObJ["MANAGERSONLY"], refId: tag["_id"] };
      });
      questionData = [...questionData, ...managerOnlyTag];
    }

    let payload = {
      templateName: this.templateNameControl.value,
      module: this.selectedModuleName,
      tags: tagData,
      questions: questionPayload,
    };

    this.workflowTemplateService.createTemplate(payload).subscribe({
      next: (res) => {
        this.createdTemplateId = res;
        this.snackBar.open("Template Created Successfully", 'close',{
          duration: 4500,
          horizontalPosition: 'center',
          verticalPosition: 'top',
        });
  
        this.router.navigate([`/template/${this.createdTemplateId}/preview`]);
      },
      error: (err) => {
        // console.log(`Create template Error: ${err}`);
      },

      complete: () => {},
    });
    return true;
  }
}
