import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";

@Injectable({
  providedIn: "root",
})
export class WorkflowTemplateService {
  constructor(private httpClient: HttpClient) {}
  baseUrl: string = environment.STATIC_API_URL;
  surveyUrl: string = environment.SURVEY_API_URL;
  clientUrl: string = environment.CLIENT_API_URL;

  getTemplateData(pageNumber: number = 0, pagePerItem: number = 5) {
    pageNumber = pageNumber ? pageNumber - 1 : pageNumber;
    let url = `${this.baseUrl}template?size=${pagePerItem}&pageNumber=${pageNumber}`;

    return this.httpClient.get<any>(url);
  }

  getTemplateByQuery(propName: string = "", propValue: string = "") {
    let url = `${this.baseUrl}template?${propName}=${propValue}`;
    return this.httpClient.get<any>(url);
  }
  getTemplateByQuery_DB(
    propName: string = "",
    propValue: string = "",
    module: string
  ) {
    let url = `${this.baseUrl}template?${propName}=${propValue}&module=${module}`;
    return this.httpClient.get<any>(url);
  }

  getTemplateByQueryOne(query: Object = {}) {
    // console.log("--- query On template service ----", query);

    return this.httpClient.post<any>(`${this.baseUrl}template/query`, query);
  }

  createTemplate(payload: Object = {}) {
    return this.httpClient.post<any>(`${this.baseUrl}template`, payload);
  }

  getTemplatesByModule(module: string) {
    return this.httpClient.get<any>(
      `${this.baseUrl}template?module=${module}&size=100&pageNumber=0`
    );
  }

  getTemplatesByTemplateId(templateId: any) {
    return this.httpClient.get<any>(
      `${this.baseUrl}template?id=${templateId}&size=100&pageNumber=0`
    );
  }

  updateTemplate(templateId: string, payload: any) {
    return this.httpClient.put<any>(
      `${this.baseUrl}template/${templateId}`,
      payload
    );
  }

  getSurveyById(id: string) {
    return this.httpClient.get<any>(
      `${this.surveyUrl}survey?id=${id}&size=100&pageNumber=0`
    );
  }

  getActivity(clientId: any) {
    return this.httpClient.get<any>(`${this.clientUrl}activity/${clientId}`);
  }

  getActivityById(clientId: any, id: any) {
    return this.httpClient.get<any>(
      `${this.clientUrl}activity/${clientId}?id=${id}`
    );
  }

  createActivity(payload: any) {
    return this.httpClient.post<any>(`${this.clientUrl}activity`, payload);
  }

  updateActivity(id: string, payload: any) {
    return this.httpClient.put<any>(`${this.clientUrl}activity/${id}`, payload);
  }

  deleteActivity(id: any) {
    return this.httpClient.delete<any>(`${this.clientUrl}activity/${id}`);
  }

  getClientFunctions(clientId: any) {
    return this.httpClient.get<any>(
      `${this.clientUrl}client/functions?clientId=${clientId}`
    );
  }
}
