import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OpMaturityComponent } from "./op-maturity.component";
import { SharedModule } from "@app/shared/shared.module";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "@app/core/guards/auth.guard";
import { OpMaturityScoringComponent } from "./op-maturity-scoring/op-maturity-scoring.component";
import { OpMaturityOutputsComponent } from "./op-maturity-outputs/op-maturity-outputs.component";
import { OpMaturityWeightageComponent } from "./op-maturity-weightage/op-maturity-weightage.component";
import { CoreModule } from "@app/core/core.module";
import { OpMaturitySetupComponent } from "./op-maturity-setup/op-maturity-setup.component";

const route: Routes = [
  {
    path: "",
    component: OpMaturityComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path:"setup",
        component: OpMaturitySetupComponent,
      },
      {
        path: "scoring",
        component: OpMaturityScoringComponent,
      },
      {
        path: "Weightage",
        component: OpMaturityWeightageComponent,
      },
      {
        path: "outputs",
        component: OpMaturityOutputsComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    OpMaturityComponent,
    OpMaturitySetupComponent,
    OpMaturityScoringComponent,
    OpMaturityOutputsComponent,
    OpMaturityWeightageComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    CoreModule,
    SharedModule,
    RouterModule.forChild(route),
  ],
  exports: [OpMaturityOutputsComponent],
})
export class OpMaturityModule {}
